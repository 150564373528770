import { Loading } from 'components'
import { useContext, useEffect } from 'react'
import { Container, Row } from 'reactstrap'
import AuthContext from './../../AuthContext'

const Login = () => {
  const { isLoggedIn } = useContext(AuthContext)

  useEffect(() => {
    const redir = async () => {
      if (isLoggedIn) {
        window.location.href = '/'
        return
      }

      const redirUrl = encodeURIComponent(window.location.href)
      window.location.href = `${global.appConfig.wpBaseUrl}/wp-login.php?redirect_to=${redirUrl}`
    }

    redir()
  }, [isLoggedIn])

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Loading />
        </Row>
      </Container>
    </div>
  )
}

export default Login
