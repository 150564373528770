import type { BaseRow } from 'components/Table/index'
import type { ColumnDef } from 'components/Table/types'
import type { Identifier, XYCoord } from 'dnd-core'
import get from 'lodash/get'
import { Fragment, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import type { TableRowProps } from './TableRow'
import { defaultCellRenderer } from './defaultCellRenderer'

type TableRowDropTargetProps<RowType extends BaseRow> =
  TableRowProps<RowType> & {
    id: string
    index: number
    moveItem: (dragIndex: number, hoverIndex: number) => void
  }

// Based on https://react-dnd.github.io/react-dnd/examples/sortable/simple
const TableRow = <RowType extends BaseRow>(
  props: TableRowDropTargetProps<BaseRow>,
) => {
  const {
    id,
    index,
    columnDef,
    row,
    onChangeCell,
    editCell,
    cellsLoading,
    dispatch,
    renderCell,
    renderRowClassName,
    moveItem,
    isSelectedRow,
  } = props

  const ref = useRef<HTMLTableRowElement>(null)
  const [{ handlerId }, drop] = useDrop<
    TableRowDropTargetProps<RowType>,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'row',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: TableRowDropTargetProps<BaseRow>, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'row',
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const rowClassName = renderRowClassName ? renderRowClassName({ row }) : null
  const selClassName = isSelectedRow ? 'selected' : ''
  const className = `${rowClassName} ${selClassName}`

  return (
    <tr
      data-handler-id={handlerId}
      ref={ref}
      data-id={row.id}
      className={className}
      style={{ opacity }}
    >
      {columnDef.map((col: ColumnDef) => {
        const renderer = col.renderer || renderCell || defaultCellRenderer
        const value = col.valueColumn ? get(row, col.valueColumn) : row[col.id]
        const isEditing = editCell
          ? editCell.rowId === row.id && editCell.colId === col.id
          : false
        const isLoading = cellsLoading && Object.hasOwn(cellsLoading, col.id)

        const renderProps = {
          isLoading,
          isEditing,
          value,
          col,
          row,
          onChangeCell,
          dispatch,
          isSelectedRow,
        }

        return <Fragment key={col.id}>{renderer(renderProps)}</Fragment>
      })}
    </tr>
  )
}

export default TableRow
