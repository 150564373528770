import { TagsCell } from 'components'
import ProductStatusBadge from 'components/Badges/ProductStatusBadge'
import type { ColumnDef } from 'components/Table/types'

const checkMark = (value: boolean) => {
  return value ? (
    <i style={{ color: '#256c2b' }} className="fa fa-check-circle" />
  ) : (
    <i className="text-danger fa fa-times-circle" />
  )
}

const boolRenderer = ({ value }) => {
  return <td>{checkMark(value === 'Y')}</td>
}

const columnDef: ColumnDef[] = [
  {
    label: '',
    id: 'checkbox',
    sortColumn: false,
    canExport: false,
  },
  {
    label: 'ID',
    id: 'id',
    sortColumn: true,
    defaultVisible: false,
  },
  {
    label: 'Tags',
    id: 'contentTags',
    sortColumn: false,
    renderer: ({ value }) => (value ? <TagsCell {...{ value }} /> : <td></td>),
    canExport: false,
  },
  {
    label: 'Status',
    id: 'status',
    sortColumn: false,
    defaultVisible: true,
    renderer: ({ value }) => {
      return (
        <td>
          <ProductStatusBadge value={String(value)} />
        </td>
      )
    },
  },
  {
    label: 'Name',
    id: 'name',
    sortColumn: true,
  },
  {
    label: 'Botanical name',
    id: 'botanicalName',
    defaultVisible: true,
    sortColumn: true,
  },
  {
    label: 'SKU',
    id: 'sku',
    sortColumn: true,
    hasClipboardButton: true,
  },
  {
    label: 'Web',
    id: 'numWebParent',
    sortColumn: true,
    defaultVisible: false,
  },
  {
    label: 'Brand',
    id: 'pa_brand',
    sortColumn: true,
    defaultVisible: false,
  },
  {
    label: 'Growing Zone',
    id: 'pa_growing-zone',
    defaultVisible: false,
  },
  {
    label: 'Mature Height',
    id: 'matureHeight',
    sortColumn: true,
    defaultVisible: false,
  },
  {
    label: 'Mature Width',
    id: 'matureWidth',
    sortColumn: true,
    defaultVisible: false,
  },
  {
    label: 'Complete',
    id: 'complete',
    renderer: boolRenderer,
  },
  {
    label: 'Content',
    id: 'hasMainContent',
    renderer: boolRenderer,
  },
  {
    label: 'ShortDesc',
    id: 'hasShortDesc',
    renderer: boolRenderer,
  },
  {
    label: 'TreeInfo',
    id: 'hasTreeInfo',
    renderer: boolRenderer,
  },
  {
    label: 'Image',
    id: 'hasImage',
    renderer: boolRenderer,
  },
  {
    label: 'SEO',
    id: 'hasSeo',
    renderer: boolRenderer,
  },
  {
    label: 'ContentLink',
    id: 'hasContentLink',
    defaultVisible: false,
    renderer: ({ row }) => {
      return (
        <td>
          {checkMark(!!row.contentLink)}

          {row.contentLink ? (
            <a
              title="Link to content document"
              target="_blank"
              rel="noreferrer"
              href={row.contentLink}
            >
              <i className="ml-2 fa fa-external-link" />
            </a>
          ) : null}
        </td>
      )
    },
  },
  {
    label: 'Categories',
    id: 'categories',
  },
  {
    label: 'Collections',
    id: 'collections',
  },
  {
    label: 'Deep Link',
    id: 'deepLink',
    defaultVisible: true,
    exportOnly: true,
  },
  {
    label: 'Warranty',
    id: 'isWarrantyAvailable',
    renderer: boolRenderer,
  },
]

export default columnDef
