import type { FormDef } from 'app/components/Form/types'
import { EditPanel, EditPanelCard, FormRenderer } from 'components'
import { getFormInitState, useApi, useFormWithDef, usePrevious } from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { Col, Row } from 'reactstrap'

const formDefTemplate: FormDef = [
  {
    label: 'Amount in USD',
    name: 'amount',
    type: 'text',
  },
  {
    label: 'Create Note',
    name: 'note',
    type: 'text',
  },
  {
    label: 'Free Shipping',
    name: 'free_shipping',
    type: 'checkbox',
    defaultValue: false,
  },
]

const AddPanel = ({
  isOpen,
  onClose,
  defaultValues,
}: {
  isOpen: boolean
  onClose: (id?: string, isNew?: boolean) => void
  defaultValues?: any
}) => {
  const formDef = useMemo(() => {
    return [...formDefTemplate]
  }, [])

  const form = useFormWithDef(formDef)

  const apiAdd = useApi({ action: 'giftCards_create' })

  // Reset form as soon as panel opens.
  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (prevIsOpen !== isOpen) {
      if (isOpen) {
        const initState = getFormInitState(formDef)
        form.reset({ ...initState, ...defaultValues })
      } else {
        form.reset()
      }
    }
  }, [form, prevIsOpen, isOpen, formDef, defaultValues])

  const handleSubmit = useCallback(async () => {
    const formSubmit = async () => {
      const json = { ...form.state }

      const ret = await apiAdd.performRequest({ json })

      if (ret) {
        onClose(ret.id)
      }
    }

    formSubmit()
  }, [apiAdd, form.state, onClose])

  const isLoading = apiAdd.isLoading

  return (
    <EditPanel
      onSubmit={handleSubmit}
      caption="Add Gift Card"
      submitButtonLabel="Create Gift Card"
      {...{ isOpen, onClose, isLoading }}
    >
      <EditPanelCard caption="Details">
        {apiAdd.error ? (
          <Row>
            <Col className="text-danger">{apiAdd.error}</Col>
          </Row>
        ) : null}
        <FormRenderer key={`${isOpen ? 1 : 0}`} {...{ form, formDef }} />
      </EditPanelCard>
    </EditPanel>
  )
}

export default AddPanel
