import { getApiBaseUrl } from 'api'
import type { Column } from 'components/Table/reducer/types'
import { useDropDownToggler } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

const ExportActionButton = ({
  query,
  requestProps,
  sortColumn,
  sortAsc,
  columns,
}: {
  query: string
  requestProps: Record<string, string>
  sortColumn: string
  sortAsc: boolean
  columns: any
}) => {
  const toggler = useDropDownToggler()

  const handleClickExport = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      const format = (e.target as HTMLElement).getAttribute('data-format')

      const visibleColumns = columns.visibleSorted.filter(
        (column: Column) => column.canExport !== false,
      )

      const params = {
        ...requestProps,
        query,
        sortColumn,
        sortAsc,
        columns: visibleColumns,
      }

      const paramsEncoded = encodeURIComponent(JSON.stringify(params))

      let url = `${getApiBaseUrl()}/?action=treeadmin_returns_export`
      url += `&params=${paramsEncoded}`
      url += `&format=${format}`

      if (e.shiftKey) {
        url = `${url}&text=1`
      }

      window.open(url)
    },
    [requestProps, query, sortColumn, sortAsc, columns],
  )

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret>
        Export
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem data-format="csv" onClick={handleClickExport}>
          Export CSV
        </DropdownItem>
        <DropdownItem data-format="xlsx" onClick={handleClickExport}>
          Export Excel
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ExportActionButton
