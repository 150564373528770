import type { FormDef, FormDefElement } from 'components/Form/types'
import { EditPanel, EditPanelCard, FormRenderer, Loading } from 'components'
import { useApi, useFormWithDef, usePrevious, useStateful } from 'hooks'
import { Fragment, useCallback, useEffect, useMemo } from 'react'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { askQuestion } from 'utils'

const addQuantityFormDef: FormDef = [
  {
    label: 'Quantity',
    placeholder: '',
    name: 'qty',
    type: 'number',
    defaultValue: '10',
  },
  {
    label: 'Discount in %',
    placeholder: '',
    name: 'discount_percentage',
    type: 'number',
    defaultValue: '20',
  },
]

const formDef: FormDef = [
  {
    label: 'Enabled',
    placeholder: '',
    name: 'enabled',
    type: 'checkbox',
    defaultValue: '',
  },
  {
    label: 'Internal Name',
    placeholder: '',
    name: 'name',
    type: 'text',
    defaultValue: '',
  },
  {
    label: 'Internal Description',
    placeholder: '',
    name: 'description',
    type: 'text',
    defaultValue: '',
  },
]

const CardActions = ({ handleClickDelete }) => {
  return (
    <EditPanelCard caption="Actions" defaultIsOpen={false}>
      <Row>
        <Col>
          <FormGroup className="form-actions mt-3 text-center">
            <Button
              name="delete"
              size="lg"
              type="button"
              color="danger"
              onClick={handleClickDelete}
            >
              Delete Discount
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </EditPanelCard>
  )
}

type DiscountEditorProps = {
  isReserved: boolean
  readOnly: boolean
  form: any
  editingQuantity: any
}

const DiscountEditor = (props: DiscountEditorProps) => {
  const { editingQuantity, readOnly, form } = props

  const addQuantityForm = useFormWithDef(addQuantityFormDef)

  const handleClickConfirm = useCallback(() => {
    if (addQuantityForm.state.qty <= 1) {
      return
    }
    if (addQuantityForm.state.discount_percentage <= 0) {
      return
    }

    const { [editingQuantity.value]: _, ...nitems } = form.state.items || []
    const items = {
      [addQuantityForm.state.qty]: addQuantityForm.state.discount_percentage,
      ...nitems,
    }
    form.set('items', items)
    editingQuantity.set(null)
  }, [form, addQuantityForm, editingQuantity])

  const handleAddQuantityDiscount = useCallback(() => {
    addQuantityForm.reset()
    editingQuantity.set(0)
  }, [editingQuantity, addQuantityForm])

  const handleClickCancel = useCallback(() => {
    editingQuantity.set(null)
  }, [editingQuantity])

  const handleClickTrash = useCallback(() => {
    const { [addQuantityForm.state.qty]: _, ...items } = form.state.items
    form.set('items', items)
    editingQuantity.set(null)
  }, [form, editingQuantity, addQuantityForm])

  const editQuantityDiscount = (
    quantity: number,
    discount_percentage: number,
  ) => {
    addQuantityForm.setState({ qty: quantity, discount_percentage })
    editingQuantity.set(quantity)
  }

  const EditQuantity = (
    <>
      <div className="d-flex" style={{ gap: 10 }}>
        <Input
          bsSize="sm"
          name="qty"
          type="number"
          min={2}
          value={addQuantityForm.state.qty}
          placeholer="Quantity"
          {...{ readOnly, ...addQuantityForm.inputProps }}
        />
        <Input
          bsSize="sm"
          name="discount_percentage"
          type="number"
          min={1}
          max={100}
          value={addQuantityForm.state.discount_percentage}
          placeholer="Discount"
          {...{ readOnly, ...addQuantityForm.inputProps }}
        />
        %
        <Button
          title="Confirm"
          size="sm"
          color="success"
          className="ml-2"
          type="submit"
          onClick={handleClickConfirm}
        >
          <i className="fa fa-check" />
        </Button>
        <Button
          title="Cancel"
          size="sm"
          type="button"
          onClick={handleClickCancel}
        >
          <i className="fa fa-times" />
        </Button>
        {editingQuantity.value !== 0 ? (
          <Button
            title="Delete"
            size="sm"
            type="button"
            color="danger"
            onClick={handleClickTrash}
          >
            <i className="fa fa-trash" />
          </Button>
        ) : null}
      </div>
    </>
  )

  const formDef_ = useMemo(() => {
    return (formDef as Array<FormDefElement>).map((field) => {
      if (
        (field.name === 'name' || field.name === 'description') &&
        form.state.isReserved
      ) {
        return { ...field, readOnly: true }
      }
      if (field.name === 'enabled' && form.state.canEditConfig === false) {
        return { ...field, readOnly: true }
      }

      return field
    })
  }, [form])

  return (
    <EditPanelCard caption="Discount" stateId="discount">
      <FormRenderer {...{ form, formDef: formDef_, readOnly }} />
      {form.state.canEditConfig !== false ? (
        <FormGroup>
          <Label className="mb-0" htmlFor="input-config">
            Configuration
          </Label>
          <ul className="pl-3">
            {Object.keys(form.state.items || []).map((quantity) => (
              <li key={quantity}>
                {editingQuantity.value === Number(quantity) ? (
                  EditQuantity
                ) : (
                  <Button
                    className="p-0 m=0"
                    color="link"
                    type="button"
                    size="sm"
                    disabled={readOnly}
                    onClick={() =>
                      editQuantityDiscount(
                        Number(quantity),
                        form.state.items[quantity],
                      )
                    }
                  >
                    {quantity} Pack → {form.state.items[quantity]}% off
                  </Button>
                )}
              </li>
            ))}
            {!readOnly && editingQuantity.value !== 0 ? (
              <li>
                <Button
                  className="p-0 m=0"
                  color="link"
                  type="button"
                  size="sm"
                  onClick={handleAddQuantityDiscount}
                >
                  Add quantity discount
                </Button>
              </li>
            ) : null}
            {editingQuantity.value === 0 ? <li>{EditQuantity}</li> : null}
          </ul>
        </FormGroup>
      ) : null}
      <div className="clearfix" />
    </EditPanelCard>
  )
}

type EditDiscountPanelProps = {
  readOnly: boolean
  isOpen: boolean
  onClose: () => void
  editId: string
  rowData?: any
}

const EditDiscountPanel = (props: EditDiscountPanelProps) => {
  const { readOnly, isOpen, onClose, editId } = props

  const caption = `${editId == null ? 'Add' : 'Edit'} Quantity Discount`

  const form = useFormWithDef(formDef)

  const apiLoad = useApi(null, null, { errorModal: true })
  const apiWrite = useApi(null, null, { errorModal: true })

  const editingQuantity = useStateful<number | null>(null)

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    const fetchData = async () => {
      const values = await apiLoad.performRequest({
        action: 'discounts_getRow',
        json: { id: editId },
      })
      form.setState({ ...values, enabled: values.status === 'ACTIVE' })
    }

    if (isOpen && isOpen !== prevIsOpen) {
      if (editId == null) {
        form.reset()
      } else {
        fetchData()
      }
    }
  }, [form, apiLoad, prevIsOpen, isOpen, editId])

  const handleSubmit = useCallback(async () => {
    if (editingQuantity.value != null) {
      return
    }

    await apiWrite.performRequest({
      action: 'discounts_setRow',
      json: {
        id: editId,
        type: 'QUANTITY',
        ...form.state,
      },
    })

    onClose()
  }, [onClose, form, apiWrite, editId, editingQuantity])

  const canSubmit = form.state.name !== '' && editingQuantity.value == null

  const { isReserved } = form.state

  const isLoading = apiLoad.isLoading || apiWrite.isLoading

  const handleClickDelete = useCallback(async () => {
    if (
      !(await askQuestion('Are you sure you want to delete this discount?'))
    ) {
      return
    }

    await apiWrite.performRequest({
      action: 'discounts_trashRow',
      json: { id: editId },
    })

    onClose()
  }, [apiWrite, onClose, editId])

  return (
    <EditPanel
      onSubmit={readOnly || isLoading ? null : handleSubmit}
      {...{ isOpen, onClose, caption, isLoading, canSubmit }}
    >
      {apiLoad.isLoading ? (
        <Loading />
      ) : isOpen ? (
        <Fragment key={JSON.stringify({ isOpen, editId })}>
          {!isReserved && !readOnly && editId != null ? (
            <CardActions {...{ handleClickDelete }} />
          ) : null}
          <DiscountEditor
            {...{
              readOnly,
              form,
              editingQuantity,
              isReserved,
            }}
          />
          {apiWrite.isLoading ? <Loading /> : null}
        </Fragment>
      ) : null}
    </EditPanel>
  )
}

export default EditDiscountPanel
