import { Button, Col, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Alert = withReactContent(Swal)

const Playground = () => {
  const handleClickAlert = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    Alert.fire({
      icon: 'error',
      title: <p>Error message</p>,
    })
  }

  return (
    <div className="animated fadeIn">
      <h2 className="mb-4">Playground</h2>
      <Row>
        <Col>
          <Button size="lg" color="primary" onClick={handleClickAlert}>
            Open Alert
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default Playground
