import type { UseSidebarResult } from 'hooks/useSidebar'
import { Button } from 'reactstrap'

const SidebarToggleButton = ({ sidebar }: { sidebar: UseSidebarResult }) => {
  const isLocked = !sidebar.isAutoToggle

  if (sidebar.isOpen) {
    return null
  }

  return (
    <Button
      style={{ position: 'relative', color: isLocked ? '#aaa' : '' }}
      color={!isLocked ? 'link' : ''}
      onClick={sidebar.userToggle}
    >
      <i className="icon-list" />
      {isLocked && (
        <span
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            color: 'black',
            fontWeight: 'black',
            fontSize: 11,
          }}
        >
          <i className="icon-lock" />
        </span>
      )}
    </Button>
  )
}

export default SidebarToggleButton
