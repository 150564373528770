import type { Mail } from '@ttc/api/orders'
import { useCallback, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { formatDateReadable } from 'utils/date'
import ResendMailForm from './ResendMailForm'

type MailRowProps = {
  mail: Mail
  onClick: (mail: Mail) => void
  onResent: () => void
  canResend: boolean
}

const MailRow = ({ mail, onClick, onResent, canResend }: MailRowProps) => {
  const [resendMode, setResendMode] = useState<boolean>(false)

  const handleClickMail = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()

      onClick(mail)
    },
    [mail, onClick],
  )

  const handleClickResend = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()

      setResendMode(true)
    },
    [],
  )

  const handleCancelResend = useCallback(() => {
    setResendMode(false)
  }, [])

  return (
    <Row key={mail.id} className="mb-2">
      <Col>
        <div>
          {mail.has_message_data ? (
            <a href="#" onClick={handleClickMail}>
              <i className="fa fa-envelope mr-1" />
              {mail.subject}
            </a>
          ) : (
            <span>
              <i className="fa fa-envelope mr-1" />
              {mail.subject}
            </span>
          )}
        </div>
        <div className="font-italic" style={{ fontSize: 11 }}>
          To {mail.recipient} on {formatDateReadable(mail.date, true)}
          {canResend && !resendMode ? (
            <a className="ml-1" href="#" onClick={handleClickResend}>
              (resend)
            </a>
          ) : null}
        </div>
        {resendMode ? (
          <ResendMailForm
            {...{ mail }}
            defaultRecipient={mail.recipient}
            onClose={handleCancelResend}
            onResent={onResent}
          />
        ) : (
          mail.resents.map((resent) => {
            return (
              <div
                key={resent.id}
                className="font-italic"
                style={{ fontSize: 11 }}
              >
                ↪ Resent to {resent.recipient} on{' '}
                {formatDateReadable(resent.date, true)}
              </div>
            )
          })
        )}
      </Col>
    </Row>
  )
}

export default MailRow
