import { CellEditable } from 'components/Table'
import type { ColumnDef } from 'components/Table/types'
import keyBy from 'lodash/keyBy'
import { formatDateReadable } from 'utils'

const truckStatusColorClass = {
  NEW: 'text-danger',
  SCHEDULED: 'text-danger',
  OTW: 'text-warning',
  DOCK: 'text-warning',
  COMPLETE: 'text-success',
}

const truckStatusOptions = [
  { label: 'NEW', value: 'NEW' },
  { label: 'SCHEDULED', value: 'SCHEDULED' },
  { label: 'OTW', value: 'OTW' },
  { label: 'PUTAWAY', value: 'DOCK' },
  { label: 'COMPLETE', value: 'COMPLETE' },
]

const truckStatusOptionsByValue = keyBy(truckStatusOptions, 'value')

export const renderTruckStatusValue = (value: string) => {
  const className = truckStatusColorClass[value] || 'text-danger'

  return (
    <>
      <span className={className}>●</span>
      {truckStatusOptionsByValue[value].label}
    </>
  )
}

export const getColumnDef = (readOnly: boolean) => {
  const isEditable = !readOnly

  const columnDef: ColumnDef[] = [
    {
      thStyle: { width: 100 },
      label: 'No',
      id: 'reference_number',
      sortColumn: true,
      isEditable,
      renderValue: (_value, props) => {
        return (
          <span title={`Unique ID: ${props.row.id}`}>
            {props.row.reference_number}
          </span>
        )
      },
      renderer: !readOnly
        ? (props) => {
            return <CellEditable {...props} />
          }
        : null,
    },
    {
      thStyle: { width: 250 },
      label: 'Status',
      id: 'status',
      sortColumn: true,
      isSelect: true,
      selectOptions: truckStatusOptions,
      renderValue: renderTruckStatusValue,
      isEditable: !readOnly,
      renderer: (props) => {
        return readOnly ? (
          <td>{renderTruckStatusValue(props.value)}</td>
        ) : (
          <CellEditable {...props} />
        )
      },
    },
    {
      thStyle: { width: 200 },
      label: 'Date',
      id: 'date',
      sortColumn: true,
      isDate: true,
      isEditable,
      renderer: !readOnly
        ? (props) => {
            return <CellEditable {...props} />
          }
        : (props) => {
            return <td>{formatDateReadable(props.value)}</td>
          },
    },
  ]

  return columnDef
}
