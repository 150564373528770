import { useDropDownToggler } from 'hooks'
import loescape from 'lodash/escape'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

const getShortcodeFromRequestProps = (
  query: string,
  requestProps: Record<string, string>,
) => {
  const filterStrings = []

  let i = 0

  if (query) {
    filterStrings.push(`query="${loescape(query)}"`)
  }

  for (const filter in requestProps) {
    const key = `filter_${i}`
    filterStrings.push(`${key}_key="${filter}"`)
    filterStrings.push(`${key}_value="${loescape(requestProps[filter])}"`)

    i += 1
  }

  return `[ttc_products limit="4" columns="2" orderby="popularity" ${filterStrings.join(
    ' ',
  )}]`
}

const ShortcodeActionButton = ({ query, filters }) => {
  const toggler = useDropDownToggler()

  const handleClickCopy = useCallback(async () => {
    const shortcode = getShortcodeFromRequestProps(query, filters.requestProps)

    try {
      navigator.clipboard.writeText(shortcode)
    } catch (_e) {
      /* Ignore */
    }
  }, [query, filters.requestProps])

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret>
        Shortcode
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickCopy}>Copy</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ShortcodeActionButton
