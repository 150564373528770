import AuthContext from 'AuthContext'
import { MANAGERETURNS_READ } from 'caps'
import { useCallback, useContext } from 'react'

const LinkToReturn = ({ onClick, returnId, label }) => {
  const { hasCap } = useContext(AuthContext)
  const canOpen = hasCap(MANAGERETURNS_READ)

  const handleClick = useCallback(
    (e) => {
      e.preventDefault()
      onClick(returnId)
    },
    [onClick, returnId],
  )

  if (!canOpen || !onClick) {
    return <>{label}</>
  }

  return (
    <a target="noopener" href="#" onClick={handleClick}>
      {label}
    </a>
  )
}

export default LinkToReturn
