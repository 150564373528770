import { useDropDownToggler } from 'hooks'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

type AddActionButtonProps = {
  onAddVariation: () => void
  onAddSimple: () => void
}

const AddActionButton = (props: AddActionButtonProps) => {
  const { onAddVariation, onAddSimple } = props

  const toggler = useDropDownToggler()

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret>
        Add
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={onAddVariation}>
          Variable Product with Variation
        </DropdownItem>
        <DropdownItem onClick={onAddSimple}>
          Simple Product (Addon)
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default AddActionButton
