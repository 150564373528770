import NumericFilterButton from 'components/Filters/NumericFilterButton'
import { useDropDownToggler } from 'hooks'
import { useCallback, useMemo } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

const AddFilterButton = ({ label, filter, filters }) => {
  const handleClick = useCallback(() => {
    const defaultValue = ['=', '0']

    filters.addFilter(filter, defaultValue)
  }, [filters, filter])

  return <DropdownItem onClick={handleClick}>{label}</DropdownItem>
}

const qtyFilters = [
  { label: 'GRND', filter: 'numGround' },
  { label: 'TO_PULL', filter: 'numAwaitingPull' },
  { label: 'WEB', filter: 'numWeb' },
  { label: 'ORDRD', filter: 'numOrdered' },
  { label: 'LISTED', filter: 'numListed' },
  { label: 'PRELISTED', filter: 'numPrelisted' },
  { label: 'IN_BATCH', filter: 'numInBatch' },
  { label: 'IDEAL', filter: 'numIdeal' },
  { label: 'NEED', filter: 'numNeed' },
  { label: 'REVENUE', filter: 'revenue' },
  { label: 'QTY SOLD', filter: 'qtySold' },
]

type QuantityFilterButtonProps = {
  filters: FiltersType
  hiddenColumns: string[]
}

const QuantityFilterButton = (props: QuantityFilterButtonProps) => {
  const { filters, hiddenColumns } = props

  const toggler = useDropDownToggler()

  const filteredQtyFilters = useMemo(
    () =>
      qtyFilters.filter((filter) => {
        return !(hiddenColumns || []).includes(filter.filter)
      }),
    [hiddenColumns],
  )

  return (
    <>
      {filteredQtyFilters
        .filter((qtyFilter) => filters.has(qtyFilter.filter))
        .map((qtyFilter) => {
          return (
            <NumericFilterButton
              key={qtyFilter.filter}
              {...{
                filter: filters[qtyFilter.filter],
                label: qtyFilter.label,
              }}
            />
          )
        })}
      <Dropdown nav {...toggler}>
        <DropdownToggle nav caret>
          <span>Quantity</span>
        </DropdownToggle>
        <DropdownMenu>
          {filteredQtyFilters.map((qtyFilter) => {
            return (
              <AddFilterButton
                {...{ filters }}
                key={qtyFilter.filter}
                label={qtyFilter.label}
                filter={qtyFilter.filter}
              />
            )
          })}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default QuantityFilterButton
