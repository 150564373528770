import StatusFilterButton from './StatusFilterButton'

export type ManageBatchesFiltersType = FiltersTypeWithUrlUpdater & {
  status: FilterType
}

export const Filters = (props: {
  filters: ManageBatchesFiltersType
  batchStatuses: string[]
}) => {
  const { filters, batchStatuses } = props

  return (
    <>
      <StatusFilterButton {...{ filters, batchStatuses }} />
    </>
  )
}

export default Filters
