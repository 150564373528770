import { ClipboardButton, GiftCardStatusBadge, LinkButton } from 'components'
import GiftCardTypeBadge from 'components/Badges/GiftCardTypeBadge'
import type { ColumnDef } from 'components/Table/types'
import { customerCellRenderer } from 'components/cellRenderer'
import { formatDateReadable, formatPrice } from 'utils'
import { toCents } from 'utils/price'

const columnDef: ColumnDef[] = [
  {
    label: '',
    id: 'checkbox',
  },
  {
    label: 'ID',
    id: 'id',
    sortColumn: true,
  },
  {
    label: 'Code',
    id: 'code',
    sortColumn: false,
    renderer: ({ value, row, col }) => {
      const isActive = Number(row.active) === 1

      return (
        <td data-col-id={col.id}>
          <div className="d-flex">
            <LinkButton>
              <span
                className="text-monospace pr-2"
                style={{ textDecoration: isActive ? '' : 'line-through' }}
              >
                {value}
              </span>
            </LinkButton>
            <ClipboardButton
              style={{ alignSelf: 'center', opacity: 0.4 }}
              value={String(value)}
            />
          </div>
        </td>
      )
    },
  },
  {
    label: 'Type',
    id: 'type',
    sortColumn: true,
    renderer: ({ value }) => {
      return (
        <td>
          <GiftCardTypeBadge type={value} />
        </td>
      )
    },
  },
  {
    label: 'Status',
    id: 'status',
    sortColumn: true,
    renderer: ({ value }) => {
      return (
        <td>
          <GiftCardStatusBadge status={value} />
        </td>
      )
    },
  },
  {
    label: 'Amount',
    id: 'amount',
    sortColumn: true,
    renderer: ({ value }) => {
      return <td className="text-right">${formatPrice(toCents(value))}</td>
    },
  },
  {
    label: 'Balance',
    id: 'balance',
    sortColumn: true,
    renderer: ({ value }) => {
      return <td className="text-right">${formatPrice(toCents(value))}</td>
    },
  },
  {
    label: 'Free Shipping',
    id: 'free_shipping',
    renderer: ({ value }) => {
      return (
        <td>
          {Number(value) === 1 ? (
            <i className="fa fa-check-circle text-success" />
          ) : (
            <i style={{ opacity: 0.5 }} className="fa fa-times-circle" />
          )}
        </td>
      )
    },
  },
  {
    label: 'Order',
    id: 'order_number',
    sortColumn: true,
    hasClipboardButton: true,
  },
  {
    label: 'Customer email',
    id: 'billing_email',
    sortColumn: true,
    defaultVisible: false,
  },
  {
    label: 'Customer name',
    id: 'billing_name',
    sortColumn: true,
    defaultVisible: false,
    renderer: customerCellRenderer,
  },
  {
    label: 'Create note',
    id: 'note',
    sortColumn: false,
  },
  {
    label: 'Batch',
    id: 'batch_id',
    sortColumn: true,
  },
  {
    label: 'Created at',
    id: 'created_at',
    renderer: ({ value }) => {
      return (
        <td>{value ? formatDateReadable(String(value), true, true) : ''}</td>
      )
    },
    sortColumn: true,
  },
  {
    label: 'Expires at',
    id: 'expires_at',
    renderer: ({ value }) => {
      return <td>{value ? formatDateReadable(String(value)) : ''}</td>
    },
    sortColumn: true,
  },
]

export default columnDef
