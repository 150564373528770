import AuthContext from 'AuthContext'
import { MANAGEINVENTORY_READWRITE } from 'caps'
import { FormRenderer, SelectCategories, SelectCollections } from 'components'
import EditPanelCard from 'components/EditPanelCard'
import type {
  FormDefElement,
  FormDefGroup,
  FormDefItem,
  UseFormReturn,
} from 'components/Form/types'
import { useCallback, useContext, useMemo } from 'react'
import { FormGroup, Label } from 'reactstrap'
import type { ProductFormState } from '../types'
import _formDef from './formDef'

const ProductCardForm = ({
  form,
  readOnly,
}: {
  form: UseFormReturn<Partial<ProductFormState>>
  readOnly: boolean
}) => {
  const { hasCap } = useContext(AuthContext)

  const transformField = useCallback(
    (field: FormDefElement) => {
      if (field.name === 'contentLink') {
        return {
          ...field,
          label: (
            <a target="_blank" rel="noreferrer" href={form.state.contentLink}>
              {field.label}
            </a>
          ),
        }
      }

      if (field.name === 'parentSku' || field.name === 'status') {
        return {
          ...field,
          readOnly: !hasCap(MANAGEINVENTORY_READWRITE),
        }
      }

      return field
    },
    [form, hasCap],
  )

  const formDef = useMemo(() => {
    return (_formDef as FormDefItem[]).map((field) => {
      if ((field as FormDefGroup).group) {
        return {
          group: (field as FormDefGroup).group.map(transformField),
        }
      }

      if ((field as FormDefElement).name) {
        return transformField(field as FormDefElement)
      }

      return field
    })
  }, [transformField])

  const handleChangeCategory = useCallback(
    (value: string[]) => {
      form.set('categories', value)
    },
    [form],
  )

  const handleChangeCollection = useCallback(
    (value: string[]) => {
      form.set('collections', value)
    },
    [form],
  )

  return (
    <EditPanelCard caption="Product" stateId="product_content_card">
      <FormRenderer {...{ form, formDef, readOnly }} />

      <FormGroup className="mb-0">
        <Label className="mb-0">Categories</Label>
        <SelectCategories
          placeholder="Categories"
          value={form.state.categories}
          onChange={handleChangeCategory}
          isDisabled={readOnly}
        />
      </FormGroup>
      <FormGroup className="mb-0">
        <Label className="mb-0">Collections</Label>
        <SelectCollections
          placeholder="Collections"
          value={form.state.collections}
          onChange={handleChangeCollection}
          isDisabled={readOnly}
        />
      </FormGroup>
    </EditPanelCard>
  )
}

export default ProductCardForm
