import max from 'lodash/max'
import min from 'lodash/min'
import range from 'lodash/range'
import { useCallback } from 'react'
import ZoneButton from './ZoneButton'

const ZoneRangeSelect = ({
  selectedZones,
  onChange,
  readOnly,
}: {
  selectedZones: number[]
  onChange: (zones: number[]) => void
  readOnly?: boolean
}) => {
  const handleClick = useCallback(
    (index: number) => {
      if (selectedZones.includes(index)) {
        // Click the only selected: Deselect
        if (selectedZones.length === 1) {
          onChange([])
          return
        }

        // Click any selected: select exclusivly
        onChange([index])
        return
      }

      let zones = [...selectedZones, index]

      const minZone = min(zones)
      const maxZone = max(zones)

      zones = range(minZone, maxZone + 1)

      onChange(zones)
    },
    [onChange, selectedZones],
  )

  return (
    <div style={{ display: 'flex', gap: 5 }}>
      {range(2, 12).map((i) => {
        return (
          <ZoneButton
            onClick={readOnly ? null : handleClick}
            selected={selectedZones.includes(i)}
            key={i}
            index={i}
            disabled={readOnly}
          />
        )
      })}
    </div>
  )
}

export default ZoneRangeSelect
