import type { Row } from 'components/Table'
import { useApi, useStateful } from 'hooks'
import { useCallback } from 'react'

type DoNotListButtonProps = {
  onChange?: () => void
  tableTriggerSearch: (silent?: boolean) => void
  className?: string
  row: Row
  readOnly?: boolean
}

const DoNotListButton = (props: DoNotListButtonProps) => {
  const { onChange, tableTriggerSearch, className, row, readOnly } = props
  const clickedState = useStateful({ isClicked: false, isChecked: false })
  const apiToggle = useApi(
    () => ({ action: 'purchaseOrderItems_toggleListOrderItem' }),
    null,
    () => ({ errorModal: true }),
  )

  let isChecked = row.do_not_list == 1
  if (clickedState.value.isClicked) {
    isChecked = clickedState.value.isChecked
  }

  const clockStyle: React.CSSProperties = isChecked
    ? {
        verticalAlign: 'middle',
        fontWeight: 'bold',
        background: 'yellow',
        borderRadius: '10px',
      }
    : { verticalAlign: 'middle' }

  const handleClick = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault()

      if (readOnly) {
        return
      }

      clickedState.set({ isClicked: true, isChecked: !isChecked })

      const id = e.currentTarget.getAttribute('data-id')

      const ret = await apiToggle.performRequest({ id })
      tableTriggerSearch(true)

      if (onChange) {
        onChange()
      }

      if (ret) {
        clickedState.set({ isClicked: true, isChecked: ret.do_not_list == 1 })
      } else {
        clickedState.set({ isClicked: false, isChecked: false })
      }
    },
    [
      apiToggle,
      tableTriggerSearch,
      onChange,
      clickedState,
      isChecked,
      readOnly,
    ],
  )

  return (
    <a
      {...{ className }}
      data-id={row.id}
      href="#"
      onClick={handleClick}
      title="Mark as 'do not ship'"
    >
      <i style={clockStyle} className="icon icon-clock" />
    </a>
  )
}

export default DoNotListButton
