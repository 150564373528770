import { InfoIcon } from 'components/InfoIcon/InfoIcon'
import type { DropDownState } from 'components/InfoIcon/types'
import { useInfoIcon } from 'components/InfoIcon/useInfoIcon'
import type { StatefulState } from 'hooks'
import { Fragment, useRef } from 'react'
import { Col, CustomInput, FormGroup, Input, Label, Row } from 'reactstrap'
import type { InputType } from 'reactstrap/es/Input'
import { SelectField } from '..'
import type { FormDef, FormDefElement, UseFormReturn } from './types'

type FormElementProps = {
  form: UseFormReturn
  dropDownState?: StatefulState<DropDownState>
  container?: React.RefObject<HTMLDivElement>
} & FormDefElement

/**
 * FormElement is a component that renders a form field based on the provided props.
 *
 * It uses the form state and methods provided by the useForm hook.
 *
 * @param form - The form state and methods returned by the useForm hook.
 * @param label - The label for the form field.
 * @param value - The current value of the form field.
 * @param name - The name of the form field.
 * @param type - The type of the form field (e.g., 'text', 'select', 'checkbox').
 * @param placeholder - The placeholder for the form field.
 * @param required - Whether the form field is required.
 * @param options - The options for the form field (for 'select' type).
 * @param readOnly - Whether the form field is read-only.
 * @param rows - The number of rows for the form field (for 'textarea' type).
 * @param multi - Whether the form field allows multiple selections (for 'select' type).
 * @param pattern - The pattern for the form field (for 'text' type).
 * @param dropDownState - id of the open popover, if any.
 * @param info - The hover documentation for the form field.
 */
const FormElement = ({
  form,
  label,
  value,
  name,
  type,
  placeholder,
  required,
  options,
  readOnly,
  rows,
  multi,
  pattern,
  info,
  dropDownState,
  container,
}: FormElementProps) => {
  const inputProps = form.inputProps
  const id = `form-input-${name}`

  const infoIcon = info ? (
    <InfoIcon
      dropDownState={dropDownState}
      id={`${id}-info`}
      className="form-info"
      placement="top"
      container={container}
    >
      {info}
    </InfoIcon>
  ) : null

  if (type === 'select' && multi) {
    return (
      <FormGroup className="mb-0">
        <Label className="mb-0" htmlFor={id}>
          {label} {infoIcon}
        </Label>
        <SelectField
          {...form.selectFieldProps}
          isMulti
          options={options}
          value={Array.isArray(value) ? value : null}
          name={name}
          isClearable
          isDisabled={readOnly}
        />
      </FormGroup>
    )
  }

  const inputChildren =
    type === 'select'
      ? (options || []).map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          )
        })
      : null

  if (type === 'checkbox') {
    return (
      <FormGroup className="mb-0 mt-2">
        <CustomInput
          {...inputProps}
          checked={value === true || value === '1'}
          disabled={readOnly}
          type="checkbox"
          id={id}
          name={name}
          label={
            <span>
              {label} {infoIcon}
            </span>
          }
          readOnly={readOnly}
        >
          {inputChildren}
        </CustomInput>
      </FormGroup>
    )
  }

  return (
    <FormGroup className="mb-0">
      <Label className="mb-0" htmlFor={id}>
        {label} {infoIcon}
      </Label>
      <Input
        {...inputProps}
        value={String(value || '')}
        type={(type || 'text') as InputType}
        placeholder={placeholder || ''}
        required={required || false}
        disabled={readOnly}
        {...{
          id,
          name,
          rows,
          pattern,
        }}
      >
        {inputChildren}
      </Input>
    </FormGroup>
  )
}

type FormRendererProps = {
  form: UseFormReturn
  formDef: FormDef
  readOnly?: boolean
}

/**
 * FormRenderer is a React component that renders a dynamic form based on a given form definition.
 * It iterates over the form definition array and renders each form element or group of elements.
 * The component integrates with useForm hook to manage form state and handle changes.
 *
 * @param form - The form state and methods returned by the useForm hook.
 * @param formDef - An array of form definition items (FormDefItem) that describe the structure and fields of the form.
 * @param readOnly - A boolean indicating if the form should be rendered in read-only mode.
 */
const FormRenderer = ({ form, formDef, readOnly }: FormRendererProps) => {
  // id of the open popover
  const { dropDownState } = useInfoIcon('form-info')

  const formRef = useRef<HTMLDivElement>(null)

  return (
    <div ref={formRef}>
      {formDef.map((item, i: number) => {
        const groupItems = item.group ? item.group : [item]

        return (
          <Row key={i}>
            {groupItems.map((groupItem: FormDefElement, i: number) => {
              if (groupItem.type === 'custom') {
                if (groupItem.render) {
                  return <Fragment key={i}>{groupItem.render(form)}</Fragment>
                }

                return null
              }

              return (
                <Col key={i}>
                  <FormElement
                    container={formRef}
                    dropDownState={dropDownState}
                    {...{
                      readOnly,
                      form,
                      value: form.state[groupItem.name],
                    }}
                    {...groupItem}
                  />
                </Col>
              )
            })}
          </Row>
        )
      })}
    </div>
  )
}

export default FormRenderer
