import { Badge } from 'reactstrap'

const typeColors = {
  VALID_WARRANTY: 'success',
  VALID_ARRIVE_ALIVE: 'success',
  COURTESY_INVALID_WARRANTY: 'danger',
  ORDER_ERROR: 'danger',
  COURTESY_OTHER: 'warning',
  LEGACY_OTHER: 'warning',
}

type Props = {
  type?: string
  className?: string
}

const StoreCreditReasonBadge = ({ className, type }: Props) => {
  if (!type) {
    return null
  }

  return (
    <Badge {...{ className }} color={typeColors[type]}>
      {String(type).toUpperCase()}
    </Badge>
  )
}

export default StoreCreditReasonBadge
