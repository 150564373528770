import { LinkButton, Loading, PageNavHead } from 'components'
import {
  useApi,
  useInterval,
  usePageTitle,
  usePrevious,
  useStateful,
} from 'hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Button, Col, Container, Progress, Row } from 'reactstrap'
import { askQuestion } from 'utils'
import DiffView from './DiffView'

const POLL_INTERVAL = 5000

/**
 * ImportInventory is used to synchronize the database inventory with a Google Sheets.
 * It can be used to import/export both Products and Variations, which end up in two
 * different sheets of the same spreadsheet document.
 *
 * ## Diff and import
 *
 * When an import is prepared, a diff is run between the inventory and the content of
 * the selected sheet. The result of that diff is displayed for confirmation.
 * This is done by calling the `productSync_diff` API endpoint, which is implemented
 * in the PHP ProductSync::api_diff() method.
 *
 * ## Applying the diff
 *
 * When a diff has been accepted, it is sent to the `productSync_apply` endpoint,
 * where it is applied asynchronously. The apply method is implemented in PHP in
 * ProductSync::api_apply().
 *
 * This calls ProductSync::LoadAndDiff_products() which will:
 * - get the sheets data from Google Sheets
 * - validate the Sheets data using validators (see below)
 * - export the products from the database using ProductExport::ExportAllProductsCached
 * - compute the diff
 *
 * ## Overwriting the sheet
 *
 * The sheet can be overwritten with the data from the database in order to have a
 * clean start. Because data gets updated in wp-admin all the time, the DB
 * is the source of truth. As such, before beginning a work session, the sheet
 * should be overwritten.
 *
 * This is handle by ProductSync::api_import().
 *
 * This first deletes all the rows in the sheet, the adds the necessary amount of empty
 * rows, and then updates the result.
 *
 * ## Validators
 *
 *
 */
const ImportInventory = ({ scope }: { scope: string }) => {
  const navigate = useNavigate()

  const pageTitle = useMemo(() => {
    const scopeLabel = scope === 'products' ? 'Products' : 'Variations'

    return `Import ${scopeLabel} from Google Sheets`
  }, [scope])

  usePageTitle(pageTitle)

  const getSheetLink = useApi(
    useMemo(() => ({ action: 'productSync_getSheetLink', scope }), [scope]),
    null,
    () => ({ cache: true, autoPerform: true }),
  )

  const getDiff = useApi(
    useMemo(() => ({ action: 'productSync_diff', scope }), [scope]),
    null,
    { errorModal: true, autoPerform: true },
  )

  const isRedirecting = useStateful(false)
  useEffect(() => {
    if (getDiff.result?.redirectUri && !isRedirecting.value) {
      isRedirecting.set(true)
      document.location.href = getDiff.result.redirectUri
    }
  }, [getDiff, isRedirecting])

  const applyDiff = useApi(
    () => ({ action: 'productSync_apply' }),
    null,
    () => ({ errorModal: false }),
  )

  const applyImport = useApi(
    () => ({ action: 'productSync_import' }),
    null,
    () => ({ errorModal: true }),
  )

  const handleClickOverwriteSheet = useCallback(async () => {
    if (
      await askQuestion(
        `Are you sure you want to overwrite ${scope} sheets with DB data?`,
      )
    ) {
      getDiff.reset()
      applyDiff.reset()
      applyImport.reset()
      await applyImport.performRequest({ scope })
      await getDiff.performRequest()
    }
  }, [applyImport, applyDiff, getDiff, scope])

  const handleClickReload = useCallback(() => {
    applyDiff.reset()
    getDiff.performRequest()
  }, [applyDiff, getDiff])

  const handleClickReloadClearCache = useCallback(() => {
    applyDiff.reset()
    getDiff.reset()
    getDiff.performRequest({ clearCache: true })
  }, [applyDiff, getDiff])

  const fallbackDisplayDiffResult = {
    create: [],
    update: [],
    errors: [],
    hasUpdates: false,
  }

  const displayDiffResult =
    getDiff.hasResult && Object.hasOwn(getDiff.result, 'displayDiff')
      ? getDiff.result.displayDiff
      : fallbackDisplayDiffResult

  const importResult = getDiff.result?.isCachedResult ? getDiff.result : null

  const hasUpdates = displayDiffResult?.hasUpdates

  const [pollInterval, setPollInterval] = useState<number | null>(POLL_INTERVAL)

  const progress = getDiff.result?.progress

  const isLoading =
    getDiff.isLoading ||
    applyImport.isLoading ||
    progress != null ||
    pollInterval != null

  const canApply =
    !isLoading &&
    hasUpdates &&
    displayDiffResult.errors.length === 0 &&
    getDiff.result?.isCachedResult !== true

  const canOverwrite = !isLoading

  const prevProgress = usePrevious(progress)
  useEffect(() => {
    if (progress !== prevProgress) {
      setPollInterval(progress ? POLL_INTERVAL : null)
    }
  }, [progress, prevProgress])

  useInterval(
    useCallback(() => {
      if (getDiff.isLoading) {
        return
      }

      getDiff.performRequest()
    }, [getDiff]),
    pollInterval,
  )

  const handleClickApply = useCallback(() => {
    if (!canApply) {
      return
    }

    const { diff } = getDiff.result

    applyDiff.performRequest({ json: { diff, scope } })

    setPollInterval(1000)
  }, [applyDiff, canApply, getDiff, scope])

  const pageTitleHeader = useMemo(() => {
    const scopeLabel = scope === 'products' ? 'Products' : 'Variations'
    const switchLink =
      scope === 'products' ? (
        <small>
          (
          <LinkButton onClick={() => navigate('/inventory/import-variations')}>
            go to Variations
          </LinkButton>
          )
        </small>
      ) : (
        <small>
          (
          <LinkButton onClick={() => navigate('/inventory/import-products')}>
            go to Products
          </LinkButton>
          )
        </small>
      )

    return (
      <>
        <LinkButton
          className=""
          title="Click to reload"
          onClick={handleClickReload}
        >
          Import <b>{scopeLabel}</b> from Google Sheets
        </LinkButton>{' '}
        {switchLink}
      </>
    )
  }, [scope, handleClickReload, navigate])

  return (
    <>
      <PageNavHead
        hasTail={false}
        sidebar={false}
        prevPageLink="/inventory"
        prevPageLabel="Manage Inventory"
        {...{
          isLoading,
          pageTitle: pageTitleHeader,
        }}
      >
        {getSheetLink.hasResult ? (
          <div style={{ marginLeft: 'auto' }}>
            <a
              target="_blank"
              rel="noreferrer"
              href={getSheetLink.result?.link}
            >
              Spreadsheet link ({getSheetLink.result?.name})
            </a>
          </div>
        ) : null}

        <div style={{ marginLeft: 'auto' }}>
          <Button
            disabled={!canOverwrite}
            className="mr-2"
            onClick={handleClickOverwriteSheet}
          >
            Overwrite sheet
          </Button>
          <Button
            disabled={isLoading}
            className="mr-2"
            onClick={handleClickReloadClearCache}
          >
            Reload
          </Button>
          <Button
            disabled={!canApply}
            className="mr-2"
            onClick={handleClickApply}
          >
            Apply
          </Button>
        </div>
      </PageNavHead>
      <Container fluid>
        <div className="import-inventory animated fadeIn large-min-height">
          {progress ? (
            <>
              <Progress
                className="mb-3"
                style={{ height: 20 }}
                animated
                color="info"
                value={(progress.progress / progress.changesCount) * 100}
              />
              <div className="m-5 text-center">
                Sync is currently in progress {progress.progress} /{' '}
                {progress.changesCount}.
              </div>
            </>
          ) : null}

          {isLoading ? <Loading /> : null}

          {/* Show apply results. */}

          {!isLoading && importResult ? (
            <>
              <Row>
                <Col>
                  <Alert color="info">
                    Import done. Press <i>Reload</i> to generate a new diff.
                  </Alert>
                </Col>
              </Row>
              {importResult.displayResult.errors.map(
                (error: string, i: number) => {
                  return (
                    <Row key={i}>
                      <Col>
                        <Alert color="danger">{error}</Alert>
                      </Col>
                    </Row>
                  )
                },
              )}
            </>
          ) : null}

          {/* Show diff. */}

          {getDiff.hasResult ? (
            <DiffView {...{ scope, result: displayDiffResult }} />
          ) : null}
        </div>
      </Container>
    </>
  )
}

export default ImportInventory
