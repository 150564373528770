import { EditPanelCard } from 'components'
import { useApi, useBoolean } from 'hooks'
import startCase from 'lodash/startCase'
import { useCallback, useMemo } from 'react'
import { Col, Row } from 'reactstrap'
import AddressEditor from './AddressEditor'
import type { AddressType } from './types'

const extractAddress = (addressType: string, props: any) => {
  return {
    firstName: props[`${addressType}FirstName`],
    lastName: props[`${addressType}LastName`],
    company: props[`${addressType}Company`],
    address: props[`${addressType}Address`],
    address2: props[`${addressType}Address2`],
    city: props[`${addressType}City`],
    state: props[`${addressType}State`],
    zip: props[`${addressType}Zip`],
    email: props[`${addressType}Email`],
    phone: props[`${addressType}Phone`],
  }
}

const Address = (props: AddressType) => {
  const {
    company,
    firstName,
    lastName,
    address,
    address2,
    city,
    state,
    zip,
    phone,
    email,
  } = props

  return (
    <>
      {company ? <div>{company}</div> : null}
      <div>
        {firstName} {lastName}
      </div>
      {address ? <div>{address}</div> : null}
      {address2 ? <div>{address2}</div> : null}
      <div>
        {city}
        {city ? ',' : ''} {state} {zip}
      </div>
      {email ? <div>{email}</div> : null}
      {phone ? <div>{phone}</div> : null}
    </>
  )
}

const AddressVerification = (props) => {
  const { getOrder } = props

  const addressVerifyStatus = getOrder.result.addressVerifyStatus || ''
  const addressVerifyMessages =
    (getOrder.result.addressVerifyResult || {}).messages || []

  const apiVerifyAddress = useApi(
    { action: 'orders_verifyShippingAddress', id: getOrder.result.orderId },
    null,
    { errorModal: true },
  )

  const handleClickVerifyAddress = useCallback(async () => {
    if (apiVerifyAddress.isLoading) {
      return
    }

    await apiVerifyAddress.performRequest()
    getOrder.performRequest()
  }, [apiVerifyAddress, getOrder])

  return (
    <>
      {addressVerifyStatus ? (
        <span style={{ textTransform: 'capitalize', paddingLeft: 5 }}>
          {addressVerifyStatus === 'verified' ? (
            <i
              style={{ color: 'rgb(37, 108, 43)', paddingRight: 3 }}
              className="fa fa-check-circle"
            />
          ) : (
            <i
              style={{ paddingRight: 3 }}
              className="text-danger fa fa-times-circle"
            />
          )}
          {addressVerifyStatus}
        </span>
      ) : (
        <button
          className="btn btn-link btn-sm m-0 p-0 pl-1 align-baseline"
          onClick={handleClickVerifyAddress}
          disabled={apiVerifyAddress.isLoading}
        >
          Verify address
        </button>
      )}
      {addressVerifyMessages.map((m) => {
        return (
          <div
            key={`${m.code} ${m.message}`}
            className="font-weight-bold text-danger"
          >
            <i
              style={{ paddingRight: 3 }}
              className="text-danger fa fa-times-circle"
            />
            {m.message}
          </div>
        )
      })}
    </>
  )
}

export const AddressRow = (props) => {
  const { addressType, readOnly, getOrder } = props

  const addressFields = useMemo(
    () => extractAddress(addressType, getOrder.result),
    [getOrder.result, addressType],
  )

  const isEditing = useBoolean(false)

  const handleClickEditAddress = useCallback(() => {
    isEditing.toggle()
  }, [isEditing])

  const orderId = getOrder.result.orderId

  return (
    <>
      <div>
        <b>{startCase(addressType)} Address</b>
        {!readOnly ? (
          <button
            className="btn btn-link btn-sm m-0 p-0 pl-1 align-baseline"
            onClick={handleClickEditAddress}
          >
            {isEditing.value ? 'Cancel' : 'Edit'}
          </button>
        ) : null}
        {!isEditing.value && addressType === 'shipping' ? (
          <AddressVerification {...{ getOrder }} />
        ) : null}
      </div>
      {isEditing.value ? (
        <AddressEditor
          {...{ getOrder, orderId, addressType }}
          {...addressFields}
          onClose={handleClickEditAddress}
        />
      ) : (
        <Address {...{ addressType }} {...addressFields} />
      )}
    </>
  )
}

export const ShippingCard = (props) => {
  const { readOnly, getOrder } = props

  return (
    <EditPanelCard
      caption="Shipping Address"
      stateId="orders_shipping"
      bodyProps={{ className: 'p-3' }}
    >
      <Row>
        <Col>
          <AddressRow {...{ getOrder, readOnly }} addressType="shipping" />
        </Col>
      </Row>
    </EditPanelCard>
  )
}

const CustomerCard = (props) => {
  const { canModifyOrderAddress, getOrder } = props

  const readOnly = !canModifyOrderAddress

  return (
    <EditPanelCard
      caption="Customer"
      stateId="orders_customer"
      bodyProps={{ className: 'p-3' }}
    >
      <Row className="mb-3">
        <Col>
          <AddressRow {...{ getOrder, readOnly }} addressType="billing" />
        </Col>
      </Row>
      <Row>
        <Col>
          <AddressRow {...{ getOrder, readOnly }} addressType="shipping" />
        </Col>
      </Row>
    </EditPanelCard>
  )
}

export default CustomerCard
