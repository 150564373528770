import { useCallback, useMemo } from 'react'
import usePersistedState from './usePersistedState'

export type SetType<T> = {
  toggle: (val: T) => void
  values: T[]
  clear: () => void
  has: (value: T) => boolean
  setValues: (values: T[]) => void
  remove: (value: T | Array<T>) => void
}

function useSet<T = string>(
  initValue: Array<T> = [],
  storageKey: null | string = null,
): SetType<T> {
  const [values, setValues] = usePersistedState(initValue, storageKey)

  const toggle = useCallback(
    (val: T) => {
      if (values.includes(val)) {
        setValues(
          values.filter((item: T) => {
            return item !== val
          }),
        )
      } else {
        setValues([...values, val])
      }
    },
    [setValues, values],
  )

  const remove = useCallback(
    (val: T | Array<T>) => {
      const removeList = Array.isArray(val) ? val : [val]

      const newValues = values.filter((item: T) => {
        return removeList.includes(item)
      })

      if (JSON.stringify(values) !== JSON.stringify(newValues)) {
        setValues(newValues)
      }
    },
    [values, setValues],
  )

  const clear = useCallback(() => setValues([]), [setValues])

  const has = useCallback((val: T) => values.includes(val), [values])

  return useMemo(
    () => ({
      toggle,
      clear,
      has,
      values,
      setValues,
      remove,
    }),
    [toggle, clear, has, values, setValues, remove],
  )
}

export default useSet
