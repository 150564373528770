import type { ColumnPreset } from '../types'
import type { Action, BaseRow, TableType } from './types'

export const SET_VISIBLE_COLUMNS = 'SET_VISIBLE_COLUMNS'
export const SET_SORTED_COLUMNS = 'SET_SORTED_COLUMNS'
export const SET_COLUMN_COLOR = 'SET_COLUMN_COLOR'
export const RESET_COLUMN_COLORS = 'RESET_COLUMN_COLORS'
export const RESET_COLUMNS = 'RESET_COLUMNS'
export const SET_TABLE = 'SET_TABLE'
export const SET_RESPONSE = 'SET_RESPONSE'
export const RESET_TABLE = 'RESET_TABLE'
export const SET_LOADING = 'SET_LOADING'
export const SET_ERROR = 'SET_ERROR'
export const SET_QUERY = 'SET_QUERY'
export const SET_PAGINATION_LIMIT = 'SET_PAGINATION_LIMIT'
export const SET_PAGINATION_PAGE = 'SET_PAGINATION_PAGE'
export const SET_SORT_COLUMN = 'SET_SORT_COLUMN'
export const SET_SORT_ASC = 'SET_SORT_ASC'
export const SET_EDIT_CELL = 'SET_EDIT_CELL'
export const SET_CELL_LOADING = 'SET_CELL_LOADING'
export const SET_CELL_LOADING_DONE = 'SET_CELL_LOADING_DONE'
export const SET_CELL_VALUE = 'SET_CELL_VALUE'
export const SET_ROW = 'SET_ROW'
export const SET_SELECTED_ROW = 'SET_SELECTED_ROW'
export const SET_SELECTED_ROWS = 'SET_SELECTED_ROWS'
export const SET_INITIAL_SELECTED_ROWS = 'SET_INITIAL_SELECTED_ROWS'
export const SELECT_ROW_RANGE = 'SELECT_ROW_RANGE'
export const TOGGLE_SELECTED_ROW = 'TOGGLE_SELECTED_ROW'
export const MOVE_ITEM = 'MOVE_ITEM'
export const SET_ROW_ORDER = 'SET_ROW_ORDER'
export const SET_NEXT_ROW = 'SET_NEXT_ROW'
export const SET_PREV_ROW = 'SET_PREV_ROW'
export const SET_NEXT_PAGE = 'SET_NEXT_PAGE'
export const SET_PREV_PAGE = 'SET_PREV_PAGE'
export const SET_PRESET = 'SET_PRESET'

/**
 * Sets the next row as the selected row.
 */
export const setNextRow = <RowType extends BaseRow>(): Action<RowType> => {
  return { type: SET_NEXT_ROW }
}

/**
 * Sets the previous row as the selected row.
 */
export const setPrevRow = <RowType extends BaseRow>(): Action<RowType> => {
  return { type: SET_PREV_ROW }
}

/**
 * Advances the pagination to the next page.
 */
export const setNextPage = <RowType extends BaseRow>(): Action<RowType> => {
  return { type: SET_NEXT_PAGE }
}

/**
 * Moves the pagination to the previous page.
 */
export const setPrevPage = <RowType extends BaseRow>(): Action<RowType> => {
  return { type: SET_PREV_PAGE }
}

/**
 * Sets the visible columns in the table.
 * @param columns - Array of strings representing column identifiers.
 */
export const setVisibleColumns = <RowType extends BaseRow>(
  columns: string[],
): Action<RowType> => {
  return {
    type: SET_VISIBLE_COLUMNS,
    columns,
  }
}

/**
 * Sets the sorted columns in the table.
 * @param columns - Array of strings representing sorted column identifiers.
 */
export const setSortedColumns = <RowType extends BaseRow>(
  columns: string[],
): Action<RowType> => {
  return {
    type: SET_SORTED_COLUMNS,
    columns,
  }
}

/**
 * Sets the color for a specific column.
 * @param columnName - The name of the column to set the color.
 * @param color - The color value to set for the column.
 */
export const setColumnColor = <RowType extends BaseRow>(
  columnName: string,
  color: string,
): Action<RowType> => {
  return {
    type: SET_COLUMN_COLOR,
    columnName,
    color,
  }
}

/**
 * Resets the colors of all columns.
 */
export const resetColumnColors = <
  RowType extends BaseRow,
>(): Action<RowType> => {
  return { type: RESET_COLUMN_COLORS }
}

/**
 * Resets both visible and sorted columns to their default state.
 */
export const resetColumns = <RowType extends BaseRow>(): Action<RowType> => {
  return { type: RESET_COLUMNS }
}

/**
 * Updates or sets a specific row's data.
 * @param rowId - The identifier of the row to update.
 * @param row - The data object for the row.
 */
export const setRow = <RowType extends BaseRow>(
  rowId: string,
  row: RowType,
): Action<RowType> => {
  return {
    type: SET_ROW,
    rowId,
    row,
  }
}

/**
 * Sets the value of a specific cell.
 * @param rowId - The row identifier.
 * @param colId - The column identifier.
 * @param value - The value to set for the cell.
 */
export const setCellValue = <RowType extends BaseRow>(
  rowId: string,
  colId: string,
  value: any,
): Action<RowType> => {
  return {
    type: SET_CELL_VALUE,
    rowId,
    colId,
    value,
  }
}

/**
 * Sets the cell to be edited.
 * @param rowId - The row identifier.
 * @param colId - The column identifier, defaults to null.
 */
export const setEditCell = <RowType extends BaseRow>(
  rowId: string,
  colId = null,
): Action<RowType> => {
  return {
    type: SET_EDIT_CELL,
    rowId,
    colId,
  }
}

/**
 * Sets a single row as selected.
 * @param rowId - The identifier of the row to select.
 */
export const setSelectedRow = <RowType extends BaseRow>(
  rowId: string,
): Action<RowType> => {
  return {
    type: SET_SELECTED_ROW,
    rowId,
  }
}

/**
 * Sets the initially selected rows in the table.
 * @param rowIds - Array of row identifiers.
 */
export const setInitialSelectedRows = <RowType extends BaseRow>(
  rowIds: string[],
): Action<RowType> => {
  return {
    type: SET_INITIAL_SELECTED_ROWS,
    rowIds,
  }
}

/**
 * Sets the selected rows in the table.
 * @param rowIds - Array of row identifiers.
 */
export const setSelectedRows = <RowType extends BaseRow>(
  rowIds: string[],
): Action<RowType> => {
  return {
    type: SET_SELECTED_ROWS,
    rowIds,
  }
}

/**
 * Selects a range of rows starting from the last selected row to the row with the given rowId.
 * @param rowId - The identifier of the row to start selecting from.
 */
export const selectRowRange = <RowType extends BaseRow>(
  rowId: string,
): Action<RowType> => {
  return {
    type: SELECT_ROW_RANGE,
    rowId,
  }
}

/**
 * Toggles the selection state of a row.
 * @param rowId - The identifier of the row to toggle.
 */
export const toggleSelectedRow = <RowType extends BaseRow>(
  rowId: string,
): Action<RowType> => {
  return {
    type: TOGGLE_SELECTED_ROW,
    rowId,
  }
}

/**
 * Sets the loading state for a specific cell.
 * @param rowId - The row identifier, can be null.
 * @param colId - The column identifier, can be null.
 * @param value - The loading state value.
 */
export const setCellLoading = <RowType extends BaseRow>(
  rowId: string = null,
  colId: string = null,
  value: string = null,
): Action<RowType> => {
  return {
    type: SET_CELL_LOADING,
    rowId,
    colId,
    value,
  }
}

export const setCellLoadingDone = <RowType extends BaseRow>(
  rowId: string = null,
  colId: string = null,
): Action<RowType> => {
  return { type: SET_CELL_LOADING_DONE, rowId, colId }
}

/**
 * Sets the current pagination page.
 * @param page - The page number to set.
 */
export const setPaginationPage = <RowType extends BaseRow>(
  page: number,
): Action<RowType> => {
  return {
    type: SET_PAGINATION_PAGE,
    page,
  }
}

/**
 * Sets the pagination limit (number of items per page).
 * @param limit - The limit value to set.
 */
export const setPaginationLimit = <RowType extends BaseRow>(
  limit: number,
): Action<RowType> => {
  return {
    type: SET_PAGINATION_LIMIT,
    limit,
  }
}

/**
 * Sets the column used for sorting.
 * @param column - The identifier of the column to sort by.
 */
export const setSortColumn = <RowType extends BaseRow>(
  column: string,
): Action<RowType> => {
  return {
    type: SET_SORT_COLUMN,
    column,
  }
}

/**
 * Sets the sorting order (ascending/descending).
 * @param asc - Boolean indicating if sorting should be ascending.
 */
export const setSortAsc = <RowType extends BaseRow>(
  asc: boolean,
): Action<RowType> => {
  return {
    type: SET_SORT_ASC,
    asc,
  }
}

/**
 * Moves an item from one position to another in the table.
 * @param dragIndex - The current index of the item being dragged.
 * @param hoverIndex - The index where the item is to be moved.
 */
export const dragDropMoveItem = <RowType extends BaseRow>(
  dragIndex: number,
  hoverIndex: number,
): Action<RowType> => {
  return {
    type: MOVE_ITEM,
    dragIndex,
    hoverIndex,
  }
}

/**
 * Sets the order of rows based on their IDs.
 * @param rowIds - Array of row identifiers in the desired order.
 */
export const setRowOrder = <RowType extends BaseRow>(
  rowIds: string[],
): Action<RowType> => {
  return {
    type: SET_ROW_ORDER,
    rowIds,
  }
}

/**
 * Sets the response data for the table.
 * @param response - The response data to set.
 */
export const setResponse = <RowType extends BaseRow>(
  response: any,
): Action<RowType> => {
  return {
    type: SET_RESPONSE,
    response,
  }
}

/**
 * Sets the table data including rows, total number of rows, offset, and limit.
 * @param table - The table data object containing rows, offset, limit, and total.
 */
export const setTable = <RowType extends BaseRow>(
  table: TableType<RowType>,
): Action<RowType> => {
  const { rows, offset, limit, total } = table
  return {
    type: SET_TABLE,
    rows,
    offset,
    limit,
    total,
  }
}

/**
 * Resets the table to its initial state.
 */
export const resetTable = <RowType extends BaseRow>(): Action<RowType> => {
  return { type: RESET_TABLE }
}

/**
 * Sets the loading state of the table.
 * @param isLoading - Boolean indicating the loading state.
 */
export const setLoading = <RowType extends BaseRow>(
  isLoading = true,
): Action<RowType> => {
  return {
    type: SET_LOADING,
    isLoading,
  }
}

/**
 * Sets the error message for the table.
 * @param error - The error message to set.
 */
export const setError = <RowType extends BaseRow>(
  error: string,
): Action<RowType> => {
  return {
    type: SET_ERROR,
    error,
  }
}

/**
 * Sets the query string for filtering or searching.
 * @param query - The query string to set.
 */
export const setQuery = <RowType extends BaseRow>(
  query: string,
): Action<RowType> => {
  return {
    type: SET_QUERY,
    query,
  }
}
export const columnColors = <RowType extends BaseRow>(
  state: { [key: string]: string },
  action: Action<RowType>,
) => {
  switch (action.type) {
    case SET_COLUMN_COLOR: {
      return {
        ...state,
        [action.columnName]: action.color,
      }
    }
  }

  return state
}

/**
 * Sets a preset configuration for visible and sorted columns.
 * @param preset - The column preset configuration to set.
 */
export const setPreset = <RowType extends BaseRow>(
  preset: ColumnPreset,
): Action<RowType> => {
  return {
    type: SET_PRESET,
    visibleColumns: preset.visibleColumns,
    sortedColumns: preset.sortedColumns,
  }
}
