import type React from 'react'
import type { CSSProperties } from 'react'

type CheckboxFieldProps = {
  label: string
  name: string
  checked: boolean
  style?: CSSProperties
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  id: string
  className?: string
  disabled?: boolean
  required?: boolean
  ariaLabel?: string
  ariaLabelledBy?: string
}

const CheckboxField = (props: CheckboxFieldProps) => {
  const {
    label,
    name,
    checked,
    style,
    onChange,
    id,
    className,
    disabled,
    required,
    ariaLabel,
    ariaLabelledBy,
  } = props

  return (
    <div className={`${className || ''} form-check mb-2`} style={style}>
      <input
        type="checkbox"
        className="form-check-input"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        required={required}
        aria-label={ariaLabel || label}
        aria-labelledby={ariaLabelledBy}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export default CheckboxField
