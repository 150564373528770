import { apiRequest } from 'api'
import { showError } from './alert'

export const uiApiRequest = async (props: string | Record<string, any>) => {
  try {
    await apiRequest(props)
    return true
  } catch (e) {
    await showError(e.message)
    return false
  }
}
