import { useStateful } from 'hooks'
import { useCallback, useRef } from 'react'
import { Button, CardBody, Form, Input } from 'reactstrap'
import type { StepProps } from '..'

const Step = ({ getOrderData, fetchOrderData }: StepProps) => {
  const inputEl = useRef()

  const orderNumber = useStateful('')

  const handleChangeInput = useCallback(
    (e) => {
      if (getOrderData.error) {
        getOrderData.reset()
      }

      orderNumber.set(e.target.value.trim())
    },
    [getOrderData, orderNumber],
  )

  const handleFormSubmit = useCallback(
    (e) => {
      e.preventDefault()

      fetchOrderData(orderNumber.value)
    },
    [fetchOrderData, orderNumber.value],
  )

  return (
    <CardBody className="text-center" style={{ fontSize: 24 }}>
      <Form onSubmit={handleFormSubmit}>
        <div className="p-3">Scan order invoice to begin shipping.</div>
        <div className="pt-3">
          <i style={{ fontSize: 90 }} className="fa fa-barcode" />
        </div>
        <div>
          <Input
            innerRef={inputEl}
            style={{ fontSize: 24, width: 180 }}
            className="d-inline text-center"
            name="orderNumber"
            type="text"
            value={orderNumber.value}
            onInput={handleChangeInput}
            disabled={getOrderData.isLoading}
          />
        </div>
        <Button
          size="lg"
          color="primary"
          className="m-3"
          disabled={
            getOrderData.isLoading || String(orderNumber.value).length === 0
          }
        >
          Submit
        </Button>
      </Form>
    </CardBody>
  )
}

export default Step
