import {
  ADMIN,
  DRAFTPURCHASEORDERS_READ,
  DRAFTPURCHASEORDERS_READWRITE,
  EDITORDERS_READWRITE,
  MANAGEBATCHES_READ,
  MANAGEBATCHES_READWRITE,
  MANAGECONTENT_READ,
  MANAGECONTENT_READWRITE,
  MANAGECUSTOMERS_READ,
  MANAGECUSTOMERS_READWRITE,
  MANAGEDELIVERIES_READ,
  MANAGEDELIVERIES_READWRITE,
  MANAGEGIFTCARDS_READ,
  MANAGEGIFTCARDS_READWRITE,
  MANAGEINVENTORYPURCHASING_READ,
  MANAGEINVENTORYPURCHASING_READWRITE,
  MANAGEINVENTORY_READ,
  MANAGEINVENTORY_READWRITE,
  MANAGEORDERS_READ,
  MANAGEORDERS_READWRITE,
  MANAGERETURNS_READ,
  MANAGERETURNS_READWRITE,
  MANAGESHIPMENTS_READ,
  MANAGESHIPMENTS_READWRITE,
  MANAGESTORECREDITTEMPLATES_READWRITE,
  MANAGESTORECREDIT_READ,
  MANAGESTORECREDIT_READWRITE,
  MANAGESUBSCRIPTIONPLANS_READ,
  MANAGESUBSCRIPTIONPLANS_READWRITE,
  MANAGEZONES_READ,
  MANAGEZONES_READWRITE,
  PURCHASEORDERS_READ,
  PURCHASEORDERS_READWRITE,
  SHIPNOW_READWRITE,
  SHIPPINGEMPLOYEES_READ,
  SHIPPINGREPORT_READ,
  MANAGEDISCOUNTS_READ,
  MANAGEDISCOUNTS_READWRITE,
} from 'caps'
import ManageContent from 'containers/ManageContent'
import { Config as StoreCreditConfig } from 'containers/ManageStoreCredits/Config'
import { useContext, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import AuthContext, { type AuthContextType } from './AuthContext'
import AddOrder from './containers/AddOrder'
import EditCustomer from './containers/EditCustomer'
import EditOrder from './containers/EditOrder'
import EditPurchaseOrder from './containers/EditPurchaseOrder'
import ImportInventory from './containers/ImportInventory'
import ManageBarcodes from './containers/ManageBarcodes'
import ManageBatches from './containers/ManageBatches'
import ManageCustomers from './containers/ManageCustomers'
import ManageDeliveries from './containers/ManageDeliveries'
import ManageGiftCards from './containers/ManageGiftCards'
import ManageInventory from './containers/ManageInventory'
import ManageOrders from './containers/ManageOrders'
import ManagePurchaseOrders from './containers/ManagePurchaseOrders'
import ManageReturns from './containers/ManageReturns'
import ManageScanappEmployees from './containers/ManageScanappEmployees'
import ManageShipments from './containers/ManageShipments'
import ManageShippingEmployees from './containers/ManageShippingEmployees'
import ManageStoreCredit from './containers/ManageStoreCredits'
import ManageSubscriptionPlans from './containers/ManageSubscriptionPlans'
import ManageSuppliers from './containers/ManageSuppliers'
import ManageZones from './containers/ManageZones'
import Playground from './containers/Playground'
import ShipNow from './containers/ShipNow'
import ShippingReport from './containers/ShippingReport'
import ShippingReport2 from './containers/ShippingReport2'
import ManageDiscounts from './containers/ManageDiscounts'

const Redirect = ({ to }) => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  })
  return null
}

const NotFound = () => <div>404 Page not found</div>

const getDefaultRoute = (hasCap: (cap: string) => boolean) => {
  if (hasCap(MANAGEORDERS_READ)) {
    return '/orders/manage/all'
  }
  if (hasCap(MANAGEBATCHES_READ)) {
    return '/orders/manage/batches'
  }
  if (hasCap(MANAGECUSTOMERS_READ)) {
    return '/customers'
  }
  if (hasCap(MANAGERETURNS_READ)) {
    return '/returns'
  }
  if (hasCap(MANAGEINVENTORY_READ)) {
    return '/inventory'
  }
  if (hasCap(MANAGECONTENT_READ)) {
    return '/content'
  }
  if (hasCap(PURCHASEORDERS_READ)) {
    return '/purchase-orders'
  }
  if (hasCap(DRAFTPURCHASEORDERS_READ)) {
    return '/draft-purchase-orders'
  }
  if (hasCap(MANAGEDELIVERIES_READ)) {
    return '/deliveries'
  }
  if (hasCap(SHIPPINGEMPLOYEES_READ)) {
    return '/shipping/employees'
  }
  if (hasCap(SHIPPINGREPORT_READ)) {
    return '/shipping/report'
  }
  if (hasCap(EDITORDERS_READWRITE)) {
    return '/orders/add'
  }
  if (hasCap(SHIPNOW_READWRITE)) {
    return '/shipnow'
  }
  if (hasCap(MANAGESHIPMENTS_READ)) {
    return '/shipments'
  }
  if (hasCap(MANAGEZONES_READ)) {
    return '/zones'
  }
  if (hasCap(MANAGEGIFTCARDS_READ)) {
    return '/gift-cards'
  }
  if (hasCap(MANAGESUBSCRIPTIONPLANS_READ)) {
    return '/subscription-plans'
  }
  if (hasCap(MANAGEDISCOUNTS_READ)) {
    return '/discounts'
  }

  return '/inventory'
}

const MainContentRouter = () => {
  const { hasCap } = useContext<AuthContextType>(AuthContext)

  let caps: PageCaps = {
    canModifyBatches: false,
    canModifyTags: false,
    canModifySchedule: false,
    canModifyNotes: false,
    canModifyOrderAddress: false,
    canModifyReturns: false,
    canSplit: false,
    canTools: false,
    canEditOrder: false,
    canBlockUnblockOrders: false,
  }

  if (hasCap(MANAGEORDERS_READWRITE)) {
    caps.canModifyTags = true
    caps.canModifySchedule = true
    caps.canModifyNotes = true
    caps.canModifyOrderAddress = true
    caps.canSplit = true
    caps.canTools = true
    caps.canBlockUnblockOrders = true
  }

  if (hasCap(EDITORDERS_READWRITE)) {
    caps.canEditOrder = true
    caps.canModifyTags = true
    caps.canModifyNotes = true
    caps.canModifyOrderAddress = true
  }

  if (hasCap(MANAGEBATCHES_READWRITE)) {
    caps.canModifyBatches = true
  }

  if (hasCap(MANAGERETURNS_READWRITE)) {
    caps.canModifyReturns = true
  }

  if (global.appConfig.capsFilter) {
    caps = global.appConfig.capsFilter(caps, { hasCap })
  }

  return (
    <Routes>
      {hasCap(MANAGEBATCHES_READ) || hasCap(MANAGEBATCHES_READWRITE) ? (
        <Route
          path="/orders/manage/batches"
          element={<ManageBatches readOnly={caps.canModifyBatches === false} />}
        />
      ) : null}
      {hasCap(MANAGEORDERS_READ) ? (
        <Route
          path="/orders/manage/:filterStatus"
          element={<ManageOrders {...caps} />}
        />
      ) : null}
      {hasCap(EDITORDERS_READWRITE) ? (
        <Route path="/orders/add" element={<AddOrder />} />
      ) : null}
      {hasCap(EDITORDERS_READWRITE) ? (
        <Route path="/orders/edit/*" element={<EditOrder />} />
      ) : null}
      {hasCap(MANAGESTORECREDITTEMPLATES_READWRITE) ? (
        <Route path="/store-credit/config" element={<StoreCreditConfig />} />
      ) : null}
      {hasCap(MANAGEINVENTORY_READ) ? (
        <Route
          path="/inventory"
          element={
            <ManageInventory
              key="inventory"
              pageTitle="Manage Inventory"
              readOnly={!hasCap(MANAGEINVENTORY_READWRITE)}
              hiddenColumns={['numOrdered', 'numIdeal', 'numNeed', 'shouldWeb']}
              storageKey="manageInventory"
            />
          }
        />
      ) : null}
      {hasCap(MANAGEINVENTORYPURCHASING_READ) ? (
        <Route
          path="/inventory-purchasing"
          element={
            <ManageInventory
              key="inventory-purchasing"
              pageTitle="Manage Inventory Purchasing"
              readOnly={!hasCap(MANAGEINVENTORYPURCHASING_READWRITE)}
              storageKey="manageInventoryPurchasing"
            />
          }
        />
      ) : null}
      {hasCap(MANAGEINVENTORY_READWRITE) ? (
        <Route
          path="/inventory/import-products"
          element={<ImportInventory scope="products" />}
        />
      ) : null}
      {hasCap(MANAGEINVENTORY_READWRITE) ? (
        <Route
          path="/inventory/import-variations"
          element={<ImportInventory scope="variations" />}
        />
      ) : null}
      {hasCap(MANAGECONTENT_READ) ? (
        <Route
          path="/content"
          element={
            <ManageContent readOnly={!hasCap(MANAGECONTENT_READWRITE)} />
          }
        />
      ) : null}
      {hasCap(MANAGEGIFTCARDS_READ) ? (
        <Route
          path="/gift-cards"
          element={
            <ManageGiftCards
              pageTitle="Manage Gift Cards"
              storageKey="manageGiftCards"
              readOnly={!hasCap(MANAGEGIFTCARDS_READWRITE)}
              canCreate={hasCap(MANAGEGIFTCARDS_READWRITE) && hasCap(ADMIN)}
            />
          }
        />
      ) : null}
      {hasCap(MANAGESTORECREDIT_READ) ? (
        <Route
          path="/store-credit"
          element={
            <ManageStoreCredit
              pageTitle="Manage Store Credit"
              storageKey="manageStoreCredit"
              readOnly={!hasCap(MANAGESTORECREDIT_READWRITE)}
            />
          }
        />
      ) : null}
      {hasCap(PURCHASEORDERS_READ) ? (
        <Route
          path="/purchase-orders"
          element={
            <ManagePurchaseOrders
              readOnly={!hasCap(PURCHASEORDERS_READWRITE)}
            />
          }
        />
      ) : null}
      {hasCap(DRAFTPURCHASEORDERS_READ) ? (
        <Route
          path="/draft-purchase-orders"
          element={
            <ManagePurchaseOrders
              isDraftsView
              readOnly={!hasCap(DRAFTPURCHASEORDERS_READWRITE)}
            />
          }
        />
      ) : null}
      {hasCap(MANAGEDELIVERIES_READ) ? (
        <Route
          path="/deliveries"
          element={
            <ManageDeliveries readOnly={!hasCap(MANAGEDELIVERIES_READWRITE)} />
          }
        />
      ) : null}
      {hasCap(PURCHASEORDERS_READ) || hasCap(MANAGEDELIVERIES_READ) ? (
        <Route
          path="/purchase-orders/edit/:id/*"
          element={
            <EditPurchaseOrder readOnly={!hasCap(PURCHASEORDERS_READWRITE)} />
          }
        />
      ) : null}
      {hasCap(DRAFTPURCHASEORDERS_READ) ? (
        <Route
          path="/draft-purchase-orders/edit/:id/*"
          element={
            <EditPurchaseOrder
              isDraftsView
              readOnly={!hasCap(DRAFTPURCHASEORDERS_READWRITE)}
            />
          }
        />
      ) : null}
      {hasCap(PURCHASEORDERS_READ) ? (
        <Route path="/suppliers" element={<ManageSuppliers />} />
      ) : null}
      {hasCap(SHIPPINGEMPLOYEES_READ) ? (
        <Route
          path="/shipping/employees"
          element={<ManageShippingEmployees />}
        />
      ) : null}
      {hasCap(SHIPPINGEMPLOYEES_READ) ? (
        <Route
          path="/shipping/employees/scanapp"
          element={<ManageScanappEmployees />}
        />
      ) : null}
      {hasCap(SHIPPINGREPORT_READ) ? (
        <Route path="/shipping/report2" element={<ShippingReport2 />} />
      ) : null}
      {hasCap(SHIPPINGREPORT_READ) ? (
        <Route path="/shipping/report" element={<ShippingReport />} />
      ) : null}
      {hasCap(SHIPPINGREPORT_READ) ? (
        <Route
          path="/shipping/report-legacy"
          element={<ShippingReport legacy />}
        />
      ) : null}
      {hasCap(SHIPNOW_READWRITE) ? (
        <Route path="/shipnow" element={<ShipNow />} />
      ) : null}
      {hasCap(MANAGESHIPMENTS_READ) ? (
        <Route
          path="/shipments"
          element={
            <ManageShipments readOnly={!hasCap(MANAGESHIPMENTS_READWRITE)} />
          }
        />
      ) : null}
      {hasCap(MANAGEZONES_READ) ? (
        <Route
          path="/zones"
          element={<ManageZones readOnly={!hasCap(MANAGEZONES_READWRITE)} />}
        />
      ) : null}
      {hasCap(MANAGECUSTOMERS_READ) ? (
        <Route
          path="/customers"
          element={
            <ManageCustomers readOnly={!hasCap(MANAGECUSTOMERS_READWRITE)} />
          }
        />
      ) : null}
      {hasCap(MANAGECUSTOMERS_READ) ? (
        <Route path="/customers/:id" element={<EditCustomer />} />
      ) : null}
      {hasCap(MANAGERETURNS_READ) ? (
        <Route
          path="/returns"
          element={
            <ManageReturns readOnly={!hasCap(MANAGERETURNS_READWRITE)} />
          }
        />
      ) : null}
      {hasCap(MANAGESUBSCRIPTIONPLANS_READ) ? (
        <Route
          path="/subscription-plans"
          element={
            <ManageSubscriptionPlans
              readOnly={!hasCap(MANAGESUBSCRIPTIONPLANS_READWRITE)}
            />
          }
        />
      ) : null}
      {hasCap(MANAGEDISCOUNTS_READ) ? (
        <Route
          path="/discounts"
          element={
            <ManageDiscounts readOnly={!hasCap(MANAGEDISCOUNTS_READWRITE)} />
          }
        />
      ) : null}
      <Route path="/shipping/tools/barcodes" element={<ManageBarcodes />} />
      <Route path="/playground" element={<Playground />} />
      <Route path="/" element={<Redirect to={getDefaultRoute(hasCap)} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default MainContentRouter
