import type { Zone } from '@ttc/api/zones'
import { LoadingImage } from 'components'
import { type StatefulState, useApi, usePrevious, useStateful } from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { Button, Input, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Modal from 'reactstrap/es/Modal'
import type { EditProductZoneQty } from '.'

type ZoneQtyMap = Record<string, string>

const ZoneQtyEditor = ({
  zoneQty,
  zone,
}: {
  zoneQty: StatefulState<ZoneQtyMap>
  zone: Zone
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      zoneQty.set({ ...zoneQty.value, [zone.id]: e.target.value })
    },
    [zone.id, zoneQty],
  )

  const qty =
    zoneQty.value && Object.hasOwn(zoneQty.value, zone.id)
      ? zoneQty.value[zone.id]
      : '0'

  return (
    <div
      key={zone.id}
      style={{
        backgroundColor: zone.color,
        padding: 10,
        color: 'white',
        borderRadius: 5,
        flex: '1 1 25%',
        margin: '0 10px 10px 0',
        fontWeight: 'bold',
      }}
    >
      <div style={{ marginBottom: 10 }}>{zone.name}</div>
      <Input
        style={{ display: 'inline' }}
        type="number"
        onChange={handleChange}
        value={qty}
      />
    </div>
  )
}

type PutawayModalProps = {
  purchaseOrderTruckId: number
  zones: Zone[]
  isOpen: boolean
  onClose: () => void
  editProductZoneQty?: EditProductZoneQty
}

const PutawayModal = ({
  purchaseOrderTruckId,
  editProductZoneQty,
  zones,
  isOpen,
  onClose,
}: PutawayModalProps) => {
  const apiSubmitPutaway = useApi(
    () => ({ action: 'purchaseOrderTrucks_submitPutaway' }),
    null,
    { errorModal: true },
  )

  const product = editProductZoneQty?.product

  const zoneQty = useStateful<ZoneQtyMap>(null)

  const handleSubmit = useCallback(async () => {
    const zoneQtys = {}
    Object.keys(zoneQty.value).forEach((zoneId) => {
      zoneQtys[zoneId] = Number(zoneQty.value[zoneId])
    })

    const ret = await apiSubmitPutaway.performRequest({
      json: {
        id: purchaseOrderTruckId,
        confirmedQty: { [product.sku]: zoneQtys },
      },
    })

    if (ret !== false) {
      onClose()
    }
  }, [onClose, zoneQty, apiSubmitPutaway, purchaseOrderTruckId, product])

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      const zoneQtys = {}
      Object.keys(editProductZoneQty?.zoneQty || {}).forEach((zoneId) => {
        zoneQtys[zoneId] = String(editProductZoneQty?.zoneQty[zoneId] || 0)
      })

      zoneQty.set(zoneQtys)
    }
  }, [zoneQty, editProductZoneQty, isOpen, prevIsOpen])

  const canSubmit = useMemo(() => {
    let isValid = true

    Object.keys(zoneQty.value || {}).forEach((zoneId) => {
      if (!/^\d+$/.test(String(zoneQty.value[zoneId]))) {
        isValid = false
      }
    })

    return isValid
  }, [zoneQty.value])

  const { isLoading } = apiSubmitPutaway

  return (
    <Modal backdrop="static" {...{ isOpen }} toggle={onClose}>
      {editProductZoneQty != null ? (
        <>
          <ModalHeader toggle={isLoading ? null : onClose}>
            Put away: {product.name} {product.size} ({product.sku})
          </ModalHeader>
          <ModalBody style={{ display: 'flex', flexWrap: 'wrap' }}>
            {zones.map((zone) => {
              return <ZoneQtyEditor key={zone.id} {...{ zoneQty, zone }} />
            })}
          </ModalBody>
          <ModalFooter>
            {isLoading ? <LoadingImage className="mr-2" /> : null}
            <Button
              disabled={!canSubmit || isLoading}
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>{' '}
            <Button disabled={isLoading} color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </>
      ) : null}
    </Modal>
  )
}

export default PutawayModal
