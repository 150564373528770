import InputMask from '@mona-health/react-input-mask'
import type React from 'react'
import type { CSSProperties } from 'react'
import { validateField } from 'utils/validate'

const RegularInput = (props: any) => {
  return <input {...props} />
}

type TextFieldProps = {
  label: string
  name: string
  value: any
  style?: CSSProperties
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onPressReturn?: () => void
  id: string
  maskPlaceholder?: string
  isValid?: boolean
  placeholder?: string
  className?: string
  mask?: string
  disabled?: boolean
  required?: boolean
  isPercentage?: boolean
  isNumeric?: boolean
  isCcNumber?: boolean
  minLength?: number
  isZipCode?: boolean
  isEmail?: boolean
  isPhoneNumber?: boolean
  ariaLabel?: string
  ariaLabelledBy?: string
}

const TextField = (props: TextFieldProps) => {
  const {
    label,
    name,
    value,
    style,
    onChange,
    onKeyDown,
    onPressReturn,
    id,
    placeholder,
    className,
    mask,
    maskPlaceholder,
    disabled,
    isValid,
  } = props

  const _isValid = isValid == null ? validateField(props) : isValid

  const Input: any = mask ? InputMask : RegularInput
  const maskProps = maskPlaceholder ? { maskPlaceholder } : {}

  const handleKeyDown =
    onKeyDown || onPressReturn
      ? (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (onKeyDown) {
            onKeyDown(e)
          }
          if (onPressReturn && e.key === 'Enter') {
            onPressReturn()
          }
        }
      : null

  return (
    <div className={`${className || ''} form-label-group`} {...{ style }}>
      <Input
        {...{
          name,
          mask,
          disabled,
          value,
          onChange,
          id,
          placeholder: placeholder || label,
          onKeyDown: handleKeyDown,
          'aria-label': label,
        }}
        {...maskProps}
        type="text"
        className={`form-control ${_isValid ? '' : 'is-invalid'}`}
        autoComplete="nope"
        autoCorrect="off"
        spellCheck={false}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export default TextField
