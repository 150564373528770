import PurchaseCostPerSupplier from './PurchaseCostPerSupplier'
import PurchaseOrdersCard from './PurchaseOrdersCard'
import PurchaseYearlyAverageCost from './PurchaseYearlyAverageCost'

type DeliveriesCardProps = {
  productId: number
}

const DeliveriesCard = (props: DeliveriesCardProps) => {
  const { productId } = props

  return (
    <>
      <PurchaseOrdersCard {...{ productId }} />
      <PurchaseCostPerSupplier {...{ productId }} />
      <PurchaseYearlyAverageCost {...{ productId }} />
    </>
  )
}

export default DeliveriesCard
