import { Badge } from 'reactstrap'

export const NumBadge = ({ num }) => {
  if (num <= 0) {
    return null
  }

  return (
    <Badge
      style={{
        marginTop: -20,
        top: '50%',
        left: '55%',
        position: 'absolute',
      }}
      pill
    >
      {num}
    </Badge>
  )
}

export default NumBadge
