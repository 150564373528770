import type {
  InventoryAutocompleteProductsRequest,
  InventoryAutocompleteProductsResult,
} from '@ttc/api/inventory'
import { apiRequest } from 'api'
import { useCallback } from 'react'
import AsyncSelect from 'react-select/async'
import { useDebouncedCallback } from 'use-debounce'
import { formatPrice } from 'utils/price'
import styles from './../selectStyles'

type SelectProductsProps = {
  className?: string
  value?: any
  isDisabled?: boolean
  onChange?: (value: any) => void
  placeholder?: string
  bySku?: boolean
  cacheOptions?: boolean
  isMulti?: boolean
  filterStatus?: string[]
}

const SelectProducts = (props: SelectProductsProps) => {
  const {
    className,
    value,
    isDisabled,
    onChange,
    placeholder,
    bySku,
    cacheOptions,
    filterStatus,
  } = props

  const isMulti = props.isMulti == null ? true : props.isMulti

  const loadOptions = useCallback(
    async (query: string) => {
      const ret: InventoryAutocompleteProductsResult | Record<string, never> =
        await apiRequest<
          InventoryAutocompleteProductsResult,
          InventoryAutocompleteProductsRequest
        >({
          action: 'inventory_autocompleteProducts',
          json: {
            query,
            filterStatus: filterStatus ?? [],
            limit: 40,
          },
        })

      return ret.map((row) => {
        const { sku, name, size, price, isAlwaysStock, numWeb, type } = row
        let pInfo = ''
        // if the type is a variation or size is empty, don't output the size
        if (type === 'variation' || size === '') {
          pInfo = `${sku} - ${name} - $${formatPrice(price)}`
        } else {
          pInfo = `${sku} - ${name} - ${size} - $${formatPrice(price)}`
        }
        const stockInfo = !isAlwaysStock ? ` (${numWeb})` : ''

        return {
          value: bySku === true ? sku : row.id,
          sku,
          label: `${pInfo}${stockInfo}`,
        }
      })
    },
    [bySku, filterStatus],
  )

  const Select = AsyncSelect

  const debouncedLoadOptions = useDebouncedCallback(
    (inputValue: string, callback) => {
      loadOptions(inputValue).then((options) => callback(options))
    },
    500,
  )

  return (
    <Select
      {...{
        className,
        onChange,
        loadOptions: debouncedLoadOptions,
        value,
        isDisabled,
        cacheOptions: cacheOptions != null ? cacheOptions : true,
      }}
      styles={styles}
      isMulti={isMulti}
      isClearable={!isMulti}
      placeholder={placeholder || 'Select Products'}
      aria-label="Select Products"
    />
  )
}

export default SelectProducts
