import type { Product } from '@ttc/api/products'
import type { ZonesGetAllResponse } from '@ttc/api/zones'
import AuthContext from 'AuthContext'
import { MANAGEINVENTORY_READ } from 'caps'
import useApi from 'hooks/useApi'
import { useCallback, useContext, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'

type LinkToInventoryProps = {
  sku: string
  availableQty?: number
  name?: string
  text?: string
  popOverProductData?: Product
}

export const LinkToInventory = ({
  sku,
  name,
  availableQty,
  text,
  popOverProductData,
}: LinkToInventoryProps) => {
  const { hasCap } = useContext(AuthContext)
  const canOpenInventory = hasCap(MANAGEINVENTORY_READ)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const apiLoadZones = useApi<ZonesGetAllResponse>(
    () => ({ action: 'zones_getAll' }),
    null,
    { autoPerform: isOpen, cache: true, freshCache: true },
  )

  const isPopoverVisible = isOpen && apiLoadZones.hasResult
  const zones = apiLoadZones.result?.rows || []

  const ref = useRef(null)
  const popOver = popOverProductData?.zoneQty ? (
    <Popover
      target={ref}
      placement="bottom"
      trigger="hover"
      toggle={handleToggle}
      isOpen={isPopoverVisible}
    >
      <PopoverHeader>{popOverProductData.name}</PopoverHeader>
      <PopoverBody>
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
          <li>Available: {popOverProductData.availableQty}</li>
          {zones
            .filter((zone) => {
              const isVariation = popOverProductData.type === 'variation'
              const isPresentInZone = popOverProductData.zoneQty[zone.id] > 0
              if (isVariation) {
                return (
                  (!zone.is_addon_zone && !zone.is_box_zone) || isPresentInZone
                )
              }
              return isPresentInZone
            })
            .map((zone) => (
              <li key={zone.id}>
                {zone.description ?? `Zone ${zone.id}`}:{' '}
                {popOverProductData.zoneQty[zone.id]}
              </li>
            ))}
          <li>Web: {popOverProductData.numWeb}</li>
        </ul>
      </PopoverBody>
    </Popover>
  ) : null

  return (
    <>
      {canOpenInventory ? (
        <>
          {text ? (
            <Link
              ref={ref}
              className="m-0 p-0 pl-2"
              to={`/inventory/?q=${sku}`}
            >
              {text}
            </Link>
          ) : (
            <div>
              Inventory:{' '}
              <Link ref={ref} to={`/inventory/?q=${sku}`}>
                {name ?? sku}
              </Link>
              {availableQty ? ` (${availableQty})` : null}
            </div>
          )}

          {popOver}
        </>
      ) : null}
    </>
  )
}
