import { useCallback, useMemo } from 'react'
import usePersistedState from './usePersistedState'

const useBoolean = (initValue = false, storageKey: string | null = null) => {
  const [value, setValue] = usePersistedState(initValue, storageKey)

  const toggle = useCallback(() => setValue(!value), [setValue, value])
  const on = useCallback(() => setValue(true), [setValue])
  const off = useCallback(() => setValue(false), [setValue])

  return useMemo(
    () => ({
      value,
      toggle,
      on,
      off,
      set: setValue,
    }),
    [value, toggle, on, off, setValue],
  )
}

export default useBoolean
