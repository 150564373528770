import { useBarcodeScanner, useStateful } from 'hooks'
import { useCallback, useEffect } from 'react'
import { Button, CardBody, Form, Input } from 'reactstrap'
import type { StepProps } from '..'

const Step = ({ state, dispatch }: StepProps) => {
  const numPlants = useStateful(0)
  const isWrong = useStateful(false)

  const checkAnswer = useCallback(
    (numPlants) => {
      const isWrongValue =
        Number(numPlants) !== Number(state.orderData.numPlants)

      isWrong.set(isWrongValue)

      if (!isWrongValue) {
        dispatch({ type: 'CONFIRM_NUM_PLANTS' })
      }
    },
    [dispatch, isWrong, state.orderData.numPlants],
  )

  useBarcodeScanner(
    useCallback(
      (barcode) => {
        if (barcode === 'TTCINC') {
          numPlants.set(numPlants.value + 1)
        } else if (barcode === 'TTCDEC') {
          numPlants.set(numPlants.value - 1)
        } else if (barcode === 'TTCCONFIRM') {
          checkAnswer(numPlants.value)
        }
      },
      [checkAnswer, numPlants],
    ),
  )

  const handleFormSubmit = useCallback(
    (e) => {
      e.preventDefault()

      checkAnswer(Number(numPlants.value))
    },
    [checkAnswer, numPlants.value],
  )

  const handleChangeInput = useCallback(
    (e) => {
      numPlants.set(e.target.value)
    },
    [numPlants],
  )

  useEffect(() => {
    if (numPlants.value < 0) {
      numPlants.set(0)
    }
  }, [numPlants])

  const handleClickDecrease = useCallback(() => {
    numPlants.set(Number(numPlants.value) - 1)
  }, [numPlants])

  const handleClickIncrease = useCallback(() => {
    numPlants.set(Number(numPlants.value) + 1)
  }, [numPlants])

  return (
    <CardBody className="text-center" style={{ fontSize: 24 }}>
      <Form onSubmit={handleFormSubmit}>
        <div className="p-3">How many plants are in the order?</div>
        <div>
          <Button size="lg" className="mr-2" onClick={handleClickDecrease}>
            -
          </Button>
          <Input
            style={{ fontSize: 24, width: 100 }}
            className="d-inline text-center"
            name="orderNumber"
            type="text"
            value={numPlants.value}
            onInput={handleChangeInput}
          />
          <Button size="lg" className="ml-2" onClick={handleClickIncrease}>
            +
          </Button>
        </div>
        <Button size="lg" color="primary" className="m-3">
          Submit
        </Button>
        {isWrong.value ? (
          <div className="alert alert-danger" role="alert">
            Number of plants is wrong. Please check again.
          </div>
        ) : null}
      </Form>
    </CardBody>
  )
}

export default Step
