import type { GetProductApiParams } from '@ttc/api/products'
import {
  CellProductName,
  FilterBar,
  PageNavHead,
  PageNavTail,
  Portal,
} from 'components'
import {
  Table,
  type Row as TableRow,
  defaultCellRenderer,
  useTable,
  useTableApi,
  useTableColumns,
} from 'components/Table'
import type { TableCellRenderProps } from 'components/Table/types'
import ShortcodeActionButton from 'containers/ManageInventory/Actions/ShortcodeActionButton'
import { useShortcodePaste } from 'containers/ManageInventory/Actions/useShortcodePaste'
import ExportInventoryActionButton from 'containers/common/Actions/ExportInventoryActionButton'
import ManageColumnsPanel from 'containers/common/ManageColumnsPanel'
import ManageTagsPanel from 'containers/common/ManageTagsPanel'
import TagActionButton from 'containers/common/TagActionButton'
import {
  useApi,
  useEffectIfObjectChanges,
  useFiltersWithUrlUpdaterWithTableSync,
  usePageTitle,
  usePanelControl,
  useSidebar,
} from 'hooks'
import { useCallback, useMemo } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { getStorage } from 'utils/storage'
import Filters, { type ManageContentFiltersType } from './Filters'
import ProductContentSidebar from './ProductContentSidebar'
import type { GetProduct } from './ProductContentSidebar/types'
import columnDef from './columnDef'

const pageTitle = 'Manage Content'

const storageKey = 'manageContent'

const [setItem, getItem] = getStorage(storageKey)

const visibleFilters = [
  'q',
  'status',
  'tags',
  'categories',
  'collections',
  'zones',
  'range',
  'suppliers',
]

type ManageContentProps = {
  readOnly: boolean
}

const ManageContent = (props: ManageContentProps) => {
  const { readOnly } = props

  const [state, dispatch] = useTable({
    getItem,
    setItem,
    cacheKey: storageKey,
  })
  const { rows, selectedRows, isLoading, sortColumn, sortAsc } = state

  const filters = useFiltersWithUrlUpdaterWithTableSync(
    'manageContentFilters',
    visibleFilters,
    state,
    dispatch,
  ) as ManageContentFiltersType

  const query = filters.searchQueryString

  const sidebar = useSidebar({
    openForSingleRow: true,
    openForMultiRows: false,
    selectedRows,
  })

  const columns = useTableColumns(storageKey, state, dispatch, columnDef)

  const tableColumnDef = useMemo(
    () => columnDef.filter((column) => column.exportOnly !== true),
    [],
  )

  const shortcodePaste = useShortcodePaste(filters)

  const requestColumnsIds = useMemo(
    // Some columns are always required regardless of column visibility.
    () => [
      'status',
      'tags',
      'isManagingStock',
      'thumbnailImageUrl',
      'contentLink',
      ...columns.visibleColumnsIds,
    ],
    [columns.visibleColumnsIds],
  )

  const requestParams = useMemo(
    () => ({
      query: '',
      ...filters.requestProps,
      columns: requestColumnsIds,
      types: ['simple', 'variable'],
    }),
    [filters.requestProps, requestColumnsIds],
  )

  const exportRequestProps = useMemo(
    () => ({
      ...filters.requestProps,
      types: 'simple,variable',
    }),
    [filters.requestProps],
  )

  const [triggerSearch] = useTableApi(
    'inventory_getProducts',
    state,
    dispatch,
    requestParams,
    { autoReloadInterval: false },
  )

  /* Trigger search when filters or columns change. */
  useEffectIfObjectChanges(triggerSearch, requestParams)

  usePageTitle(pageTitle)

  const manageColumnsPanel = usePanelControl()
  const manageTagsPanel = usePanelControl()

  const handleChangeSearch = useCallback(
    (query: string) => {
      filters.q.setValues([query])
    },
    [filters.q],
  )

  const renderCell = useCallback((props: TableCellRenderProps<TableRow>) => {
    const { id } = props.col

    if (id === 'name') {
      const { value, row } = props

      return (
        <CellProductName
          {...{
            productId: Number(row.id),
            value,
            parentProductId: row.parentProductId,
            name: row.name,
            thumbnailImageUrl: row.thumbnailImageUrl,
            status: row.status,
            isBlocked: row.isBlocked,
          }}
        />
      )
    }

    return defaultCellRenderer(props)
  }, [])

  const getTags = useApi(
    () => ({ action: 'inventoryTags_getAll', json: { context: 'CONTENT' } }),
    () => [],
    () => ({
      autoPerform: true,
      normalize: (input) => input.rows,
    }),
  )

  const handleCloseTagsPanel = useCallback(() => {
    manageTagsPanel.close()
    getTags.performRequest()
  }, [manageTagsPanel, getTags])

  const renderRowClassName = useCallback(({ row }) => {
    const ret = !row.complete ? 'highlight-red' : ''
    if (ret) {
      return ret
    }

    return row.tags.filter((tag: any) => tag.label).length
      ? 'highlight-orange'
      : ''
  }, [])

  const selectedProductId = selectedRows.length === 1 ? selectedRows[0] : null
  const getProduct = useApi<GetProduct, GetProductApiParams>(
    useMemo(
      () => ({
        action: 'content_getProduct',
        id: selectedProductId,
      }),
      [selectedProductId],
    ),
    null,
    {
      autoPerform: sidebar.isOpen && selectedProductId != null,
      cache: true,
    },
  )

  const handleCloseSidebar = useCallback(() => {
    sidebar.close()
    getProduct.performRequest()
  }, [sidebar, getProduct])

  return (
    <>
      <PageNavHead
        {...{
          isLoading,
          pageTitle,
          sidebar,
          onClickReload: triggerSearch,
        }}
      />
      <PageNavTail
        {...{
          isLoading,
          query,
          handleChangeSearch,
          onPaste: shortcodePaste.handlePaste,
        }}
      >
        {!readOnly ? (
          <TagActionButton
            columnName="contentTags"
            toggleTagApiAction="inventory_toggleTags"
            toggleTagApiContext="CONTENT"
            {...{
              selectedRows,
              rows,
              tags: getTags.result,
              onClickManage: manageTagsPanel.open,
              onModified: triggerSearch,
            }}
          />
        ) : null}
        <ExportInventoryActionButton
          {...{
            requestProps: exportRequestProps,
            query,
            sortColumn,
            sortAsc,
            columns,
          }}
        />
        <ShortcodeActionButton {...{ query, filters }} />
      </PageNavTail>
      <FilterBar {...{ filters, manageColumnsPanel, columns }}>
        <Filters
          {...{
            tags: getTags.result,
            filters,
            visibleColumnIds: columns.visibleColumnsIds,
          }}
        />
      </FilterBar>
      <Container fluid>
        <div className="mt-4 manage-content animated fadeIn">
          <ManageTagsPanel
            apiPrefix="inventoryTags"
            apiContext="CONTENT"
            isOpen={manageTagsPanel.isOpen}
            onClose={handleCloseTagsPanel}
          />
          <ManageColumnsPanel
            {...{ columns }}
            isOpen={manageColumnsPanel.isOpen}
            onClose={manageColumnsPanel.close}
          />
          <Row>
            <Col>
              <Table
                entityName="products"
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef: tableColumnDef,
                  dispatch,
                  renderCell,
                  renderRowClassName,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
      <Portal id="sidebar-content">
        <div className={getProduct.params.id === null ? 'dim' : ''}>
          <ProductContentSidebar
            {...{
              readOnly,
              getProduct,
              onToggle: sidebar.userToggle,
              isOpen: sidebar.isOpen,
              onClose: handleCloseSidebar,
              onSaved: triggerSearch,
            }}
          />
        </div>
      </Portal>
    </>
  )
}

export default ManageContent
