import { PageNavHead, PageNavTail } from 'components'
import {
  Table,
  setQuery,
  useTableApi,
  useTableWithUrlUpdater,
} from 'components/Table'
import { usePageTitle } from 'hooks'
import { useSidebar } from 'hooks'
import find from 'lodash/find'
import { useCallback } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { getStorage } from 'utils/storage'
import { SyncActionButton } from './Actions/SyncActionButton'
import { VoidActionButton } from './Actions/VoidActionButton'
import Sidebar from './Sidebar'
import columnDef from './columnDef'

const pageTitle = 'Manage Shipments'

const [setItem, getItem] = getStorage('manageShipments')

const ManageShipments = ({ readOnly }: { readOnly: boolean }) => {
  const [state, dispatch] = useTableWithUrlUpdater({
    getItem,
    setItem,
    cacheKey: 'manageShipments',
  })
  const { query, isLoading, selectedRows, rows } = state
  const [triggerSearch] = useTableApi('shipments_getAll', state, dispatch, {})

  const sidebar = useSidebar({
    openForSingleRow: true,
    selectedRows,
  })

  const handleChangeSearch = useCallback(
    (query) => {
      dispatch(setQuery(query))
    },
    [dispatch],
  )

  usePageTitle(pageTitle)

  const selectedRow =
    selectedRows.length === 1 ? find(rows, { id: selectedRows[0] }) : null

  return (
    <>
      <PageNavHead
        {...{ isLoading, pageTitle, sidebar, onClickReload: triggerSearch }}
      />
      <PageNavTail
        {...{
          isLoading,
          query,
          handleChangeSearch,
          searchPlaceholder: 'Search order number / name',
        }}
      >
        {!readOnly ? (
          <VoidActionButton
            {...{ selectedRows, rows, onUpdated: triggerSearch }}
          />
        ) : null}
        {!readOnly ? (
          <SyncActionButton
            {...{ selectedRows, rows, onUpdated: triggerSearch }}
          />
        ) : null}
      </PageNavTail>
      <Container fluid className="mt-4">
        <div className="ship-now animated fadeIn">
          <Row>
            <Col>
              <Table
                entityName="shipments"
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef,
                  dispatch,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
      <Sidebar {...{ onToggle: sidebar.userToggle, shipment: selectedRow }} />
    </>
  )
}

export default ManageShipments
