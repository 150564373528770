import type { InfoIconProps } from 'components/InfoIcon/types'
import { useCallback, useRef } from 'react'
import { PopoverBody, UncontrolledPopover } from 'reactstrap'

export const InfoIcon = (props: InfoIconProps) => {
  const { children, id, dropDownState, className, placement, container } = props

  const isOpen = dropDownState.value.id === id

  const handleToggle = useCallback(() => {
    dropDownState.update({ id: isOpen ? null : id })
  }, [isOpen, dropDownState, id])

  const ref = useRef(null)

  return (
    <>
      <i
        ref={ref}
        className={`cursor-pointer info-icon fa fa-info-circle ml-1 ${
          className || ''
        }`}
      />
      <UncontrolledPopover
        className={'hovering'}
        placement={placement || 'left'}
        container={container}
        isOpen={isOpen}
        target={ref}
        trigger="hover"
        toggle={handleToggle}
        key={id}
      >
        <PopoverBody
          style={{
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
          }}
        >
          {children}
        </PopoverBody>
      </UncontrolledPopover>
    </>
  )
}
