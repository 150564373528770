import {
  CellEditable,
  defaultCellRenderer,
  setCellLoading,
  setEditCell,
  useTable,
  useTableApi,
} from 'components/Table'
import { useApi, useEffectIfObjectChanges } from 'hooks'
import { useCallback, useMemo } from 'react'
import { getStorage } from 'utils/storage'
import columnDef from './columnDef'

import type { TableProps } from 'components/Table/types'
import type { ManageBatchesFiltersType } from '../Filters'

const storageKey = 'batchesTable'
const [setItem, getItem] = getStorage(storageKey)

type UseBatchesTableReturn = {
  triggerSearch: () => void
  batchStatuses: string[]
} & TableProps

export const useBatchesTable = (
  filters: ManageBatchesFiltersType,
  readOnly: boolean,
): UseBatchesTableReturn => {
  const getStatuses = useApi(() => ({ action: 'batches_getStatuses' }), null, {
    autoPerform: true,
  })

  const [state, dispatch] = useTable({
    cacheKey: 'manageBatches',
    getItem,
    setItem,
  })
  const { selectedRows } = state
  const [triggerSearch] = useTableApi(
    'batches_getAll',
    state,
    dispatch,
    { query: '', ...filters.requestProps },
    { autoReloadInterval: 120, shouldLoad: getStatuses.hasResult },
  )

  /* Trigger search when filters change. */
  useEffectIfObjectChanges(triggerSearch, filters.requestProps)

  const selectedRow = selectedRows.length === 1 ? selectedRows[0] : null

  const handleClickCell = useCallback(
    (rowId, colId) => {
      if (colId === 'status') {
        dispatch(setEditCell(rowId, colId))
        return true
      }
    },
    [dispatch],
  )

  const apiSetStatus = useApi({ action: 'batches_setStatus' })

  const handleChangeCell = useCallback(
    async ({ value, rowId, colId }) => {
      dispatch(setCellLoading(rowId, colId))
      await apiSetStatus.performRequest({ id: rowId, status: value })
      await triggerSearch()
      dispatch(setCellLoading())
    },
    [apiSetStatus, dispatch, triggerSearch],
  )

  const renderRowClassName = useCallback(
    ({ row }) => {
      return row.id === selectedRow ? 'selected' : ''
    },
    [selectedRow],
  )

  const renderCell = useCallback(
    (props) => {
      if (!readOnly && props.col.id === 'status') {
        return <CellEditable {...props} />
      }

      return defaultCellRenderer(props)
    },
    [readOnly],
  )

  return useMemo(
    () => ({
      ...state,
      setItem,
      getItem,
      columnDef,
      dispatch,
      triggerSearch,
      onClickCell: handleClickCell,
      onChangeCell: handleChangeCell,
      renderRowClassName,
      batchStatuses: getStatuses.result || [],
      renderCell,
    }),
    [
      state,
      dispatch,
      triggerSearch,
      handleClickCell,
      handleChangeCell,
      renderRowClassName,
      getStatuses.result,
      renderCell,
    ],
  )
}
