import type { Mail } from '@ttc/api/orders'
import type { Product } from '@ttc/api/products'
import { openPrintWindow } from 'api/index'
import { EditPanelCard, Loading } from 'components'
import { useApi } from 'hooks'
import { useCallback } from 'react'
import { formatDateReadable } from 'utils'

type LogEntry = {
  id: number
  message: string
  event_type: string
  user: string
  created_at: string
  mail?: Mail
  product?: Product
}

const LogEntry = (entry: LogEntry) => {
  const { id, mail, event_type, message, user, created_at, product } = entry

  const handleClickMail = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()

      openPrintWindow({
        action: 'treeadmin_mails_render',
        id: String(mail.id),
        key: mail.key,
      })
    },
    [mail],
  )

  return (
    <li key={id}>
      <div>{event_type}</div>
      {product ? (
        <>
          <div>
            {product.name} ({product.size})
          </div>
          <div>SKU: {product.sku}</div>
        </>
      ) : null}
      <div>{message}</div>
      <div style={{ fontSize: 11, fontStyle: 'italic' }}>
        {user} on {formatDateReadable(created_at, true)}
      </div>
      {mail ? (
        <>
          <div>
            {mail.has_message_data ? (
              <a href="#" onClick={handleClickMail}>
                <i className="fa fa-envelope mr-1" />
                {mail.subject}
              </a>
            ) : (
              <span>
                <i className="fa fa-envelope mr-1" />
                {mail.subject}
              </span>
            )}
          </div>
          <div className="font-italic" style={{ fontSize: 11 }}>
            Email To {mail.recipient} on {formatDateReadable(mail.date, true)}
          </div>
        </>
      ) : null}
    </li>
  )
}

const Log = ({ id, apiName }) => {
  const apiGetLog = useApi(
    () => ({ action: apiName, id }),
    null,
    () => ({ autoPerform: id != null }),
  )

  const entries = apiGetLog.result?.rows || []

  if (apiGetLog.isLoading) {
    return <Loading />
  }

  if (entries.length === 0) {
    return <i>Log empty</i>
  }

  return (
    <>
      <ul>
        {entries.map((entry: LogEntry) => (
          <LogEntry key={entry.id} {...entry} />
        ))}
      </ul>
    </>
  )
}

type InventoryLogCardProps = {
  id: number
  apiName: string
}

const InventoryLogCard = (props: InventoryLogCardProps) => {
  const { id, apiName } = props

  return (
    <EditPanelCard caption="Inventory Log" stateId="inventory-log">
      <Log key={id} {...{ id, apiName }} />
    </EditPanelCard>
  )
}

export default InventoryLogCard
