import moment from 'moment'
import { useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { getDateRange } from './utils'

const options = [
  { id: 'today', label: 'Today' },
  { id: 'yesterday', label: 'Yesterday' },
  { id: 'this_week', label: 'This week' },
  { id: 'last_week', label: 'Last week' },
  { id: 'this_month', label: 'This month' },
  { id: 'last_month', label: 'Last month' },
  { id: 'this_year', label: 'This year' },
  { id: 'last_year', label: 'Last year' },
  { id: 'custom', label: 'Custom' },
]

const DATE_FROM_FORMAT = 'YYYY-MM-DD 00:00:00'
const DATE_TO_FORMAT = 'YYYY-MM-DD 23:59:59'

type DatePickerRowProps = {
  onChange: (dateId: string, dateFrom: string, dateTo: string) => void
  dateId: string
  getItem: (key: string) => string
  setItem: (key: string, value: string) => void
}

const DatePickerRow = (props: DatePickerRowProps) => {
  const { onChange, dateId, getItem, setItem } = props

  const [dateFrom, setDateFrom] = useState(getItem('customDateFrom') || '')
  const [dateTo, setDateTo] = useState(getItem('customDateTo') || '')

  const triggerChangeCustom = () => {
    if (onChange) {
      const dateFromObj = moment(dateFrom)
      const dateToObj = moment(dateTo)

      onChange(
        'custom',
        dateFromObj.isValid() ? dateFromObj.format(DATE_FROM_FORMAT) : null,
        dateToObj.isValid() ? dateToObj.format(DATE_TO_FORMAT) : null,
      )
    }
  }

  const triggerChange = (dateId: string) => {
    if (onChange) {
      if (dateId === 'custom') {
        triggerChangeCustom()
        return
      }

      const [from, to] = getDateRange(dateId)

      onChange(
        dateId,
        from.isValid() ? from.format(DATE_FROM_FORMAT) : null,
        to.isValid() ? to.format(DATE_TO_FORMAT) : null,
      )
    }
  }

  useEffect(() => {
    triggerChange(dateId)
  }, []) // eslint-disable-line

  const handleClickButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    triggerChange(e.currentTarget.name)
  }

  const handleClickSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    triggerChangeCustom()
  }

  const handleChangeDate = (e: React.FormEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget

    if (name === 'from') {
      setDateFrom(e.currentTarget.value)
      setItem('customDateFrom', e.currentTarget.value)
    } else if (name === 'to') {
      setDateTo(e.currentTarget.value)
      setItem('customDateTo', e.currentTarget.value)
    }
  }

  return (
    <>
      <Row className="pb-4">
        <Col>
          {options.map((option) => {
            const isSelected = option.id === dateId

            return (
              <Button
                key={option.id}
                name={option.id}
                outline={!isSelected}
                className="mr-2"
                size="sm"
                color="primary"
                onClick={handleClickButton}
              >
                {option.label}
              </Button>
            )
          })}
        </Col>
      </Row>
      {dateId === 'custom' ? (
        <Row className="pb-4">
          <Col>
            <Form action="" method="post" inline>
              <FormGroup className="pr-1">
                <Label htmlFor="input-date-from" className="pr-1">
                  From
                </Label>
                <Input
                  name="from"
                  onChange={handleChangeDate}
                  value={dateFrom}
                  type="date"
                  id="input-date-from"
                  placeholder="date"
                  required
                />
              </FormGroup>
              <FormGroup className="pr-2">
                <Label htmlFor="input-date-to" className="pr-1">
                  To
                </Label>
                <Input
                  name="to"
                  onChange={handleChangeDate}
                  value={dateTo}
                  type="date"
                  id="input-date-to"
                  placeholder="date"
                  required
                />
              </FormGroup>
              <FormGroup className="pr-1">
                <Button
                  onClick={handleClickSubmit}
                  type="submit"
                  color="primary"
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      ) : null}
    </>
  )
}

export default DatePickerRow
