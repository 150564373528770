import { DropDownFilterButton, TagFilterButton } from 'components'
import BoolFilterButton from 'containers/common/Filters/BoolFilterButton'
import CategoriesFilterButton from 'containers/common/Filters/CategoriesFilterButton'
import CollectionsFilterButton from 'containers/common/Filters/ColectionsFilterButton'
import ProductAttributesFilterButton from 'containers/common/Filters/ProductAttributesFilterButton'
import { contentBoolFilters } from './contentBoolFilters'

const productStatuses = ['publish', 'private', 'draft']

const advancedBoolFilters = [
  {
    name: 'inStock',
    label: 'In Stock',
    optionLabels: ['Out Of Stock', 'In Stock'],
  },
  {
    name: 'isWarrantyAvailable',
    label: 'Is Warranty Available',
    optionLabels: ['Is Warranty unavailable', 'Is Warranty available'],
  },
]

export type ManageContentFiltersType = FiltersTypeWithUrlUpdater & {
  q: FilterType
  selected: FilterType
  status: FilterType
  tags: FilterType
  categories: FilterType
  collections: FilterType
  isEnabled: FilterType
  hasImage: FilterType
  hasMainContent: FilterType
  hasShortDesc: FilterType
}

type FiltersProps = {
  visibleColumnIds: string[]
  filters: ManageContentFiltersType
  tags: ApiTag[]
}

export const Filters = (props: FiltersProps) => {
  const { tags, filters, visibleColumnIds } = props

  return (
    <>
      <TagFilterButton {...{ tags, filter: filters.tags }} />
      {visibleColumnIds.includes('status') ? (
        <DropDownFilterButton
          label="Status"
          filter={filters.status}
          values={productStatuses}
        />
      ) : null}
      <ProductAttributesFilterButton {...{ filters }} />
      <CategoriesFilterButton {...{ filter: filters.categories }} />
      <CollectionsFilterButton {...{ filter: filters.collections }} />
      <BoolFilterButton
        label="Content"
        boolFilters={contentBoolFilters}
        {...{ filters }}
      />
      <BoolFilterButton
        label="Advanced"
        boolFilters={advancedBoolFilters}
        {...{ filters }}
      />
    </>
  )
}

export default Filters
