import { formatDateReadable } from 'utils'

type Note = {
  id: number
  author: string
  content_html: string
  created_at: string
}

type NotesListProps = {
  notes: Note[]
}

export const NotesList = (props: NotesListProps) => {
  const { notes } = props

  return (
    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
      {notes.map((note) => {
        const { id, author, content_html, created_at } = note
        return (
          <li className="mb-2" key={id}>
            <div
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: content_html }}
            />
            <div style={{ fontSize: 11, fontStyle: 'italic' }}>
              {author} on {formatDateReadable(created_at, true)}
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default NotesList
