import Swal from 'sweetalert2'

export const askQuestion = async (text: string, options = {}) => {
  const ret = await Swal.fire({
    icon: 'question',
    text,
    showCancelButton: true,
    showCloseButton: true,
    ...options,
  })

  return ret.value
}

export const askQuestionWithReason = async (
  text: string,
  options: any = {},
) => {
  const ret = await Swal.fire({
    icon: 'question',
    text,
    showCancelButton: true,
    showCloseButton: true,
    input: 'text',
    inputLabel: 'Reason',
    inputPlaceholder: 'Enter reason here',
    inputValidator: (value) => {
      if (!value) {
        return 'Please enter a reason.'
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
    showLoaderOnConfirm: options.preConfirm != null,
    ...options,
  })

  return ret.value
}

export const showError = (text: string) => {
  return Swal.fire({
    icon: 'error',
    text,
  })
}

export const showInfo = (text: string) => {
  return Swal.fire({
    icon: 'info',
    text,
  })
}
