import type {
  FormDef,
  FormDefElement,
  FormDefItem,
} from 'components/Form/types'
import states from 'utils/us-states'

const stateOptions = [
  {
    label: 'Select State...',
    value: '',
  },
  ...states.map((state) => {
    return {
      label: state.name,
      value: state.abbreviation,
    }
  }),
]

export const getFormDef = (addressType: string): FormDef => {
  const ret = [
    {
      label: 'First Name',
      placeholder: 'First Name',
      name: `${addressType}FirstName`,
      type: 'text',
    },
    {
      label: 'Last Name',
      placeholder: 'Last Name',
      name: `${addressType}LastName`,
      type: 'text',
    },
    {
      group: [
        {
          label: 'Address',
          placeholder: 'Address',
          name: `${addressType}Address1`,
          type: 'text',
        },
        {
          label: 'Address 2',
          placeholder: 'Address 2',
          name: `${addressType}Address2`,
          type: 'text',
        },
      ],
    },
    {
      label: 'State',
      placeholder: 'Select State...',
      name: `${addressType}State`,
      type: 'select',
      options: stateOptions,
    },
    {
      group: [
        {
          label: 'City',
          placeholder: 'City',
          name: `${addressType}City`,
          type: 'text',
        },
        {
          label: 'Postcode',
          placeholder: 'Postcode',
          name: `${addressType}Postcode`,
          type: 'text',
        },
      ],
    },
    {
      label: 'Billing Email',
      placeholder: 'Billing Email',
      name: 'billingEmail',
      type: 'text',
    },
    {
      label: 'Billing Phone',
      placeholder: 'Billing Phone',
      name: 'billingPhone',
      type: 'text',
    },
  ]

  const ret2: Array<FormDefItem> = ret.filter((field) => {
    if (field.group) {
      return true
    } else {
      const field_ = field as FormDefElement
      return String(field_.name).indexOf(addressType) === 0
    }
  }) as Array<FormDefItem>

  return ret2
}

export const getFormFields = (formDef: any) => {
  let ret = []

  for (const field of formDef) {
    if (field.group) {
      ret = [...ret, ...getFormFields(field.group)]
    } else {
      ret = [...ret, field.name]
    }
  }

  return ret
}
