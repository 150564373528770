import { LoadingImage } from 'components'
import { useDropDownToggler } from 'hooks'
import { useApi } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

export const SyncActionButton = (props) => {
  const { selectedRows, onUpdated } = props

  const apiAction = useApi({ action: 'shipments_syncShipments' }, null, {
    errorModal: true,
  })

  const toggler = useDropDownToggler()

  const handleClickSync = useCallback(async () => {
    await apiAction.performRequest({ json: { ids: selectedRows } })

    if (onUpdated) {
      onUpdated()
    }
  }, [onUpdated, apiAction, selectedRows])

  const disabled = selectedRows.length === 0

  const isLoading = apiAction.isLoading

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret className={disabled ? 'disabled' : ''}>
        Sync {isLoading ? <LoadingImage small /> : null}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickSync}>Sync Shipments</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default SyncActionButton
