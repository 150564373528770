import EditPanelCard from 'components/EditPanelCard'
import type { FormDefElement, UseFormReturn } from 'components/Form/types'
import { FormRenderer } from 'components/index'
import { useCallback, useMemo } from 'react'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import type { GetProduct, ProductFormState } from '../types'
import ZoneRangeSelect from './ZoneRangeSelect'
import _formDef from './formDef'

const TreeInfoCard = ({
  form,
  product,
  readOnly,
}: {
  form: UseFormReturn<ProductFormState>
  product?: GetProduct
  readOnly: boolean
}) => {
  const formDef = useMemo(() => {
    return (_formDef as FormDefElement[]).map((field) => {
      // Fill select options from API response.
      if (
        field.type === 'select' &&
        product != null &&
        Object.hasOwn(product, `${field.name}Options`)
      ) {
        let label = field.label

        if (field.name === 'pa_excluded-states') {
          label = (
            <>
              Excluded States{' '}
              <small>
                <i>
                  (can omit globally excl. states:{' '}
                  {product.globalExcludeStates.join(' ')})
                </i>
              </small>
            </>
          )
        }

        const options = product?.[`${field.name}Options`] || []

        return {
          ...field,
          label,
          options: field.multi
            ? options
            : [{ value: '', option: '' }, ...options],
        }
      }

      return field
    })
  }, [product])

  const handleChangeZones = useCallback(
    (zones: number[]) => {
      form.set('growingZones', zones)
    },
    [form],
  )

  return (
    <EditPanelCard caption="Tree Info" stateId="treeinfo_card">
      <Row>
        <Col>
          <FormGroup className="mb-0">
            <Label className="mb-0">Growing Zones</Label>
            <ZoneRangeSelect
              selectedZones={form.state.growingZones || []}
              onChange={handleChangeZones}
              {...{ readOnly }}
            />
          </FormGroup>
        </Col>
      </Row>

      <FormRenderer {...{ form, formDef, readOnly }} />
    </EditPanelCard>
  )
}

export default TreeInfoCard
