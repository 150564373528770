import { Badge } from 'reactstrap'

type ReturnStatusBadgeProps = {
  value?: string
  className?: string
}

const ReturnStatusBadge = ({ className, value }: ReturnStatusBadgeProps) => {
  let statusColor = 'secondary'
  const statusText = String(value).toUpperCase().replace(/_/g, ' ')

  switch (value.toLowerCase()) {
    case 'waiting_for_customer':
    case 'waiting_for_items':
      statusColor = 'warning'
      break
    case 'needs_return_to_customer':
      statusColor = 'danger'
      break
    case 'completed':
      statusColor = 'success'
      break
  }

  return (
    <Badge {...{ className }} color={statusColor}>
      {statusText}
    </Badge>
  )
}

export default ReturnStatusBadge
