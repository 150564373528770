import type { LocationsGetAllResponse } from '@ttc/api/locations'
import { PageNavHead, PageNavTail } from 'components'
import {
  Table,
  defaultCellRenderer,
  setSelectedRows,
  useTableApi,
  useTableWithUrlUpdater,
} from 'components/Table'
import { useApi, usePageTitle } from 'hooks'
import { useBoolean, usePanelControl } from 'hooks'
import { useCallback } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { getStorage } from 'utils/storage'
import EditZonePanel from './EditZonePanel'
import columnDef from './columnDef'

const pageTitle = 'Manage Zones'

const cacheKey = 'manageZones'
const [setItem, getItem] = getStorage(cacheKey)

const ManageZones = ({ readOnly }: { readOnly: boolean }) => {
  const [state, dispatch] = useTableWithUrlUpdater({
    getItem,
    setItem,
    cacheKey,
  })
  const { isLoading, selectedRowId, selectedRows } = state
  const [triggerSearch] = useTableApi('zones_getAll', state, dispatch, {
    withQty: true,
    limit: 1000,
  })

  const apiLoadLocations = useApi<LocationsGetAllResponse>(
    () => ({ action: 'locations_getAll' }),
    null,
    () => ({
      autoPerform: true,
      cache: true,
    }),
  )

  usePageTitle(pageTitle)

  const editZonePanel = usePanelControl({
    selectedRows,
    openForSingleRow: true,
  })

  const addZone = useBoolean(false)

  const handleClickAdd = useCallback(() => {
    addZone.on()
    editZonePanel.open()
  }, [addZone, editZonePanel])

  const handleClosePanel = useCallback(() => {
    editZonePanel.close()
    addZone.off()
    dispatch(setSelectedRows([]))
    triggerSearch()
  }, [dispatch, editZonePanel, addZone, triggerSearch])

  const renderCell = useCallback((props) => {
    const { row, col } = props

    if (col.id === 'name') {
      return (
        <td style={{ fontWeight: 'bold' }}>
          <div
            className="mr-2 d-inline-block"
            style={{
              width: 10,
              height: 10,
              borderRadius: 3,
              background: row.color,
            }}
          />
          {row.name}
        </td>
      )
    } else if (col.id.indexOf('is_') === 0) {
      return <td> {row[col.id] ? 'Yes' : 'No'} </td>
    }

    return defaultCellRenderer(props)
  }, [])

  return (
    <>
      <PageNavHead {...{ pageTitle, isLoading, onClickReload: triggerSearch }}>
        {!readOnly ? <Button onClick={handleClickAdd}>Add Zone</Button> : null}
      </PageNavHead>
      <PageNavTail />
      <Container fluid className="mt-4">
        <div className="animated fadeIn">
          <EditZonePanel
            {...{ readOnly, apiLoadLocations }}
            editId={addZone.value ? null : selectedRowId}
            isOpen={editZonePanel.isOpen}
            onClose={handleClosePanel}
          />
          <Row>
            <Col>
              <Table
                entityName="zones"
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef,
                  dispatch,
                  hasPagination: false,
                  renderCell,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default ManageZones
