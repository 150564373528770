import { EditPanel, Loading } from 'components'
import InventoryLogCard from 'containers/common/InventoryLogCard'

type LogPanelProps = {
  id: number
  isOpen: boolean
  onClose: () => void
}

const LogPanel = (props: LogPanelProps) => {
  const { isOpen, onClose, id } = props

  return (
    <EditPanel caption="Log" {...{ isOpen, onClose }}>
      {isOpen ? (
        <InventoryLogCard apiName="purchaseOrders_getLog" id={id} />
      ) : (
        <Loading />
      )}
    </EditPanel>
  )
}

export default LogPanel
