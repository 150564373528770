import type { Action, CartItemType } from 'containers/AddOrder/useCart/types'
import { useCallback, useEffect, useRef } from 'react'
import { Button, Card, CardBody, CustomInput } from 'reactstrap'
import Swal from 'sweetalert2'
import { showError } from 'utils/alert'
import {
  addCartItem,
  resetEditCartItem,
  setEditCartItemPrice,
  setEditCartItemQuantity,
  setEditCartItemWarranty,
} from './../useCart'
import ProductInfo from './ProductInfo'

type EditProductCartProps = {
  dispatch: React.Dispatch<Action>
  editCartItem: CartItemType
  isUpdatingCartItem: boolean
} & CartItemType

const EditProductCard = (props: EditProductCartProps) => {
  const {
    dispatch,
    id,
    lineItemId,
    name,
    imageUrl,
    quantity,
    customPrice,
    editCartItem,
    isUpdatingCartItem,
    numWeb,
    isAlwaysStock,
    isSubscription,
    permalink,
    hasWarranty,
    allowWarranty,
  } = props

  const qtyInput = useRef(null)

  useEffect(() => {
    if (qtyInput && qtyInput.current) {
      /* setTimeout prevents triggering the onKeyUp event immediatly after
       * the input element appeared. */
      setTimeout(() => {
        qtyInput.current?.focus()
      }, 100)
    }
  }, [])

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape') {
        dispatch(resetEditCartItem())
      } else if (e.key === 'Enter') {
        dispatch(
          addCartItem(
            editCartItem,
            Number(customPrice) * 100,
            quantity,
            hasWarranty,
          ),
        )
      }
    },
    [dispatch, editCartItem, customPrice, quantity, hasWarranty],
  )

  const handleChangeQuantity = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setEditCartItemQuantity(Number(e.currentTarget.value)))
    },
    [dispatch],
  )

  const handleChangePrice = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setEditCartItemPrice(e.currentTarget.value))
    },
    [dispatch],
  )

  const handleChangeWarranty = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setEditCartItemWarranty(e.currentTarget.checked))
    },
    [dispatch],
  )

  const handleClickAdd = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      const addQty = editCartItem.originalQuantity
        ? quantity - editCartItem.originalQuantity
        : quantity

      const confirm = () => {
        dispatch(
          addCartItem(
            editCartItem,
            Number(customPrice) * 100,
            quantity,
            hasWarranty,
          ),
        )
      }

      // Prevent adding new subscription items to cart.
      if (isSubscription && editCartItem.originalQuantity == null) {
        showError('Subscriptions can only be purchased via web checkout.')
        return
      }

      if (!isAlwaysStock && addQty > numWeb) {
        Swal.fire({
          text: `You are attempting to add (${addQty}) ${name} to your
            cart but there are (${numWeb}) in stock. Do you want to proceed?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        }).then((result) => {
          if (result.value) {
            confirm()
          }
        })
      } else {
        confirm()
      }
    },
    [
      isAlwaysStock,
      isSubscription,
      hasWarranty,
      editCartItem,
      customPrice,
      numWeb,
      quantity,
      dispatch,
      name,
    ],
  )

  const handleClickCancel = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      dispatch(resetEditCartItem())
    },
    [dispatch],
  )

  return (
    <Card>
      <CardBody className="d-flex">
        <div style={{ flex: 1, alignSelf: 'center', maxWidth: 90 }}>
          <a target="noopener" href={permalink}>
            <img
              style={{ objectFit: 'contain', background: 'white' }}
              alt=""
              width="80"
              height="80"
              src={imageUrl}
            />
          </a>
        </div>
        <div style={{ flex: 1 }}>
          <ProductInfo showStock={true} {...props} hasWarranty={false} />
          {allowWarranty ? (
            <CustomInput
              className="checkbox"
              type="checkbox"
              bsSize="sm"
              name="warranty"
              label="Warranty"
              id={`warranty-checkbox-${id}-${lineItemId}`}
              checked={hasWarranty}
              onChange={handleChangeWarranty}
            />
          ) : null}
        </div>
        <div
          className="d-flex"
          style={{ minWidth: 140, flex: 1, flexDirection: 'column' }}
        >
          <div className="text-right" style={{ flex: 1 }}>
            <input
              onKeyUp={handleKeyUp}
              ref={qtyInput}
              style={{ maxWidth: 60 }}
              name="quantity"
              value={quantity}
              onChange={handleChangeQuantity}
              type="number"
              min={1}
              id="input-product-quantity"
              className="d-inline-block form-control"
              placeholder="Qty"
            />
            <span className="ml-1 mr-1"> x </span>
            <input
              onKeyUp={handleKeyUp}
              style={{ maxWidth: 90 }}
              name="price"
              value={customPrice}
              onChange={handleChangePrice}
              type="number"
              min={0}
              step="any"
              id="input-product-price"
              className="d-inline-block form-control"
              placeholder="Price"
            />
          </div>
          <div
            className="text-right"
            style={{ flex: 1, verticalAlign: 'bottom', maxHeight: 30 }}
          >
            <Button
              onClick={handleClickAdd}
              className="mr-2"
              size="sm"
              color="primary"
            >
              {isUpdatingCartItem ? 'Update' : 'Add to Cart'}
            </Button>
            <Button onClick={handleClickCancel} size="sm" color="danger">
              Cancel
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default EditProductCard
