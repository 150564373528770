import { Badge } from 'reactstrap'

const typeColors = {
  giftcard: 'pink',
  warranty_claim: 'light_blue',
  store_credit: 'orange',
}

type GiftCardTypeBadgeProps = {
  type?: string
  className?: string
}

const GiftCardTypeBadge = ({ className, type }: GiftCardTypeBadgeProps) => {
  return (
    <Badge {...{ className }} style={{ backgroundColor: typeColors[type] }}>
      {String(type).toUpperCase()}
    </Badge>
  )
}

export default GiftCardTypeBadge
