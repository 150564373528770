import React from 'react'

export type AuthContextType = {
  isLoggedIn: boolean
  userName: string
  userID: number
  profileImageUrl: string
  logout: () => Promise<void>
  hasCap: (cap: string) => boolean
}

const AuthContext = React.createContext<AuthContextType>(null)

export default AuthContext
