import { assetPath } from 'utils'

type LoadingImageProps = {
  className?: string
  small?: boolean
}

export const LoadingImage = (props: LoadingImageProps) => {
  return (
    <img
      {...{ className: props.className }}
      style={props.small ? { width: 11 } : null}
      src={assetPath('img/loading-spinner-grey.gif')}
      alt="Loading..."
    />
  )
}

const Loading = () => {
  return (
    <div style={{ textAlign: 'center', padding: '30px' }}>
      <LoadingImage />
    </div>
  )
}

export default Loading
