import { DropDownItemCheckbox, SelectCategories } from 'components'
import { useApi, useBoolean, useDropDownToggler, useStateful } from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

type CategoriesFilterButtonProps = {
  filter: FilterType
}

const CategoriesFilterButton = (props: CategoriesFilterButtonProps) => {
  const { filter } = props

  const toggler = useDropDownToggler()

  const handleChangeCategories = useCallback(
    (options: any[]) => {
      filter.setValues(options || [])
    },
    [filter],
  )

  const isWithoutChecked =
    filter.values.length === 1 && filter.values[0] === 'without'

  const handleClickWithout = useCallback(() => {
    if (isWithoutChecked) {
      filter.clear()
    } else {
      filter.setValues(['without'])
    }
  }, [filter, isWithoutChecked])

  const hasValue = filter.values.length > 0

  const categoryNames = useStateful<Record<string, string> | null>(null)

  // filter.value can be string[], if hydrated from query string
  const values = useMemo(() => {
    return filter.values.map((value: SelectOption | string) => {
      if (typeof value === 'object') {
        return value
      }

      const label = categoryNames.value ? categoryNames.value[value] : value

      return { label, value }
    })
  }, [filter.values, categoryNames])

  const isHydrated = useBoolean(false)

  const apiGetCategory = useApi<{
    rows: { id: number; label: string }[]
  } | null>({
    action: 'inventory_getCategories',
  })

  // We need to fetch the category names if we have been hydrated from query string.
  useEffect(() => {
    if (values.length === 0 || isHydrated.value) {
      return
    }

    isHydrated.on()

    const load = async () => {
      const names = {}

      for (const cat of values) {
        const id = Number(cat.value)

        const ret = await apiGetCategory.performRequest({
          json: { id: Number(cat.value) },
        })

        if (ret.rows) {
          names[id] = ret.rows[0].label
        }
      }

      categoryNames.set(names)
    }

    load()
  }, [apiGetCategory, isHydrated, values, categoryNames])

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret className={hasValue ? 'hasValue' : ''}>
        {isWithoutChecked ? (
          <span>Without Category</span>
        ) : values.length === 0 ? (
          <span>Categories</span>
        ) : values.length === 1 ? (
          <span>{values[0].label}</span>
        ) : (
          <span>{values.length} Categories</span>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={filter.clear}>
          <i className="mr-1 fa fa-ban" /> Clear
        </DropdownItem>
        {!isWithoutChecked ? (
          <div
            className="p-3"
            style={{
              minWidth: 400,
              maxWidth: 600,
              borderBottomWidth: 1,
              borderBottomColor: '#c8ced3',
              borderBottomStyle: 'solid',
            }}
          >
            <SelectCategories
              placeholder="Categories"
              value={values}
              onChange={handleChangeCategories}
            />
          </div>
        ) : null}
        <DropDownItemCheckbox
          onClick={handleClickWithout}
          isChecked={isWithoutChecked}
        >
          Without Category
        </DropDownItemCheckbox>
      </DropdownMenu>
    </Dropdown>
  )
}

export default CategoriesFilterButton
