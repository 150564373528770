import type { FormDef } from 'app/components/Form/types'
import { EditPanel, EditPanelCard, FormRenderer } from 'components'
import { getFormInitState, useApi, useFormWithDef, usePrevious } from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { Col, Row } from 'reactstrap'
import { askQuestion, showInfo } from 'utils/alert'

const formDefTemplate: FormDef = [
  {
    label: 'Amount in USD',
    name: 'amount',
    type: 'text',
  },
  {
    label: 'Number of codes',
    name: 'count',
    type: 'number',
  },
]

const AddBatchPanel = ({
  isOpen,
  onClose,
  defaultValues,
}: {
  isOpen: boolean
  onClose: (id?: string, isNew?: boolean) => void
  defaultValues?: any
}) => {
  const formDef = useMemo(() => {
    return [...formDefTemplate]
  }, [])

  const form = useFormWithDef(formDef)

  const apiAdd = useApi({ action: 'giftCards_createBatch' })

  // Reset form as soon as panel opens.
  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (prevIsOpen !== isOpen) {
      if (isOpen) {
        const initState = getFormInitState(formDef)
        form.reset({ ...initState, ...defaultValues })
      } else {
        form.reset()
      }
    }
  }, [form, prevIsOpen, isOpen, formDef, defaultValues])

  const handleSubmit = useCallback(async () => {
    const formSubmit = async () => {
      const { count, amount } = form.state

      if (
        !(await askQuestion(
          `Please confirm the creation of ${count} codes with a value of $${amount} each.`,
        ))
      ) {
        return
      }

      const json = { ...form.state }

      const ret = await apiAdd.performRequest({ json })

      if (ret && ret.batchId) {
        await showInfo(`Batch ${ret.batchId} created.`)

        onClose(ret.id)
      }
    }

    formSubmit()
  }, [apiAdd, form.state, onClose])

  const isLoading = apiAdd.isLoading

  const canSubmit =
    !isLoading && !!Number(form.state.amount) && !!Number(form.state.count)

  return (
    <EditPanel
      onSubmit={handleSubmit}
      caption="Create Gift Card Batch"
      submitButtonLabel="Create Gift Card Batch"
      {...{ isOpen, onClose, isLoading, canSubmit }}
    >
      <EditPanelCard caption="Details">
        {apiAdd.error ? (
          <Row>
            <Col className="text-danger">{apiAdd.error}</Col>
          </Row>
        ) : null}
        <FormRenderer key={`${isOpen ? 1 : 0}`} {...{ form, formDef }} />
      </EditPanelCard>
    </EditPanel>
  )
}

export default AddBatchPanel
