import { EditPanelCard, NotesList } from 'components'
import CardEditButton from 'containers/common/CardEditButton'
import { type ApiCall, useApi, useBoolean, useStateful } from 'hooks'
import { useCallback } from 'react'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'

type NotesCardProps = {
  getOrder: ApiCall
  readOnly?: boolean
}

const NotesCard = (props: NotesCardProps) => {
  const { getOrder, readOnly } = props
  const { result } = getOrder
  const { hasNotesFromBuyer, notesFromBuyer, hasNotesToBuyer, notesToBuyer } =
    result
  const note = useStateful<string>('')
  const editMode = useBoolean()

  const handleChangeText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      note.set(e.target.value)
    },
    [note],
  )

  const headerActions = (
    <CardEditButton isEditing={editMode.value} onClick={editMode.toggle} />
  )

  const handleClickCancel = useCallback(() => {
    editMode.off()
  }, [editMode])

  const apiAddNote = useApi(
    () => ({ action: 'orders_addOrderNote' }),
    null,
    () => ({ errorModal: true, throws: true }),
  )

  const isLoading = apiAddNote.isLoading
  const canSubmit = !isLoading && note.value.length

  const { orderId } = getOrder.result
  const handleClickSubmit = useCallback(async () => {
    try {
      await apiAddNote.performRequest({
        json: { id: orderId, notes: note.value },
      })
      editMode.off()
      note.set('')

      getOrder.performRequest()
    } catch (_e) {
      /* Ignore */
    }
  }, [orderId, note, apiAddNote, editMode, getOrder])

  return (
    <EditPanelCard
      caption="Notes"
      stateId="orders_notes"
      bodyProps={{ className: 'p-3' }}
      headerActions={!readOnly ? headerActions : null}
    >
      <Row className="mt-2">
        <Col>
          <b>Notes from the buyer</b>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>{hasNotesFromBuyer ? notesFromBuyer : <i>(empty)</i>}</Col>
      </Row>
      {hasNotesToBuyer || editMode.value ? (
        <>
          <Row className="mt-2">
            <Col>
              <b>Notes to the buyer</b>
            </Col>
          </Row>
          {editMode.value ? (
            <>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="note_textarea">
                      Add Note to the buyer (salutation will be added
                      automatically)
                    </Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="note_textarea"
                      value={note.value}
                      rows={6}
                      onChange={handleChangeText}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="text-right">
                  <Button
                    disabled={!canSubmit}
                    size="sm"
                    className="m-2"
                    onClick={handleClickSubmit}
                  >
                    Send email
                  </Button>
                  <Button
                    disabled={isLoading}
                    size="sm"
                    className="m-2"
                    onClick={handleClickCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </>
          ) : null}
          <Row className="mb-2">
            <Col>{hasNotesToBuyer && <NotesList notes={notesToBuyer} />}</Col>
          </Row>
        </>
      ) : null}
    </EditPanelCard>
  )
}

export default NotesCard
