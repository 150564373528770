import useApi from 'hooks/useApi'
import type { WarrantyGetOrderResponse } from '@ttc/api/warranty'

export const useWarrantyInfo = (
  orderId: number,
): WarrantyGetOrderResponse | null => {
  const getWarrantyInfo = useApi<WarrantyGetOrderResponse>(
    () => ({ action: 'warranty_getOrderById', json: { id: orderId } }),
    null,
    () => ({ autoPerform: true }),
  )

  return getWarrantyInfo.result
}
