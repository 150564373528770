import { EditPanel, Loading } from 'components'
import type { ApiCall, StatefulState } from 'hooks'
import { useCallback } from 'react'
import { Badge } from 'reactstrap'
import LogCard from './LogCard'
import OrdersCard from './OrdersCard'
import OrphanedCard from './OrphanedCard'

type BatchOrdersProps = {
  readOnly: boolean
  onToggle: () => void
  getOrders: ApiCall
  batchIds: string[]
  selectedOrder: StatefulState<any>
  loadOrdersNow: () => void
  triggerSearch: () => void
}

const BatchOrders = (props: BatchOrdersProps) => {
  const {
    readOnly,
    onToggle,
    getOrders,
    batchIds,
    selectedOrder,
    loadOrdersNow,
  } = props

  const triggerSearch = useCallback(() => {
    loadOrdersNow()
    props.triggerSearch()
  }, [loadOrdersNow, props])

  // XXX getOrders -> getOrderSplits
  const { result, hasResult } = getOrders
  const numTotalOrderSplits = hasResult ? result.orderSplits.length : 0
  const numTotalPlants = hasResult ? result.numTotalPlants : 0
  const numRemainingOrderSplits = hasResult ? result.numRemainingOrderSplits : 0
  const numRemainingPlants = hasResult ? result.numRemainingPlants : 0

  const caption = hasResult
    ? `${numRemainingOrderSplits} Orders | ${numRemainingPlants} Plants remaining`
    : ''

  const numBadOrderSplits = hasResult ? result.numBadOrderSplits : 0
  const ordersCardCaption = (
    <>
      {`${numTotalOrderSplits} Orders | ${numTotalPlants} Plants`}
      {numBadOrderSplits ? (
        <Badge style={{ fontSize: 13 }} className="ml-2" pill color="danger">
          {numBadOrderSplits}
        </Badge>
      ) : null}
    </>
  )

  return (
    <>
      <ul className="nav nav-tabs flex-row-reverse">
        <li className="nav-item">
          <a onClick={onToggle} className="active nav-link">
            <i className="icon-list"></i>
          </a>
        </li>
      </ul>

      {!getOrders.hasResult ? (
        <div className="tab-content">
          <Loading />
        </div>
      ) : (
        <div className="tab-content pb-4">
          <EditPanel embedded isOpen {...{ caption }}>
            <OrdersCard
              {...{
                readOnly,
                getOrders,
                triggerSearch,
                isLoading: getOrders.isLoading,
                selectedOrder,
                caption: ordersCardCaption,
              }}
            />
            {batchIds.length === 1 ? (
              <OrphanedCard
                {...{
                  readOnly,
                  triggerSearch,
                  batchId: batchIds[0],
                  getOrders,
                }}
              />
            ) : null}
            {batchIds.length === 1 ? (
              <LogCard
                {...{
                  batchId: batchIds[0],
                }}
              />
            ) : null}
          </EditPanel>
        </div>
      )}
    </>
  )
}

export default BatchOrders
