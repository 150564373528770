import { useTable, useTableApi, useTableColumns } from 'components/Table'
import columnDef from 'containers/ManageShipments/columnDef'
import { useMemo } from 'react'
import { getStorage } from 'utils'

const storageKey = 'editCustomer.shipmentsTable'
const [setItem, getItem] = getStorage(storageKey)

const visibleColumns = [
  'checkbox',
  'id',
  'status',
  'label_status',
  'order_number',
  'tracking_number',
  'created_at',
  'delivered_at',
]
const filteredColumnDef = columnDef.filter((col) =>
  visibleColumns.includes(col.id),
)

export const useShipmentsTable = (id: string) => {
  const [state, dispatch] = useTable({
    cacheKey: `${storageKey}.${id}`,
    getItem,
    setItem,
  })
  const [triggerSearch] = useTableApi('shipments_getAll', state, dispatch, {
    filterCustomers: id,
    offset: 0,
    limit: 1000,
  })

  const columns = useTableColumns(
    'manageCustomerShipments',
    state,
    dispatch,
    columnDef,
  )

  const isEmpty = state.rows.length === 0 && state.didLoad

  return useMemo(
    () => ({
      ...state,
      setItem,
      getItem,
      columnDef: filteredColumnDef,
      dispatch,
      triggerSearch,
      isEmpty,
      columns,
    }),
    [state, dispatch, triggerSearch, isEmpty, columns],
  )
}
