import Header from 'components/Header'
import { usePageTitle } from 'hooks'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Col, Container, Row } from 'reactstrap'
import { getStorage } from 'utils/storage'

const pageTitle = 'Barcode Generator'

const [setItem, getItem] = getStorage('manageBarcodes')

const barcodeUrl = `${global.appConfig.wpBaseUrl}/wp-json/tadmin/v1/barcode?format=svg&code=`

const barcodeSheetUrl = `${global.appConfig.wpBaseUrl}/wp-json/tadmin/v1/barcode-sheet?format=svg&`

const ManageBarcodes = () => {
  const [text, setText] = useState(getItem('text') || '')
  const [codes, setCodes] = useState([])

  usePageTitle(pageTitle)

  useEffect(() => {
    setCodes(
      String(text)
        .split('\n')
        .map((a) => a.trim())
        .filter((a) => a !== ''),
    )
    setItem('text', text)
  }, [text])

  const handleClickCreateSheet = () => {
    const params = codes.map((a) => `codes[]=${a}`).join('&')

    window.open(`${barcodeSheetUrl}${params}`, '_blank')
  }

  const handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
  }

  const handleScan = useCallback((e) => {
    const input = e.detail
    toast.info(input)
  }, [])

  useEffect(() => {
    document.addEventListener('barcodeScan', handleScan)

    return () => {
      document.removeEventListener('barcodeScan', handleScan)
    }
  })

  return (
    <Container fluid>
      <div className="mt-4 manage-inventory animated fadeIn">
        <Header isLoading={false}>{pageTitle}</Header>
        <Row>
          <Col md="2">
            <Button
              className="mb-3"
              color="primary"
              onClick={handleClickCreateSheet}
            >
              Create Sheet
            </Button>
            <textarea
              placeholder="Enter one code per line"
              style={{ width: '100%', height: 300 }}
              onChange={handleChangeText}
              value={text}
            />
          </Col>
          <Col className="pl-4" md="10">
            {codes.map((c, i) => {
              const [code] = c.split(',')

              return (
                <div className="mb-2" key={`${i}.${c}`}>
                  <img alt={c} src={`${barcodeUrl}${code}`} />
                </div>
              )
            })}
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default ManageBarcodes
