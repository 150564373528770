import type { ProductTaxonomyOptions } from '@ttc/api/products'
import { EditPanelCard, SelectField } from 'components'
import type { UseFormReturn } from 'components/Form/types'
import moment from 'moment'
import { useMemo } from 'react'
import {
  Card,
  CardBody,
  Col,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import type { ProductFormState } from './types'

const CardEditor = ({
  parentProductId,
  readOnly,
  form,
  taxonomyOptions,
}: {
  parentProductId: number
  readOnly: boolean
  form: UseFormReturn<ProductFormState>
  taxonomyOptions: ProductTaxonomyOptions
}) => {
  const { state } = form

  const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD')
  const isSimpleProduct = state.type === 'simple'
  const isNewVariation = state.variationId === '0' && !isSimpleProduct
  const isNewSimpleProduct = isSimpleProduct && !parentProductId
  const didSelectValidVariation =
    !isNewVariation || state.newSize != null || isSimpleProduct

  // Do not allow adding bundles from here for now.
  const newSizesOptions = useMemo(
    () =>
      taxonomyOptions.sizesOptions.filter(
        (option) => option.value !== 'bundle',
      ),
    [taxonomyOptions.sizesOptions],
  )

  return (
    <>
      <Card style={{ borderTopWidth: 0 }}>
        <CardBody>
          <FormGroup className="mb-0">
            {isSimpleProduct ? (
              <SelectField
                isDisabled
                placeholder="Simple Product"
                name=""
                value=""
                options={null}
              />
            ) : (
              <>
                <Label className="mb-0" htmlFor="product-variation">
                  Variation
                </Label>
                <SelectField
                  {...form.selectFieldProps}
                  options={state.variationOptions}
                  value={state.variationId}
                  name="variationId"
                  isClearable={false}
                  isSearchable={false}
                />
              </>
            )}
          </FormGroup>
          {isNewVariation ? (
            <FormGroup className="mb-0">
              <Label className="mb-0">Select new variation size:</Label>
              <SelectField
                {...form.selectFieldProps}
                options={newSizesOptions}
                value={state.newSize}
                name="newSize"
                isClearable={false}
                isSearchable={false}
              />
            </FormGroup>
          ) : null}
        </CardBody>
      </Card>
      {didSelectValidVariation ? (
        <>
          <EditPanelCard
            caption={isSimpleProduct ? 'Properties' : 'Variation Properties'}
            stateId="product-properties"
          >
            {state.type === 'variable' ? (
              <>
                <Row>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label className="mb-0" htmlFor="product-sku">
                        {isNewVariation ? 'SKU Suffix' : 'SKU'}
                      </Label>
                      <Input
                        {...form.inputProps}
                        value={state.sku || ''}
                        type="text"
                        id="product-sku"
                        name="sku"
                        placeholder={
                          isNewVariation
                            ? 'Enter SKU suffix only (without dash)'
                            : 'SKU'
                        }
                        required
                        disabled={readOnly}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label
                        className="mb-0"
                        htmlFor="product-variation-description"
                      >
                        Variation Description
                      </Label>
                      <Input
                        {...form.inputProps}
                        value={state.variationDescription || ''}
                        type="text"
                        id="product-variation-description"
                        name="variationDescription"
                        disabled={readOnly}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            ) : null}
            <Row>
              {state.type === 'variable' ? (
                <Col>
                  <FormGroup className="mb-0">
                    <CustomInput
                      {...form.inputProps}
                      bsSize="lg"
                      className="mt-3"
                      checked={state.enabled}
                      label="Enabled"
                      type="checkbox"
                      id="product-enabled"
                      name="enabled"
                      disabled={readOnly}
                    />
                  </FormGroup>
                </Col>
              ) : null}
              <Col>
                <FormGroup className="mb-0">
                  <CustomInput
                    {...form.inputProps}
                    bsSize="lg"
                    className="mt-3"
                    checked={state.isBlocked}
                    label="Blocked"
                    title="Blocked = Product will not be batched."
                    type="checkbox"
                    id="product-blocked"
                    name="isBlocked"
                    disabled={readOnly}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <FormGroup className="mb-0">
                  <Label className="mb-0" htmlFor="supplier-address">
                    Regular Price
                  </Label>
                  <Input
                    {...form.inputProps}
                    value={state.regularPrice || ''}
                    id="product-price"
                    type="text"
                    name="regularPrice"
                    disabled={readOnly}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-0">
                  <Label className="mb-0" htmlFor="product-salePrice">
                    Sale Price
                  </Label>
                  <Input
                    {...form.inputProps}
                    value={state.salePrice || ''}
                    id="product-salePrice"
                    type="text"
                    name="salePrice"
                    disabled={readOnly}
                  />
                </FormGroup>
              </Col>
            </Row>
            {state.type === 'variable' ? (
              <Row>
                <Col>
                  <FormGroup className="mb-0">
                    <Label className="mb-0" htmlFor="product-preorderDate">
                      Pre-Order Date
                    </Label>
                    <Input
                      {...form.inputProps}
                      value={state.preorderDate || ''}
                      id="product-preorderDate"
                      type="date"
                      name="preorderDate"
                      min={tomorrow}
                      disabled={readOnly}
                    />
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
          </EditPanelCard>
        </>
      ) : null}
      <EditPanelCard
        caption={
          state.type === 'simple'
            ? 'Product properties'
            : 'Parent product properties'
        }
        stateId="plant-properties"
      >
        <Row>
          <Col>
            <FormGroup className="mb-0">
              <Label className="mb-0" htmlFor="parent-name">
                Product Name
              </Label>
              <Input
                {...form.inputProps}
                value={state.name || ''}
                type="text"
                id="parent-name"
                name="name"
                placeholder=""
                required
                disabled={readOnly}
              />
            </FormGroup>
          </Col>
        </Row>
        {!isNewSimpleProduct ? (
          <Row>
            <Col>
              <FormGroup className="mb-0">
                <Label className="mb-0" htmlFor="botanicalName">
                  Botanical Name
                </Label>
                <Input
                  {...form.inputProps}
                  value={state.botanicalName || ''}
                  type="text"
                  id="botanicalName"
                  name="botanicalName"
                  placeholder=""
                  disabled={readOnly}
                />
              </FormGroup>
            </Col>
          </Row>
        ) : null}
        {!isNewSimpleProduct && !isNewVariation ? (
          <Row>
            <Col>
              <FormGroup className="mb-0">
                <Label className="mb-0" htmlFor="parent-sku">
                  {isSimpleProduct ? 'SKU' : 'Parent SKU'}
                </Label>
                <Input
                  {...form.inputProps}
                  value={state.parentSku || ''}
                  type="text"
                  id="parent-sku"
                  name="parentSku"
                  placeholder=""
                  required
                  disabled={readOnly}
                />
              </FormGroup>
            </Col>
          </Row>
        ) : null}
        {!isNewSimpleProduct ? (
          <Row>
            <Col>
              <FormGroup className="mb-0">
                <Label className="mb-0" htmlFor="product-plantType">
                  Plant Type
                </Label>
                <SelectField
                  {...form.selectFieldProps}
                  isMulti
                  options={taxonomyOptions.plantTypeOptions}
                  value={state.plantType || null}
                  name="plantType"
                  isClearable
                  isSearchable={false}
                  isDisabled={readOnly}
                />
              </FormGroup>
            </Col>
          </Row>
        ) : null}
      </EditPanelCard>
    </>
  )
}

export default CardEditor
