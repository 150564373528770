import { apiRequest } from 'api'
import styles from 'components/selectStyles'
import AsyncSelect from 'react-select/async'

type SelectProductsProps = {
  className?: string
  value?: any
  isDisabled?: boolean
  onChange?: (value: any) => void
  placeholder?: string
}

type GetProductsReponse = {
  rows: {
    id: number
    sku: string
    name: string
  }[]
}

const loadOptions = async (query: string) => {
  try {
    const ret: GetProductsReponse | Record<string, never> = await apiRequest({
      action: 'products_findVariableProducts',
      query,
    })

    if (ret && ret.rows) {
      return ret.rows.map((row) => {
        const { sku, name } = row
        const label = `${sku} - ${name}`

        return {
          value: row.id,
          sku,
          label,
        }
      })
    } else {
      throw new Error('Invalid response')
    }
  } catch (e) {
    throw new Error(e.message)
  }
}

const SelectVariation = ({
  className,
  value,
  isDisabled,
  onChange,
  placeholder,
}: SelectProductsProps) => {
  return (
    <AsyncSelect
      {...{
        className,
        onChange,
        loadOptions,
        value,
        isDisabled,
      }}
      styles={styles}
      cacheOptions
      placeholder={placeholder || 'Select Products'}
    />
  )
}

export default SelectVariation
