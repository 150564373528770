import type { GiftCard } from '@ttc/api/giftcards'
import { FilterBar, PageNavHead, PageNavTail } from 'components'
import {
  Table,
  setSelectedRow,
  useTableApi,
  useTableColumns,
  useTableWithUrlUpdater,
} from 'components/Table'
import Filters from 'containers/ManageGiftCards/Filters'
import type { ManageGiftcardsFiltersType } from 'containers/ManageGiftCards/Filters/types'
import ManageColumnsPanel from 'containers/common/ManageColumnsPanel'
import {
  useApi,
  useEffectIfObjectChanges,
  useFiltersWithUrlUpdaterWithTableSync,
  usePageTitle,
  usePanelControl,
} from 'hooks'
import { useSidebarForClickOnCell } from 'hooks/useSidebarForClickOnCell'
import { useCallback, useMemo } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { getStorage } from 'utils/storage'
import AddPanel from './AddPanel'
import Sidebar from './Sidebar'
import columnDef from './columnDef'
import AddActionButton from './Actions/AddActionButton'
import ExportActionButton from './Actions/ExportActionButton'
import { getApiBaseUrl } from 'api/index'
import AddBatchPanel from './AddBatchPanel'

const visibleFilters = [
  'q',
  'type',
  'amount',
  'balance',
  'status',
  'reason',
  'batchId',
]

type ManageGiftCardsProps = {
  readOnly: boolean
  canCreate: boolean
  storageKey: string
  pageTitle: string
}

const ManageGiftCards = (props: ManageGiftCardsProps) => {
  const { readOnly, canCreate, storageKey, pageTitle } = props

  const [setItem, getItem] = useMemo(() => getStorage(storageKey), [storageKey])

  // date

  const [state, dispatch] = useTableWithUrlUpdater({
    getItem,
    setItem,
    cacheKey: storageKey,
  })
  const { isLoading, selectedRows } = state

  // This adds the filters and also a 'selected' URL parameter.
  const filters = useFiltersWithUrlUpdaterWithTableSync(
    'manageGiftCardsFilters',
    visibleFilters,
    state,
    dispatch,
  ) as ManageGiftcardsFiltersType

  const query = filters.searchQueryString

  const columns = useTableColumns('manageGiftCards', state, dispatch, columnDef)

  const handleChangeSearch = useCallback(
    (query: string) => {
      filters.q.setValues([query])
    },
    [filters.q],
  )

  const requestParams = useMemo(
    () => ({
      query: '',
      ...filters.requestProps,
      columns: columns.visibleColumnsIds,
    }),
    [filters.requestProps, columns.visibleColumnsIds],
  )

  const [triggerSearch] = useTableApi(
    'giftCards_getAll',
    state,
    dispatch,
    requestParams,
    { autoReloadInterval: 2 * 60 },
  )
  useEffectIfObjectChanges(triggerSearch, requestParams)

  const manageColumnsPanel = usePanelControl()

  const { sidebar, handleClickCell, clickedRowId, sidebarRowIds } =
    useSidebarForClickOnCell(dispatch, selectedRows, 'code')

  usePageTitle(pageTitle)

  const getGiftCard = useApi<GiftCard>(
    useMemo(
      () => ({
        action: 'giftCards_get',
        json: {
          id: clickedRowId.value,
          withLog: true,
        },
      }),
      [clickedRowId.value],
    ),
    null,
    { autoPerform: sidebar.isOpen && clickedRowId.value != null },
  )

  const handleSidebarUpdate = useCallback(() => {
    triggerSearch()
    getGiftCard.performRequest()
  }, [triggerSearch, getGiftCard])

  const addPanel = usePanelControl()

  const handleClickAdd = useCallback(() => {
    addPanel.open()
  }, [addPanel])

  const addBatchPanel = usePanelControl()

  const handleClickAddBatch = useCallback(() => {
    addBatchPanel.open()
  }, [addBatchPanel])

  const apiGetBatches = useApi(
    () => ({ action: 'giftCards_getBatches' }),
    null,
    () => ({ autoPerform: true }),
  )

  const didSelectBatch = filters.filters.batchId.values.length > 0

  const handleClickExport = useCallback(() => {
    const batchId = filters.filters.batchId.values[0]

    let url = `${getApiBaseUrl()}/?action=treeadmin_giftCards_exportBatchCsv`
    url += `&batchId=${batchId}`

    const win = window.open(url)
    win.document.title = 'Exporting...'
  }, [filters])

  const handleCloseAddPanel = useCallback(
    (id: string) => {
      addPanel.close()

      triggerSearch().then(() => {
        /* Open sidebar with newly created gift card. */
        if (id) {
          clickedRowId.set(id)
          dispatch(setSelectedRow(id))
        }
      })
    },
    [dispatch, clickedRowId, addPanel, triggerSearch],
  )

  const handleCloseAddBatchPanel = useCallback(() => {
    addBatchPanel.close()

    apiGetBatches.performRequest()
  }, [apiGetBatches, addBatchPanel])

  return (
    <>
      <PageNavHead
        {...{
          isLoading,
          pageTitle,
          onClickReload: triggerSearch,
          sidebar,
        }}
      />
      <PageNavTail {...{ isLoading, query, handleChangeSearch }}>
        {canCreate ? (
          <>
            <AddActionButton
              onAddSingle={handleClickAdd}
              onAddBatch={handleClickAddBatch}
            />
            {didSelectBatch ? (
              <ExportActionButton onExport={handleClickExport} />
            ) : null}
          </>
        ) : null}
      </PageNavTail>
      <FilterBar
        {...{
          filters,
          manageColumnsPanel,
          columns,
        }}
      >
        <Filters {...{ filters, apiGetBatches }} />
      </FilterBar>
      <Container fluid>
        <div className="mt-4 manage-inventory animated fadeIn">
          <AddPanel isOpen={addPanel.isOpen} onClose={handleCloseAddPanel} />
          <AddBatchPanel
            isOpen={addBatchPanel.isOpen}
            onClose={handleCloseAddBatchPanel}
          />
          <ManageColumnsPanel
            {...{ columns }}
            isOpen={manageColumnsPanel.isOpen}
            onClose={manageColumnsPanel.close}
          />
          <Row>
            <Col>
              <Table
                entityName="gift cards"
                onClickCell={handleClickCell}
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef,
                  dispatch,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
      <Sidebar
        {...{
          readOnly,
          selectedRows: sidebarRowIds,
          getGiftCard,
          onToggle: sidebar.userToggle,
          onUpdate: handleSidebarUpdate,
        }}
      />
    </>
  )
}

export default ManageGiftCards
