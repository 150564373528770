import { CellEditable } from 'components/Table'
import type { ColumnDef } from 'components/Table/types'

export const getColumnDef = (readOnly: boolean, isInternal: boolean) => {
  const columnDef: ColumnDef[] = [
    {
      label: 'Expected',
      id: 'eqty',
      sortColumn: true,
      isEditable: true,
      renderer: !readOnly
        ? (props) => {
            return <CellEditable {...props} />
          }
        : null,
    },
    isInternal
      ? {
          label: 'Loaded',
          id: 'truck_loaded_qty',
          sortColumn: true,
        }
      : null,
    {
      label: 'Arrived',
      id: 'aqty',
      sortColumn: true,
      isEditable: true,
      renderer: !readOnly
        ? (props) => {
            return <CellEditable {...props} />
          }
        : null,
    },
    {
      label: 'WEB',
      id: 'numWeb',
      sortColumn: true,
      isNumeric: true,
    },
    {
      label: 'Product',
      id: 'product',
      sortColumn: true,
    },
    {
      label: 'Size',
      id: 'size',
      sortColumn: true,
      valueColumn: 'product_data.size',
    },
    {
      label: 'SKU',
      id: 'sku',
      sortColumn: true,
      valueColumn: 'product_data.sku',
    },
    {
      label: 'Putaway',
      id: 'zones',
    },
  ].filter((c) => c != null)

  return columnDef
}
