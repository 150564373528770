import { assetPath } from 'utils'

const Header = ({ isLoading, children }) => {
  return (
    <h2 className="mb-4">
      {children}
      {isLoading && (
        <div className="ml-2 d-inline-block">
          <img src={assetPath('img/loading-spinner-grey.gif')} alt="" />
        </div>
      )}
    </h2>
  )
}

export default Header
