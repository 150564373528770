import AuthContext from 'AuthContext'
import type { ColumnPreset, Columns } from 'app/components/Table/types'
import { MANAGETABLECOLUMNPRESETS_READWRITE } from 'caps'
import EditPanel from 'components/EditPanel'
import EditPanelCard from 'components/EditPanelCard'
import type { Column } from 'components/Table/reducer/types'
import { usePrevious, useStateful } from 'hooks'
import {
  Fragment,
  type MouseEvent,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import Creatable from 'react-select/creatable'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import { ListGroup } from 'reactstrap'
import { askQuestion } from 'utils/alert'
import ColorButton from './ColorButton'
import ColorSelector from './ColorSelector'
import Item from './Item'

type ManageColumnsPanelProps = {
  columns: Columns
  isOpen: boolean
  onClose: () => void
}

const ManageColumnsPanel = (props: ManageColumnsPanelProps) => {
  const { hasCap } = useContext(AuthContext)
  const { columns, isOpen, onClose } = props
  const { tableName } = columns

  const editColor = useStateful<string>(null)

  const handleClickItem = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const id = e.currentTarget.getAttribute('data-id')
      columns.toggle(id)
    },
    [columns],
  )

  const handleMoveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      columns.move(hoverIndex, dragIndex)
    },
    [columns],
  )

  const handleClickReset = useCallback(() => {
    columns.reset()
  }, [columns])

  const handleClickResetColors = useCallback(() => {
    columns.resetColors()
  }, [columns])

  const handleClickColorButton = useCallback(
    (id: string) => {
      if (editColor.value) {
        editColor.set(null)
        return
      }

      editColor.set(id)
    },
    [editColor],
  )

  const handleClickCloseColorSelector = useCallback(() => {
    editColor.set(null)
  }, [editColor])

  const preset = useStateful<ColumnPreset | null>(null)

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (prevIsOpen !== isOpen && isOpen) {
      preset.set(columns.selectedPreset)
    }
  }, [prevIsOpen, isOpen, columns.selectedPreset, preset])

  const handleCreatePreset = useCallback(
    async (name: string) => {
      await columns.apiSavePreset.performRequest({
        json: {
          name,
          tableName,
          visibleColumns: columns.visibleSorted.map((c) => c.id),
          sortedColumns: columns.sorted.map((c) => c.id),
        },
      })
      await columns.apiLoadPresets.performRequest()

      preset.set({
        value: name,
        label: name,
        visibleColumns: columns.visibleSorted.map((c) => c.id),
        sortedColumns: columns.sorted.map((c) => c.id),
      })
    },
    [columns, preset, tableName],
  )

  const handleChangePreset = useCallback(
    (input: ColumnPreset | null) => {
      preset.set(input)

      if (input) {
        columns.setPreset(input)
      }
    },
    [preset, columns],
  )

  const handleClickDeletePreset = useCallback(async () => {
    if (!preset) {
      return
    }
    const ret = await askQuestion(
      `Are you sure you want to delete the preset "${preset?.value?.value}"?`,
    )
    if (!ret) {
      return
    }

    await columns.apiDeletePreset.performRequest({ name: preset.value?.value })
    await columns.apiLoadPresets.performRequest()
    preset.set(null)
  }, [columns, preset])

  const handleClickSavePreset = useCallback(async () => {
    if (!preset) {
      return
    }
    const name = preset.value?.value

    await columns.apiSavePreset.performRequest({
      json: {
        name,
        tableName,
        visibleColumns: columns.visibleSorted.map((c) => c.id),
        sortedColumns: columns.sorted.map((c) => c.id),
      },
    })
    await columns.apiLoadPresets.performRequest()
  }, [columns, preset, tableName])

  const canSave = preset?.value != null && columns.selectedPreset == null

  const canManagePresets = hasCap(MANAGETABLECOLUMNPRESETS_READWRITE)

  return (
    <EditPanel caption="Manage Columns" {...{ isOpen, onClose }}>
      <EditPanelCard caption="Columns">
        {canManagePresets ? (
          <>
            <Row>
              <Col>
                <Label className="mb-0">Preset</Label>
                <FormGroup>
                  <Creatable<ColumnPreset>
                    isClearable
                    name="preset"
                    options={columns.presets}
                    value={preset.value}
                    placeholder="None"
                    onCreateOption={handleCreatePreset}
                    onChange={handleChangePreset}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Button
                    disabled={!canSave}
                    size="sm"
                    type="submit"
                    onClick={handleClickSavePreset}
                    className="mr-2"
                  >
                    Save
                  </Button>
                  <Button
                    disabled={preset?.value == null}
                    color="danger"
                    size="sm"
                    type="submit"
                    onClick={handleClickDeletePreset}
                    className="mr-2"
                  >
                    Delete
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </>
        ) : null}
        <Row>
          <Col>
            <Label className="mb-0">Columns</Label>
            <ListGroup>
              {columns.sorted.map((column: Column, i: number) => {
                const { id, label } = column
                const isChecked = columns.isVisible(id)
                const label_ = (
                  <>
                    {label ? label : id}
                    {column.exportOnly ? <i> (export only)</i> : ''}
                  </>
                )

                return (
                  <Fragment key={id}>
                    <Item
                      key={id}
                      index={i}
                      moveItem={handleMoveItem}
                      onClick={handleClickItem}
                      id={id}
                    >
                      {isChecked ? (
                        <i
                          style={{ color: '#4394ee' }}
                          className="fa fa-check-square mr-2"
                        />
                      ) : (
                        <i
                          style={{ color: 'gray' }}
                          className="fa fa-square mr-2"
                        />
                      )}
                      {label_}
                      <ColorButton
                        color={columns.colors[id]}
                        onClick={handleClickColorButton}
                        {...{ id, columns }}
                      />
                    </Item>
                    {editColor.value === id ? (
                      <ColorSelector
                        {...{ id, columns }}
                        onClose={handleClickCloseColorSelector}
                      />
                    ) : null}
                  </Fragment>
                )
              })}
            </ListGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup className="form-actions mt-3">
              <Button
                size="sm"
                type="submit"
                color="danger"
                onClick={handleClickReset}
              >
                Reset Order
              </Button>
              <Button
                size="sm"
                type="submit"
                color="danger"
                onClick={handleClickResetColors}
                className="ml-2"
              >
                Reset Colors
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </EditPanelCard>
    </EditPanel>
  )
}

export default ManageColumnsPanel
