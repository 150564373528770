export default [
  '',
  '#cce5ff',
  '#e2e3e5',
  '#d4edda',
  '#f8d7da',
  '#fff3cd',
  '#d1ecf1',
  '#d6d8d9',
]
