import { EditPanelCard } from 'components'
import type { ApiCall } from 'hooks'
import { Badge, Col, Row } from 'reactstrap'
import ShipmentLabel from './ShipmentLabel'

type OrderSplitCardProps = {
  index: number
  split: any
}

const OrderSplitCard = ({ index, split }: OrderSplitCardProps) => {
  const caption = (
    <span>
      {split.orderNumber} <Badge color="warning">{split.splitStatus}</Badge>
    </span>
  )

  return (
    <EditPanelCard
      caption={caption}
      stateId={`orders_split_${index}`}
      bodyProps={{ className: 'p-3' }}
    >
      <Row>
        <Col>
          Order Status:{' '}
          <Badge color="warning">{String(split.status).toUpperCase()}</Badge>
        </Col>
      </Row>
      {split.splitStatus != null ? (
        <Row>
          <Col>
            Split Status:{' '}
            <Badge color="warning">
              {String(split.splitStatus).toUpperCase()}
            </Badge>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col>
          Batch:{' '}
          {split.batchName ? (
            <Badge color="info">{split.batchName}</Badge>
          ) : (
            'n/a'
          )}
        </Col>
      </Row>
      {split.shipmentLabels.length ? (
        <Row className="mt-2">
          <Col>
            <b>Tracking Info</b>
            {split.shipmentLabels.map((item: any) => {
              return <ShipmentLabel key={item.id} {...item} />
            })}
          </Col>
        </Row>
      ) : null}
    </EditPanelCard>
  )
}

type OrderSplitDeliveryCardsProps = {
  getOrder: ApiCall
}

const OrderSplitDeliveryCards = (props: OrderSplitDeliveryCardsProps) => {
  const { getOrder } = props
  const { result } = getOrder

  if (!result.orderSplits) {
    return null
  }

  return result.orderSplits.map((split: any, index: number) => (
    <OrderSplitCard key={split.orderNumber} {...{ index, split }} />
  ))
}

export default OrderSplitDeliveryCards
