import type { GiftCard } from '@ttc/api/giftcards'
import { EditPanelCard, GiftCardStatusBadge } from 'components'
import type { ApiCall } from 'hooks'
import { Col, Row } from 'reactstrap'
import { formatDateReadable } from 'utils'

const GiftCardCard = (props: { getOrder: ApiCall }) => {
  const { getOrder } = props

  if (!getOrder.hasResult) {
    return null
  }

  const { result } = getOrder
  const cards = result.giftCardInfo?.cards || []

  return (
    <EditPanelCard caption="Purchased Gift Cards" stateId="orders_giftcards">
      {Object.keys(cards)
        .map((code) => cards[code])
        .map((card: GiftCard) => {
          return (
            <Row key={card.code}>
              <Col>
                <div>
                  <i className="fa fa-gift mr-2" />
                  <span className="text-monospace">{card.code}</span>
                  <GiftCardStatusBadge className="ml-2" status={card.status} />
                </div>
                <ul>
                  <li>Amount: ${card.amount}</li>
                  <li>Balance: ${card.balance}</li>
                  <li>Expires at: {formatDateReadable(card.expires_at)}</li>
                </ul>
              </Col>
            </Row>
          )
        })}
    </EditPanelCard>
  )
}

export default GiftCardCard
