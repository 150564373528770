import { validateField } from 'utils/validate'
import type { State } from './types'

const currentYear = new Date().getFullYear()

export const isBillingAddressEmpty = (state: State) => {
  return (
    !state.billingFirstName &&
    !state.billingLastName &&
    !state.billingCompany &&
    !state.billingAddress &&
    !state.billingAddress2 &&
    !state.billingCity &&
    !state.billingState
  )
}

export const isBillingSameAsShipping = (state: State) => {
  return (
    state.billingFirstName == state.shippingFirstName &&
    state.billingLastName == state.shippingLastName &&
    state.billingAddress == state.shippingAddress &&
    state.billingAddress2 == state.shippingAddress2 &&
    state.billingCity == state.shippingCity &&
    state.billingState == state.shippingState &&
    state.billingCompany == state.shippingCompany
  )
}

export const shouldCheckBillingSameAsShipping = (state: State) => {
  return isBillingAddressEmpty(state) || isBillingSameAsShipping(state)
}

export const validatePayment = (state: State) => {
  if (state.isStoreCredit) {
    return { ...state, isPaymentValid: true }
  }

  if (state.isCardOnFile && state.cardsOnFileId) {
    return { ...state, isPaymentValid: true }
  }

  const isPaymentEmpty =
    String(state.ccNumber) == '' &&
    String(state.ccCvv) == '' &&
    !state.ccMonth &&
    !state.ccYear

  const isCardNumberValid = validateField({
    value: state.ccNumber,
    required: true,
    isCcNumber: true,
  })

  const isPaymentValid =
    isCardNumberValid &&
    String(state.ccCvv).length > 0 &&
    Number(state.ccMonth) > 0 &&
    Number(state.ccYear) >= currentYear

  return { ...state, isPaymentValid, isPaymentEmpty }
}

export const validateAddresses = (state: State) => {
  const isBillingZipValid = validateField({
    value: state.billingZip,
    required: true,
    isZipCode: true,
  })

  const isBillingAddressValid = Boolean(
    state.billingFirstName &&
      state.billingLastName &&
      state.billingAddress &&
      state.billingCity &&
      isBillingZipValid &&
      state.billingState,
  )

  const isShippingPhoneValid = validateField({
    value: state.shippingPhone,
    required: false,
    isPhoneNumber: true,
  })

  const isShippingZipValid = validateField({
    value: state.shippingZip,
    required: true,
    isZipCode: true,
  })

  const isShippingEmailValid = validateField({
    value: state.shippingEmail,
    required: false,
    isEmail: true,
  })

  const isShippingAddressValid = Boolean(
    state.shippingFirstName &&
      state.shippingLastName &&
      state.shippingAddress &&
      state.shippingCity &&
      isShippingZipValid &&
      isShippingEmailValid &&
      isShippingPhoneValid &&
      state.shippingState,
  )

  return {
    ...state,
    isBillingAddressValid: state.isBillingSameAsShipping
      ? isShippingAddressValid
      : isBillingAddressValid,
    isShippingAddressValid,
  }
}
