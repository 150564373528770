import { LinkToOrder } from 'components'
import type { StatefulState } from 'hooks'
import { useCallback } from 'react'
import { Col } from 'reactstrap'
import type { OrderType } from '.'

type OrderLineProps = {
  order: OrderType
  checked: boolean
  selectedOrder: StatefulState<any>
  onClick: (orderId: number) => void
}

const OrderLine = (props: OrderLineProps) => {
  const { order, onClick, checked, selectedOrder } = props

  const handleClick = useCallback(() => {
    onClick(order.order_id)
  }, [order, onClick])

  const handleClickOrderNumber = useCallback(
    ({ orderId }) => {
      selectedOrder.set(orderId)
    },
    [selectedOrder],
  )

  const prettyOrderSplitStatus = (status: string) => {
    const pretty = {
      IN_BATCH: 'in batch',
      TO_BE_BATCHED: 'to be batched',
    }
    return status in pretty ? pretty[status] : status.toLowerCase()
  }

  return (
    <div
      className="d-flex"
      key={order.order_id}
      style={{
        borderTop: '1px solid #c5dbea',
        background:
          order.status === 'COMPLETED'
            ? '#cdedd8'
            : order.status === 'ASSEMBLED'
              ? '#ffff0030'
              : '',
      }}
    >
      <Col
        className="d-flex flex-grow-0"
        style={{
          padding: 5,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'right',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <div style={{ padding: '10px 20px 10px 10px' }}>
          <input
            style={{ cursor: 'pointer' }}
            type="checkbox"
            checked={checked}
            onChange={handleClick}
          />
        </div>
      </Col>
      <Col
        style={{
          padding: '10px 10px 10px 0',
        }}
      >
        <div>
          <LinkToOrder
            onClick={handleClickOrderNumber}
            orderNumber={order.order_number}
            orderId={order.order_id}
          />{' '}
          ({prettyOrderSplitStatus(order.status)})
        </div>
        <div style={{ color: '#999' }}>{order.customer_name}</div>
      </Col>
      <Col
        className="flex-grow-0 d-flex p-0 flex-column"
        style={{
          borderLeft: '1px solid #c5dbea',
          alignItems: 'center',
        }}
      >
        <div
          title="Number of plants"
          className="d-flex text-center"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            fontSize: 22,
            width: 70,
          }}
        >
          {order.num_plants}
        </div>
      </Col>
    </div>
  )
}

export default OrderLine
