import StoreCreditReasonBadge from 'components/Badges/StoreCreditReasonBadge'
import CellEditable from 'components/Table/CellEditable'
import type { ColumnDef } from 'components/Table/types'
import { customerCellRenderer } from 'components/cellRenderer'
import { formatDateReadable, formatPrice } from 'utils'
import { toCents } from 'utils/price'

const columnDef: ColumnDef[] = [
  {
    label: '',
    id: 'checkbox',
  },
  {
    label: 'ID',
    id: 'id',
    sortColumn: true,
  },
  {
    label: 'Amount',
    id: 'amount',
    sortColumn: true,
    isEditable: true,
    isMoney: true,
    renderer: (props) => {
      const { row, value } = props
      if (!row.can_update_amount) {
        return <td data-col-id="amount">${formatPrice(toCents(value))}</td>
      } else {
        return <CellEditable {...props} />
      }
    },
  },
  {
    label: 'Balance',
    id: 'balance',
    sortColumn: true,
    isMoney: true,
    renderer: (props) => {
      const { row, value } = props
      if (!row.can_update_balance) {
        return <td data-col-id="balance">${formatPrice(toCents(value))}</td>
      } else {
        return <CellEditable {...props} />
      }
    },
  },
  {
    label: 'Use Normal Shipping',
    id: 'use_normal_shipping',
    sortColumn: true,
    renderer: ({ value }) => {
      return (
        <td data-col-id="use_normal_shipping">
          {value === '1' ? 'Yes' : 'No'}
        </td>
      )
    },
  },
  {
    label: 'Per Item Shipping',
    id: 'per_item_shipping',
    sortColumn: true,
    isMoney: true,
    renderer: (props) => {
      return (
        <td data-col-id="per_item_shipping">
          ${formatPrice(props.value * 100)}
        </td>
      )
    },
  },
  {
    label: 'Status',
    id: 'status',
    sortColumn: true,
    renderer: ({ row }) => {
      return (
        <td data-col-id={'status'}>
          {row.cancelled_at ? (
            <i className="text-danger fa fa-times-circle" />
          ) : (
            <i style={{ color: '#256c2b' }} className="fa fa-check-circle" />
          )}
        </td>
      )
    },
  },
  {
    label: 'Reason',
    id: 'reason',
    sortColumn: true,
    renderer: ({ value }) => {
      return (
        <td data-col-id={'reason'}>
          <StoreCreditReasonBadge type={value} />
        </td>
      )
    },
  },
  {
    label: 'Order',
    id: 'order_number',
    sortColumn: true,
    hasClipboardButton: true,
  },
  {
    label: 'Gift card',
    id: 'code',
    sortColumn: false,
    hasClipboardButton: true,
  },
  {
    label: 'Customer email',
    id: 'billing_email',
    sortColumn: true,
  },
  {
    label: 'Customer name',
    id: 'billing_name',
    sortColumn: true,
    renderer: customerCellRenderer,
  },
  {
    label: 'Created at',
    id: 'created_at',
    renderer: ({ value }) => {
      return (
        <td>{value ? formatDateReadable(String(value), true, true) : ''}</td>
      )
    },
    sortColumn: true,
  },
]

export default columnDef
