import { datadogLogs } from '@datadog/browser-logs'

export const debugLog = (msg: string) => {
  console.log(msg) // eslint-disable-line

  datadogLogs.logger.debug(msg, {
    channel: 'ship-now',
    barcode: msg,
  })
}
