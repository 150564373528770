import { SelectOneFilterButton, TagFilterButton } from 'components'
import range from 'lodash/range'
import { useMemo } from 'react'

const thisYear = new Date().getFullYear()

export type ManagePurchaseOrdersFiltersType = FiltersTypeWithUrlUpdater & {
  tags: FilterType
  year: FilterType
}

type FiltersProps = {
  filters: ManagePurchaseOrdersFiltersType
  maxYear: number
  tags: ApiTag[]
}

export const Filters = (props: FiltersProps) => {
  const { tags, filters, maxYear } = props

  const yearFilterOptions = useMemo(() => {
    return [
      ...range(2019, Number(maxYear) + 1)
        .map((y) => ({ value: String(y), label: String(y) }))
        .reverse(),
      { value: 'any', label: 'Any Year' },
    ]
  }, [maxYear])

  return (
    <>
      <SelectOneFilterButton
        {...{
          filter: filters.year,
          label: String(thisYear),
          clearLabel: `Reset to this year`,
          clearIcon: 'clock-o',
          options: yearFilterOptions,
        }}
      />
      <TagFilterButton {...{ tags, filter: filters.tags }} />
    </>
  )
}

export default Filters
