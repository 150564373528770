import { useCallback, useMemo } from 'react'
import usePersistedState from './usePersistedState'

export type StatefulState<T> = {
  value: T
  set: (value: T) => void
  update: (props: T) => void
}

/**
 * useStateful is a custom hook that provides a stateful value and a function to update it.
 * It uses the usePersistedState hook to persist state across sessions.
 *
 * @param initValue - The initial value of the state.
 * @param storageKey - The key to use for storing the state in localStorage.
 * @param canLoad - A flag indicating if the state can be loaded from localStorage.
 *
 * @returns An object with the following properties:
 * - value: The current state value.
 * - set: A function to set the state.
 * - update: A function to update the state. If the current state is an object, it merges the new properties into the current state.
 */
function useStateful<T = any>(
  initValue: T,
  storageKey?: string | null,
  canLoad = true,
): StatefulState<T> {
  const [value, setValue] = usePersistedState<T>(initValue, storageKey, {
    canLoad,
  })

  const update = useCallback(
    (props: T) => {
      if (typeof value === 'object') {
        setValue({ ...value, ...props })
      } else {
        setValue(props)
      }
    },
    [setValue, value],
  )

  return useMemo(
    () => ({
      value,
      set: setValue,
      update,
    }),
    [value, setValue, update],
  )
}

export default useStateful
