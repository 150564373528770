import { Badge } from 'reactstrap'

type StatusBadgeProps = {
  status: string
}

const StatusBadge = ({ status }: StatusBadgeProps) => {
  let statusColor = 'warning'
  const statusText = String(status).toUpperCase().replace(/_/g, ' ')

  switch (status.toLowerCase()) {
    case 'unspecified':
      statusColor = 'secondary'
      break
  }

  return <Badge color={statusColor}>{statusText}</Badge>
}

export default StatusBadge
