import { useEffect } from 'react'

const defaultTitle = 'The Tree Center Admin'

/**
 * This hook updates the page title, if the title changes.
 */
export const usePageTitle = (title: string) => {
  useEffect(() => {
    document.title = `${title} - ${defaultTitle}`

    return () => {
      document.title = defaultTitle
    }
  }, [title])
}

export default usePageTitle
