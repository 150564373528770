import { LinkCell, PageNavHead, PageNavTail } from 'components'
import {
  Table,
  defaultCellRenderer,
  setQuery,
  setSelectedRows,
  useTableApi,
  useTableWithUrlUpdater,
} from 'components/Table'
import { useApi, usePageTitle } from 'hooks'
import { useCallback, useMemo, useState } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { askQuestion, getStorage } from 'utils'
import PrintActionButton from './Actions/PrintActionButton'
import EmployeeEditor from './EmployeeEditor'
import columnDef from './columnDef'

export type ManageEmployeesProps = {
  canEdit: boolean
  pageTitle: string
  storageKey: string
  role: string
  hasCapabilities?: boolean | ((capability: string) => boolean)
}

export const ManageEmployees = ({
  canEdit,
  pageTitle,
  storageKey,
  role,
  hasCapabilities,
}: ManageEmployeesProps) => {
  const [setItem, getItem] = useMemo(() => getStorage(storageKey), [storageKey])

  /* Hide capabilities column if needed. */
  const filteredColumnDef = useMemo(
    () =>
      hasCapabilities !== false
        ? columnDef
        : columnDef.filter((cap) => cap.id !== 'capSummary'),
    [hasCapabilities],
  )

  const [editRow, setEditRow] = useState(null)
  const [state, dispatch] = useTableWithUrlUpdater({
    getItem,
    setItem,
    cacheKey: storageKey,
  })
  const { selectedRows, query, isLoading } = state
  const [triggerSearch] = useTableApi('users_getAll', state, dispatch, {
    role,
  })

  usePageTitle(pageTitle)

  const handleClickNameCell = useCallback((rowId) => {
    setEditRow(rowId)
  }, [])

  const renderCell = useCallback(
    (props) => {
      if (props.col.id === 'username' && canEdit) {
        return (
          <LinkCell
            className="cell"
            id={props.row.id}
            onClick={handleClickNameCell}
            title={props.row.id}
          >
            {props.value}
          </LinkCell>
        )
      }

      return defaultCellRenderer(props)
    },
    [canEdit, handleClickNameCell],
  )

  const handleChangeSearch = useCallback(
    (query) => {
      dispatch(setQuery(query))
    },
    [dispatch],
  )

  const handleCloseEditPanel = useCallback(() => {
    setEditRow(null)
    dispatch(setSelectedRows([]))
    triggerSearch()
  }, [dispatch, triggerSearch])

  const handleClickAdd = useCallback(() => {
    setEditRow('new')
  }, [])

  const handleClickLeaderboard = useCallback(() => {
    window.open(`/shipping/leaderboard`, '_blank')
  }, [])

  const apiDelete = useApi(() => ({ action: 'users_deactivate' }), null, {
    errorModal: true,
  })

  const handleClickDelete = async (rowId: string, name: string) => {
    setEditRow(null)
    dispatch(setSelectedRows([]))

    const confirm = await askQuestion(
      `Are you sure you want to delete Employee "${name}"?`,
    )

    if (!confirm) {
      return
    }

    await apiDelete.performRequest({ id: rowId })
    triggerSearch()
  }

  const apiGetRoleCaps = useApi(
    () => ({ action: 'users_getRoleCaps', role }),
    () => [],
    () => ({
      errorModal: false,
      autoPerform: true,
    }),
  )

  return (
    <>
      <PageNavHead {...{ isLoading, pageTitle, onClickReload: triggerSearch }}>
        <div style={{ marginLeft: 'auto' }}>
          <Button className="mr-2" onClick={handleClickLeaderboard}>
            <i className="fa fa-superpowers" /> Leaderboard
          </Button>
          {canEdit ? (
            <Button className="mr-2" onClick={handleClickAdd}>
              Add Employee
            </Button>
          ) : null}
        </div>
      </PageNavHead>
      <PageNavTail
        {...{
          isLoading,
          query,
          handleChangeSearch,
        }}
      >
        <PrintActionButton {...{ selectedRows, role }} />
      </PageNavTail>
      <Container fluid className="mt-4">
        <div className="manage-shipping-employees animated fadeIn">
          <EmployeeEditor
            {...{ role, hasCapabilities }}
            caps={apiGetRoleCaps.result}
            editId={editRow === 'new' ? null : editRow}
            isOpen={editRow != null}
            onClickDelete={handleClickDelete}
            onClose={handleCloseEditPanel}
          />
          <Row>
            <Col>
              <Table
                entityName="employees"
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef: filteredColumnDef,
                  renderCell,
                  dispatch,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default ManageEmployees
