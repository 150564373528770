import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

const EMPTY = {}

/**
 * This hook provides a way to control the visibility of a panel based on the
 * number of selected rows.
 *
 * @param selectedRows - The list of selected rows.
 * @param openForSingleRow - If true, the panel will be open if there is only one
 * row selected.
 * @param openForMultiRows - If true, the panel will be open if there are more
 * than one row selected.
 *
 * @returns An object with the following properties:
 * - isOpen: A boolean indicating whether the panel is open.
 * - toggle: A function that toggles the panel's visibility.
 * - open: A function that opens the panel.
 * - close: A function that closes the panel.
 */
export type PanelControlResult = {
  isOpen: boolean
  toggle: (e?: React.MouseEvent<HTMLElement> | null) => void
  open: () => void
  close: () => void
}

export type PanelControlProps = {
  selectedRows?: string[]
  openForSingleRow?: boolean
  openForMultiRows?: boolean
}

export const usePanelControl = ({
  selectedRows,
  openForSingleRow,
  openForMultiRows,
}: PanelControlProps = EMPTY): PanelControlResult => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = useCallback(
    (e?: React.MouseEvent<HTMLElement> | null) => {
      e?.preventDefault()
      setIsOpen(!isOpen)
    },
    [isOpen],
  )

  const open = useCallback(() => {
    setIsOpen(true)
  }, [])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  const oldSelection = useRef<string[]>()
  useEffect(() => {
    if (selectedRows == null) {
      return
    }

    const selectionChanged =
      JSON.stringify(oldSelection.current) !== JSON.stringify(selectedRows)

    if (selectionChanged) {
      if (openForSingleRow && selectedRows.length === 1) {
        open()
      } else if (openForMultiRows && selectedRows.length > 1) {
        open()
      } else {
        close()
      }
    }

    oldSelection.current = selectedRows
  }, [selectedRows, open, close, openForSingleRow, openForMultiRows])

  return useMemo(
    () => ({
      isOpen,
      toggle,
      open,
      close,
    }),
    [isOpen, toggle, open, close],
  )
}

export default usePanelControl
