import { ClipboardButton } from 'components'
import { Col } from 'reactstrap'
import { formatPrice } from 'utils/price'

const InvoiceLineItem = (props) => {
  const { item } = props

  return (
    <div
      className="row d-flex p-3"
      key={item.id}
      style={{ borderBottom: '1px solid #c5dbea' }}
    >
      <Col sm={9}>
        <div>
          <a target="noopener" href={item.product_data.permalink}>
            {item.product_data.name}
            {item.product_data.variationDescription ? (
              <i> - {item.product_data.variationDescription}</i>
            ) : (
              ''
            )}
          </a>
        </div>
        <div>
          <ClipboardButton value={item.sku} text={`SKU: ${item.sku}`} />
        </div>
        {item.product_data.size ? (
          <div>Size: {item.product_data.size}</div>
        ) : null}
      </Col>
      <Col sm={3} className="text-right">
        <div>QTY: {item.quantity}</div>
        <div>
          ${formatPrice(item.quantity > 0 ? item.total / item.quantity : 0)}
        </div>
        <div style={{ color: '#aaa' }}>${formatPrice(item.total)}</div>
      </Col>
    </div>
  )
}

export default InvoiceLineItem
