import { EditPanel, EditPanelCard, FormRenderer, Loading } from 'components'
import { useApi, useFormWithDef, usePrevious } from 'hooks'
import type { ApiCall } from 'hooks'
import { Fragment, useCallback, useEffect } from 'react'
import { Button, Col, FormGroup, Row } from 'reactstrap'
import { askQuestion } from 'utils'
import formDef from './formDef'

const CardActions = ({ getCustomer, onClose, passwordResetAction }) => {
  const handleClickReset = useCallback(async () => {
    const email = getCustomer.result.email

    const confirm = await askQuestion(
      `This will send a password reset link to ${email}. Continue?`,
    )

    if (!confirm) {
      return
    }

    const ret = await passwordResetAction.performRequest()

    if (ret) {
      onClose(true)
    }
  }, [onClose, passwordResetAction, getCustomer])

  return (
    <EditPanelCard caption="Actions" defaultIsOpen={false}>
      <Row>
        <Col>
          <FormGroup className="form-actions mt-3 text-center">
            <Button
              name="delete"
              size="lg"
              type="button"
              color="danger"
              onClick={handleClickReset}
            >
              Send password reset email
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </EditPanelCard>
  )
}

type CustomerEditorProps = {
  getCustomer: ApiCall
  isOpen: boolean
  onClose: () => void
  editId: string
  isLoading: boolean
}

const CustomerEditor = (props: CustomerEditorProps) => {
  const { getCustomer, isOpen, onClose, editId } = props
  const form = useFormWithDef(formDef)

  const passwordResetAction = useApi(
    { action: 'customers_sendPasswordResetEmail', id: editId },
    null,
    { errorModal: true },
  )

  // Fill form as soon as panel opens.
  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (prevIsOpen !== isOpen) {
      if (isOpen && getCustomer.result != null) {
        const { firstName, lastName, email } = getCustomer.result

        form.setState({ firstName, lastName, email })
      } else if (!isOpen) {
        form.reset()
      }
    }
  }, [form, prevIsOpen, isOpen, editId, getCustomer.result])

  const updateCustomer = useApi({ action: 'customers_update' })

  const handleSubmit = useCallback(() => {
    const formSubmit = async () => {
      const ret = await updateCustomer.performRequest({
        id: editId,
        ...form.state,
      })

      if (ret) {
        onClose()
      }
    }

    formSubmit()
  }, [editId, form.state, onClose, updateCustomer])

  const isLoading =
    getCustomer.isLoading ||
    updateCustomer.isLoading ||
    passwordResetAction.isLoading

  return (
    <EditPanel
      caption="Customer Info"
      onSubmit={isLoading ? null : handleSubmit}
      {...{ isOpen, onClose, isLoading }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment key={editId}>
          {editId != null ? (
            <CardActions {...{ getCustomer, onClose, passwordResetAction }} />
          ) : null}
          <EditPanelCard caption="Edit Customer Info" stateId="customer_info">
            {updateCustomer.error ? (
              <Row>
                <Col className="text-danger">{updateCustomer.error}</Col>
              </Row>
            ) : null}
            <FormRenderer {...{ form, formDef }} />
          </EditPanelCard>
        </Fragment>
      )}
    </EditPanel>
  )
}

export default CustomerEditor
