import type { Location, LocationsGetAllResponse } from '@ttc/api/locations'
import { EditPanel, EditPanelCard, FormRenderer, Loading } from 'components'
import type { FormDefElement } from 'components/Form/types'
import {
  useApi,
  useEffectIfObjectChanges,
  useFormWithDef,
  usePrevious,
} from 'hooks'
import { Fragment, useCallback, useEffect, useMemo } from 'react'
import { Button, Col, FormGroup, Row } from 'reactstrap'
import _formDef from './formDef'

const CardActions = ({ handleClickDelete }) => {
  return (
    <EditPanelCard caption="Actions" defaultIsOpen={false}>
      <Row>
        <Col>
          <FormGroup className="form-actions mt-3 text-center">
            <Button
              name="delete"
              size="lg"
              type="button"
              color="danger"
              onClick={handleClickDelete}
            >
              Delete Supplier
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </EditPanelCard>
  )
}

type SupplierEditorProps = {
  isOpen: boolean
  onClose?: (isSaved?: boolean) => void
  editId: string
  onClickDelete?: (editId: string, name: string) => void
  onUpdateRow?: (editId: string, row: any) => void
  readOnly: boolean
}

const SupplierEditor = (props: SupplierEditorProps) => {
  const { isOpen, onClose, editId, onClickDelete, onUpdateRow, readOnly } =
    props

  const apiLoadLocations = useApi<LocationsGetAllResponse>(
    () => ({ action: 'locations_getAll' }),
    null,
    () => ({ autoPerform: true, cache: true }),
  )

  const locationOptions = useMemo(() => {
    return (apiLoadLocations.result?.rows || []).map((location: Location) => {
      return { value: String(location.id), label: location.name }
    })
  }, [apiLoadLocations.result])

  const formDef = useMemo(() => {
    return _formDef.map((item) => {
      if ((item as FormDefElement)?.name === 'source_location_id') {
        return {
          ...item,
          options: [{ value: '', label: '' }, ...locationOptions],
        }
      }

      return item
    })
  }, [locationOptions])

  const form = useFormWithDef(_formDef)

  const getSupplier = useApi({ action: 'suppliers_getRow', id: editId }, null, {
    cache: true,
  })

  // Fill form with fetched data.
  useEffectIfObjectChanges(
    useCallback(() => {
      if (getSupplier.result != null) {
        form.setState({ ...getSupplier.result })
      }
    }, [getSupplier.result, form]),
    getSupplier.result,
    true,
  )

  // Load data as soon as panel opens.
  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (prevIsOpen !== isOpen) {
      if (isOpen && editId != null) {
        getSupplier.performRequest()
      } else if (!isOpen) {
        getSupplier.reset()
        form.reset()
      }
    }
  }, [form, getSupplier, prevIsOpen, isOpen, editId])

  const setSupplier = useApi({ action: 'suppliers_setRow' })

  const handleSubmit = useCallback(() => {
    const formSubmit = async () => {
      const ret = await setSupplier.performRequest({
        id: editId,
        json: { ...form.state },
      })

      if (ret) {
        onUpdateRow(editId, ret)

        if (onClose) {
          onClose(true)
        }
      }
    }

    formSubmit()
  }, [editId, setSupplier, form.state, onUpdateRow, onClose])

  const handleClickDelete = useCallback(() => {
    if (onClickDelete) {
      onClickDelete(editId, form.state.name)
    }
  }, [onClickDelete, editId, form.state.name])

  const caption = readOnly
    ? 'Supplier'
    : `${editId == null ? 'Add' : 'Edit'} Supplier`

  const isLoading = (editId && !getSupplier.hasResult) || setSupplier.isLoading

  return (
    <EditPanel
      onSubmit={readOnly || isLoading ? null : handleSubmit}
      {...{ error: getSupplier.error, isOpen, onClose, caption, isLoading }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment key={editId}>
          {editId != null && !readOnly ? (
            <CardActions {...{ handleClickDelete }} />
          ) : null}
          <EditPanelCard caption="Contact info" stateId="supplier_contact_info">
            {setSupplier.error ? (
              <div className="text-danger">{setSupplier.error}</div>
            ) : null}
            <FormRenderer {...{ readOnly, form, formDef }} />
          </EditPanelCard>
        </Fragment>
      )}
    </EditPanel>
  )
}

export default SupplierEditor
