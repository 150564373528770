import type { GiftCard } from '@ttc/api/giftcards'
import { ClipboardButton, GiftCardStatusBadge } from 'components'
import useApi from 'hooks/useApi'
import { useCallback } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { askQuestionWithReason, formatDateReadable, formatPrice } from 'utils'

export const GiftCardCard = ({
  data,
  readOnly,
  onUpdate,
}: {
  data: GiftCard
  readOnly: boolean
  onUpdate: () => void
}) => {
  const { id } = data

  const apiUpdateStatus = useApi(
    () => ({ action: 'giftCards_changeActivation' }),
    null,
    () => ({ errorModal: true }),
  )

  const handleClickActivate = useCallback(async () => {
    const comment = await askQuestionWithReason(
      'Are you sure you want to activate this gift card?',
    )

    if (!comment) {
      return
    }

    await apiUpdateStatus.performRequest({
      json: { id, active: true, comment },
    })

    onUpdate()
  }, [apiUpdateStatus, id, onUpdate])

  const handleClickDeactivate = useCallback(async () => {
    const comment = await askQuestionWithReason(
      'Are you sure you want to deactivate this gift card?',
    )

    if (!comment) {
      return
    }

    await apiUpdateStatus.performRequest({
      json: { id, active: false, comment },
    })

    onUpdate()
  }, [apiUpdateStatus, id, onUpdate])

  return (
    <>
      <Row>
        <Col xs={4}>Code:</Col>
        <Col>
          <ClipboardButton value={data.code} text={data.code} />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>Status:</Col>
        <Col>
          <GiftCardStatusBadge status={data.status} />
          {!readOnly && data.can_deactivate ? (
            <Button
              className="align-baseline m-0 p-0 ml-2"
              size="sm"
              color="link"
              onClick={handleClickDeactivate}
            >
              Deactivate
            </Button>
          ) : null}
          {!readOnly && data.can_activate ? (
            <Button
              className="align-baseline m-0 p-0 ml-2"
              size="sm"
              color="link"
              onClick={handleClickActivate}
            >
              Activate
            </Button>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col xs={4}>Amount:</Col>
        <Col>${formatPrice(data.amount)}</Col>
      </Row>
      <Row>
        <Col xs={4}>Balance:</Col>
        <Col>${formatPrice(data.balance)}</Col>
      </Row>
      <Row>
        <Col xs={4}>Free Shipping:</Col>
        <Col>
          {Number(data.free_shipping) === 1 ? (
            <i className="fa fa-check-circle text-success" />
          ) : (
            <i style={{ opacity: 0.5 }} className="fa fa-times-circle" />
          )}
        </Col>
      </Row>
      {data.order_number ? (
        <Row>
          <Col xs={4}>Order Number:</Col>
          <Col>
            <ClipboardButton
              value={data.order_number}
              text={data.order_number}
            />
          </Col>
        </Row>
      ) : null}
      {data.created_by_name ? (
        <Row>
          <Col xs={4}>Created by:</Col>
          <Col>{data.created_by_name} </Col>
        </Row>
      ) : null}
      <Row>
        <Col xs={4}>Created at:</Col>
        <Col>{formatDateReadable(data.created_at, true, true)} </Col>
      </Row>
      <Row>
        <Col xs={4}>Expires at:</Col>
        <Col>{formatDateReadable(data.expires_at)} </Col>
      </Row>
      {data.note ? (
        <Row>
          <Col xs={4}>Create note:</Col>
          <Col>{data.note} </Col>
        </Row>
      ) : null}
    </>
  )
}

export default GiftCardCard
