import { DropDownFilterButton, TagFilterButton } from 'components'

export const Filters = ({ filters, tags, getStatuses }) => {
  return (
    <>
      <TagFilterButton {...{ tags, filter: filters.tags }} />
      <DropDownFilterButton
        label="Status"
        filter={filters.status}
        values={getStatuses.result ? getStatuses.result.status : []}
      />
      <DropDownFilterButton
        label="Reason"
        filter={filters.reason}
        values={getStatuses.result ? getStatuses.result.return_reason : []}
      />
    </>
  )
}

export default Filters
