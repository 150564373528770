import type { GetProductApiParams } from '@ttc/api/products'
import EditPanel from 'components/EditPanel'
import { LoadingImage } from 'components/Loading'
import TabBar, { useTabBar } from 'components/TabBar'
import type { TabsConfig } from 'components/TabBar/types'
import InventoryLogCard from 'containers/common/InventoryLogCard'
import NotesPanelCard from 'containers/common/NotesPanelCard'
import { useCooldown, useForm, usePrevious } from 'hooks/index'
import useApi, { type ApiCall } from 'hooks/useApi'
import max from 'lodash/max'
import min from 'lodash/min'
import range from 'lodash/range'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { showError } from 'utils/alert'
import InfoCard from './InfoCard'
import ProductCardForm from './ProductCardForm'
import SaveButtonFooter from './SaveButtonFooter'
import TreeInfoCard from './TreeInfoCard'
import type { GetProduct, ProductFormState } from './types'

const initialState = {}

const TAB_PRODUCT = 'product'
const TAB_NOTES = 'notes'

type Tab = typeof TAB_PRODUCT | typeof TAB_NOTES

const tabsConfig: TabsConfig<Tab> = [
  { type: TAB_PRODUCT, title: 'Product', icon: 'leaf' },
  { type: TAB_NOTES, title: 'Notes', icon: 'notes' },
]

const getFormState = (product: GetProduct) => {
  const {
    name,
    botanicalName,
    sku,
    status,
    contentLink,
    matureHeight,
    matureWidth,
    categories,
    collections,
    gtin,
    mpn,
  } = product

  // Convert growingZone option into array for ZoneRangeSelect
  let growingZones = []
  if (product['pa_growing-zone']?.length) {
    const zoneRangeStr = String(product['pa_growing-zone'][0].value)

    if (zoneRangeStr.indexOf('-') !== -1) {
      const zoneRangeArr = zoneRangeStr.split(/-/)
      growingZones = range(Number(zoneRangeArr[0]), Number(zoneRangeArr[1]) + 1)
    } else {
      growingZones = [Number(zoneRangeStr)]
    }
  }

  const formState = {
    name,
    botanicalName,
    categories,
    collections,
    parentSku: sku,
    status,
    contentLink,
    matureHeight,
    matureWidth,
    pa_plant_type: product['pa_plant_type'],
    'pa_growing-zone': product['pa_growing-zone'],
    'pa_sun-needs': product['pa_sun-needs'],
    pa_brand: product['pa_brand']?.[0]?.value || null,
    'pa_special-features': product['pa_special-features'],
    'pa_water-needs': product['pa_water-needs'],
    pa_style: product['pa_style'],
    'pa_flower-color': product['pa_flower-color'],
    'pa_flowering-season': product['pa_flowering-season'],
    'pa_growth-rate': product['pa_growth-rate'],
    'pa_excluded-states': product['pa_excluded-states'],
    growingZones,
    gtin,
    mpn,
  }

  return formState
}

export type ProductContentSidebarProps = {
  readOnly: boolean
  getProduct: ApiCall<GetProduct, GetProductApiParams>
  onToggle: (e: React.MouseEvent<HTMLElement>) => void
  isOpen: boolean
  onClose: () => void
  onSaved: () => void
}

const ProductContentSidebar = (props: ProductContentSidebarProps) => {
  const { getProduct, onToggle, isOpen, onSaved } = props

  const product = getProduct?.result

  const readOnly = !getProduct?.hasResult || props.readOnly

  const caption = product?.name || <LoadingImage small />
  const href = product?.permalink

  const form = useForm<ProductFormState>(initialState)

  const didSaveMessageCooldown = useCooldown(2000)

  const [serverFormState, setServerFormState] = useState<ProductFormState>(null)

  const hasChanges = useMemo(() => {
    if (
      serverFormState == null ||
      !Object.keys(serverFormState).length ||
      !Object.keys(form.state).length
    ) {
      return false
    }

    return JSON.stringify(serverFormState) !== JSON.stringify(form.state)
  }, [form.state, serverFormState])

  const productId = getProduct?.hasResult ? product?.id : null
  const prevProductId = usePrevious(productId)

  useEffect(() => {
    if (productId !== prevProductId) {
      if (!product || !isOpen) {
        form.reset()
        getProduct.reset()
        return
      }

      const formState = getFormState(product)

      form.setState(formState)
      setServerFormState(formState)
    }
  }, [isOpen, prevProductId, product, form, productId, getProduct])

  const tabBarState = useTabBar<Tab>(
    TAB_PRODUCT,
    tabsConfig,
    'content.sidebar.selectedTab',
  )

  const updateProduct = useApi(null, null, () => ({
    errorModal: true,
    throws: true,
  }))

  const handleFormSubmit = useCallback(async () => {
    // Validate n/a
    for (const key in form.state) {
      const value = form.state[key]

      if (Array.isArray(value)) {
        const values = value.map((val) => val.value)

        if (values.indexOf('n-a') >= 0 && values.length > 1) {
          const keyName = key.substring(3)
          showError(
            `Validation error for treeinfo field ${keyName}: "N/A" cannot be mixed with other values.`,
          )
          return
        }
      }
    }

    const json = {
      id: product.id,
      ...form.state,
    }

    const growingZones = json['growingZones']
    if (growingZones.length === 0) {
      json['pa_growing-zone'] = []
    } else {
      const minGrowingZone = min(growingZones)
      const maxGrowingZone = max(growingZones)
      const growingZoneRange =
        minGrowingZone === maxGrowingZone
          ? String(minGrowingZone)
          : `${minGrowingZone}-${maxGrowingZone}`
      json['pa_growing-zone'] = [{ value: growingZoneRange, label: '' }]

      // Validate growing zone
      if (
        product['pa_growing-zoneOptions'].filter(
          (option) => option.value === growingZoneRange,
        ).length === 0
      ) {
        showError(`Invalid growing zone: ${growingZoneRange}`)
      }
    }

    try {
      const updatedProduct = await updateProduct.performRequest({
        action: 'content_setProduct',
        json,
      })

      if (updatedProduct) {
        didSaveMessageCooldown.start()

        const updatedFormState = getFormState(updatedProduct)
        form.setState(updatedFormState)
        setServerFormState(updatedFormState)

        getProduct.invalidateCache()

        onSaved()
      }
    } catch (_e) {
      return
    }
  }, [
    updateProduct,
    product,
    form,
    onSaved,
    didSaveMessageCooldown,
    getProduct,
  ])

  const { isLoading } = updateProduct

  const handleSubmit =
    tabBarState.selectedTab !== TAB_PRODUCT ? null : handleFormSubmit

  const showSaveButton =
    didSaveMessageCooldown.isActive ||
    (!readOnly &&
      !getProduct.isLoading &&
      getProduct.hasResult &&
      handleSubmit &&
      hasChanges)

  const canSubmit = hasChanges && !isLoading && !getProduct.isLoading
  const showSaveSuccess = didSaveMessageCooldown.isActive && !hasChanges

  return (
    <>
      <ul className="nav nav-tabs flex-row-reverse">
        <li className="nav-item m-0">
          <a onClick={onToggle} className="active nav-link">
            <i className="icon-list"></i>
          </a>
        </li>
      </ul>

      <EditPanel noHeader embedded isOpen {...{ caption }}>
        <Row>
          <Col className="d-flex justify-content-between align-items-center m-3 pl-1 pr-1">
            <span style={{ fontSize: '1rem' }}>
              <a
                style={{ verticalAlign: 'middle' }}
                target="noopener"
                href={href}
              >
                {caption}
              </a>
            </span>
          </Col>
        </Row>
        {getProduct.error ? (
          <div className="tab-content">
            <Row>
              <Col>
                <div className="text-danger">{getProduct.error}</div>
              </Col>
            </Row>
          </div>
        ) : null}
        <TabBar className="border-top border-bottom" {...tabBarState} />
      </EditPanel>
      <div className="tab-content" style={{ height: 'calc(100vh - 210px)' }}>
        <EditPanel
          noHeader
          embedded
          isOpen
          onSubmit={handleSubmit}
          hasButtons={false}
        >
          {!getProduct.error ? (
            <>
              <InfoCard product={product} />
              {tabBarState.selectedTab === TAB_PRODUCT ? (
                <>
                  <ProductCardForm {...{ form, readOnly }} />
                  <TreeInfoCard {...{ form, product, readOnly }} />
                </>
              ) : null}
              {tabBarState.selectedTab === TAB_NOTES ? (
                <>
                  <NotesPanelCard
                    isLoading={getProduct.isLoading}
                    apiAddAction="content_addNote"
                    apiRemoveAction="content_removeNote"
                    id={getProduct.result?.id}
                    notes={getProduct.result?.notes || []}
                    onSaved={getProduct.performRequest}
                    {...{ readOnly }}
                  />
                  <InventoryLogCard
                    apiName="inventory_getLog"
                    id={getProduct.result?.id}
                  />
                </>
              ) : null}
            </>
          ) : null}
          {showSaveButton ? (
            <SaveButtonFooter {...{ showSaveSuccess, canSubmit }} />
          ) : null}
        </EditPanel>
      </div>
    </>
  )
}

export default ProductContentSidebar
