import { SelectField, TextField } from 'components'
import { setValue } from 'containers/AddOrder/useCart'
import { deleteCard } from 'containers/AddOrder/useCart/api'
import type {
  Action as CartAction,
  State as CartState,
} from 'containers/AddOrder/useCart/types'
import { useCallback } from 'react'
import { CustomInput } from 'reactstrap'
import Swal from 'sweetalert2'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const monthOptions = months.map((name, i) => {
  const month = i + 1

  return {
    value: String(month),
    label: `${month} - ${name}`,
  }
})

const currentYear = new Date().getFullYear()
const years = Array.from(Array(50).keys()).map((i) => currentYear + i)
const yearOptions = years.map((year) => {
  return {
    value: String(year),
    label: String(year),
  }
})

type CreditCardForm = {
  onChange: (name: string, value: any) => void
  onChangeInput: (e: any) => void
} & Partial<CartState>

const CreditCardForm = (props: CreditCardForm) => {
  const {
    onChange,
    onChangeInput,
    isCardOnFile,
    isStoreCredit,
    ccNumber,
    ccCvv,
    ccMonth,
    ccYear,
  } = props

  const handleChangeSelect = useCallback(
    (name, v) => {
      onChange(name, v && v.value ? v.value : null)
    },
    [onChange],
  )

  if (isCardOnFile || isStoreCredit) {
    return null
  }

  return (
    <>
      <TextField
        id={`input-payment-ccnumber`}
        name="ccNumber"
        value={ccNumber}
        onChange={onChangeInput}
        label="Credit Card Number"
        mask="9999 9999 9999 9999"
        maskPlaceholder={' '}
        required
        isCcNumber
      />
      <div className="d-flex">
        <div className="mr-1 form-label-group" style={{ flex: 1 }}>
          <SelectField
            ariaLabel="Credit card expire month"
            highlightIfEmpty
            name="ccMonth"
            value={String(ccMonth)}
            options={monthOptions}
            placeholder="Month"
            onChange={handleChangeSelect}
          />
        </div>
        <div className="ml-1 form-label-group" style={{ flex: 1 }}>
          <SelectField
            ariaLabel="Credit card expire year"
            highlightIfEmpty
            name="ccYear"
            value={String(ccYear)}
            options={yearOptions}
            placeholder="Year"
            onChange={handleChangeSelect}
          />
        </div>
        <TextField
          className="ml-1"
          style={{ flex: 1 }}
          name="ccCvv"
          value={ccCvv}
          onChange={onChangeInput}
          id="input-payment-cvv"
          label="CVV"
          required
        />
      </div>
    </>
  )
}

type CardsOnFileSelectType = {
  dispatch: React.Dispatch<CartAction>
} & Partial<CartState>

const CardsOnFileSelect = (props: CardsOnFileSelectType) => {
  const {
    dispatch,
    customer,
    cardsOnFileId,
    cardsOnFile,
    isCardOnFile,
    isStoreCredit,
  } = props

  const handleChange = useCallback(
    (_name: string, v: any) => {
      dispatch(setValue('cardsOnFileId', v && v.value ? v.value : null))
    },
    [dispatch],
  )

  const handleClickDeleteCard = useCallback(
    (e) => {
      e.preventDefault()

      const card = cardsOnFile.find((c: any) => cardsOnFileId == c.value)
      if (card == null) {
        return
      }

      const confirm = async () => {
        try {
          const newCards = await deleteCard(customer.value, cardsOnFileId)
          dispatch(setValue('cardsOnFile', newCards))
        } catch (e) {
          Swal.fire({
            text: e.message,
            icon: 'error',
          })
        }
      }

      Swal.fire({
        text: `Are you sure you want to delete this card: ${card.label}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.value) {
          confirm()
        }
      })
    },
    [dispatch, cardsOnFile, cardsOnFileId, customer],
  )

  if (!(isCardOnFile && !isStoreCredit)) {
    return null
  }

  return (
    <>
      <div className="form-label-group" key={cardsOnFileId}>
        <SelectField
          ariaLabel="Cards on file"
          highlightIfEmpty
          name="cardsOnFileId"
          options={cardsOnFile}
          value={cardsOnFileId}
          onChange={handleChange}
        />
      </div>

      {cardsOnFileId &&
      String(cardsOnFileId).indexOf('paypal:') !== 0 &&
      String(cardsOnFileId).indexOf('affirm:') !== 0 ? (
        <div style={{ textAlign: 'right' }}>
          <button className="p-0 btn btn-link" onClick={handleClickDeleteCard}>
            Delete this card
          </button>
        </div>
      ) : null}
    </>
  )
}

type PaymentFormProps = {
  dispatch: React.Dispatch<CartAction>
} & Partial<CartState>

const PaymentForm = (props: PaymentFormProps) => {
  const { dispatch, isStoreCredit, isCardOnFile, cardsOnFile } = props

  const handleChange = useCallback(
    (name, value) => {
      dispatch(setValue(name, value))
    },
    [dispatch],
  )

  const handleChangeInput = useCallback(
    (e) => {
      const { type, name, value, checked } = e.currentTarget

      dispatch(setValue(name, type === 'checkbox' ? checked : value))
    },
    [dispatch],
  )

  return (
    <div className="floating-labels pb-4">
      <div className="clearfix">
        <h5 className="d-inline-block mb-3">Payment</h5>

        <CustomInput
          name="isStoreCredit"
          className="float-right checkbox input-payment-storecredit"
          bsSize="lg"
          id="input-payment-storecredit"
          checked={isStoreCredit}
          onChange={handleChangeInput}
          type="checkbox"
          label="Store Credit"
        />

        {cardsOnFile.length > 0 ? (
          <CustomInput
            name="isCardOnFile"
            className="pr-3 float-right checkbox input-payment-cardonfile"
            bsSize="lg"
            id="input-payment-cardonfile"
            checked={isCardOnFile}
            onChange={handleChangeInput}
            type="checkbox"
            label="Use card on file"
          />
        ) : null}
      </div>

      <CreditCardForm
        onChange={handleChange}
        onChangeInput={handleChangeInput}
        {...props}
      />

      <CardsOnFileSelect {...props} />
    </div>
  )
}

export default PaymentForm
