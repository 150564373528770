import type { Product } from '@ttc/api/products'
import type { Zone } from '@ttc/api/zones'
import { LinkButton } from 'components'
import keyBy from 'lodash/keyBy'
import { useCallback } from 'react'

type ZoneCellProps = {
  product?: Product
  zoneQty: { [zone: string]: number }
  zones: Zone[]
  canCheckin?: boolean
  handleClickChangePutaway?: (
    product: Product,
    zoneQty: { [zone: string]: number },
  ) => void
}

const ZoneCell = ({
  product,
  zones,
  zoneQty,
  canCheckin,
  handleClickChangePutaway,
}: ZoneCellProps) => {
  const handleClickChange = useCallback(() => {
    handleClickChangePutaway(product, zoneQty)
  }, [product, zoneQty, handleClickChangePutaway])

  const zonesById = keyBy(zones, 'id')

  if (zoneQty.length === 0 && canCheckin) {
    return (
      <td>
        <LinkButton onClick={handleClickChange}>Check in</LinkButton>
      </td>
    )
  }

  return (
    <td>
      {Object.keys(zoneQty)
        .filter((zoneId) => Object.hasOwn(zonesById, zoneId))
        .map((zoneId) => {
          const qty = zoneQty[zoneId]

          return (
            <span
              onClick={canCheckin ? handleClickChange : null}
              key={zoneId}
              style={{
                backgroundColor: zonesById[zoneId].color,
                padding: 5,
                color: 'white',
                borderRadius: 5,
                cursor: canCheckin ? 'pointer' : 'default',
              }}
            >
              {zonesById[zoneId].name}: {qty}
            </span>
          )
        })}
    </td>
  )
}

export default ZoneCell
