import { type SetStateAction, useEffect, useMemo, useState } from 'react'

export type UsePersistedStateOptions = {
  storeFilter?: (store: any) => any
  canLoad?: boolean
}

/**
 * usePersistedState is a custom hook that provides a stateful value and a function to update it.
 * The state is persisted in localStorage across sessions.
 *
 * TODO(manuel, 2023-07-13) This is redundant with usePersistedState.ts
 *
 * @param defaultValue - The default value of the state.
 * @param storageKey - The key to use for storing the state in localStorage. If false, the state will not be persisted.
 * @param options - An object with the following properties:
 * - storeFilter: A function to filter the state before storing it in localStorage.
 * - canLoad: A flag indicating if the state can be loaded from localStorage. If false, returns the defaultValue
 *
 * @returns An array containing:
 * - value: The current state value.
 * - setValue: A function to set the state.
 */
export const usePersistedState = <T = any>(
  defaultValue: T,
  storageKey?: null | string,
  options: UsePersistedStateOptions = {},
): [T, React.Dispatch<SetStateAction<T>>] => {
  const storedValue = useMemo<T>(() => {
    if (!storageKey) {
      return defaultValue
    }

    if (!(options?.canLoad ?? true)) {
      return defaultValue
    }

    try {
      const val = localStorage.getItem(storageKey)
      return val == null ? defaultValue : JSON.parse(val)
    } catch (_e) {
      return defaultValue
    }
  }, [storageKey, defaultValue, options])

  const [value, setValue] = useState<T>(storedValue)

  useEffect(() => {
    if (!storageKey) {
      return
    }

    try {
      if (value === null) {
        localStorage.removeItem(storageKey)
      } else {
        let storeValue = value

        if (options?.storeFilter) {
          storeValue = options.storeFilter(value)
        }

        localStorage.setItem(storageKey, JSON.stringify(storeValue))
      }
    } catch (_e) {
      /* Ignore */
    }
  }, [value, storageKey, options])

  return [value, setValue]
}

export default usePersistedState
