import { useEffect, useRef } from 'react'

export const usePreviousJson = (value: any): string => {
  const ref = useRef<string>('')

  useEffect(() => {
    ref.current = JSON.stringify(value)
  }, [value])

  return ref.current
}
