import { apiRequest } from 'api'
import { useBarcodeScanner, usePageTitle, useStateful } from 'hooks'
import { useCallback, useContext, useEffect } from 'react'
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap'
import AuthContext from './../../AuthContext'

const pageTitle = 'Login'

const Login = () => {
  const { isLoggedIn } = useContext(AuthContext)

  usePageTitle(pageTitle)

  const error = useStateful(null)

  const checkLoginRedir = useCallback(async () => {
    if (isLoggedIn) {
      window.location.href = '/'
      return
    }
  }, [isLoggedIn])

  useEffect(() => {
    checkLoginRedir()
  }, [checkLoginRedir])

  useBarcodeScanner(
    useCallback(
      async (_barcode) => {
        const barcode = String(_barcode).toUpperCase()

        const invalidBarcode = () => error.set('Unrecognized Barcode')

        if (barcode.indexOf('TTC') !== 0) {
          invalidBarcode()
          return
        }

        const seperator = barcode.substring(3, 4)
        const parts = barcode.split(seperator)

        if (parts.length !== 3) {
          invalidBarcode()
          return
        }

        const id = parts[1]
        const password = parts[2]

        error.set(null)

        try {
          await apiRequest({ action: 'badgeLogin', json: { id, password } })
          localStorage.setItem('logout_redirect', '/badgelogin')
          window.location.reload()
        } catch (e) {
          error.set(e.message)
        }
      },
      [error],
    ),
  )

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col className="mb-3" md="8">
            <CardGroup>
              <Card className="py-5">
                <CardBody className="text-center">
                  <div className="pt-3">
                    <i style={{ fontSize: 120 }} className="fa fa-qrcode" />
                  </div>
                  <div className="mb-2">Scan Badge to Login</div>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
          {error.value != null ? (
            <Col md="8">
              <CardGroup>
                <Card className="py-1 bg-danger">
                  <CardBody className="text-center">
                    <div>{error.value}</div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          ) : null}
        </Row>
      </Container>
    </div>
  )
}

export default Login
