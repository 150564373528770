import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

const EMPTY = {}

export type UseSidebarResult = {
  // function that toggles the open/closed state of the sidebar
  toggle: () => void
  // function that toggles the open/closed state of the sidebar when the user interacts with it
  userToggle: () => void
  isOpen: boolean
  open: () => void
  close: () => void
  // boolean indicating whether the sidebar should automatically open/close based on the number of selected rows
  isAutoToggle: boolean
}

/**
 * `useSidebar` is a custom React hook for managing the state and behavior of a sidebar component.
 *
 * @param options - Configuration options for the hook.
 *
 * options.selectedRows - An array of selected row identifiers.
 * options.openForSingleRow - If true, the sidebar will automatically open when a single row is selected.
 * options.openForMultiRows - If true, the sidebar will automatically open when multiple rows are selected.
 *
 * The state of the sidebar is persisted across sessions using the local storage.
 *
 * The hook also performs cleanup to remove the sidebar class from the body when the component unmounts.
 */

const useSidebar = ({
  selectedRows,
  openForSingleRow,
  openForMultiRows,
}: {
  selectedRows?: string[]
  openForSingleRow?: boolean
  openForMultiRows?: boolean
} = EMPTY): UseSidebarResult => {
  const defaultValue = useMemo(
    () => document.body.classList.contains('aside-menu-lg-show'),
    [],
  )

  const [isOpen, setIsOpen] = useState<boolean>(defaultValue)
  const [isAutoToggle, setIsAutoToggle] = useState<boolean>(true)

  const toggle = useCallback((isUserToggle = false) => {
    const { classList } = document.body

    const isOpen = classList.toggle('aside-menu-lg-show')
    setTimeout(
      () => {
        setIsOpen(isOpen)

        if (isUserToggle) {
          setIsAutoToggle(isOpen)
        }
      },
      isOpen ? 0 : 200,
    )
    localStorage.setItem('sidebar.isOpen', isOpen ? '1' : '0')

    return isOpen
  }, [])

  const open = useCallback(() => {
    if (!isOpen) {
      toggle()
    }
  }, [toggle, isOpen])

  const close = useCallback(() => {
    if (isOpen) {
      toggle()
    }
  }, [toggle, isOpen])

  const userToggle = useCallback(() => {
    toggle(true)
  }, [toggle])

  useEffect(() => {
    const { classList } = document.body

    const shouldOpen = localStorage.getItem('sidebar.isOpen') === '1'
    setIsOpen(shouldOpen)
    if (shouldOpen) {
      classList.add('aside-menu-lg-show')
    } else {
      classList.remove('aside-menu-lg-show')
    }

    return () => {
      classList.remove('aside-menu-lg-show')
    }
  }, [])

  const oldSelection = useRef<string[]>()
  useEffect(() => {
    if (selectedRows == null) {
      return
    }

    if (isAutoToggle === false) {
      return
    }

    const selectionChanged =
      JSON.stringify(oldSelection.current) !== JSON.stringify(selectedRows)

    if (selectionChanged) {
      if (openForSingleRow && selectedRows.length === 1) {
        open()
      } else if (openForMultiRows && selectedRows.length > 1) {
        open()
      } else {
        close()
      }
    }

    oldSelection.current = selectedRows
  }, [
    selectedRows,
    isAutoToggle,
    open,
    close,
    openForSingleRow,
    openForMultiRows,
  ])

  return useMemo(
    () => ({
      toggle,
      userToggle,
      isOpen,
      open,
      close,
      isAutoToggle,
    }),
    [isOpen, toggle, userToggle, open, close, isAutoToggle],
  )
}

export default useSidebar
