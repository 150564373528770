import keyBy from 'lodash/keyBy'
import lowerFirst from 'lodash/lowerFirst'
import lounescape from 'lodash/unescape'

export const importShortcode = (filters: FiltersType, shortcode: string) => {
  filters.clear()

  const parsed = Array.from(
    shortcode.matchAll(/filter_(?<i>\d+)_(?<t>[^"]+)="(?<v>[^"]+)"+/gi),
  ).map((a) => a.groups)

  const values = keyBy(
    parsed.filter((a) => a.t === 'value'),
    'i',
  )
  const keys = keyBy(
    parsed.filter((a) => a.t === 'key'),
    'i',
  )

  const filterState = {}

  for (const i in keys) {
    const key = lowerFirst(keys[i].v.substring(6))
    const value = values[i].v

    filterState[key] = lounescape(value).split(',')
  }

  filters.state.set(filterState)

  const allAttributes = keyBy(
    Array.from(shortcode.matchAll(/ (?<t>[^"]+)="(?<v>[^"]+)"+/gi)).map(
      (a) => a.groups,
    ),
    't',
  )

  return Object.hasOwn(allAttributes, 'query')
    ? lounescape(allAttributes.query.v)
    : null
}
