import type { Product, ProductType } from '@ttc/api/products'

export const TAB_PRODUCT = 'product'
export const TAB_DELIVERIES = 'deliveries'
export const TAB_LOG = 'log'

export type Tab = typeof TAB_PRODUCT | typeof TAB_LOG | typeof TAB_DELIVERIES

export type SelectedProductProps = {
  addType?: 'variable' | 'simple'
  name?: string
  productId?: number
  parentProductId?: number
}

export type EditProductPanelProps = {
  isOpen: boolean
  onClose: () => void
  onProductUpdated: (product: Product | null) => Promise<any> | void
  readOnly: boolean
} & SelectedProductProps

export type VariationOption = SelectOption & { product: Product | null }

export type VariationFormState = {
  enabled: boolean
  isBlocked: boolean
  sku: string
  variationDescription: string
  salePrice: string
  regularPrice: string
  preorderDate: string
  variationId: string
}

export type ProductFormState = {
  type: ProductType
  variationOptions: VariationOption[]
  variation?: string
  parentSku: string
  name: string
  botanicalName: string
  plantType: SelectOption[]
  plantTypeOptions: SelectOption[]
  sizesOptions: SelectOption[]
  newSize?: string | null
} & VariationFormState
