import { LoadingImage } from 'components'
import { type SetType, useApi, useDropDownToggler } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import Swal from 'sweetalert2'
import { askQuestion, askQuestionWithReason } from 'utils'

type BatchActionButtonProps = {
  selectedOrders: SetType<number>
  triggerSearch: () => void
}

const BatchActionButton = (props: BatchActionButtonProps) => {
  const { selectedOrders, triggerSearch } = props

  const toggler = useDropDownToggler()

  const apiAction = useApi(null, null, () => ({ errorModal: true }))
  const apiActionThrows = useApi(null, null, () => ({ throws: true }))

  const handleClickRemove = useCallback(async () => {
    if (
      !(await askQuestion(
        `Are you sure you want to remove ${selectedOrders.values.length} order(s) from the batch?`,
      ))
    ) {
      return
    }

    await apiAction.performRequest({
      action: 'batches_unassignOrders',
      json: {
        orderIds: selectedOrders.values,
      },
    })

    triggerSearch()

    if (global.updateOrderStats) {
      global.updateOrderStats()
    }
  }, [selectedOrders, triggerSearch, apiAction])

  const handleClickBlock = useCallback(async () => {
    await askQuestionWithReason(
      `Are you sure you want to block ${selectedOrders.values.length} orders? Those orders will be removed from this batch and will be excluded from auto-batch.`,
      {
        preConfirm: async (reason: string) => {
          try {
            await apiActionThrows.performRequest({
              action: 'batches_blockOrders',
              json: {
                orderIds: selectedOrders.values,
                reason,
              },
            })
          } catch (e) {
            Swal.showValidationMessage(e.message)
          }
        },
      },
    )

    triggerSearch()

    if (global.updateOrderStats) {
      global.updateOrderStats()
    }
  }, [selectedOrders, triggerSearch, apiActionThrows])

  const isLoading = false
  const disabled = isLoading || selectedOrders.values.length === 0

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle
        nav
        caret={!isLoading}
        className={disabled ? 'disabled' : ''}
      >
        Batch {isLoading ? <LoadingImage small /> : null}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickRemove}>
          Remove from Batch
        </DropdownItem>
        <DropdownItem onClick={handleClickBlock}>Block orders</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default BatchActionButton
