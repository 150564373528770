import { Badge } from 'reactstrap'

type Props = {
  value?: string
  className?: string
  isDisabled?: boolean
}

const statusColors = {
  default: 'success',
  'n/a': 'muted',
  none: 'danger',
  QUANTITY: 'success',
  BOGO: 'warning',
}

const statusIcons = {
  BOGO: 'gift',
}

const DiscountBadge = ({ className, value, isDisabled }: Props) => {
  const statusText = String(value).toUpperCase().replace(/_/g, ' ')

  const hasIcon = value !== 'n/a'

  const className_ = isDisabled
    ? `${className} text-decoration-line-through`
    : className

  const color = isDisabled ? 'muted' : statusColors[value] || 'warning'

  const icon = statusIcons[value] || 'percent'

  return (
    <Badge
      className={className_}
      color={color}
      title={isDisabled ? 'This discount has been disabled' : ''}
    >
      {hasIcon ? <i className={`fa fa-${icon} pr-1`} /> : null}
      {statusText}
    </Badge>
  )
}

export default DiscountBadge
