import type { Zone } from '@ttc/api/zones'
import { useCallback } from 'react'
import { Button } from 'reactstrap'

type ZoneButtonProps = {
  zone: Zone
  onClick: (zone: Zone) => any
}

const ZoneButton = ({ zone, onClick }: ZoneButtonProps) => {
  const handleClick = useCallback(() => {
    onClick(zone)
  }, [zone, onClick])

  return (
    <Button
      onClick={handleClick}
      key={zone.id}
      className="mr-2"
      style={{ background: zone.color }}
    >
      <span
        style={{
          fontWeight: 'bold',
          color: 'white',
        }}
      >
        {zone.column_label}
      </span>
    </Button>
  )
}

export default ZoneButton
