const getSetting = (key: string, defaultValue = '') => {
  const settings = global.appSettings || {}

  if (Object.hasOwn(settings, key)) {
    return settings[key]
  }

  return defaultValue
}

const getSettingCents = (key: string, defaultValue = 0) => {
  const ret = getSetting(key, String(defaultValue))

  return Number(ret) * 100
}

export const getWarrantyPercent = () => {
  return getSetting('_ttc_warranty_cost', '10')
}

export const getOversizeFeeCents = () => {
  return getSettingCents('_ttc_oversize_amount', 9.9)
}

export const getShippingRateCents = () => {
  return getSettingCents('_ttc_shipping_cost', 9.95)
}

export const getFreeShippingThresholdCents = () => {
  return getSettingCents('_ttc_free_shipping_threshold', 100)
}

export const getDefaultCouponAmount = () => {
  return getSetting('_ttc_default_coupon_amount', '20%')
}

export const getDefaultCouponFreeShipping = () => {
  return Boolean(getSetting('_ttc_default_coupon_free_shipping', '0'))
}
