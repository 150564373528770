import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import {
  type PrinterQueueType,
  QUEUE_STATUS_IDLE,
  QUEUE_STATUS_PAUSED,
  QUEUE_STATUS_RUNNING,
  QUEUE_STATUS_WAIT,
  STATUS_DONE,
  STATUS_FAILED,
  STATUS_NEW,
  STATUS_QUEUED,
  STATUS_RUNNING,
} from './usePrinterQueue'

const isDebugMode = localStorage.getItem('DEBUG') === '1'

const PrintCard = ({ printQueue }: { printQueue: PrinterQueueType }) => {
  const { zebraPrinter } = printQueue

  const handleClickAddJob = () => {
    printQueue.addJob({
      name: 'test',
      desc: 'Just a test job',
      data: Math.random(),
    })
  }

  return (
    <Card className="mb-3">
      <CardHeader className="d-flex justify-content-center">
        Printer Queue
      </CardHeader>
      <CardBody className="text-align-center">
        <Row className="mb-2">
          <Col>Printer Status: {zebraPrinter.status.value}</Col>
        </Row>
        <hr />
        <Row className="mb-2">
          <Col>
            {printQueue.status === QUEUE_STATUS_IDLE ? (
              <>Ready to print</>
            ) : printQueue.status === QUEUE_STATUS_WAIT ||
              printQueue.status === null ? (
              <>Waiting for printer...</>
            ) : printQueue.status === QUEUE_STATUS_PAUSED ? (
              <>PAUSED</>
            ) : printQueue.status === QUEUE_STATUS_RUNNING ? (
              <>Processing...</>
            ) : null}
          </Col>
        </Row>
        {isDebugMode ? (
          <Row>
            <Col>
              <Button onClick={handleClickAddJob}>Add Test job</Button>

              {printQueue.status === QUEUE_STATUS_PAUSED ? (
                <Button onClick={printQueue.resume}>Resume</Button>
              ) : null}
            </Col>
          </Row>
        ) : null}
        {printQueue.jobs.length ? <hr /> : null}
        {printQueue.jobs.map((job) => {
          return (
            <Row key={job.id}>
              <Col>
                {job.status === STATUS_NEW || job.status === STATUS_QUEUED ? (
                  <i className="fa fa-clock-o mr-1" title="Queued" />
                ) : null}
                {job.status === STATUS_RUNNING ? (
                  <i className="fa fa-spin fa-circle-o-notch mr-1" />
                ) : null}
                {job.status === STATUS_FAILED ? (
                  <i
                    style={{ color: 'red' }}
                    className="fa fa-times-circle mr-1"
                  />
                ) : null}
                {job.status === STATUS_DONE ? (
                  <i
                    style={{ color: 'green' }}
                    className="fa fa-check-square mr-1"
                  />
                ) : null}
                {job.desc}{' '}
                {job.status === STATUS_FAILED ? ` ERROR: ${job.error}` : ''}
              </Col>
            </Row>
          )
        })}
      </CardBody>
    </Card>
  )
}

export default PrintCard
