import { EditPanel, EditPanelCard, FormRenderer, Loading } from 'components'
import type { FormDef } from 'components/Form/types'
import { useApi, useFormWithDef, usePrevious } from 'hooks'
import { Fragment, useCallback, useEffect } from 'react'
import { Button, Col, FormGroup, Row } from 'reactstrap'
import { askQuestion } from 'utils'

const formDef: FormDef = [
  {
    label: 'Enabled',
    placeholder: '',
    name: 'enabled',
    type: 'checkbox',
    defaultValue: '',
  },
  {
    label: 'Internal Name',
    placeholder: '',
    name: 'name',
    type: 'text',
    defaultValue: '',
  },
  {
    label: 'Internal Description',
    placeholder: '',
    name: 'description',
    type: 'text',
    defaultValue: '',
  },
  {
    label: 'Percentage discount for the second item',
    placeholder: '',
    name: 'percentage_discount',
    type: 'number',
    defaultValue: '100',
  },
  {
    label: 'Promo text for the price',
    placeholder: '',
    name: 'promo_text_price',
    type: 'text',
    defaultValue: 'Buy One, Get One Free!',
  },
  {
    label: 'Short promo text for quantity selection',
    placeholder: '',
    name: 'promo_text_qty',
    type: 'text',
    defaultValue: 'BOGO',
  },
]

const CardActions = ({ handleClickDelete }) => {
  return (
    <EditPanelCard caption="Actions" defaultIsOpen={false}>
      <Row>
        <Col>
          <FormGroup className="form-actions mt-3 text-center">
            <Button
              name="delete"
              size="lg"
              type="button"
              color="danger"
              onClick={handleClickDelete}
            >
              Delete Discount
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </EditPanelCard>
  )
}

type DiscountEditorProps = {
  readOnly: boolean
  form: any
}

const DiscountEditor = (props: DiscountEditorProps) => {
  const { readOnly, form } = props

  return (
    <EditPanelCard caption="Discount" stateId="discount">
      <FormRenderer {...{ form, formDef, readOnly }} />
      <div className="clearfix" />
    </EditPanelCard>
  )
}

type EditDiscountPanelProps = {
  readOnly: boolean
  isOpen: boolean
  onClose: () => void
  editId: string
  rowData?: any
}

const EditDiscountPanel = (props: EditDiscountPanelProps) => {
  const { readOnly, isOpen, onClose, editId } = props

  const caption = `${editId == null ? 'Add' : 'Edit'} BOGO Discount`

  const form = useFormWithDef(formDef)

  const apiLoad = useApi(null, null, { errorModal: true })
  const apiWrite = useApi(null, null, { errorModal: true })

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    const fetchData = async () => {
      const values = await apiLoad.performRequest({
        action: 'discounts_getRow',
        json: { id: editId },
      })
      form.setState({
        ...values,
        enabled: values.status === 'ACTIVE',
        percentage_discount: Number(values.items['2'] ?? '100') * 2,
      })
    }

    if (isOpen && isOpen !== prevIsOpen) {
      if (editId == null) {
        form.reset()
      } else {
        fetchData()
      }
    }
  }, [form, apiLoad, prevIsOpen, isOpen, editId])

  const handleSubmit = useCallback(async () => {
    await apiWrite.performRequest({
      action: 'discounts_setRow',
      json: {
        id: editId,
        type: 'BOGO',
        ...form.state,
        items: { '2': form.state.percentage_discount / 2 },
      },
    })

    onClose()
  }, [onClose, form, apiWrite, editId])

  const canSubmit = form.state.name !== ''

  const isLoading = apiLoad.isLoading || apiWrite.isLoading

  const handleClickDelete = useCallback(async () => {
    if (
      !(await askQuestion('Are you sure you want to delete this discount?'))
    ) {
      return
    }

    await apiWrite.performRequest({
      action: 'discounts_trashRow',
      json: { id: editId },
    })

    onClose()
  }, [apiWrite, onClose, editId])

  return (
    <EditPanel
      onSubmit={readOnly || isLoading ? null : handleSubmit}
      {...{ isOpen, onClose, caption, isLoading, canSubmit }}
    >
      {apiLoad.isLoading ? (
        <Loading />
      ) : isOpen ? (
        <Fragment key={JSON.stringify({ isOpen, editId })}>
          {!readOnly && editId != null ? (
            <CardActions {...{ handleClickDelete }} />
          ) : null}
          <DiscountEditor {...{ readOnly, form }} />
          {apiWrite.isLoading ? <Loading /> : null}
        </Fragment>
      ) : null}
    </EditPanel>
  )
}

export default EditDiscountPanel
