import { reducer, removeCartItem, setValues } from 'containers/AddOrder/useCart'

export function calculateFullRefundPlan(state: any, orderNumber = null) {
  let s = { ...state }

  const removeItems = []
  let refundItemSubtotal = 0

  for (const cartItem of state.cartItems) {
    if (orderNumber != null && cartItem.orderNumber !== orderNumber) {
      continue
    }

    if (cartItem.isEditable && cartItem.quantity > 0) {
      s = reducer(s, removeCartItem(cartItem.id, cartItem.lineItemId))

      const refundItemAmount = cartItem.price * cartItem.quantity
      refundItemSubtotal += refundItemAmount

      removeItems.push({
        name: `${cartItem.id} ${cartItem.lineItemId} ${cartItem.quantity}x ${cartItem.name} ${cartItem.size}`,
        amount: refundItemAmount * -1,
      })
    }
  }

  s = reducer(s, setValues({ shipping: '0' }))
  s = reducer(s, setValues({ discount: '0' }))

  const isFullRefund = orderNumber === null

  if (isFullRefund && s.shippingCalculated != state.shippingCalculated) {
    removeItems.push({
      name: 'Shipping',
      amount: (state.shippingCalculated - s.shippingCalculated) * -1,
    })
  }

  if (s.warrantyCalculated != state.warrantyCalculated) {
    removeItems.push({
      name: 'Warranty',
      amount: (state.warrantyCalculated - s.warrantyCalculated) * -1,
    })
  }

  if (s.hasTax && s.tax != state.tax) {
    removeItems.push({
      name: 'Tax',
      amount: (state.tax - s.tax) * -1,
    })
  }

  if (isFullRefund && s.discountCalculated != state.discountCalculated) {
    removeItems.push({
      name: 'Discount',
      amount: state.discountCalculated - s.discountCalculated,
    })
  }

  const refundTotal = Math.abs(removeItems.reduce((a, p) => a + p.amount, 0))

  removeItems.push({
    name: 'Suggested Refund Amount',
    amount: refundTotal,
    isBold: true,
  })

  return {
    refundAmount: refundTotal,
    refundItemSubtotal,
    removeItems,
    newState: s,
  }
}
