import { useDropDownToggler } from 'hooks'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

type ExportActionButtonProps = {
  onExport: () => void
}

const ExportActionButton = (props: ExportActionButtonProps) => {
  const { onExport } = props

  const toggler = useDropDownToggler()

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret>
        Export
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={onExport}>Export Batch CSV</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ExportActionButton
