import { usePersistedState } from 'hooks'
import type { UsePersistedStateOptions } from 'hooks/usePersistedState'
import { useCallback, useMemo } from 'react'
import type { FormDef, FormDefElement, UseFormReturn } from './types'

/**
 * useForm is a custom hook that manages form state and handles form changes.
 *
 * It uses the usePersistedState hook to persist form state in local storage.
 *
 * The form state is a plain object where the keys are the names of the form fields and the values are the field values.
 * The set method can be used to set a specific field value.
 * The reset method can be used to reset the form state to the initial state or to a new state.
 * The handleChange method can be used as an onChange handler for input fields.
 * The handleChangeSelect method can be used as an onChange handler for select fields.
 * Note: If the storageKey is null, the form state will not be persisted in local storage.
 *
 * @param initState - The initial state of the form.
 * @param storageKey - The key to store the form state in local storage. If it's null, the form state will not be persisted.
 * @param options - The options for usePersistedState hook.
 * @returns object - Contains the initial state, current state, and several methods to manipulate the form state.
 */
function useForm<T = any>(
  initState: Record<string, any>,
  storageKey: string | null = null,
  options: UsePersistedStateOptions = null,
): UseFormReturn<T> {
  const [state, setState] = usePersistedState<any>(
    initState,
    storageKey,
    options,
  )

  const set = useCallback(
    (key: string, value: any) => {
      const updateState = {
        ...state,
        [key]: value,
      }
      setState(updateState)
    },
    [state, setState],
  )

  const reset = useCallback(
    (state = null) => {
      if (state === null) {
        setState({ ...initState })
      } else {
        setState({ ...state })
      }
    },
    [initState, setState],
  )

  const handleChange = useCallback<
    (e: React.ChangeEvent<HTMLInputElement>) => void
  >(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, type, checked } = e.target

      /**
       * TODO(manuel, 2023-07-31) This could be more elegant in order to handle boolean props
       *
       * See notes in tadmin/plugin/src/API/Suppliers.php
       */
      set(name, type === 'checkbox' ? checked : value)
    },
    [set],
  )

  const handleChangeSelect = useCallback<(name: string, value: any) => void>(
    (name: string, value: any) => {
      if (Array.isArray(value)) {
        set(name, value)
        return
      }

      set(
        name,
        value != null && typeof value === 'object' ? value.value : value,
      )
    },
    [set],
  )

  const inputProps = useMemo<{
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  }>(() => ({ onChange: handleChange }), [handleChange])

  const selectFieldProps = useMemo(
    () => ({ onChange: handleChangeSelect }),
    [handleChangeSelect],
  )

  return useMemo<UseFormReturn<T>>(
    () => ({
      initState,
      state,
      reset,
      set,
      setState,
      handleChange,
      inputProps,
      selectFieldProps,
    }),
    [
      initState,
      state,
      reset,
      set,
      setState,
      handleChange,
      inputProps,
      selectFieldProps,
    ],
  )
}

export function getFormInitState(formDef: FormDef) {
  const ret: Record<string, any> = {}

  for (const field of formDef) {
    if (field.group === undefined) {
      const f = field as FormDefElement
      ret[f.name] = f.defaultValue || ''
    }
  }

  return ret
}

export default useForm
