import type { GetAllBatchesApiResult } from '@ttc/api/batch'
import { DropDownItemCheckbox } from 'components'
import { useApi, useDropDownToggler, usePrevious } from 'hooks'
import padStart from 'lodash/padStart'
import { useCallback, useEffect } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

const labels = {
  NULL: 'None',
  BLOCKED: 'Blocked',
  NOT_BLOCKED: 'Not Blocked',
}

const formatValue = (value: string) => {
  if (Object.hasOwn(labels, value)) {
    return labels[value]
  }

  return 'B' + padStart(value, 5, '0')
}

const FilterButton = ({
  onClick,
  isChecked,
  batch,
}: {
  onClick: (id: string) => void
  isChecked: boolean
  batch: any
}) => {
  const handleClick = useCallback(async () => {
    onClick(batch.id)
  }, [onClick, batch])

  return (
    <DropDownItemCheckbox
      {...{ isChecked }}
      id={batch.id}
      data-name={batch.name}
      onClick={handleClick}
    >
      {batch.name}
    </DropDownItemCheckbox>
  )
}

const BatchFilterButton = ({ filter }: { filter: FilterType }) => {
  const toggler = useDropDownToggler()

  const apiLoad = useApi<GetAllBatchesApiResult>(
    { action: 'batches_getAll' },
    [],
    {
      cache: true,
    },
  )

  const togglerIsOpenPrev = usePrevious(toggler.isOpen)
  useEffect(() => {
    if (togglerIsOpenPrev != toggler.isOpen && toggler.isOpen) {
      apiLoad.performRequest()
    }
  }, [togglerIsOpenPrev, toggler.isOpen, apiLoad])

  const handleClickClear = useCallback(() => {
    filter.clear()
  }, [filter])

  const handleClickNone = useCallback(() => {
    filter.setValues(['NULL'])
  }, [filter])

  const handleClickBlocked = useCallback(() => {
    filter.setValues(['BLOCKED'])
  }, [filter])

  const handleClickNotBlocked = useCallback(() => {
    filter.setValues(['NOT_BLOCKED'])
  }, [filter])

  const handleClick = useCallback(
    async (id: string) => {
      let values = filter.values

      if (values.includes(id)) {
        values = values.filter((item: string) => item !== id)
      } else {
        values = [...values, id]
      }

      // Combining batch numbers with special filters
      // like BLOCKED/NOT_BLOCKED is not allowed.
      values = values.filter((id) => /\d+/.test(id))

      filter.setValues(values)
    },
    [filter],
  )

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle
        nav
        caret
        className={filter.values.length != 0 ? 'hasValue' : ''}
      >
        {filter.values.length === 0 ? (
          <span>Batch</span>
        ) : filter.values.length === 1 ? (
          <span>{formatValue(filter.values[0])}</span>
        ) : (
          <span>
            {filter.values.map((v: string) => formatValue(v)).join(',')}
          </span>
        )}
      </DropdownToggle>
      <DropdownMenu className="pre-scrollable">
        <DropdownItem onClick={handleClickClear}>
          <i className="mr-1 fa fa-ban" /> Clear
        </DropdownItem>
        {(apiLoad.result.rows || []).map((batch) => {
          return (
            <FilterButton
              {...{ batch }}
              key={batch.id}
              onClick={handleClick}
              isChecked={filter.has(batch.id)}
            />
          )
        })}
        <DropDownItemCheckbox
          isChecked={filter.has('BLOCKED')}
          autoclose
          onClick={handleClickBlocked}
        >
          Blocked
        </DropDownItemCheckbox>
        <DropDownItemCheckbox
          isChecked={filter.has('NOT_BLOCKED')}
          autoclose
          onClick={handleClickNotBlocked}
        >
          Not Blocked
        </DropDownItemCheckbox>
        <DropDownItemCheckbox
          isChecked={filter.has('NULL')}
          autoclose
          onClick={handleClickNone}
        >
          None
        </DropDownItemCheckbox>
      </DropdownMenu>
    </Dropdown>
  )
}

export default BatchFilterButton
