export const contentBoolFilters = [
  {
    name: 'complete',
    label: 'Complete',
    optionLabels: ['Incomplete', 'Complete'],
  },
  {
    name: 'hasImage',
    label: 'Has Image',
    optionLabels: ['No Image', 'With Image'],
  },
  {
    name: 'hasMainContent',
    label: 'Has Main Content',
    optionLabels: ['No Main Content', 'With Main Content'],
  },
  {
    name: 'hasShortDesc',
    label: 'Has Short Desc',
    optionLabels: ['No Short Desc', 'With Short Desc'],
  },
  {
    name: 'hasTreeInfo',
    label: 'Has Tree Info',
    optionLabels: ['No Tree Info', 'With Tree Info'],
  },
  {
    name: 'hasSeo',
    label: 'Has SEO',
    optionLabels: ['No SEO', 'With SEO'],
  },
  {
    name: 'hasContentLink',
    label: 'Has Content Link',
    optionLabels: ['No Content Link', 'With Content Link'],
  },
]
