import type { FormDef } from 'app/components/Form/types'
import states from 'utils/us-states'

const stateOptions = [
  { label: 'Select State...', value: '' },
  ...states.map((state) => {
    return { label: state.name, value: state.abbreviation }
  }),
]

const formDef: FormDef = [
  {
    label: 'Source location for internal transfers',
    name: 'source_location_id',
    type: 'select',
    required: false,
    options: [],
  },
  {
    label: 'Name',
    placeholder: 'Name',
    name: 'name',
    type: 'text',
    required: true,
  },
  {
    group: [
      {
        label: 'Address',
        placeholder: 'Address',
        name: 'address',
        type: 'text',
        required: true,
      },
      {
        label: 'Address 2',
        placeholder: 'Address 2',
        name: 'address2',
        type: 'text',
        required: false,
      },
    ],
  },
  {
    label: 'State',
    placeholder: 'Select State...',
    name: 'state',
    type: 'select',
    required: true,
    options: stateOptions,
  },
  {
    group: [
      {
        label: 'City',
        placeholder: 'City',
        name: 'city',
        type: 'text',
        required: true,
      },
      {
        label: 'Zip Code',
        placeholder: 'Zip Code',
        name: 'zip',
        type: 'text',
        required: false,
      },
    ],
  },
  {
    label: 'Email',
    placeholder: 'Email',
    name: 'email',
    type: 'text',
  },
  {
    label: 'Phone',
    placeholder: 'Phone',
    name: 'phone',
    type: 'text',
  },
  {
    label: 'Comment',
    placeholder: 'Comment',
    name: 'comment',
    type: 'text',
  },
  {
    label: 'Ignore For Cogs',
    placeholder: 'Ignore For Cogs',
    name: 'ignore_for_cogs',
    type: 'checkbox',
    info: 'When checked, purchased orders from this supplier will be ignored when computing COGS. This is necessary for suppliers used for internal logistics, such as TTC-2.',
  },
]

export default formDef
