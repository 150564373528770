import { Button, Col, FormGroup, Row } from 'reactstrap'

const SaveButtonFooter = ({
  canSubmit,
  showSaveSuccess,
}: {
  canSubmit: boolean
  showSaveSuccess: boolean
}) => {
  return (
    <Row
      style={{
        position: 'sticky',
        bottom: 0,
        background: 'white',
        borderTop: '1px solid #c5dbea',
      }}
    >
      <Col>
        <FormGroup className="form-actions mt-3 text-center">
          <Button
            style={{ minWidth: '120px' }}
            disabled={canSubmit === false}
            type="submit"
            color={showSaveSuccess ? 'success' : 'primary'}
          >
            {showSaveSuccess ? (
              <>
                {' '}
                <i className="fa fa-check"></i> Saved{' '}
              </>
            ) : (
              <>Save Changes</>
            )}
          </Button>
        </FormGroup>
      </Col>
    </Row>
  )
}

export default SaveButtonFooter
