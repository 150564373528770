import { FilterBar, Loading, PageNavHead, PageNavTail } from 'components'
import { Table, resetTable } from 'components/Table'
import TablePagination from 'components/Table/TablePagination'
import EditProductPanel from 'containers/common/EditProductPanel'
import ManageTagsPanel from 'containers/common/ManageTagsPanel'
import { SingleOrderSummarySidebar } from 'containers/common/OrderSummarySidebar'
import TagActionButton from 'containers/common/TagActionButton'
import {
  useApi,
  useFiltersWithUrlUpdater,
  usePageTitle,
  usePanelControl,
  usePrevious,
  useSidebar,
  useStateful,
} from 'hooks'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Container, Row, TabContent } from 'reactstrap'
import { useDebouncedCallback } from 'use-debounce'
import ActionButton from './Actions/ActionButton'
import PrintActionButton from './Actions/PrintActionButton'
import { useBatchesTable } from './BatchesTable'
import Filters from './Filters'
import { useItemsTable } from './ItemsTable'
import Sidebar from './Sidebar'

import type { GetOrderApiResult, GetOrdersApiParams } from '@ttc/api/orders'
import type { ManageBatchesFiltersType } from './Filters'

const pageTitle = 'Open Batches'

const visibleFilters = ['q', 'status']

const ManageBatches = (props: { readOnly: boolean }) => {
  const { readOnly } = props

  usePageTitle(pageTitle)

  const filters = useFiltersWithUrlUpdater(
    'manageBatchesFilters',
    visibleFilters,
  ) as ManageBatchesFiltersType

  const selectedOrder = useStateful<string>(null)

  const getOrder = useApi<GetOrderApiResult, GetOrdersApiParams>(
    useMemo(
      () => ({ action: 'editOrder_getOrder', order_id: selectedOrder.value }),
      [selectedOrder.value],
    ),
    null,
    { autoPerform: Boolean(selectedOrder.value) },
  )

  const manageTagsPanel = usePanelControl()
  const editProductPanel = usePanelControl()
  const [editProductProps, setEditProductProps] = useState<any>(false)
  const handleClickProductName = useCallback(
    (props) => {
      setEditProductProps(props)
      editProductPanel.open()
    },
    [editProductPanel],
  )

  const getOrders = useApi(() => ({ action: 'batches_getOrders' }))

  const batchesTable = useBatchesTable(filters, readOnly)
  const selectedRow =
    batchesTable.selectedRows.length === 1 ? batchesTable.selectedRows[0] : null
  const { selectedRows } = batchesTable

  const sidebar = useSidebar({
    openForSingleRow: true,
    openForMultiRows: true,
    selectedRows,
  })

  const loadOrdersNow = useCallback(() => {
    if (selectedRows.length) {
      return getOrders.performRequest({
        json: { ids: selectedRows, qtyField: 'checked_in_qty' },
      })
    }
  }, [getOrders, selectedRows])

  const loadOrders = useDebouncedCallback(
    useCallback(() => {
      loadOrdersNow()
    }, [loadOrdersNow]),
    750,
  )

  const itemsTable = useItemsTable({
    readOnly,
    batchIds: selectedRows,
    handleClickProductName,
    loadOrders,
  })

  /* If batches selection changes, reload items and sidebar. */
  const prevSelectedRows = usePrevious(selectedRows)
  const prevIsOpen = usePrevious(sidebar.isOpen)
  const maybeReloadItems = useCallback(() => {
    let loadSidebar = false

    if (JSON.stringify(prevSelectedRows) !== JSON.stringify(selectedRows)) {
      getOrders.reset()

      itemsTable.dispatch(resetTable())

      if (selectedRows.length) {
        itemsTable.triggerSearch()
      }

      loadSidebar = sidebar.isOpen
    }

    if (loadSidebar || (prevIsOpen !== sidebar.isOpen && sidebar.isOpen)) {
      loadOrdersNow()
    }

    if (!sidebar.isOpen) {
      selectedOrder.set(null)
    }
  }, [
    sidebar.isOpen,
    loadOrdersNow,
    getOrders,
    selectedRows,
    prevSelectedRows,
    selectedOrder,
    itemsTable,
    prevIsOpen,
  ])
  useEffect(maybeReloadItems, [maybeReloadItems, sidebar.isOpen, selectedRows])

  /* Reload items and sidebar when auto-reload of batches table triggers. */
  const prevBatchRows = usePrevious(batchesTable.rows)
  useEffect(() => {
    if (prevBatchRows != batchesTable.rows) {
      if (selectedRows.length) {
        itemsTable.triggerSearch()

        if (sidebar.isOpen) {
          loadOrders()
        }
      }
    }
  }, [
    prevBatchRows,
    batchesTable.rows,
    sidebar,
    itemsTable,
    loadOrders,
    selectedRows,
  ])

  const query = filters.searchQueryString

  const handleChangeSearch = useCallback(
    (query) => {
      filters.q.setValues([query])
    },
    [filters.q],
  )

  const apiCombine = useApi(
    () => ({ action: 'batches_combine' }),
    null,
    () => ({
      errorModal: true,
    }),
  )

  const apiArchive = useApi({ action: 'batches_archive' }, null, {
    errorModal: true,
  })

  const handleOrderSidebarBack = useCallback(() => {
    selectedOrder.set(null)
  }, [selectedOrder])

  const isLoading =
    batchesTable.isLoading ||
    itemsTable.isLoading ||
    getOrders.isLoading ||
    apiArchive.isLoading ||
    apiCombine.isLoading

  const getTags = useApi(
    () => ({ action: 'batchTags_getAll' }),
    () => [],
    () => ({
      autoPerform: true,
      normalize: (input) => input.rows,
      cache: true,
    }),
  )

  const handleCloseTagsPanel = useCallback(() => {
    manageTagsPanel.close()
    getTags.performRequest()
  }, [manageTagsPanel, getTags])

  return (
    <Fragment>
      <PageNavHead
        {...{
          isLoading,
          pageTitle,
          sidebar,
          onClickReload: batchesTable.triggerSearch,
        }}
      />
      <PageNavTail
        {...{
          isLoading,
          query,
          handleChangeSearch,
          searchPlaceholder: 'Search Batch Number',
        }}
      >
        {!readOnly ? (
          <ActionButton
            {...{ selectedRows, batchesTable, apiCombine, apiArchive }}
          />
        ) : null}
        <PrintActionButton
          {...{ selectedRows, triggerSearch: batchesTable.triggerSearch }}
        />
        <TagActionButton
          toggleTagApiAction="batches_toggleTags"
          {...{
            selectedRows,
            rows: batchesTable.rows,
            tags: getTags.result,
            onClickManage: manageTagsPanel.open,
            onModified: batchesTable.triggerSearch,
            disabled: readOnly,
          }}
        />
      </PageNavTail>
      <FilterBar {...{ filters }}>
        <Filters {...{ filters, batchStatuses: batchesTable.batchStatuses }} />
      </FilterBar>
      <Container fluid>
        <ManageTagsPanel
          apiPrefix="batchTags"
          isOpen={manageTagsPanel.isOpen}
          onClose={handleCloseTagsPanel}
        />
        <EditProductPanel
          {...{ readOnly }}
          {...editProductProps}
          onProductUpdated={itemsTable.triggerSearch}
          isOpen={editProductPanel.isOpen}
          onClose={editProductPanel.close}
        />
        <Row>
          <Col>
            <TabContent className="mb-5 mt-4">
              <div className="manage-batches animated fadeIn">
                <Row noGutters>
                  <Col
                    lg={6}
                    xl={4}
                    style={{ borderRight: '1px solid #c8ced3' }}
                  >
                    <Table
                      border={false}
                      hasPagination={false}
                      entityName="batches"
                      {...batchesTable}
                    />
                    <TablePagination
                      className="mb-2 mt-2 mr-2"
                      compact
                      {...batchesTable}
                    />
                    {!query &&
                    !batchesTable.error &&
                    batchesTable.didLoad &&
                    batchesTable.resultTotal === 0 ? (
                      <div className="m-3">No open batches.</div>
                    ) : null}
                  </Col>
                  <Col lg={6} xl={8} style={{ minHeight: 140 }}>
                    {itemsTable.batchIds.length &&
                    !itemsTable.didLoad &&
                    itemsTable.isLoading ? (
                      <Loading />
                    ) : selectedRows.length ? (
                      !itemsTable.error &&
                      itemsTable.didLoad &&
                      itemsTable.rows.length === 0 ? (
                        <div className="m-3">Batch is empty.</div>
                      ) : (
                        <Table
                          key={selectedRow}
                          border={false}
                          hasPagination={false}
                          entityName="items"
                          {...itemsTable}
                        />
                      )
                    ) : selectedRows.length === 0 &&
                      batchesTable.rows.length ? (
                      <div className="m-3">Please select a batch.</div>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </TabContent>
          </Col>
        </Row>
      </Container>
      {selectedOrder.value ? (
        <SingleOrderSummarySidebar
          {...{
            getOrder,
            onToggle: sidebar.userToggle,
            onBack: handleOrderSidebarBack,
          }}
        />
      ) : (
        <Sidebar
          {...{
            selectedOrder,
            getOrders,
            selectedRows,
            onToggle: sidebar.userToggle,
            triggerSearch: batchesTable.triggerSearch,
            loadOrders,
            loadOrdersNow,
            readOnly,
          }}
        />
      )}
    </Fragment>
  )
}

export default ManageBatches
