import { getApiBaseUrl } from 'api'
import type { Column } from 'app/components/Table/reducer/types'
import type { Columns } from 'components/Table/types'
import { useDropDownToggler } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

const ExportActionButton = ({
  query,
  requestProps,
  sortColumn,
  sortAsc,
  columns,
}: {
  query: string
  requestProps: Record<string, string>
  sortColumn: string
  sortAsc: boolean
  columns: Columns
}) => {
  const toggler = useDropDownToggler()

  const handleClickExport = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      const format = (e.target as HTMLElement).getAttribute('data-format')

      const visibleColumns = columns.visibleSorted.filter(
        (column: Column) => column.canExport !== false,
      )

      const params = {
        ...requestProps,
        query,
        sortColumn,
        sortAsc,
        columns: visibleColumns,
      }

      const paramsEncoded = encodeURIComponent(JSON.stringify(params))

      let url = `${getApiBaseUrl()}/?action=treeadmin_inventory_export`
      url += `&params=${paramsEncoded}`
      url += `&format=${format}`

      if (e.shiftKey) {
        url = `${url}&text=1`
      }

      const win = window.open(url)
      win.document.title = 'Exporting...'

      // Show message while exporting instead of a blank page.
      // This is just eye candy and only works in Firefox but not Chrome.
      const host = document.location.host
      const protocol = document.location.protocol
      const spinnerUrl = `${protocol}//${host}/assets/img/loading-spinner-grey.gif`
      win.document.body.innerHTML = `<div style="text-align: center; padding-top: 2em"><img src="${spinnerUrl}"> <br><br> Generating export. Please note that exporting historical data may take a few minutes.</div>`
    },
    [requestProps, query, sortColumn, sortAsc, columns],
  )

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret>
        Export
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem data-format="csv" onClick={handleClickExport}>
          Export CSV
        </DropdownItem>
        <DropdownItem data-format="xlsx" onClick={handleClickExport}>
          Export Excel
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ExportActionButton
