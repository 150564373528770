import { CellEditable } from 'components/Table'
import type { ColumnDef } from 'components/Table/types'
import { formatDateReadable, formatPrice } from 'utils'
import { renderTruckStatusValue } from '../EditPurchaseOrder/DeliveriesTable/TrucksTable/columnDef'

export const getColumnDef = (readOnly: boolean): ColumnDef[] => {
  const isEditable = !readOnly

  return [
    {
      label: 'Purchase Order',
      id: 'purchase_order_id',
      sortColumn: true,
    },
    {
      label: 'Truck ID',
      id: 'id',
      sortColumn: true,
      defaultVisible: false,
    },
    {
      label: 'Truck #',
      id: 'truck_name',
      sortColumn: false,
    },
    {
      label: 'Status',
      id: 'status',
      sortColumn: true,
      renderer: (props) => {
        return <td>{renderTruckStatusValue(props.value)}</td>
      },
    },
    {
      label: 'Supplier',
      id: 'supplier',
      sortColumn: true,
    },
    {
      label: 'Supplier ID',
      id: 'supplier_id',
      sortColumn: false,
      defaultVisible: false,
    },
    {
      label: 'Truck Date',
      id: 'date',
      sortColumn: true,
      renderer: ({ value }) => {
        return <td>{value ? formatDateReadable(String(value)) : ''}</td>
      },
    },
    {
      label: 'Freight charge',
      id: 'freight_charge',
      sortColumn: true,
      isEditable,
      isMoney: true,
      renderer: isEditable
        ? (props) => {
            return <CellEditable {...props} />
          }
        : ({ value }) => {
            return <td>${formatPrice(Number(value) * 100)}</td>
          },
    },
    {
      label: 'Arrived',
      id: 'arrived_qty',
      sortColumn: false,
    },
    {
      label: 'Expected',
      id: 'expected_qty',
      sortColumn: false,
    },
    {
      label: 'Total',
      id: 'total',
      title: 'Purchase Order Item Cost * Arrived Quantity',
      sortColumn: false,
      renderer: ({ value }) => {
        return <td>{value ? '$' + formatPrice(Number(value) * 100) : null}</td>
      },
    },
    {
      label: 'Putaway total',
      id: 'zones_total',
      sortColumn: true,
    },
    {
      label: 'Putaway',
      id: 'zones',
    },
  ]
}
