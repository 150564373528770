import type { NoteEntry } from '@ttc/api/notes'
import { EditPanelCard } from 'components'
import useApi from 'hooks/useApi'
import useBoolean from 'hooks/useBoolean'
import useStateful from 'hooks/useStateful'
import { useCallback } from 'react'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { askQuestion } from 'utils/alert'
import CardEditButton from '../CardEditButton'
import Note from './Note'

type NotesPanelCard = {
  id: number
  notes: NoteEntry[]
  isLoading: boolean
  readOnly?: boolean
  apiAddAction: string
  apiRemoveAction?: string
  onSaved?: () => void
}

const NotesPanelCard = (props: NotesPanelCard) => {
  const {
    id,
    notes,
    readOnly,
    isLoading,
    apiAddAction,
    apiRemoveAction,
    onSaved,
  } = props

  const editMode = useBoolean()
  const note = useStateful<string>('')

  const handleChangeText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      note.set(e.target.value)
    },
    [note],
  )

  const apiAddNote = useApi(
    () => ({ action: apiAddAction }),
    null,
    () => ({
      errorModal: true,
      throws: true,
    }),
  )

  const handleClickSubmit = useCallback(async () => {
    try {
      await apiAddNote.performRequest({ json: { id, note: note.value } })
      editMode.off()
      note.set('')

      if (onSaved) {
        onSaved()
      }
    } catch (_e) {
      /* Ignore */
    }
  }, [note, id, apiAddNote, editMode, onSaved])

  const apiRemoveNote = useApi(
    () => ({ action: apiRemoveAction }),
    null,
    () => ({
      errorModal: true,
      throws: true,
    }),
  )

  const handleClickRemove = useCallback(
    async (note: NoteEntry) => {
      try {
        const confirm = await askQuestion(
          `Really delete this note? "${note.note}"`,
        )
        if (!confirm) {
          return
        }

        await apiRemoveNote.performRequest({ json: { id, noteId: note.id } })

        if (onSaved) {
          onSaved()
        }
      } catch (_e) {
        /* Ignore */
      }
    },
    [id, apiRemoveNote, onSaved],
  )

  const handleClickCancel = useCallback(() => {
    editMode.off()
  }, [editMode])

  const headerActions = (
    <CardEditButton isEditing={editMode.value} onClick={editMode.toggle} />
  )

  const canSubmit = !isLoading && note.value.length

  return (
    <EditPanelCard
      caption="Notes"
      stateId="entity_notes"
      bodyProps={{ className: 'p-3' }}
      headerActions={!readOnly ? headerActions : null}
    >
      {editMode.value ? (
        <>
          <Row>
            <Col>
              <FormGroup>
                <Label for="note_textarea">Add Note</Label>
                <Input
                  type="textarea"
                  name="text"
                  id="note_textarea"
                  value={note.value}
                  onChange={handleChangeText}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              <Button
                disabled={!canSubmit}
                size="sm"
                className="m-2"
                onClick={handleClickSubmit}
              >
                Submit
              </Button>
              <Button
                disabled={isLoading}
                size="sm"
                className="m-2"
                onClick={handleClickCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </>
      ) : null}
      {notes.length ? (
        <Row className="mt-2 mb-2">
          <Col>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              {notes.map((note: NoteEntry) => {
                return (
                  <Note
                    key={note.id}
                    note={note}
                    onRemove={
                      !readOnly && apiRemoveAction && editMode.value
                        ? handleClickRemove
                        : null
                    }
                  />
                )
              })}
            </ul>
          </Col>
        </Row>
      ) : null}
      {notes.length === 0 ? (
        <Row>
          <Col>
            <i>No notes have been added yet.</i>
          </Col>
        </Row>
      ) : null}
    </EditPanelCard>
  )
}

export default NotesPanelCard
