import type { PurchaseOrder } from '@ttc/api/purchaseOrders'
import { EditPanel, EditPanelCard, FormRenderer, Loading } from 'components'
import type { FormDef } from 'components/Form/types'
import { type ApiCall, useApi, useFormWithDef, usePrevious } from 'hooks'
import { useCallback, useEffect } from 'react'
import { Col, Row } from 'reactstrap'

const formDef: FormDef = [
  {
    label: 'Reference No.',
    placeholder: '',
    name: 'reference_number',
    type: 'text',
  },
]

type RefNumberEditorProps = {
  isOpen: boolean
  onClose: (isSaved?: boolean) => void
  editId: number
  getPurchaseOrder: ApiCall<PurchaseOrder>
}

const RefNumberEditor = (props: RefNumberEditorProps) => {
  const { getPurchaseOrder, isOpen, onClose, editId } = props

  const form = useFormWithDef(formDef)

  const updatePurchaseOrder = useApi({ action: 'purchaseOrders_update' })

  // Fill form as soon as panel opens.
  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (prevIsOpen !== isOpen) {
      if (isOpen && getPurchaseOrder.result != null) {
        const { reference_number } = getPurchaseOrder.result

        form.setState({ reference_number })
      } else if (!isOpen) {
        form.reset()
      }
    }
  }, [form, prevIsOpen, isOpen, getPurchaseOrder.result])

  const handleSubmit = useCallback(() => {
    const formSubmit = async () => {
      const ret = await updatePurchaseOrder.performRequest({
        id: editId,
        ...form.state,
      })

      if (ret) {
        onClose(true)
      }
    }

    formSubmit()
  }, [editId, form.state, onClose, updatePurchaseOrder])

  const isLoading = updatePurchaseOrder.isLoading

  return (
    <EditPanel
      caption="Reference No."
      onSubmit={isLoading ? null : handleSubmit}
      {...{ isOpen, onClose, isLoading }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <EditPanelCard caption="Reference No." stateId="reference_number">
          {updatePurchaseOrder.error ? (
            <Row>
              <Col className="text-danger">{updatePurchaseOrder.error}</Col>
            </Row>
          ) : null}
          <FormRenderer {...{ form, formDef }} />
        </EditPanelCard>
      )}
    </EditPanel>
  )
}

export default RefNumberEditor
