import { useCallback } from 'react'
import { Col, Input, Row } from 'reactstrap'
import Pagination from './Pagination'
import { setPaginationLimit, setPaginationPage } from './reducer/actions'
import type { TableProps } from './types'

type TablePaginationProps = {
  compact?: boolean
  className?: string
} & TableProps

/**
 * TablePagination is a React component that renders a pagination bar for table data.
 * It uses 'reactstrap' components for styling and 'lodash' for handling page ranges.
 *
 * Props:
 * - pages (number): Total number of pages to display.
 * - activePage (number): The currently active or selected page.
 * - listClassName (string): CSS class for styling the pagination list.
 * - onChange (function): Callback function that is called with the new page number when a page is changed.
 */
const TablePagination = (props: TablePaginationProps) => {
  const {
    compact,
    className,
    rows,
    error,
    resultLimit,
    resultTotal,
    paginationLimit,
    paginationPage,
    dispatch,
    entityName,
  } = props

  const hasResults = error == null && rows.length > 0

  const totalPages = Math.max(
    1,
    resultLimit > 0 ? Math.ceil(resultTotal / resultLimit) : 1,
  )

  const handleChangePage = useCallback(
    (page: number) => {
      dispatch(setPaginationPage(page))
    },
    [dispatch],
  )

  const handleChangePerPageLimit = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      e.stopPropagation()

      const limit = Number(e.target.value || 10)
      dispatch(setPaginationLimit(limit))
    },
    [dispatch],
  )

  return (
    <Row {...{ className }}>
      <Col xs="12" md="6" className={!compact ? 'mb-4' : null}>
        {hasResults ? (
          <div>
            {!compact ? `Showing` : null}
            <Input
              className="mr-2 ml-2 d-inline"
              type="select"
              name="per-page"
              value={paginationLimit}
              style={{ width: 'auto' }}
              onChange={handleChangePerPageLimit}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
              <option value="250">250</option>
              <option value="500">500</option>
              <option value="750">750</option>
              <option value="1000">1000</option>
            </Input>
            {!compact ? (
              <>
                out of {Number(resultTotal) === 10000 ? ' > ' : ''}
                {Number(resultTotal).toLocaleString()} {entityName || 'entries'}
                .
              </>
            ) : null}
          </div>
        ) : null}
      </Col>
      <Col xs="12" md="6">
        {hasResults ? (
          <Pagination
            onChange={handleChangePage}
            listClassName="justify-content-end justify-content-xs-center mb-0"
            pages={totalPages}
            activePage={paginationPage}
          />
        ) : null}
      </Col>
    </Row>
  )
}

export default TablePagination
