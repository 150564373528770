import type { Columns } from 'components/Table/types'
import ColumnPresetButton from 'containers/ManageOrders/Filters/ColumnPresetButton'
import { Nav, NavItem, NavLink } from 'reactstrap'

export const FilterBar = ({
  filters,
  manageColumnsPanel,
  children,
  columns,
}: {
  filters?: { isEmpty: boolean; clear: () => void }
  manageColumnsPanel?: { toggle: () => void }
  children?: React.ReactNode
  columns?: Columns
}) => {
  return (
    <Nav pills className="nav-filters" style={{ paddingLeft: 7 }}>
      {children}
      {filters && !filters.isEmpty ? (
        <NavItem>
          <NavLink
            style={{ letterSpacing: 0, color: 'orange' }}
            href="#"
            onClick={filters.clear}
          >
            <i className="mr-1 fa fa-ban" /> Clear
          </NavLink>
        </NavItem>
      ) : null}
      <NavItem className="ml-auto" />
      {manageColumnsPanel && columns ? (
        <ColumnPresetButton {...{ manageColumnsPanel, columns }} />
      ) : null}
    </Nav>
  )
}

export default FilterBar
