import { datadogLogs } from '@datadog/browser-logs'
import { ErrorBoundary, Provider } from '@rollbar/react'
import type { AuthContextType } from 'AuthContext'
import { apiRequest } from 'api'
import { MANAGEORDERS_READ } from 'caps'
import { useApi, useInterval, useStateful } from 'hooks'
import { useCallback, useEffect, useMemo, useReducer } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Flip, ToastContainer } from 'react-toastify'
import { loginRedirect } from 'utils'
import AuthContext from './AuthContext'
import BadgeLogin from './containers/BadgeLogin'
import DefaultLayout from './containers/DefaultLayout'
import Leaderboard from './containers/Leaderboard'
import Login from './containers/Login'

import 'react-toastify/dist/ReactToastify.css'
import { isEmpty } from 'utils/types'

/* Workaround for vite, see https://github.com/sweetalert2/sweetalert2/issues/2414 */
import 'sweetalert2/dist/sweetalert2.css'

global.testUtils = {}

const isLoginPage = () => {
  const { pathname } = window.location

  return (
    pathname.indexOf('/login') === 0 || pathname.indexOf('/badgelogin') === 0
  )
}

const isLoginRequired = () => {
  const { pathname } = window.location

  return (
    isLoginPage() === false && pathname.indexOf('/shipping/leaderboard') !== 0
  )
}

const initBarcodeEvents = () => {
  let inputStr = ''
  let timer = null

  /* This code works with USB barcode scanners sending keyboard events.
   * This scanner must be configured to send a newline after each scan. */
  document.addEventListener('keyup', (e) => {
    const keyCode = e.keyCode
    const chrCode = keyCode - 48 * Math.floor(keyCode / 48)
    let chr: string

    if (keyCode === 16) {
      /* Ignore shift */
      return
    } else if (keyCode === 9 || keyCode === 13) {
      chr = '\n'
    } else {
      chr = String.fromCharCode(96 <= keyCode ? chrCode : keyCode)
    }

    if (timer) {
      clearTimeout(timer)
      timer = null
    }

    inputStr += chr

    timer = setTimeout(() => {
      const input = inputStr

      if (input.length && input.indexOf('\n') > 0) {
        const lines = input.split(/\n/)

        for (const line of lines) {
          const trimmedLine = line.trim()

          if (trimmedLine.length >= 3) {
            datadogLogs.logger.debug(`Barcode scanned: ${line}`, {
              channel: 'barcodes',
              barcode: line,
            })

            const e = new CustomEvent('barcodeScan', { detail: line })
            document.dispatchEvent(e)
          }
        }
      }

      inputStr = ''
    }, 150)
  })
}

initBarcodeEvents()

global.triggerTestException = (msg: string) => {
  throw new Error(msg)
}

const RollbarWrapper = ({ children }) => {
  if (global.appConfig.rollbarEnv && global.appConfig.rollbarToken) {
    const config = {
      accessToken: global.appConfig.rollbarToken,
      environment: global.appConfig.rollbarEnv,
      code_version: process.env.VITE_APP_VERSION,
    }

    return (
      <Provider config={config}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Provider>
    )
  }

  return children
}

type State = {
  isLoading: boolean
  isLoggedIn: boolean | null
  isAllowed: boolean
  userName: string
  userID: number
  profileImageUrl: string | null
  caps: any
}

const initState: State = {
  isLoading: true,
  isLoggedIn: null,
  isAllowed: false,
  userName: null,
  userID: 0,
  profileImageUrl: null,
  caps: {},
}

type Action =
  | {
      type: 'SIGN_IN'
      userID: number
      isAllowed: boolean
      userName: string
      caps: string[]
      profileImageUrl: string
    }
  | { type: 'SIGN_OUT' }
  | { type: 'SET_LOADING' }

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SIGN_IN': {
      return {
        ...state,
        isLoading: false,
        isLoggedIn: action.userID > 0,
        isAllowed: action.isAllowed,
        userName: action.userName,
        userID: action.userID,
        profileImageUrl: action.profileImageUrl,
        caps: action.caps,
      }
    }
    case 'SIGN_OUT': {
      return { ...initState }
    }
    case 'SET_LOADING': {
      return { ...state, isLoading: state.isLoading }
    }
  }
}

const App = () => {
  const [state, dispatch] = useReducer(reducer, initState)

  const apiStats = useApi(
    () => ({ action: 'orders_getStats' }),
    () => ({}),
    () => ({ autoPerform: false }),
  )

  const checkLoginRequest = useCallback(async () => {
    let ret: ApiCheckLoginResponse = null

    try {
      dispatch({ type: 'SET_LOADING' })
      const result = await apiRequest<ApiCheckLoginResponse>({
        action: 'checkLogin',
      })
      if (isEmpty(result)) {
        return false
      }
      ret = result

      dispatch({
        type: 'SIGN_IN',
        userName: ret.userName,
        userID: Number(ret.user),
        isAllowed: ret.isAllowed,
        profileImageUrl: ret.profileImageUrl,
        caps: ret.caps,
      })

      global.appSettings = ret.settings
    } catch (_e) {
      return false
    }

    if (global.appConfig.datadogToken) {
      datadogLogs.init({
        clientToken: global.appConfig.datadogToken,
        env: global.appConfig.datadogEnv || 'dev',
        service: 'treeadmin-js',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        version: global.appConfig.version || '',
        beforeSend: (log) => {
          // Discard "TypeError: NetworkError when attempting to fetch resource."
          if (
            log.http &&
            log.http.status_code === 0 &&
            log.message &&
            log.message.indexOf('Fetch error ') === 0 &&
            log.error.origin === 'network'
          ) {
            return false
          }

          // Discard normal expected API errors.
          if (
            log.error &&
            log.error.origin === 'network' &&
            log.http &&
            log.http.method === 'POST' &&
            log.http.status_code === 500 &&
            log.http.url &&
            log.http.url.indexOf('?action=treeadmin_') > 0
          ) {
            return false
          }
        },
      })

      if (ret.user) {
        datadogLogs.setGlobalContextProperty('context', {
          user: {
            id: ret.user,
            login: ret.userName || null,
          },
        })
      }
    }

    if (global.Rollbar) {
      global.Rollbar.configure({
        payload: {
          person: {
            id: ret.user,
            username: ret.userName || null,
            email: ret.email,
          },
        },
      })
    }
  }, [])

  const authContext = useMemo<AuthContextType>(
    () => ({
      isLoggedIn: state.isLoggedIn,
      userName: state.userName,
      userID: state.userID,
      profileImageUrl: state.profileImageUrl,
      logout: async () => {
        try {
          await apiRequest({ action: 'logout' })
        } catch (_e) {
          /* Ignore */
        }

        dispatch({ type: 'SIGN_OUT' })

        global.appSettings = {}
      },
      hasCap: (cap: string) => {
        return Object.hasOwn(state.caps, cap) && state.caps[cap] === true
      },
    }),
    [
      state.isLoggedIn,
      state.userID,
      state.userName,
      state.caps,
      state.profileImageUrl,
    ],
  )

  useEffect(() => {
    checkLoginRequest()
  }, [checkLoginRequest])

  const updateOrderStats = useCallback(() => {
    if (authContext.hasCap(MANAGEORDERS_READ)) {
      apiStats.performRequest()
    }
  }, [authContext, apiStats])

  global.updateOrderStats = updateOrderStats

  const didInit = useStateful(false)
  useEffect(() => {
    if (state.isLoggedIn && didInit.value === false) {
      updateOrderStats()
      didInit.set(true)
    }
  }, [updateOrderStats, didInit, state.isLoggedIn])

  useInterval(() => {
    updateOrderStats()
  }, 120000)

  if (isLoginRequired()) {
    if (!isLoginPage() && state.isLoggedIn === false) {
      loginRedirect()
      return null
    }

    if (state.isLoggedIn && !state.isAllowed && isLoginPage()) {
      return (
        <div>
          Logged in wordpress user is not authorized to access this page.
        </div>
      )
    }
  }

  if (state.isLoggedIn === null) {
    return null
  }

  // Handle logged in users without any capabilities (e.g. customers)
  if (state.isLoggedIn && state.isAllowed === false) {
    return <div className="p-3">Unauthorized</div>
  }

  return (
    <AuthContext.Provider value={authContext}>
      <RollbarWrapper>
        <DndProvider backend={HTML5Backend}>
          <ToastContainer
            position="top-center"
            autoClose={1500}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Flip}
          />
          <BrowserRouter>
            <Routes>
              <Route path="/shipping/leaderboard" element={<Leaderboard />} />
              <Route path="/login" element={<Login />} />
              <Route path="/badgelogin" element={<BadgeLogin />} />
              <Route path="*" element={<DefaultLayout {...{ apiStats }} />} />
            </Routes>
          </BrowserRouter>
        </DndProvider>
      </RollbarWrapper>
    </AuthContext.Provider>
  )
}

export default App
