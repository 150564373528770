import type { FormDef } from 'components/Form/types'

const formDef: FormDef = [
  {
    label: 'Link to Content document',
    name: 'contentLink',
    type: 'text',
  },
  {
    group: [
      {
        label: 'SKU',
        name: 'parentSku',
        type: 'text',
      },
      {
        label: 'Status',
        name: 'status',
        type: 'select',
        options: [
          { value: 'publish', label: 'publish' },
          { value: 'private', label: 'private' },
          { value: 'draft', label: 'draft' },
        ],
      },
    ],
  },
  {
    label: 'Name',
    name: 'name',
    type: 'text',
  },
  {
    label: 'Botanical name',
    name: 'botanicalName',
    type: 'text',
  },
]

export default formDef
