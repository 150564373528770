import { ClipboardButton } from 'components'
import type { TableCellRenderProps } from 'components/Table/types'

export const defaultCellRenderer = (props: TableCellRenderProps) => {
  const { isSelectedRow } = props
  const { id, cursor, isNumeric, hasClipboardButton, className } = props.col

  if (id === 'checkbox') {
    return (
      <td
        className="checkbox"
        data-col-id={props.col.id}
        style={{ cursor: 'pointer' }}
      >
        <input
          style={{ cursor: 'pointer' }}
          data-id={props.row.id}
          readOnly
          type="checkbox"
          checked={isSelectedRow}
        />
      </td>
    )
  }

  if (hasClipboardButton && props.value) {
    return (
      <td {...{ className }} data-col-id={id} style={{ cursor }}>
        <div className="d-flex">
          <span style={{ flex: 1 }}>{props.value}</span>
          <ClipboardButton
            style={{ alignSelf: 'center', opacity: 0.4 }}
            value={props.value}
          />
        </div>
      </td>
    )
  }

  return (
    <td {...{ className }} data-col-id={id} style={{ cursor }}>
      {isNumeric ? Number(props.value).toLocaleString() : props.value}
    </td>
  )
}

export default defaultCellRenderer
