import type { Subscription } from '@ttc/api/subscriptions'
import { Badge } from 'reactstrap'

type Props = {
  subscription: Subscription
  className?: string
}

const SubscriptionStatusBadge = ({ className, subscription }: Props) => {
  const { status, stripe_cancel_at } = subscription

  let statusColor = 'secondary'
  let statusText = String(status).toUpperCase().replace(/_/g, ' ')

  if (!status) {
    return null
  }

  switch (String(status).toLowerCase()) {
    case 'pending':
      statusColor = 'warning'
      break
    case 'active':
      statusColor = 'success'
      break
    case 'inactive':
      statusColor = 'danger'
      break
  }

  let title = ''

  if (stripe_cancel_at) {
    const cancelAtFormat = Intl.DateTimeFormat(navigator.language, {
      month: 'short',
      day: 'numeric',
    }).format(new Date(stripe_cancel_at))

    statusColor = 'warning'
    statusText = `Cancels ${cancelAtFormat}`

    title += `Cancels ${cancelAtFormat}\n`
    if (subscription.stripe_cancel_reason) {
      title += `Reason: ${subscription.stripe_cancel_reason}\n`
    }
    if (subscription.stripe_cancel_feedback) {
      title += `Feedback: ${subscription.stripe_cancel_feedback}\n`
    }
    if (subscription.stripe_cancel_comment) {
      title += `Comment: ${subscription.stripe_cancel_comment}\n`
    }
  }

  return (
    <Badge title={title} {...{ className }} color={statusColor}>
      {statusText}
    </Badge>
  )
}

export default SubscriptionStatusBadge
