import { PageNavHead, PageNavTail } from 'components'
import {
  Table,
  defaultCellRenderer,
  setSelectedRows,
  useTableApi,
  useTableWithUrlUpdater,
} from 'components/Table'
import { useApi, usePageTitle } from 'hooks'
import { usePanelControl } from 'hooks'
import { useCallback } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import { getStorage } from 'utils/storage'
import EditPlanPanel from './EditPlanPanel'
import LogPanel from './LogPanel'
import columnDef from './columnDef'

const pageTitle = 'Manage Subscription Plans'

const cacheKey = 'managePlans'
const [setItem, getItem] = getStorage(cacheKey)

const ManageSubscriptionPlans = ({ readOnly }: { readOnly: boolean }) => {
  const [state, dispatch] = useTableWithUrlUpdater({
    getItem,
    setItem,
    cacheKey,
  })
  const { isLoading, selectedRowId, selectedRows } = state
  const [triggerSearch] = useTableApi(
    'subscriptionPlans_getAll',
    state,
    dispatch,
    {
      withQty: true,
      limit: 1000,
    },
  )

  usePageTitle(pageTitle)

  const editPlanPanel = usePanelControl({
    selectedRows,
    openForSingleRow: true,
  })

  const logPanel = usePanelControl()

  const apiSync = useApi(
    () => ({ action: 'subscriptionPlans_sync' }),
    null,
    () => ({ errorModal: true }),
  )

  const handleClickSync = useCallback(async () => {
    await apiSync.performRequest()
    triggerSearch()
  }, [apiSync, triggerSearch])

  const apiAddPlan = useApi(null, null, { throws: true })

  const handleClickAdd = useCallback(async () => {
    await Swal.fire({
      title: 'Add Subscription Plan',
      text: 'Please enter the Stripe Product ID you want to use as a subscription plan. You can find this in the Stripe dashboard.',
      showCancelButton: true,
      showCloseButton: true,
      input: 'text',
      inputLabel: '',
      inputPlaceholder: 'prod_12345',
      inputValidator: (value) => {
        if (!value) {
          return 'Please enter a product ID.'
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,
      preConfirm: async (id: string) => {
        try {
          await apiAddPlan.performRequest({
            action: 'subscriptionPlans_add',
            json: { id },
          })

          triggerSearch()
          Swal.close()
        } catch (e) {
          Swal.showValidationMessage(e.message)
        }
      },
    })
  }, [apiAddPlan, triggerSearch])

  const handleClosePanel = useCallback(() => {
    editPlanPanel.close()
    dispatch(setSelectedRows([]))
    triggerSearch()
  }, [dispatch, editPlanPanel, triggerSearch])

  const renderCell = useCallback((props) => {
    const { row, col } = props

    if (col.id === 'name') {
      return (
        <td style={{ fontWeight: 'bold' }}>
          <div
            className="mr-2 d-inline-block"
            style={{
              width: 10,
              height: 10,
              borderRadius: 3,
              background: row.color,
            }}
          />
          {row.name}
        </td>
      )
    } else if (col.id.indexOf('is_') === 0) {
      return <td> {row[col.id] ? 'Yes' : 'No'} </td>
    }

    return defaultCellRenderer(props)
  }, [])

  return (
    <>
      <PageNavHead {...{ pageTitle, isLoading, onClickReload: triggerSearch }}>
        {!readOnly ? (
          <div className="actions">
            <Button onClick={handleClickSync}>Sync</Button>
            <Button onClick={handleClickAdd}>Add Plan</Button>
          </div>
        ) : null}
      </PageNavHead>
      <PageNavTail {...{ isLoading }}></PageNavTail>
      <Container fluid className="mt-4">
        <div className="animated fadeIn">
          <EditPlanPanel
            {...{ readOnly, logPanel }}
            editId={selectedRowId}
            isOpen={editPlanPanel.isOpen}
            onClose={handleClosePanel}
          />
          <LogPanel
            id={selectedRowId ? Number(selectedRowId) : null}
            isOpen={logPanel.isOpen}
            onClose={logPanel.close}
          />
          <Row>
            <Col>
              <Table
                entityName="plans"
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef,
                  dispatch,
                  hasPagination: false,
                  renderCell,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default ManageSubscriptionPlans
