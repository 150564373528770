import { usePortal } from 'hooks'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Input } from 'reactstrap'
import { assetPath } from 'utils'
import { formatDateReadable } from 'utils/date'
import { setEditCell } from './reducer/actions'
import type { CellEditableProps, EditorProps } from './types'

const elOffset = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
  }
}

const Portal = ({ id, children }) => {
  const target = usePortal(id)
  return createPortal(children, target)
}

/**
 * Editor is a reusable inline editor component for table cells.
 *
 * It supports text, number, date, and select input types.
 *
 * The editor is rendered in a portal to avoid any potential issues with overflow or positioning.
 *
 * The editor is controlled by the `isEditing` prop and the current value is managed internally with local state.
 *
 * The editor also dispatches a custom event when the Tab key is pressed to allow parent components to handle focus management.
 *
 * @param props - The properties that define the behavior and display of the Editor component.
 */
const Editor = (props: EditorProps) => {
  const { dispatch, isEditing, col, editValue, setEditValue, tdEl } = props
  const inputEl = useRef<any>()

  // actually applies the edit by calling output to the dispatch function.
  const confirmEdit = useCallback(() => {
    dispatch(setEditCell(null))

    if (editValue !== props.value && props.onChangeCell) {
      props.onChangeCell({
        value: editValue,
        oldValue: props.value,
        colId: props.col.id,
        rowId: props.row.id,
        col,
      })
    }
  }, [dispatch, editValue, props, col])

  // focus the element if the value changes.
  useEffect(() => {
    if (inputEl.current) {
      setEditValue(props.value)
      inputEl.current.focus()
    }
  }, [setEditValue, props.value])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEditValue(e.target.value)
    },
    [setEditValue],
  )

  const handleBlur = useCallback(() => {
    confirmEdit()
  }, [confirmEdit])

  // when the Tab key is pressed, we need to dispatch a custom event to allow the
  // parent component to handle focus management (see Table.tsx).
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Tab') {
        e.preventDefault()
        confirmEdit()

        const colId = props.col.id
        const rowId = props.row.id

        // Let Table handle forward/backward navigation.
        const customEvent = new CustomEvent('tablePressTab', {
          detail: {
            rowId,
            colId,
            shiftKey: e.shiftKey,
          },
        })
        document.dispatchEvent(customEvent)
      }
    },
    [confirmEdit, props.col, props.row],
  )

  // When the Escape key is pressed, we need to cancel the edit and reset the value.
  // When the Enter key is pressed, we need to apply the edit.
  const handleKeyUpSelect = useCallback(
    (e: React.KeyboardEvent<HTMLSelectElement>) => {
      if (e.key === 'Escape') {
        setEditValue(props.value)
        dispatch(setEditCell(null))
      }

      if (e.key === 'Enter') {
        confirmEdit()
      }
    },
    [setEditValue, dispatch, confirmEdit, props.value],
  )

  const handleKeyUpInput = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape') {
        setEditValue(props.value)
        dispatch(setEditCell(null))
      }

      if (e.key === 'Enter') {
        confirmEdit()
      }
    },
    [setEditValue, dispatch, confirmEdit, props.value],
  )

  const handleChangeSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const updateValue = e.target.value

      dispatch(setEditCell(null))

      if (updateValue !== props.value && props.onChangeCell) {
        props.onChangeCell({
          value: updateValue,
          oldValue: props.value,
          colId: props.col.id,
          rowId: props.row.id,
        })
      }
    },
    [dispatch, props],
  )

  let x = 0
  let y = 0
  if (tdEl && tdEl.current) {
    const { top, left } = elOffset(tdEl.current)
    const elHeight = col.isSelect ? 20 : 35
    x = 2 + left
    y = top + (tdEl.current.offsetHeight - elHeight) / 2
  }

  return (
    <Portal id="table-inline-editor">
      {isEditing && col.isSelect && col.selectOptions ? (
        <select
          ref={inputEl}
          onChange={handleChangeSelect}
          value={editValue}
          onBlur={handleBlur}
          onKeyUp={handleKeyUpSelect}
          style={{
            position: 'absolute',
            left: x,
            top: y,
          }}
        >
          {col.selectOptions.map((option: { value: string; label: string }) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </select>
      ) : (
        <Input
          innerRef={inputEl}
          type={col.isDate ? 'date' : col.isNumeric ? 'number' : 'text'}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyUp={handleKeyUpInput}
          onKeyDown={handleKeyDown}
          value={editValue}
          style={{
            width: col.isDate ? 170 : 100,
            position: 'absolute',
            left: x,
            top: y,
          }}
        />
      )}
    </Portal>
  )
}

const CellEditable = (props: CellEditableProps) => {
  const { isEditing, isLoading, col } = props
  const [editValue, setEditValue] = useState(props.value)
  const tdEl = useRef<any>()

  const className = `cell editable ${isLoading ? 'loading' : ''} ${
    props.className
  } `

  const formatValue = useCallback(
    (value: any) => {
      if (col.renderValue) {
        return col.renderValue(value, props)
      }

      if (col.isDate) {
        return formatDateReadable(value)
      }

      if (col.isMoney) {
        return Number(value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }

      return col.isNumeric ? Number(value).toLocaleString() : value
    },
    [col, props],
  )

  return (
    <td
      ref={tdEl}
      className={className}
      data-col-id={col.id}
      style={isEditing ? { position: 'relative' } : null}
    >
      {isEditing ? (
        <Editor
          {...props}
          {...{
            tdEl,
            setEditValue,
            editValue,
          }}
        />
      ) : null}
      <Fragment>
        {isLoading ? (
          <Fragment>
            <span className="editable-value">{formatValue(editValue)}</span>
            <img
              style={{
                width: '12px',
                height: '12px',
              }}
              className="ml-2"
              src={assetPath('img/loading-spinner-grey.gif')}
              alt=""
            />
          </Fragment>
        ) : (
          <span className="editable-value">{formatValue(props.value)}</span>
        )}
      </Fragment>
    </td>
  )
}

export default CellEditable
