import type { StoreCredit } from '@ttc/api/storeCredits'
import { ClipboardButton, GiftCardStatusBadge } from 'components'
import StoreCreditReasonBadge from 'components/Badges/StoreCreditReasonBadge'
import useApi from 'hooks/useApi'
import { useCallback } from 'react'
import { Col, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { askQuestionWithReason, formatDateReadable, formatPrice } from 'utils'

const Alert = withReactContent(Swal)

export const StoreCreditCard = ({
  data,
  onUpdate,
  inOrderContext,
  readOnly,
}: {
  data: StoreCredit
  onUpdate: () => void
  inOrderContext?: boolean
  readOnly: boolean
}) => {
  const handleClickViewEmail = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()

      Alert.fire({
        html: <div style={{ whiteSpace: 'pre-wrap' }}>{data.email_text}</div>,
        icon: 'info',
      })
    },
    [data.email_text],
  )

  const apiCancel = useApi(
    () => ({ action: 'storeCredits_cancelCode' }),
    null,
    () => ({ throws: true }),
  )

  const handleClickCancel = useCallback(async () => {
    await askQuestionWithReason(
      `Are you sure you want to cancel this store credit and the associated gift card?`,
      {
        preConfirm: async (reason: string) => {
          try {
            await apiCancel.performRequest({
              json: { storeCreditId: data.id, reason },
            })

            if (onUpdate) {
              onUpdate()
            }
          } catch (e) {
            Swal.showValidationMessage(e.message)
          }
        },
      },
    )
  }, [onUpdate, apiCancel, data.id])

  return (
    <>
      {data.can_cancel && (
        <Row>
          <Col xs={4}>Status:</Col>
          <Col>
            Active
            {!readOnly && (
              <a className="ml-1" href="#" onClick={handleClickCancel}>
                (Cancel Store Credit)
              </a>
            )}
          </Col>
        </Row>
      )}
      {data.cancelled_at ? (
        <Row className="text-danger font-weight-bold">
          <Col xs={4}>Cancelled:</Col>
          <Col>
            {formatDateReadable(data.cancelled_at, true, true)}
            {data.cancelled_by_name ? <> by {data.cancelled_by_name}</> : null}
          </Col>
        </Row>
      ) : null}
      {data.cancel_reason ? (
        <Row className="text-danger font-weight-bold">
          <Col xs={4}>Cancel reason:</Col>
          <Col>{data.cancel_reason} </Col>
        </Row>
      ) : null}
      {!inOrderContext && data.reason ? (
        <Row>
          <Col xs={4}>Reason:</Col>
          <Col>
            <StoreCreditReasonBadge type={data.reason} />
          </Col>
        </Row>
      ) : null}
      {data.reason_text ? (
        <Row>
          <Col xs={4}>Reason note:</Col>
          <Col>{data.reason_text}</Col>
        </Row>
      ) : null}
      <Row>
        <Col xs={4}>Created:</Col>
        <Col>
          {formatDateReadable(data.created_at, true, true)} by{' '}
          {data.created_by_name}
        </Col>
      </Row>
      {!inOrderContext ? (
        <Row>
          <Col xs={4}>Amount:</Col>
          <Col>${formatPrice(data.amount_cents)}</Col>
        </Row>
      ) : null}
      {!inOrderContext && data.to_email ? (
        <>
          <Row>
            <Col xs={4}>Sent to:</Col>
            <Col>
              <ClipboardButton value={data.to_email} text={data.to_email} />
            </Col>
          </Row>
          {data.email_text ? (
            <Row>
              <Col xs={4}></Col>
              <Col>
                <a href="#" onClick={handleClickViewEmail}>
                  (View email)
                </a>
              </Col>
            </Row>
          ) : null}
        </>
      ) : null}
      {!inOrderContext && data.order_number ? (
        <Row>
          <Col xs={4}>Order Number:</Col>
          <Col>
            <ClipboardButton
              value={data.order_number}
              text={data.order_number}
            />
          </Col>
        </Row>
      ) : null}
      {data.gift_card != null && (
        <>
          <Row>
            <Col xs={4}>Code:</Col>
            <Col>
              <ClipboardButton
                value={data.gift_card.code}
                text={data.gift_card.code}
              />
              <span>&nbsp;</span>
              <a href={`/gift-cards/?selected=${data.gift_card.id}`}>(View)</a>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>Status:</Col>
            <Col>
              <GiftCardStatusBadge status={data.gift_card.status} />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>Balance:</Col>
            <Col>
              ${formatPrice(data.gift_card.balance)} (of $
              {formatPrice(data.gift_card.amount)})
            </Col>
          </Row>
          <Row>
            <Col xs={4}>Normal Shipping:</Col>
            <Col className="text-capitalize">{data.use_normal_shipping}</Col>
          </Row>
          <Row>
            <Col xs={4}>Per Item Shipping:</Col>
            <Col>${formatPrice(data.per_item_shipping * 100)}</Col>
          </Row>
        </>
      )}
      {data.items != null && data.items.length > 0 && (
        <>
          <Row className="mt-3">
            <Col xs={4}>Products:</Col>
          </Row>
          <Row>
            <Col>
              {data.items.map((item) => {
                return (
                  <Row key={item.id}>
                    <Col xs={1}>{item.quantity}x</Col>
                    <Col xs={3}>{item.product_data.sku}</Col>
                    <Col>
                      {item.product_data.name} {item.product_data.sizeShort}
                    </Col>
                  </Row>
                )
              })}
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default StoreCreditCard
