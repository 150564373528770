import { CellEditable } from 'components/Table'
import type { ColumnDef } from 'components/Table/types'
import { formatPrice } from 'utils/price'

type CustomColumnDef = ColumnDef & {
  showInDraftsView?: boolean
}

export const getColumnDef = (
  readOnly: boolean,
  isDraftsView: boolean,
  canEditCost: boolean,
): CustomColumnDef[] => {
  const isEditable = !readOnly

  return [
    {
      label: 'Ordered',
      id: 'qty',
      sortColumn: true,
      isNumeric: true,
      isEditable,
      renderer: isEditable
        ? (props) => {
            return <CellEditable {...props} />
          }
        : null,
    },
    {
      label: 'Arrived',
      id: 'num_arrived',
      sortColumn: true,
      renderer: (props) => {
        const isInSync =
          Number(props.row.num_arrived) === Number(props.row.listed)
        const className = !isInSync ? 'bg-danger-light' : ''

        return <td className={`cell ${className}`}>{props.value}</td>
      },
      showInDraftsView: false,
    },
    {
      label: 'Expected',
      id: 'num_expected',
      sortColumn: true,
    },
    {
      label: 'Product',
      id: 'product',
      sortColumn: true,
    },
    {
      label: 'Size',
      id: 'size',
      sortColumn: true,
      valueColumn: 'product_data.size',
    },
    {
      label: 'SKU',
      id: 'sku',
      sortColumn: true,
      valueColumn: 'product_data.sku',
    },
    {
      label: 'Cost',
      id: 'cost',
      sortColumn: true,
      isMoney: true,
      isEditable: isEditable && canEditCost,
      renderer:
        canEditCost && isEditable
          ? (props) => <CellEditable {...props} />
          : null,
    },
    {
      label: 'Net Cost',
      id: 'net_cost',
      renderer: ({ value }) => (
        <td>{value ? '$' + formatPrice(Number(value) * 100) : ''}</td>
      ),
    },
    {
      label: 'Web',
      id: 'numWeb',
      sortColumn: true,
      showInDraftsView: false,
    },
    {
      label: 'Listed',
      id: 'listed',
      sortColumn: true,
      isNumeric: true,
      renderer: isEditable
        ? (props) => {
            const isInSync =
              Number(props.row.num_arrived) === Number(props.row.listed)
            const className = !isInSync ? 'bg-danger-light' : ''

            return <CellEditable {...{ className }} {...props} />
          }
        : null,
      showInDraftsView: false,
    },
    {
      label: 'Prelisted',
      id: 'prelisted',
      showInDraftsView: false,
      sortColumn: false,
    },
    {
      label: 'Total putaway',
      id: 'zones_total',
      showInDraftsView: false,
      sortColumn: false,
    },
    {
      label: 'Putaway',
      id: 'zones',
      showInDraftsView: false,
      sortColumn: false,
    },
    {
      label: '',
      id: 'actions',
      sortColumn: false,
      canExport: false,
    },
  ].filter((column) => {
    if (isDraftsView) {
      return column.showInDraftsView !== false
    }

    return true
  })
}
