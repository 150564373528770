import type { ColumnPreset, Columns } from 'components/Table/types'
import { useDropDownToggler, usePrevious } from 'hooks'
import { useCallback, useEffect } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

const Item = ({
  preset,
  isSelected,
  onClick,
}: {
  preset: ColumnPreset
  isSelected: boolean
  onClick: (preset: ColumnPreset) => void
}) => {
  const handleClick = useCallback(() => {
    onClick(preset)
  }, [onClick, preset])

  return (
    <DropdownItem
      className={isSelected ? 'font-weight-bold' : ''}
      onClick={handleClick}
    >
      {preset.label}
    </DropdownItem>
  )
}

type ColumnPresetButtonProps = {
  manageColumnsPanel: { toggle: () => void }
  columns: Columns
}

const ColumnPresetButton = ({
  manageColumnsPanel,
  columns,
}: ColumnPresetButtonProps) => {
  const toggler = useDropDownToggler()

  const togglerIsOpenPrev = usePrevious(toggler.isOpen)
  useEffect(() => {
    if (togglerIsOpenPrev != toggler.isOpen && toggler.isOpen) {
      columns.apiLoadPresets.performRequest()
    }
  }, [columns.apiLoadPresets, toggler.isOpen, togglerIsOpenPrev])

  const handleClick = useCallback(
    async (preset: ColumnPreset) => {
      columns.setPreset(preset)
    },
    [columns],
  )

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret className="hasValue">
        <i className="fa fa-columns" /> {columns.selectedPreset?.label}
      </DropdownToggle>
      <DropdownMenu className="pre-scrollable">
        {columns.presets.map((preset) => {
          return (
            <Item
              isSelected={preset.value === columns.selectedPreset?.value}
              key={preset.value}
              onClick={handleClick}
              {...{ preset }}
            />
          )
        })}
        <DropdownItem
          className="font-italic"
          style={{ borderTop: '1px solid #c8ced3' }}
          onClick={columns.reset}
        >
          Reset columns
        </DropdownItem>
        <DropdownItem
          className="font-italic"
          onClick={manageColumnsPanel.toggle}
        >
          Manage columns...
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ColumnPresetButton
