import { ADMIN } from 'caps'
import { ClipboardButton, Loading } from 'components'
import { customerCellRendererInner } from 'components/cellRenderer'
import { useCallback, useContext } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import AuthContext from './../../AuthContext'

type OverviewCardProps = {
  isLoading: boolean
  customer: any
  onClickEdit: () => void
}

const OverviewCard = (props: OverviewCardProps) => {
  const { isLoading, onClickEdit, customer } = props

  const { hasCap } = useContext(AuthContext)

  const handleClickEdit = useCallback(
    (e) => {
      e.preventDefault()
      onClickEdit()
    },
    [onClickEdit],
  )

  const renderInfo = useCallback(() => {
    if (!customer) {
      return null
    }

    const showWpAdminLink = hasCap(ADMIN)

    const customerName = customerCellRendererInner({
      value: customer.fullName,
      row: customer,
    })

    return (
      <div>
        {customerName}
        <br />
        Login Email:{' '}
        {customer.email ? (
          <ClipboardButton value={customer.email} text={customer.email} />
        ) : (
          <>(not set)</>
        )}
        <br />
        ID: <ClipboardButton value={customer.id} text={customer.id} />
        <br />
        Username: {customer.username}
        <br />
        {showWpAdminLink ? (
          <a
            href={`${global.appConfig.wpBaseUrl}/wp-admin/user-edit.php?user_id=${customer.id}`}
            target="_blank"
            rel="noreferrer"
          >
            Open in wp-admin
          </a>
        ) : null}
      </div>
    )
  }, [hasCap, customer])

  return (
    <Card className="h-100">
      <CardHeader>
        <span className="align-bottom">Customer Information</span>
        {onClickEdit ? (
          <div className="card-header-actions">
            <a
              href="#"
              onClick={handleClickEdit}
              className="card-header-action btn btn-setting"
            >
              <i className="fa fa-pencil" />
            </a>
          </div>
        ) : null}
      </CardHeader>
      <CardBody className="d-flex flex-column">
        {!customer && isLoading ? (
          <Loading />
        ) : customer ? (
          <>
            <div className="mb-2" style={{ flex: 1 }}>
              {renderInfo()}
            </div>
          </>
        ) : null}
      </CardBody>
    </Card>
  )
}

export default OverviewCard
