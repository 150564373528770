import { CellProductName } from 'components'
import {
  CellEditable,
  defaultCellRenderer,
  setCellLoading,
  setCellValue,
  setEditCell,
  useTable,
  useTableApi,
} from 'components/Table'
import { useApi } from 'hooks'
import { useCallback, useMemo } from 'react'
import { getStorage } from 'utils/storage'
import columnDef from './columnDef'

import type { TableProps } from 'components/Table/types'

const columnDefMulti = columnDef.filter((col) => !col.isEditable)

const storageKey = 'itemsTable'
const [setItem, getItem] = getStorage(storageKey)

type UseItemsTableProps = {
  readOnly: boolean
  batchIds: string[]
  handleClickProductName: (props: {
    productId: number
    parentProductId: number
    imageUrl: string
    name: string
  }) => void
  loadOrders: () => void
}

type UseItemsTableReturn = {
  triggerSearch: () => void
  batchIds: string[]
} & TableProps

export const useItemsTable = (
  props: UseItemsTableProps,
): UseItemsTableReturn => {
  const { readOnly, batchIds, handleClickProductName, loadOrders } = props

  const [state, dispatch] = useTable({
    cacheKey: `manageBatches_${JSON.stringify(batchIds)}`,
    getItem,
    setItem,
  })
  const [triggerSearch] = useTableApi(
    'batches_getAllItems',
    state,
    dispatch,
    { ids: batchIds },
    { autoReloadInterval: false },
  )

  const renderCell = useCallback(
    (props) => {
      if (!readOnly && props.col.id === 'checked_in_qty') {
        return <CellEditable {...props} />
      }

      if (props.col.id === 'product') {
        const { value, row } = props

        return (
          <CellProductName
            {...{
              ...row.product_data,
              onClick: handleClickProductName,
              value,
            }}
          />
        )
      }

      return defaultCellRenderer(props)
    },
    [readOnly, handleClickProductName],
  )

  const handleClickCell = useCallback(
    (rowId, colId) => {
      if (colId === 'checked_in_qty') {
        dispatch(setEditCell(rowId, colId))
        return true
      }
    },
    [dispatch],
  )

  const setCheckedInQtyApi = useApi(
    () => ({ action: 'batches_setCheckedInQty' }),
    null,
    () => ({
      errorModal: true,
      allowMultiple: true,
    }),
  )

  const handleChangeCell = useCallback(
    async ({ value, oldValue, rowId, colId }) => {
      dispatch(setCellLoading(rowId, colId))
      dispatch(setCellValue(rowId, colId, value))
      const ret = await setCheckedInQtyApi.performRequest({
        json: {
          id: batchIds[0],
          productId: rowId,
          value,
          oldValue,
        },
      })
      if (ret === false) {
        dispatch(setCellValue(rowId, colId, oldValue))
      } else if (ret && Object.hasOwn(ret, 'value')) {
        dispatch(setCellValue(rowId, colId, ret.value))
      }
      dispatch(setCellLoading())
      loadOrders()
    },
    [dispatch, setCheckedInQtyApi, batchIds, loadOrders],
  )

  const isSingleBatch = batchIds.length === 1

  return useMemo(
    () => ({
      ...state,
      setItem,
      getItem,
      columnDef: isSingleBatch ? columnDef : columnDefMulti,
      dispatch,
      triggerSearch,
      batchIds,
      renderCell,
      onClickCell: isSingleBatch ? handleClickCell : null,
      onChangeCell: isSingleBatch ? handleChangeCell : null,
    }),
    [
      state,
      dispatch,
      triggerSearch,
      batchIds,
      renderCell,
      isSingleBatch,
      handleChangeCell,
      handleClickCell,
    ],
  )
}
