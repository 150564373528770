import type { Return } from '@ttc/api/returns'
import { EditPanelCard, SelectProducts } from 'components'
import CardEditButton from 'containers/common/CardEditButton'
import { useApi, useBoolean, useStateful } from 'hooks'
import { useCallback, useMemo, useReducer } from 'react'
import { Button, Col, FormGroup, Row } from 'reactstrap'
import ItemLineItem from './ItemLineItem'

const headerRowStyle = { borderBottom: '1px solid #c5dbea' }

const AddProductsForm = ({ addProducts }) => {
  const handleChange = useCallback(
    (options) => {
      addProducts.set(options || [])
    },
    [addProducts],
  )

  return (
    <Row style={headerRowStyle} className="p-3">
      <Col>
        <FormGroup className="mb-0">
          <SelectProducts value={addProducts.value} onChange={handleChange} />
        </FormGroup>
      </Col>
    </Row>
  )
}

type State = Record<string, any>

type Action =
  | { type: 'INIT'; state: State }
  | { type: 'RESET' }
  | { type: 'INC'; field: string; productId: number }
  | { type: 'DEC'; field: string; productId: number }

const useQtyEditor = () => {
  const [state, dispatch] = useReducer<React.Reducer<State, Action>>(
    (state, action) => {
      switch (action.type) {
        case 'INIT': {
          return { ...action.state }
        }
        case 'RESET': {
          return {}
        }
        case 'INC': {
          const { field } = action
          const qty = Object.hasOwn(state, action.productId)
            ? Number(state[action.productId][field] || 0)
            : 0

          return {
            ...state,
            [action.productId]: {
              ...state[action.productId],
              [field]: qty + 1,
            },
          }
        }
        case 'DEC': {
          const { field } = action
          const qty = Object.hasOwn(state, action.productId)
            ? Number(state[action.productId][field] || 0)
            : 0

          if (qty <= 0) {
            return state
          }

          return {
            ...state,
            [action.productId]: {
              ...state[action.productId],
              [field]: qty - 1,
            },
          }
        }
        default:
          return state
      }
    },
    () => ({}),
  )

  const getProductQty = useCallback(
    (field, productId) => {
      return Object.hasOwn(state, productId) ? state[productId][field] : 0
    },
    [state],
  )

  const init = useCallback((state) => {
    return dispatch({ type: 'INIT', state })
  }, [])

  const reset = useCallback(() => {
    return dispatch({ type: 'RESET' })
  }, [])

  const inc = useCallback((field, productId) => {
    return dispatch({ type: 'INC', productId, field })
  }, [])

  const dec = useCallback((field, productId) => {
    return dispatch({ type: 'DEC', productId, field })
  }, [])

  return useMemo(
    () => ({ getProductQty, init, reset, inc, dec, state }),
    [getProductQty, init, reset, inc, dec, state],
  )
}

const ItemsCard = ({ getReturn, readOnly }) => {
  const result: Return = getReturn.result
  const { id } = result
  const editMode = useBoolean()
  const addProductMode = useBoolean()
  const qtyEditor = useQtyEditor()

  const addProducts = useStateful([])

  const apiAddAll = useApi({ action: 'returns_addAllItems', id }, null, () => ({
    errorModal: true,
  }))

  const apiSetQuantities = useApi(
    { action: 'returns_setProductQuantities' },
    null,
    () => ({ errorModal: true }),
  )

  const isLoading = apiAddAll.isLoading || apiSetQuantities.isLoading

  const handleClickSave = useCallback(() => {
    const quantities = { ...(qtyEditor.state || []) }

    if (addProductMode.value) {
      for (const a of addProducts.value) {
        if (a.value && !Object.hasOwn(quantities, a.value)) {
          quantities[a.value] = { expected_qty: 1, received_qty: 0 }
        }
      }
    }

    apiSetQuantities
      .performRequest({
        json: {
          id: result.id,
          quantities,
        },
      })
      .then(() => {
        getReturn.performRequest()
        editMode.off()
        addProductMode.off()
        addProducts.set([])
      })
  }, [
    qtyEditor,
    apiSetQuantities,
    result.id,
    getReturn,
    editMode,
    addProductMode,
    addProducts,
  ])

  const handleClickCancelEdit = useCallback(() => {
    editMode.off()
    addProductMode.set(false)
    qtyEditor.reset()
  }, [editMode, qtyEditor, addProductMode])

  const handleClickAddProduct = useCallback(() => {
    addProductMode.toggle()
    addProducts.set([])
  }, [addProductMode, addProducts])

  const handleClickAddAll = useCallback(() => {
    apiAddAll.performRequest().then(() => {
      getReturn.performRequest()
    })
  }, [getReturn, apiAddAll])

  const handleClickEdit = useCallback(() => {
    editMode.set(!editMode.value)

    const state = {}
    for (const item of result.items) {
      state[item.product_id] = item
    }
    qtyEditor.init(state)
  }, [editMode, qtyEditor, result.items])

  const headerActions = (
    <CardEditButton isEditing={editMode.value} onClick={handleClickEdit} />
  )

  const showAddAllItemsButton =
    !editMode.value &&
    result.num_expected_items == 0 &&
    result.num_received_items == 0 &&
    result.num_original_items > 0

  const showSaveCancel = editMode.value

  return (
    <EditPanelCard
      caption="Items"
      stateId="returns_items"
      bodyProps={{ className: 'p-0' }}
      headerActions={!readOnly ? headerActions : null}
    >
      {showSaveCancel ? (
        <>
          <Row style={headerRowStyle}>
            <Col>
              <Button
                disabled={isLoading}
                size="sm"
                className="m-2"
                onClick={handleClickAddProduct}
              >
                {addProductMode.value
                  ? 'Cancel Add Products'
                  : 'Add Products...'}
              </Button>
            </Col>
            <Col className="text-right">
              <Button
                disabled={isLoading}
                size="sm"
                className="m-2"
                onClick={handleClickSave}
              >
                Save
              </Button>
              <Button
                disabled={isLoading}
                size="sm"
                className="m-2"
                onClick={handleClickCancelEdit}
              >
                Cancel
              </Button>
            </Col>
          </Row>
          {addProductMode.value ? (
            <AddProductsForm {...{ addProducts }} />
          ) : null}
        </>
      ) : null}
      {showAddAllItemsButton ? (
        <Row style={headerRowStyle}>
          <Col>
            <Button
              color="link"
              onClick={handleClickAddAll}
              disabled={isLoading}
            >
              Add all items (Full Order Returned)
            </Button>
          </Col>
        </Row>
      ) : null}
      {result.items.map((item, i: number) => (
        <ItemLineItem
          key={`${item.id}.${item.product_id}}`}
          item={item}
          isLast={i === result.items.length - 1}
          isEditMode={editMode.value}
          expectedQty={
            editMode.value
              ? qtyEditor.getProductQty('expected_qty', item.product_id)
              : item.expected_qty || 0
          }
          receivedQty={
            editMode.value
              ? qtyEditor.getProductQty('received_qty', item.product_id)
              : item.received_qty || 0
          }
          onClickIncQty={qtyEditor.inc}
          onClickDecQty={qtyEditor.dec}
        />
      ))}
      {!showSaveCancel && result.items.length === 0 ? (
        <Row className="p-3">
          <Col>
            <i>No items added.</i>
          </Col>
        </Row>
      ) : null}
    </EditPanelCard>
  )
}

export default ItemsCard
