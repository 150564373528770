import type {
  GetProductApiParams,
  Product,
  ProductTaxonomyOptions,
} from '@ttc/api/products'
import AuthContext from 'AuthContext'
import { PURCHASEORDERS_READ } from 'caps'
import { EditPanel, Loading, TabBar, useTabBar } from 'components'
import type { TabConfig } from 'components/TabBar/types'
import { useApi, useForm, usePrevious } from 'hooks'
import { Fragment, useCallback, useContext, useEffect, useMemo } from 'react'
import { Col, Row } from 'reactstrap'
import InventoryLogCard from '../InventoryLogCard'
import NotesPanelCard from '../NotesPanelCard'
import CardEditor from './CardEditor'
import DeliveriesCard from './DeliveriesCard'
import type {
  EditProductPanelProps,
  ProductFormState,
  Tab,
  VariationFormState,
} from './types'
import { TAB_DELIVERIES, TAB_LOG, TAB_PRODUCT } from './types'

const initialState: ProductFormState = {
  type: 'variable',
  variationOptions: [],
  variation: '',
  enabled: false,
  isBlocked: false,
  parentSku: '',
  name: '',
  botanicalName: '',
  sku: '',
  variationDescription: '',
  regularPrice: '',
  salePrice: '',
  preorderDate: '',
  plantType: [],
  plantTypeOptions: [],
  sizesOptions: [],
  variationId: '0',
  newSize: null,
}

const getVariationFormState = (
  variation: Product | null,
): VariationFormState => {
  const {
    sku,
    regularPrice,
    salePrice,
    id,
    status,
    preorderDate,
    isBlocked,
    variationDescription,
  } = variation || {}

  return {
    enabled: status === 'publish',
    isBlocked: Boolean(isBlocked),
    sku: sku || '',
    salePrice: salePrice || '',
    regularPrice: regularPrice || '',
    preorderDate: preorderDate || '',
    variationId: String(id || '0'),
    variationDescription: variationDescription || '',
  }
}

const EditProductPanel = (props: EditProductPanelProps) => {
  const {
    name,
    isOpen,
    onClose,
    productId,
    parentProductId,
    onProductUpdated,
    readOnly,
    addType,
  } = props

  const { hasCap } = useContext(AuthContext)

  const hasDeliveriesTab = hasCap(PURCHASEORDERS_READ)

  const tabsConfig: TabConfig<Tab>[] = useMemo(() => {
    const ret: Array<TabConfig<Tab> | null> = [
      { type: TAB_PRODUCT, title: 'Product', icon: 'leaf' },
      hasDeliveriesTab
        ? {
            type: TAB_DELIVERIES,
            title: 'Purchase Orders',
            icon: 'fa fa-truck',
          }
        : null,
      { type: TAB_LOG, title: 'Log', icon: 'notes' },
    ]

    return ret.filter((tab) => tab != null)
  }, [hasDeliveriesTab])

  const tabBarState = useTabBar<Tab>(
    TAB_PRODUCT,
    tabsConfig,
    'editProductPanel.selectedTab',
  )

  const form = useForm<ProductFormState>(initialState)

  const taxonomyOptions = useApi<ProductTaxonomyOptions>(
    () => ({ action: 'products_getTaxonomyOptions' }),
    null,
    { errorModal: true, cache: true, autoPerform: true },
  )

  const loadData = useApi<Product, GetProductApiParams>(
    useMemo(
      () => ({
        action: 'products_getProduct',
        json: { id: Number(parentProductId) },
      }),
      [parentProductId],
    ),
    null,
    { errorModal: true, cache: true },
  )

  const updateProduct = useApi(null, null, () => ({
    errorModal: true,
    throws: true,
  }))

  const isOpenPrev = usePrevious(isOpen)
  useEffect(() => {
    if (isOpen != isOpenPrev) {
      if (isOpen && parentProductId != null) {
        loadData.performRequest()
      } else {
        loadData.reset()
      }
    }
  }, [isOpen, isOpenPrev, loadData, parentProductId])

  /* Initialize form after data has been fetched from server. */
  const prevResult = usePrevious(loadData.result)
  const prevAddType = usePrevious(addType)
  useEffect(() => {
    if (loadData.result == prevResult && prevAddType === addType) {
      return
    }

    const data: Product | null = loadData.result

    const { name, type, plantTypeOptions, sizesOptions, botanicalName } =
      data || {}

    let plantType = data?.plantType

    // Pre-select plant-type=Other for new simple products.
    if (data == null) {
      plantType =
        addType === 'simple' ? [{ label: 'Other', value: 'other' }] : []
    }

    const variationOptions = (data?.variations || []).map((product) => {
      let label = product.size
      label = product.variationDescription
        ? `${label} - ${product.variationDescription}`
        : label
      return { product, label, value: String(product.id) }
    })

    const variationOption =
      variationOptions.find(
        (option) => String(option.product.id) === String(productId),
      ) || null

    let _variationOptions = variationOptions
    if (!readOnly) {
      _variationOptions = [
        ...variationOptions,
        { product: null, label: 'Add new variation...', value: '0' },
      ]
    }

    const existingVariationSizes = variationOptions.map(
      (variation) => variation.product.size,
    )

    const _sizesOptions = (sizesOptions || []).filter(
      (sizeOption) =>
        sizeOption.value !== 'bundle' &&
        !existingVariationSizes.includes(sizeOption.label),
    )

    form.setState({
      type: type || addType,
      name,
      parentSku: data?.sku || '',
      botanicalName,
      variationOptions: _variationOptions,
      plantType,
      plantTypeOptions,
      sizesOptions: _sizesOptions,
      ...getVariationFormState(
        type === 'variable' ? variationOption?.product : data,
      ),
    })
  }, [
    readOnly,
    loadData.result,
    prevAddType,
    prevResult,
    form,
    productId,
    addType,
  ])

  /* Update form when user changes variation. */
  const variationIdPrev = usePrevious(form.state.variationId)
  useEffect(() => {
    if (form.state.variationId != variationIdPrev && isOpen) {
      const variationOption =
        form.state.variationOptions.find(
          (option) =>
            String(option.product?.id || '0') ===
            String(form.state.variationId),
        ) || null

      form.setState({
        ...form.state,
        ...getVariationFormState(
          form.state.type === 'variable'
            ? variationOption?.product
            : loadData.result,
        ),
      })
    }
  }, [form.state.variationId, variationIdPrev, form, isOpen, loadData.result])

  const selectedProductId = Number(form.state.variationId)

  const handleSubmit = useCallback(async () => {
    let updatedProduct = null

    try {
      let action = null

      const isNewParentProduct = !parentProductId
      const isNewVariation = form.state.variationId === '0'
      const productType = form.state.type

      if (isNewVariation) {
        if (isNewParentProduct) {
          // Add new variable product with new variation.
          if (productType === 'variable') {
            action = 'products_addVariableProduct'
          }
          // Add new simple product.
          else if (productType === 'simple') {
            action = 'products_addSimpleProduct'
          }
        }
        // Add product variation to existing variable product.
        else if (!isNewParentProduct) {
          action = 'products_addProductVariation'
        }
      } else {
        // Update existing variation/simple product.
        action = 'products_updateProduct'
      }

      if (action == null) {
        throw new Error('Not implemented')
      }

      const ret = await updateProduct.performRequest({
        action,
        json: {
          id: Number(form.state.variationId),
          parentProductId,
          ...form.state,
        },
      })

      if (ret?.product) {
        updatedProduct = ret.product
      }
    } catch (_e) {
      return
    }

    loadData.invalidateCache()

    await onProductUpdated(updatedProduct)
    onClose()
  }, [
    onClose,
    form.state,
    updateProduct,
    onProductUpdated,
    loadData,
    parentProductId,
  ])

  const createNewTitle =
    addType === 'simple'
      ? 'Create new simple product'
      : 'Create new variable product'

  const selectedSize = useMemo(() => {
    const selectedVariation =
      form.state.variationOptions.find(
        (option) => String(selectedProductId) === String(option?.product?.id),
      ) || null

    return selectedVariation?.product?.size
  }, [form.state.variationOptions, selectedProductId])

  const nameAndSize = `${loadData.result?.name}${
    selectedSize ? ` - ${selectedSize}` : ''
  }`

  const isLoadingProduct = !loadData.hasResult && loadData.isLoading
  const caption = parentProductId
    ? isLoadingProduct
      ? name
      : nameAndSize
    : createNewTitle
  const botanicalName = isLoadingProduct ? null : loadData.result?.botanicalName
  const isLoading = isLoadingProduct || updateProduct.isLoading
  const error = loadData.error
  const href = parentProductId
    ? `${global.appConfig.wpBaseUrl}/wp-admin/post.php?action=edit&post=${parentProductId}`
    : ''

  const isNewVariation = form.state.variationId === '0'

  let canSubmit = true

  if (
    isNewVariation &&
    form.state.type === 'variable' &&
    form.state.newSize == null
  ) {
    canSubmit = false
  }

  if (!taxonomyOptions.hasResult) {
    return null
  }

  const selectedTab = parentProductId ? tabBarState.selectedTab : TAB_PRODUCT

  return (
    <EditPanel
      noHeader
      onSubmit={
        selectedTab !== TAB_PRODUCT || isLoading || readOnly
          ? null
          : handleSubmit
      }
      {...{ canSubmit, error, isOpen, onClose, caption, isLoading }}
    >
      <Row className="border-bottom">
        <Col className="d-flex justify-content-between align-items-center m-3 pl-1 pr-1">
          <span style={{ fontSize: '1rem' }}>
            {href ? (
              <a target="noopener" href={href}>
                {caption}
              </a>
            ) : (
              caption
            )}
            <div style={{ fontSize: 12 }}>{botanicalName}</div>
          </span>
        </Col>
      </Row>
      {parentProductId ? (
        <TabBar className="border-bottom" {...tabBarState} />
      ) : null}
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment key={`${parentProductId}-${addType}`}>
          {selectedProductId && isOpen && selectedTab === TAB_LOG ? (
            <>
              <NotesPanelCard
                isLoading={loadData.isLoading}
                apiAddAction="inventory_addNote"
                apiRemoveAction="inventory_removeNote"
                id={loadData.result.id}
                notes={loadData.result.notes}
                onSaved={loadData.performRequest}
                {...{ readOnly }}
              />
              <InventoryLogCard
                apiName="inventory_getLog"
                id={selectedProductId}
              />
            </>
          ) : selectedProductId && isOpen && selectedTab === TAB_DELIVERIES ? (
            <DeliveriesCard productId={selectedProductId} />
          ) : (
            <CardEditor
              {...{
                parentProductId,
                form,
                readOnly,
                taxonomyOptions: taxonomyOptions.result,
              }}
            />
          )}
        </Fragment>
      )}
    </EditPanel>
  )
}

export default EditProductPanel
