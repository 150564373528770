import { DropDownFilterButton } from 'components/index'
import type { FiltersProps } from './types'

const Filters = ({ filters }: FiltersProps) => {
  return (
    <>
      <DropDownFilterButton
        label="Reason"
        values={[
          'VALID_WARRANTY',
          'VALID_ARRIVE_ALIVE',
          'COURTESY_INVALID_WARRANTY',
          'ORDER_ERROR',
          'COURTESY_OTHER',
          'LEGACY_WARRANTY_CLAIM',
          'LEGACY_OTHER',
        ]}
        filter={filters.reason}
      />
    </>
  )
}

export default Filters
