import type { Location, LocationsGetAllResponse } from '@ttc/api/locations'
import { EditPanel, EditPanelCard, Loading, SelectField } from 'components'
import { type ApiCall, useApi, usePrevious } from 'hooks'
import { Fragment, useCallback, useEffect, useMemo, useReducer } from 'react'
import { Button, Col, CustomInput, FormGroup, Label, Row } from 'reactstrap'
import { askQuestion } from 'utils'

const CardActions = ({ handleClickDelete }) => {
  return (
    <EditPanelCard caption="Actions" defaultIsOpen={false}>
      <Row>
        <Col>
          <FormGroup className="form-actions mt-3 text-center">
            <Button
              name="delete"
              size="lg"
              type="button"
              color="danger"
              onClick={handleClickDelete}
            >
              Delete Zone
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </EditPanelCard>
  )
}

type ZoneEditorProps = {
  readOnly: boolean
  dispatch: (x: any) => void
  state: any
  apiLoadLocations: ApiCall<LocationsGetAllResponse>
}

const ZoneEditor = (props: ZoneEditorProps) => {
  const { readOnly, dispatch, state, apiLoadLocations } = props

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { type, name, value, checked } = e.currentTarget

      dispatch({
        type: 'SET_VALUE',
        name,
        value: type === 'checkbox' ? checked : value,
      })
    },
    [dispatch],
  )

  const handleChangeSelect = useCallback(
    (name: string, value: any) => {
      dispatch({
        type: 'SET_VALUE',
        name,
        value: value?.value,
      })
    },
    [dispatch],
  )

  const locationOptions = useMemo(() => {
    return (apiLoadLocations.result?.rows || []).map((location: Location) => {
      return { value: String(location.id), label: location.name }
    })
  }, [apiLoadLocations.result])

  return (
    <EditPanelCard caption="Zone" stateId="zone">
      <FormGroup>
        <Label className="mb-0" htmlFor="input-name">
          Name
        </Label>
        <input
          className="form-control"
          id="input-name"
          type="text"
          name="name"
          maxLength={2}
          value={state.name}
          onChange={handleChange}
          disabled={readOnly}
        />
      </FormGroup>
      <FormGroup>
        <Label className="mb-0" htmlFor="input-description">
          Description
        </Label>
        <input
          className="form-control"
          id="input-description"
          type="text"
          name="description"
          value={state.description}
          onChange={handleChange}
          disabled={readOnly}
        />
      </FormGroup>
      <FormGroup>
        <Label className="mb-0" htmlFor="input-location">
          Location
        </Label>
        <SelectField
          options={locationOptions}
          value={state.location_id}
          name="location_id"
          isClearable
          isDisabled={readOnly}
          onChange={handleChangeSelect}
        />
      </FormGroup>
      <FormGroup>
        <Label className="mb-0" htmlFor="input-column-label">
          Column Label
        </Label>
        <input
          className="form-control"
          id="input-column-label"
          type="text"
          name="column_label"
          value={state.column_label}
          onChange={handleChange}
          disabled={readOnly}
        />
      </FormGroup>
      <FormGroup>
        <Label className="mb-0" htmlFor="input-color">
          Color
        </Label>
        <input
          className="form-control"
          id="input-color"
          type="color"
          name="color"
          value={state.color}
          onChange={handleChange}
          disabled={readOnly}
        />
      </FormGroup>
      <FormGroup>
        <Label className="mb-0" htmlFor="input-priority">
          Priority
        </Label>
        <input
          className="form-control"
          id="input-priority"
          type="number"
          name="priority"
          value={state.priority}
          onChange={handleChange}
          disabled={readOnly}
        />
      </FormGroup>
      <FormGroup>
        <CustomInput
          name="is_batchable"
          className="checkbox"
          bsSize="lg"
          id="input-batchable"
          checked={Boolean(state.is_batchable)}
          onChange={handleChange}
          type="checkbox"
          label="Batchable"
          disabled={readOnly}
        />
      </FormGroup>
      <FormGroup>
        <CustomInput
          name="is_drop_zone"
          className="checkbox"
          bsSize="lg"
          id="input-drop-zone"
          checked={Boolean(state.is_drop_zone)}
          onChange={handleChange}
          type="checkbox"
          label="Drop Zone"
          disabled={readOnly}
        />
      </FormGroup>
      <FormGroup>
        <CustomInput
          name="is_box_zone"
          className="checkbox"
          bsSize="lg"
          id="input-box-zone"
          checked={Boolean(state.is_box_zone)}
          onChange={handleChange}
          type="checkbox"
          label="Box Zone"
          disabled={readOnly}
        />
      </FormGroup>
      <FormGroup>
        <CustomInput
          name="is_addon_zone"
          className="checkbox"
          bsSize="lg"
          id="input-addon-zone"
          checked={Boolean(state.is_addon_zone)}
          onChange={handleChange}
          type="checkbox"
          label="Addon Zone"
          disabled={readOnly}
        />
      </FormGroup>
      <div className="clearfix" />
    </EditPanelCard>
  )
}

type State = {
  name: string
  description: string
  column_label: string
  is_batchable: boolean
  is_drop_zone: boolean
  is_box_zone: boolean
  is_addon_zone: boolean
  priority: number
  color: string
  location_id: number
}

const initialState: State = {
  name: '',
  description: '',
  column_label: '',
  is_batchable: true,
  is_drop_zone: false,
  is_box_zone: false,
  is_addon_zone: false,
  priority: 0,
  color: '#ff0000',
  location_id: 0,
}

type Action =
  | { type: 'SET_VALUE'; name: string; value: string }
  | { type: 'SET_VALUES'; values: any }

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_VALUE': {
      return { ...state, [action.name]: action.value }
    }
    case 'SET_VALUES': {
      return { ...initialState, ...action.values }
    }
    default:
      return state
  }
}

type EditZonePanelProps = {
  readOnly: boolean
  isOpen: boolean
  onClose: () => void
  editId: string
  rowData?: any
  apiLoadLocations: ApiCall<LocationsGetAllResponse>
}

const EditZonePanel = (props: EditZonePanelProps) => {
  const { readOnly, isOpen, onClose, editId, rowData, apiLoadLocations } = props

  const caption = `${editId == null ? 'Add' : 'Edit'} Zone`

  const [state, dispatch] = useReducer<React.Reducer<State, Action>>(
    reducer,
    initialState,
  )

  const apiLoad = useApi(null, null, { errorModal: true })
  const apiWrite = useApi(null, null, { errorModal: true })

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    const fetchData = async () => {
      const values = await apiLoad.performRequest({
        action: 'zones_getRow',
        json: { id: editId },
      })
      dispatch({ type: 'SET_VALUES', values })
    }

    if (isOpen && isOpen !== prevIsOpen) {
      if (editId == null) {
        dispatch({ type: 'SET_VALUES', values: initialState })
      } else {
        fetchData()
      }
    }
  }, [apiLoad, prevIsOpen, isOpen, editId, rowData])

  const handleSubmit = useCallback(async () => {
    await apiWrite.performRequest({
      action: 'zones_setRow',
      json: {
        id: editId,
        ...state,
      },
    })

    onClose()
  }, [onClose, state, apiWrite, editId])

  const canSubmit = state.name !== '' && state.location_id != null

  const isLoading = apiLoad.isLoading || apiWrite.isLoading

  const handleClickDelete = useCallback(async () => {
    if (!(await askQuestion('Are you sure you want to delete this zone?'))) {
      return
    }

    await apiWrite.performRequest({
      action: 'zones_trashRow',
      json: { id: editId },
    })

    onClose()
  }, [apiWrite, onClose, editId])

  return (
    <EditPanel
      onSubmit={readOnly || isLoading ? null : handleSubmit}
      {...{ isOpen, onClose, caption, isLoading, canSubmit }}
    >
      {apiLoad.isLoading ? (
        <Loading />
      ) : (
        <Fragment key={JSON.stringify({ isOpen, editId })}>
          {!readOnly && editId != null ? (
            <CardActions {...{ handleClickDelete }} />
          ) : null}
          <ZoneEditor {...{ readOnly, dispatch, state, apiLoadLocations }} />
          {apiWrite.isLoading ? <Loading /> : null}
        </Fragment>
      )}
    </EditPanel>
  )
}

export default EditZonePanel
