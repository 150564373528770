import { Badge } from 'reactstrap'
import { formatDate } from 'utils/date'
import type { CartItemType } from '../useCart/types'

type ProductInfoProps = {
  showStock?: boolean
  shippingState?: string
} & CartItemType

const ProductInfo = (props: ProductInfoProps) => {
  const {
    showStock,
    name,
    sku,
    size,
    isAlwaysStock,
    numWeb,
    allowWarranty,
    isOversize,
    preorderDate,
    permalink,
    orderNumber,
    splitOrderStatus,
    hasWarranty,
    shippingState,
    excludedStates,
    currentPrice,
  } = props

  const isValidState = !(
    shippingState != null &&
    excludedStates != null &&
    Array.isArray(excludedStates) &&
    excludedStates.includes(shippingState)
  )

  return (
    <>
      <div>
        <a style={{ fontWeight: 'bold' }} target="noopener" href={permalink}>
          {name}
        </a>
        <small className="ml-2">({sku})</small>
      </div>
      <div>
        {size} {isOversize ? ' (oversize)' : ''}
      </div>
      {!isAlwaysStock && numWeb <= 0 ? (
        <div className="text-danger">OUT OF STOCK</div>
      ) : showStock && !isAlwaysStock ? (
        <div>{numWeb} in stock</div>
      ) : null}
      {currentPrice ? <div>Current price: ${currentPrice}</div> : null}
      {!allowWarranty ? (
        <div className="text-danger">Not eligible for warranty</div>
      ) : null}
      {preorderDate ? (
        <div className="text-danger">Ships: {formatDate(preorderDate)}</div>
      ) : null}
      {hasWarranty ? <div>✅ Warranty</div> : null}
      {orderNumber ? (
        <div>
          <i>
            Split{' '}
            <b>
              {orderNumber}
              {splitOrderStatus ? (
                <Badge className="ml-1" color="warning">
                  {String(splitOrderStatus).toUpperCase()}
                </Badge>
              ) : null}
            </b>
          </i>
        </div>
      ) : null}
      {!isValidState ? (
        <div className="alert alert-danger" role="alert">
          Warning: Does not ship to {shippingState}
        </div>
      ) : null}
    </>
  )
}

export default ProductInfo
