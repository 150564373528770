import { useEffect, useRef } from 'react'

const usePrevious = <T>(value: T, defaultValue: T | null = null): T | null => {
  const ref = useRef<T | null>(defaultValue)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export default usePrevious
