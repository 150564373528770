import type { GetOrderApiResult } from '@ttc/api/orders'
import AuthContext from 'AuthContext'
import { MANAGESTORECREDIT_READ, MANAGESTORECREDIT_READWRITE } from 'caps'
import { EditPanelCard, LinkButton } from 'components'
import StoreCreditReasonBadge from 'components/Badges/StoreCreditReasonBadge'
import StoreCreditCard from 'components/Cards/StoreCreditCard'
import type { ApiCall } from 'hooks'
import type { PanelControlResult } from 'hooks/usePanelControl'
import { useCallback, useContext } from 'react'
import { Col, Row } from 'reactstrap'
import { formatPrice } from 'utils/price'

const StoreCreditsCard = (props: {
  canEditOrder: boolean
  getOrder: ApiCall<GetOrderApiResult>
  panel: PanelControlResult
  readOnly: boolean
}) => {
  const { hasCap } = useContext(AuthContext)

  const { getOrder, panel, readOnly } = props

  const onUpdate = useCallback(() => {
    getOrder.performRequest()
  }, [getOrder])

  const handleClickIssueNew = useCallback(() => {
    panel.open()
  }, [panel])

  if (!getOrder.hasResult) {
    return null
  }

  const { result } = getOrder
  const { storeCredits, isSplit } = result

  const canWrite = !readOnly && hasCap(MANAGESTORECREDIT_READWRITE)
  const canRead = hasCap(MANAGESTORECREDIT_READ)
  const canIssueNewStoreCredit = canWrite

  return (
    <EditPanelCard
      caption="Issued Store Credits"
      stateId="orders_storecredits"
      bodyProps={{ className: 'p-0' }}
    >
      {isSplit ? (
        <Row className="p-3">
          <Col>
            This is a split order. Go to the original order to issue a new store
            credit.
          </Col>
        </Row>
      ) : (
        <>
          <Row
            className="pt-3 pl-3 pr-3"
            style={{ display: canIssueNewStoreCredit ? 'block' : 'none' }}
          >
            <Col>
              <LinkButton onClick={handleClickIssueNew}>
                Issue new store credit...
              </LinkButton>
            </Col>
          </Row>
        </>
      )}
      <Row className="p-3">
        <Col>
          {!canRead && (
            <i>
              Permission denied: You do not have permission to view store
              credits.
            </i>
          )}
          {canRead &&
            (storeCredits.length === 0 ? (
              <>No store credit issued yet.</>
            ) : (
              <div
                style={{
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#c8ced3',
                }}
              >
                {storeCredits.map((data) => {
                  const isCancelled = data.cancelled_at !== null

                  const caption = (
                    <>
                      <span className={isCancelled ? 'text-danger' : null}>
                        {isCancelled ? 'Cancelled: ' : null}
                      </span>
                      <span
                        className={
                          isCancelled ? 'text-decoration-line-through' : null
                        }
                      >
                        ${formatPrice(data.amount_cents)}
                      </span>
                      <StoreCreditReasonBadge
                        className="ml-1"
                        type={data.reason}
                      />
                    </>
                  )

                  return (
                    <EditPanelCard key={data.id} {...{ caption }}>
                      <Row>
                        <Col>
                          <StoreCreditCard
                            inOrderContext
                            data={data}
                            onUpdate={onUpdate}
                            readOnly={!canWrite}
                          />
                        </Col>
                      </Row>
                    </EditPanelCard>
                  )
                })}
              </div>
            ))}
        </Col>
      </Row>
    </EditPanelCard>
  )
}

export default StoreCreditsCard
