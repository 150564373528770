import type { TableHeaderRenderProps } from './TableHeader'

export const defaultHeaderLabelRenderer = (props: TableHeaderRenderProps) => {
  const { col, allRowsSelected, handleClickToggleAll } = props

  if (col.id === 'checkbox') {
    return (
      <input
        onChange={handleClickToggleAll}
        style={{ cursor: 'pointer' }}
        readOnly
        type="checkbox"
        checked={allRowsSelected}
      />
    )
  }

  return col.label
}
