import { useDropDownToggler } from 'hooks'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

type AddActionButtonProps = {
  onAddSingle: () => void
  onAddBatch?: () => void
}

const AddActionButton = (props: AddActionButtonProps) => {
  const { onAddSingle, onAddBatch } = props

  const toggler = useDropDownToggler()

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret>
        Add
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={onAddSingle}>Add Single Gift Card</DropdownItem>
        <DropdownItem onClick={onAddBatch}>
          Add Physical Gift Cards Batch
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default AddActionButton
