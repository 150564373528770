import type { Product } from '@ttc/api/products'
import type { PurchaseOrder } from '@ttc/api/purchaseOrders'
import type { ZonesGetAllResponse } from '@ttc/api/zones'
import { apiFetch, openPrintWindow } from 'api'
import { LoadingImage } from 'components'
import EditProductPanel from 'containers/common/EditProductPanel'
import type { SelectedProductProps } from 'containers/common/EditProductPanel/types'
import { SelectSupplierPanel } from 'containers/common/SelectSupplierPanel'
import {
  useApi,
  useDropZone,
  usePageTitle,
  usePanelControl,
  useStateful,
} from 'hooks'
import debounce from 'lodash/debounce'
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Link,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
} from 'reactstrap'
import { askQuestion, showError, uiApiRequest } from 'utils'
import AddProductsPanel from './AddProductsPanel'
import DeliveriesTable from './DeliveriesTable'
import DeliveryDetailsCard from './DeliveryDetailsCard'
import EditProductsPanel from './EditProductsPanel'
import InvoicesCard from './InvoicesCard'
import LogPanel from './LogPanel'
import OrderDetailsCard from './OrderDetailsCard'
import OrderDetailsEditor from './OrderDetailsEditor'
import OverviewCard from './OverviewCard'
import OverviewTable from './OverviewTable'
import PlacePanel from './PlacePanel'
import RefNumberEditor from './RefNumberEditor'

type TabProps = {
  onClick: (name: string) => void
  name: string
  active: boolean
  children: ReactNode
}

const Tab = (props: TabProps) => {
  const { onClick, name, children, active } = props

  const handleClickTab = useCallback(() => {
    if (onClick) {
      onClick(name)
    }
  }, [name, onClick])

  return (
    <NavItem>
      <NavLink active={active} onClick={handleClickTab}>
        {children}
      </NavLink>
    </NavItem>
  )
}

const EditPurchaseOrder = (props: {
  readOnly: boolean
  isDraftsView?: boolean
}) => {
  const navigate = useNavigate()

  const { id } = useParams()
  const { readOnly, isDraftsView } = props

  const location = useLocation()

  const fromDeliveries = useRef(location.state?.fromDeliveries)

  const addProductsPanel = usePanelControl()
  const supplierPanel = usePanelControl()
  const orderDetailsEditor = usePanelControl()
  const refNumberEditor = usePanelControl()
  const editProductPanel = usePanelControl()
  const editProductsPanel = usePanelControl()
  const logPanel = usePanelControl()
  const placePanel = usePanelControl()

  const isDeliveriesTab =
    !!useMatch('/purchase-orders/edit/:id/deliveries/*') && !isDraftsView

  const pageTitle = useMemo(
    () => `#${id} - Edit ${isDraftsView ? 'Draft ' : ''}Purchase Order`,
    [isDraftsView, id],
  )
  usePageTitle(pageTitle)

  const breadcrumbTitle = useMemo(
    () => `Edit ${isDraftsView ? 'Draft ' : ''}Purchase Order #${id}`,
    [isDraftsView, id],
  )

  const breadcrumbBackLink = useMemo(
    () => (
      <Link to={`/${isDraftsView ? 'draft-' : ''}purchase-orders`}>
        {isDraftsView ? 'Draft ' : ''}Purchase Orders
      </Link>
    ),
    [isDraftsView],
  )

  const apiLoadZones = useApi<ZonesGetAllResponse>(
    () => ({ action: 'zones_getAll' }),
    null,
    () => ({
      autoPerform: true,
      cache: true,
    }),
  )

  const getPurchaseOrder = useApi<PurchaseOrder>(
    useMemo(
      () => ({
        action: 'purchaseOrders_getRow',
        id,
      }),
      [id],
    ),
    null,
    () => ({ cache: true }),
  )
  const { isLoading } = getPurchaseOrder
  const purchaseOrder = getPurchaseOrder.result

  const loadDataDebounced = useMemo(
    () =>
      debounce(getPurchaseOrder.performRequest, 250, {
        leading: true,
        trailing: false,
      }),
    [getPurchaseOrder.performRequest],
  )

  useEffect(() => {
    loadDataDebounced()
  }, [loadDataDebounced])

  const [reloadOverviewKey, setReviewOverviewKey] = useState<number>(0)

  const reloadOverview = useCallback(() => {
    setReviewOverviewKey(reloadOverviewKey + 1)
    loadDataDebounced()
  }, [reloadOverviewKey, loadDataDebounced])

  const handleCloseOrderDetailsEditor = useCallback(() => {
    orderDetailsEditor.close()
    reloadOverview()
    loadDataDebounced()
  }, [reloadOverview, orderDetailsEditor, loadDataDebounced])

  const handleCloseRefNumberEditor = useCallback(() => {
    refNumberEditor.close()
    loadDataDebounced()
  }, [refNumberEditor, loadDataDebounced])

  const handleCloseAddProductPanel = useCallback(() => {
    addProductsPanel.close()
    reloadOverview()
    loadDataDebounced()
  }, [loadDataDebounced, addProductsPanel, reloadOverview])

  const handleClosePlacePanel = useCallback(() => {
    placePanel.close()
    loadDataDebounced()
  }, [placePanel, loadDataDebounced])

  const addProducts = useStateful<SelectOption[]>([])

  const handleEditedProductUpdated = useCallback(
    (product: Product | null) => {
      if (product && addProductsPanel.isOpen) {
        const { sku, name, size } = product
        const label = `${sku} - ${name} - ${size}`

        addProducts.value.push({ value: String(product.id), label })
      }

      reloadOverview()
    },
    [addProducts.value, reloadOverview, addProductsPanel.isOpen],
  )

  const handleSaveSupplierPanel = useCallback(
    async (id: string) => {
      await uiApiRequest({
        action: 'purchaseOrders_setSupplier',
        id,
        supplier_id: id,
      })

      getPurchaseOrder.performRequest()
    },
    [getPurchaseOrder],
  )

  const handleDeleteSupplier = useCallback(async () => {
    supplierPanel.close()

    const isYes = await askQuestion(
      'Are you sure you want to delete this purchase order?',
    )
    if (isYes) {
      if (
        await uiApiRequest({
          action: 'purchaseOrders_trashRow',
          id,
        })
      ) {
        navigate('/purchase-orders')
      }
    }
  }, [navigate, supplierPanel, id])

  const handleClickTab = useCallback(
    (name: string) => {
      navigate(`/purchase-orders/edit/${id}${name ? `/${name}` : ''}`)
      reloadOverview()
    },
    [reloadOverview, id, navigate],
  )

  const handleChangeDeliveries = useCallback(() => {
    reloadOverview()
    loadDataDebounced()
  }, [loadDataDebounced, reloadOverview])

  const [editProductProps, setEditProductProps] =
    useState<SelectedProductProps>(null)
  const handleClickProductName = useCallback(
    (props: any) => {
      setEditProductProps(props)
      editProductPanel.open()
    },
    [editProductPanel],
  )

  const handleAddVariation = useCallback(() => {
    setEditProductProps({ addType: 'variable' })
    editProductPanel.open()
  }, [editProductPanel])

  const handleAddSimple = useCallback(() => {
    setEditProductProps({ addType: 'simple' })
    editProductPanel.open()
  }, [editProductPanel])

  const handleEditVariableProduct = useCallback(
    (productId: number) => {
      setEditProductProps({ parentProductId: productId })
      editProductPanel.open()
    },
    [editProductPanel],
  )

  const handleClickReload = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      reloadOverview()
      loadDataDebounced()
    },
    [reloadOverview, loadDataDebounced],
  )

  const [isUploading, setIsUploading] = useState(false)

  const handleDrop = useCallback(
    async (files: any[]) => {
      if (files.length === 1) {
        const url = `${global.appConfig.wpBaseUrl}/wp-admin/admin-ajax.php`
        const formData = new FormData()
        formData.append('action', 'treeadmin_purchaseOrders_uploadCsv')
        formData.append('id', String(id))
        formData.append('file', files[0])

        try {
          setIsUploading(true)
          await apiFetch(url, formData, false)
          setIsUploading(false)
        } catch (e) {
          setIsUploading(false)
          showError(e.message)
        }

        reloadOverview()
        loadDataDebounced()
      }
    },
    [id, reloadOverview, loadDataDebounced],
  )

  const handleClickPrintOverview = useCallback(() => {
    openPrintWindow({
      action: 'treeadmin_purchaseOrders_printOverview',
      print: '1',
      id: String(id),
    })
  }, [id])

  const dropZone = useDropZone('text/csv', handleDrop)

  if (getPurchaseOrder.error) {
    return <p className="m-4 text-danger">{getPurchaseOrder.error}</p>
  }

  const orderStatus = getPurchaseOrder.result?.order_status

  if (getPurchaseOrder.result) {
    if (
      (isDraftsView &&
        !(orderStatus === 'DRAFT' || orderStatus === 'REQUESTED')) ||
      (!isDraftsView && orderStatus !== 'CONFIRMED')
    ) {
      return (
        <p className="m-4 text-danger">
          Purchase Order not available for this view.
        </p>
      )
    }
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          {fromDeliveries.current ? (
            <Link to="/deliveries">Deliveries</Link>
          ) : (
            breadcrumbBackLink
          )}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          <a style={{ color: '#73818f' }} href="#" onClick={handleClickReload}>
            {breadcrumbTitle}
          </a>
          {isLoading ? <LoadingImage className="ml-2" small /> : null}
        </BreadcrumbItem>
        {!readOnly ? (
          <div className="actions">
            {isDraftsView ? (
              <Button onClick={placePanel.open}>Send Request</Button>
            ) : null}
            <Button className="ml-2" onClick={addProductsPanel.open}>
              Add Products
            </Button>
          </div>
        ) : null}
      </Breadcrumb>
      <Container fluid>
        <div className="edit-purchase-order animated fadeIn large-min-height">
          <EditProductsPanel
            purchaseOrderId={Number(id)}
            {...{ triggerSearch: reloadOverview }}
            isOpen={editProductsPanel.isOpen}
            onClose={editProductsPanel.close}
          />
          <AddProductsPanel
            {...{ addProducts }}
            purchaseOrderId={Number(id)}
            isOpen={addProductsPanel.isOpen}
            onClose={handleCloseAddProductPanel}
            onAddSimple={handleAddSimple}
            onAddVariation={handleAddVariation}
            onEditVariableProduct={handleEditVariableProduct}
          />
          <EditProductPanel
            {...{ readOnly }}
            {...editProductProps}
            onProductUpdated={handleEditedProductUpdated}
            isOpen={editProductPanel.isOpen}
            onClose={editProductPanel.close}
          />
          <SelectSupplierPanel
            purchaseOrder={purchaseOrder}
            caption="Change Supplier"
            isOpen={supplierPanel.isOpen}
            onClose={supplierPanel.close}
            onSave={handleSaveSupplierPanel}
            deleteButtonLabel="Delete Purchase Order"
            onClickDelete={handleDeleteSupplier}
          />
          <OrderDetailsEditor
            editId={Number(id)}
            isOpen={orderDetailsEditor.isOpen}
            onClose={handleCloseOrderDetailsEditor}
          />
          <RefNumberEditor
            {...{ getPurchaseOrder }}
            editId={Number(id)}
            isOpen={refNumberEditor.isOpen}
            onClose={handleCloseRefNumberEditor}
          />
          <LogPanel
            id={Number(id)}
            isOpen={logPanel.isOpen}
            onClose={logPanel.close}
          />
          <PlacePanel
            {...{ getPurchaseOrder }}
            id={Number(id)}
            isOpen={placePanel.isOpen}
            onClose={handleClosePlacePanel}
          />
          <Row className="pb-3">
            <Col>
              <OverviewCard
                {...{
                  purchaseOrder,
                  isLoading,
                  isDraftsView,
                }}
                onClickEdit={!readOnly ? supplierPanel.open : null}
                onClickPrintOverview={handleClickPrintOverview}
                onClickLog={logPanel.open}
              />
            </Col>
            <Col>
              <OrderDetailsCard
                {...{
                  purchaseOrder,
                  isLoading,
                  isDraftsView,
                  onClickEdit: !readOnly ? orderDetailsEditor.open : null,
                }}
              />
            </Col>
            {!isDraftsView ? (
              <Col>
                <DeliveryDetailsCard
                  {...{
                    purchaseOrder,
                    isLoading,
                  }}
                />
              </Col>
            ) : null}
            <Col>
              <InvoicesCard
                {...{
                  readOnly,
                  purchaseOrder,
                  isLoading,
                  onClickEdit: !readOnly ? refNumberEditor.open : null,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {!isDraftsView ? (
                <Nav tabs style={{ border: 'none' }}>
                  <Tab
                    name=""
                    active={!isDeliveriesTab}
                    onClick={handleClickTab}
                  >
                    Overview
                  </Tab>
                  <Tab
                    name="deliveries"
                    active={isDeliveriesTab}
                    onClick={handleClickTab}
                  >
                    Deliveries
                    {purchaseOrder ? (
                      <Badge className="ml-2" pill color="success">
                        {purchaseOrder.num_trucks}
                      </Badge>
                    ) : null}
                  </Tab>
                </Nav>
              ) : null}
              <TabContent className="mb-5">
                <div
                  {...dropZone.eventHandlers}
                  style={{ display: !isDeliveriesTab ? 'block' : 'none' }}
                  className={`mb-5 ${dropZone.isDragOver ? 'drag-over' : ''}`}
                >
                  {!readOnly ? (
                    <div className="m-4">
                      <i className="fa fa-upload mr-2" />
                      {isUploading ? (
                        <>Uploading...</>
                      ) : (
                        <>Drop CSV here to import bulk-import products.</>
                      )}
                    </div>
                  ) : null}
                  <OverviewTable
                    {...{
                      id: Number(id),
                      reloadKey: reloadOverviewKey,
                      onChange: handleChangeDeliveries,
                      readOnly,
                      editProductsPanel,
                      onClickProductName: handleClickProductName,
                      onClickPrintOverview: handleClickPrintOverview,
                      apiLoadZones,
                      isDraftsView,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: isDeliveriesTab ? 'block' : 'none',
                  }}
                >
                  <DeliveriesTable
                    {...{
                      id: Number(id),
                      apiLoadZones,
                      numProducts: purchaseOrder
                        ? purchaseOrder.num_products
                        : 0,
                      reloadKey: reloadOverviewKey,
                      referenceNumber: purchaseOrder
                        ? purchaseOrder.reference_number
                        : '0',
                      canAddTrucks: purchaseOrder
                        ? purchaseOrder.num_items > 0 && !readOnly
                        : false,
                      onChange: handleChangeDeliveries,
                      handleClickProductName,
                      readOnly,
                    }}
                  />
                </div>
              </TabContent>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default EditPurchaseOrder
