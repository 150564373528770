import { useCallback, useMemo, useState } from 'react'
import useTimeout from './useTimeout'

export const useCooldown = (defaultTimeMs = 1000) => {
  const [delay, setDelay] = useState<number | null>(null)

  useTimeout(() => {
    setDelay(null)
  }, delay)

  const start = useCallback(
    (timeMs: number | null = null) => {
      setDelay(timeMs === null ? defaultTimeMs : timeMs)
    },
    [defaultTimeMs],
  )

  return useMemo(() => ({ isActive: delay > 0, start }), [delay, start])
}

export default useCooldown
