import { useCallback } from 'react'
import { Button } from 'reactstrap'
import { assetPath } from 'utils'
import NumBadge from './NumBadge'
import type { TabButtonProps } from './types'

export const TabButton = <TabType,>({
  onClick,
  title,
  type,
  icon,
  num,
  selectedTab,
}: TabButtonProps<TabType>) => {
  const handleClickButton = useCallback(() => {
    onClick(type)
  }, [type, onClick])

  const isSelected = selectedTab === type

  return (
    <Button color="link" {...{ onClick: handleClickButton, title }}>
      <img
        src={assetPath(`img/tabicons/${icon}${isSelected ? '-a' : ''}.svg`)}
        alt=""
      />
      {num != null ? <NumBadge {...{ num }} /> : null}
    </Button>
  )
}
