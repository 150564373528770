import {
  type Action,
  type BaseRow,
  type Row,
  type State,
  setInitialSelectedRows,
} from 'components/Table'
import { useFiltersWithUrlUpdater } from 'hooks/useFiltersWithUrlUpdater'
import { useCallback, useEffect, useMemo } from 'react'
import { usePrevious } from '.'

// Adds a "selected" filter which encodes the selected rows in the URL
export function useFiltersWithUrlUpdaterWithTableSync<
  RowType extends BaseRow = Row,
>(
  storagePrefix: string,
  visibleFilters: string[],
  tableState: State<Row>,
  tableDispatch: React.Dispatch<Action<RowType>>,
): FiltersTypeWithUrlUpdater {
  // Sync query param selected= from URL with table state.
  const handleHydrated = useCallback(
    (filters: Record<string, any>) => {
      if (filters.selected) {
        tableDispatch(setInitialSelectedRows(filters.selected))
      }
    },
    [tableDispatch],
  )

  const visibleFilters_ = useMemo(
    () => [...visibleFilters, 'selected'],
    [visibleFilters],
  )

  const filters = useFiltersWithUrlUpdater(
    storagePrefix,
    visibleFilters_,
    ['selected'],
    handleHydrated,
  )

  const { selectedRows } = tableState

  const prevSelectedRows = usePrevious(selectedRows)
  useEffect(() => {
    const selRowsJson = JSON.stringify(selectedRows)

    if (
      JSON.stringify(prevSelectedRows) !== selRowsJson &&
      JSON.stringify(filters.selected.values) !== selRowsJson
    ) {
      filters.selected.setValues(selectedRows)
    }
  }, [filters.selected, prevSelectedRows, selectedRows])

  return filters
}

export default useFiltersWithUrlUpdaterWithTableSync
