import { useCallback } from 'react'
import Select from 'react-select'
import type { MultiValue } from 'react-select'
import { getStyles } from './../selectStyles'

const stylesHighlight = getStyles({ highlightIfEmpty: true })
const stylesNoHighlight = getStyles({ highlightIfEmpty: false })

type SelectOption = {
  label: string
  value: string
}

type SelectFieldProps = {
  name: string
  value: string | SelectOption[]
  options: SelectOption[]
  placeholder?: string
  onChange?: (name: string, value: MultiValue<string>) => void
  className?: string
  isClearable?: boolean
  isSearchable?: boolean
  isMulti?: boolean
  isDisabled?: boolean
  highlightIfEmpty?: boolean
  defaultValue?: string
  ariaLabel?: string
}

const SelectField = (props: SelectFieldProps) => {
  const {
    highlightIfEmpty,
    options,
    name,
    placeholder,
    onChange,
    className,
    isClearable,
    isSearchable,
    isDisabled,
    isMulti,
    defaultValue,
  } = props

  const handleChange = useCallback(
    (val: MultiValue<string>) => {
      if (onChange) {
        onChange(name, val)
      }
    },
    [name, onChange],
  )

  const value = isMulti
    ? props.value
    : (options || []).find((o) => o.value === props.value) || false

  return (
    <Select
      {...{ name, value, options, placeholder, isDisabled, defaultValue }}
      styles={highlightIfEmpty ? stylesHighlight : stylesNoHighlight}
      isClearable={isClearable == null ? true : isClearable}
      isSearchable={isSearchable == null ? true : isSearchable}
      isMulti={isMulti == null ? false : isMulti}
      escapeClearsValue
      className={`select ${className}`}
      onChange={handleChange}
      aria-label={props.ariaLabel}
    />
  )
}

export default SelectField
