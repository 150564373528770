import type { GiftCard } from '@ttc/api/giftcards'
import { ClipboardButton, EditPanelCard } from 'components'
import { formatDateReadable, formatPrice } from 'utils'

export const Log = ({ giftCard }: { giftCard: GiftCard }) => {
  if (giftCard.log.length === 0) {
    return <div>Log empty</div>
  }

  return (
    <>
      <ul>
        {giftCard.log.map((e) => {
          return (
            <li key={e.id}>
              {e.balance_adjustment != null ? (
                <div>
                  Balance adjusted: ${formatPrice(e.balance_adjustment * 100)}
                </div>
              ) : null}
              {e.activation_change != null ? (
                <div>
                  Activation status changed to{' '}
                  <b>
                    {Number(e.activation_change) === 1 ? 'active' : 'inactive'}
                  </b>
                </div>
              ) : null}
              {e.reason ? (
                <div>Reason: {e.reason.replace(/_/, ' ')}</div>
              ) : null}
              {e.order_number ? (
                <div>
                  Order Number:{' '}
                  <ClipboardButton
                    className="ml-1"
                    value={e.order_number}
                    text={e.order_number}
                  />
                </div>
              ) : null}
              {e.comment ? (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  Comment: {e.comment}
                </div>
              ) : null}
              <div style={{ fontSize: 11, fontStyle: 'italic' }}>
                {e.changed_by} on {formatDateReadable(e.created_at, true)}
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}

const LogCard = ({ id, giftCard }: { id: string; giftCard: GiftCard }) => {
  const isLoading = false

  return (
    <EditPanelCard stateId="giftcard_log" caption="Log" {...{ isLoading }}>
      <Log key={id} {...{ giftCard }} />
    </EditPanelCard>
  )
}

export default LogCard
