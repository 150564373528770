import { useCallback } from 'react'
import { Button } from 'reactstrap'
import NumBadge from './NumBadge'
import type { TabButtonProps } from './types'

export const TabButtonIcon = <TabType,>({
  onClick,
  title,
  type,
  icon,
  num,
  selectedTab,
}: TabButtonProps<TabType>) => {
  const handleClickButton = useCallback(() => {
    onClick(type)
  }, [type, onClick])

  const isSelected = selectedTab === type

  return (
    <Button color="link" {...{ onClick: handleClickButton, title }}>
      <i
        className={icon}
        style={{
          color: isSelected ? '#4a90e2' : '#4b5d77',
          fontSize: 22,
          verticalAlign: 'middle',
        }}
      />
      {num != null ? <NumBadge {...{ num }} /> : null}
    </Button>
  )
}

export default TabButtonIcon
