import { useCallback } from 'react'
import { DropdownItem } from 'reactstrap'

type DropDownItemCheckboxProps = {
  id?: string
  children?: React.ReactNode
  autoclose?: boolean
  onClick: (id: string, e: React.MouseEvent<HTMLElement>) => void
  isChecked: boolean
  isIntermediate?: boolean
}

export const DropDownItemCheckbox = (props: DropDownItemCheckboxProps) => {
  const { autoclose, children, onClick, isChecked, isIntermediate, id } = props

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (onClick) {
        onClick(id, e)
      }
    },
    [id, onClick],
  )

  return (
    <DropdownItem
      data-auto-close={
        autoclose == null ? 'false' : autoclose ? 'true' : 'false'
      }
      data-id={id}
      onClick={handleClick}
    >
      {isChecked ? (
        <i style={{ color: '#4394ee' }} className="fa fa-check-square" />
      ) : isIntermediate ? (
        <i style={{ color: '#4394ee' }} className="fa fa-minus-square" />
      ) : (
        <i style={{ color: 'gray' }} className="fa fa-square" />
      )}
      {children}
    </DropdownItem>
  )
}

export default DropDownItemCheckbox
