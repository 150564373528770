import { apiRequest } from 'api'
import { SelectProducts } from 'components'
import type { Action, State } from 'containers/AddOrder/useCart/types'
import { useCallback } from 'react'
import { setEditCartItem, setEditExistingCartItem } from './../useCart'
import CartItems from './CartItems'
import CartTotalItems from './CartTotalItems'
import EditProductCard from './EditProductCard'

export type ShoppingCartProps = {
  dispatch?: React.Dispatch<Action>
  readOnly?: boolean
} & State

const ShoppingCart = (props: ShoppingCartProps) => {
  const {
    dispatch,
    cartItems,
    editCartItem,
    isUpdatingCartItem,
    readOnly,
    shippingState,
  } = props
  const isEditing = editCartItem != null

  const handleChangeProducts = useCallback(
    async (options: any[]) => {
      if (!options || !options.length) {
        return
      }

      const id = options[0].value
      const existingItem = cartItems.find(
        (item) => String(item.id) === String(id),
      )

      if (existingItem != null) {
        dispatch(
          setEditExistingCartItem(existingItem.id, existingItem.lineItemId),
        )
        return
      }

      try {
        const p = await apiRequest({ action: 'inventory_getProduct', id })
        dispatch(setEditCartItem(p))
      } catch (e) {
        alert(e.message)
      }
    },
    [dispatch, cartItems],
  )

  return (
    <>
      <h5 className="mb-3">Shopping Cart</h5>

      {!readOnly ? (
        <SelectProducts
          isDisabled={isEditing}
          className="mb-2"
          placeholder="Product or SKU Number"
          value={[]}
          filterStatus={['publish']}
          onChange={handleChangeProducts}
        />
      ) : null}

      {!readOnly && editCartItem != null ? (
        <EditProductCard
          {...{ dispatch, editCartItem, isUpdatingCartItem }}
          {...editCartItem}
        />
      ) : null}

      <CartItems
        {...{ dispatch, readOnly, isEditing, cartItems, shippingState }}
      />

      {cartItems.length > 0 ? (
        <CartTotalItems {...{ dispatch, readOnly, cartState: props }} />
      ) : null}
    </>
  )
}

export default ShoppingCart
