import { useCallback, useEffect } from 'react'

export const useBarcodeScanner = (onScan: (barcode: string) => void) => {
  const handleScan = useCallback(
    (e) => {
      onScan(e.detail)
    },
    [onScan],
  )

  useEffect(() => {
    document.addEventListener('barcodeScan', handleScan)

    return () => document.removeEventListener('barcodeScan', handleScan)
  })
}

export default useBarcodeScanner
