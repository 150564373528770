import type { DropDownState } from 'components/InfoIcon/types'
import type { StatefulState } from 'hooks/useStateful'
import useStateful from 'hooks/useStateful'
import { useCallback, useEffect, useMemo } from 'react'
import { parentHasClass } from 'utils'

/**
 * Handles the state of the dropdown popover for a set of info icons.
 * If a popover is open and the user clicks outside of it, the popover is closed.
 * The popover needs to have the classes 'popover-inner' and the specified class.
 *
 * @returns An object with the following properties:
 * - dropDownState: A stateful value that contains the id of the popover that is open.
 */
export const useInfoIcon: (className: string) => {
  dropDownState: StatefulState<DropDownState>
} = (className: string) => {
  const dropDownState = useStateful<DropDownState>({
    id: null,
  })

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement

      if (
        dropDownState.value &&
        dropDownState.value.id != null &&
        !parentHasClass(target, 'popover-inner') &&
        !parentHasClass(target, 'info-icon') &&
        !parentHasClass(target, className)
      ) {
        dropDownState.set({ id: null })
      }
    },
    [className, dropDownState],
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  return useMemo(
    () => ({
      dropDownState,
    }),
    [dropDownState],
  )
}
