import type { ColumnDef } from 'components/Table/types'
import { formatDateReadable } from 'utils/date'

const columnDef: ColumnDef[] = [
  {
    label: 'Supplier',
    id: 'name',
    sortColumn: true,
  },
  {
    label: 'Source Location',
    id: 'source_location_name',
    sortColumn: false,
  },
  {
    label: 'Phone',
    id: 'phone',
    sortColumn: false,
  },
  {
    label: 'Email',
    id: 'email',
    sortColumn: false,
  },
  {
    label: 'State',
    id: 'state',
    sortColumn: true,
  },
  {
    label: 'City',
    id: 'city',
    sortColumn: true,
  },
  {
    label: 'Zip',
    id: 'zip',
    sortColumn: true,
  },
  {
    label: 'Address',
    id: 'address',
    sortColumn: true,
  },
  {
    label: 'Address 2',
    id: 'address2',
    sortColumn: false,
  },
  {
    label: 'Ignore For Cogs',
    id: 'ignore_for_cogs',
    sortColumn: false,
    defaultVisible: false,
  },
  {
    label: 'Created at',
    id: 'created_at',
    sortColumn: true,
    renderer: ({ value }) => {
      return <td>{formatDateReadable(value)}</td>
    },
  },
]

export default columnDef
