import type { ApiCall } from 'hooks/useApi'

export type ManageGiftcardsFiltersType = FiltersTypeWithUrlUpdater & {
  type: FilterType // TypeEnum
  amount: FilterType // number
  balance: FilterType // number
  reason: FilterType // ReasonEnum
  status: FilterType // StatusEnum
  hasOrder: FilterType // boolean
  batchId: FilterType // number
}

export type FiltersProps = {
  apiGetBatches: ApiCall
  filters: ManageGiftcardsFiltersType
}

export enum StatusEnum {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  CONSUMED = 'consumed',
  DEACTIVATED = 'deactivated',
}

export enum TypeEnum {
  STORE_CREDIT = 'store_credit',
  GIFTCARD = 'giftcard',
}

export enum ReasonEnum {
  DEFECT = 'defect',
  ERROR = 'error',
  SHIPPING = 'shipping',
  RETURN = 'return',
}
