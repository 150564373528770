import {
  AppAside,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
} from '@coreui/react'
import AuthContext from 'AuthContext'
import type { NavigationItem } from 'nav'
import React from 'react'
import { loginRedirect } from 'utils'
import navigation from '../../nav'
import MainContentRouter from './../../MainContentRouter'
import AppSidebarNav from './../../components/AppSidebarNav'
import DefaultAside from './DefaultAside'
import DefaultHeader from './DefaultHeader'

const itemAllowed = (
  item: NavigationItem,
  hasCap: (cap: string) => boolean,
) => {
  if (!Object.hasOwn(item, 'requiredCap')) {
    return true
  }

  if (Array.isArray(item.requiredCap)) {
    for (const cap of item.requiredCap) {
      if (hasCap(cap)) {
        return true
      }
    }
  } else {
    return hasCap(item.requiredCap)
  }

  return false
}

type DefaultLayoutProps = {
  apiStats: any
}

class DefaultLayout extends React.Component<DefaultLayoutProps> {
  static contextType = AuthContext
  declare context: React.ContextType<typeof AuthContext>

  async signOut(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()

    await this.context.logout()

    loginRedirect()
  }

  render() {
    const { apiStats } = this.props
    const { hasCap } = this.context

    const filteredNavigation = {
      items: navigation.items
        .map((item) => {
          if (item.url === '/orders/manage') {
            return {
              ...item,
              children: item.children
                .map((child) => {
                  if (
                    child.badgeKey &&
                    Object.hasOwn(apiStats.result, child.badgeKey)
                  ) {
                    return {
                      ...child,
                      badge: {
                        text: apiStats.result[child.badgeKey],
                        variant: child.badgeVariant,
                      },
                    }
                  }

                  return child
                })
                .filter((item) => itemAllowed(item, hasCap)),
            }
          }

          return item
        })
        .filter((item) => itemAllowed(item, hasCap)),
    }

    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader onLogout={(e) => this.signOut(e)} />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={filteredNavigation} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <MainContentRouter />
          </main>
          <AppAside fixed>
            <DefaultAside />
          </AppAside>
        </div>
      </div>
    )
  }
}

export default DefaultLayout
