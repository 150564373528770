export function isEmpty(t: any): t is Record<string, never> {
  return typeof t === 'object' && Object.keys(t).length === 0
}

// Return the type of a function component's prop
//
// For example, PropsType<typeof SidebarWidget, "size"> -> number
export type PropsType<
  FunctionComponent extends (P: { [k: string]: any }) => any,
  PropName extends string,
> = Parameters<FunctionComponent>[0][PropName]
