import { useBarcodeScanner, useStateful } from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { Button, CardBody, Form, Input } from 'reactstrap'
import type { StepProps } from '..'
import { TTCCONFIRM, TTCDEC, TTCINC, TTCLABELACTIONS } from './../barcodes'

const useOrderLocker = (orderNumber: string, storageKey: string) => {
  const counter = useStateful(0, `${storageKey}.${orderNumber}`)

  const didWrong = useCallback(() => {
    const value = Number(counter.value || 0)

    counter.set(value + 1)
  }, [counter])

  const clear = useCallback(() => {
    counter.set(null)
  }, [counter])

  const clearAll = useCallback(() => {
    counter.set(null)
    Object.keys(localStorage)
      .filter((a) => a.indexOf('shipNow.addonCountTries') === 0)
      .forEach((a) => localStorage.removeItem(a))
  }, [counter])

  const isLocked = Number(counter.value || 0) >= 2

  return useMemo(
    () => ({ didWrong, clear, clearAll, isLocked }),
    [didWrong, clear, clearAll, isLocked],
  )
}

const Step = ({ state, dispatch }: StepProps) => {
  const { orderNumber } = state.orderData
  const actualNumAddons = Number(state.orderData.numAddons)

  const numAddons = useStateful(0)
  const isWrong = useStateful(false)
  const orderLocker = useOrderLocker(orderNumber, 'shipNow.addonCountTries')

  const checkAnswer = useCallback(
    (numAddons) => {
      if (orderLocker.isLocked) {
        return
      }

      const isWrongValue = Number(numAddons) !== actualNumAddons

      if (isWrongValue) {
        orderLocker.didWrong()
      }

      isWrong.set(isWrongValue)

      if (!isWrongValue) {
        orderLocker.clear()

        dispatch({ type: 'CONFIRM_ADDONS' })
      }
    },
    [dispatch, isWrong, actualNumAddons, orderLocker],
  )

  useBarcodeScanner(
    useCallback(
      (barcode) => {
        if (barcode === TTCINC) {
          numAddons.set(numAddons.value + 1)
        } else if (barcode === TTCDEC) {
          numAddons.set(numAddons.value - 1)
        } else if (barcode === TTCCONFIRM) {
          checkAnswer(numAddons.value)
        } else if (barcode === TTCLABELACTIONS) {
          orderLocker.clearAll()
        }
      },
      [checkAnswer, numAddons, orderLocker],
    ),
  )

  const handleFormSubmit = useCallback(
    (e) => {
      e.preventDefault()

      checkAnswer(Number(numAddons.value))
    },
    [checkAnswer, numAddons.value],
  )

  const handleChangeInput = useCallback(
    (e) => {
      numAddons.set(e.target.value)
    },
    [numAddons],
  )

  useEffect(() => {
    if (numAddons.value < 0) {
      numAddons.set(0)
    }
  }, [numAddons])

  const handleClickDecrease = useCallback(() => {
    numAddons.set(Number(numAddons.value) - 1)
  }, [numAddons])

  const handleClickIncrease = useCallback(() => {
    numAddons.set(Number(numAddons.value) + 1)
  }, [numAddons])

  return (
    <CardBody className="text-center" style={{ fontSize: 24 }}>
      <Form onSubmit={handleFormSubmit}>
        <div className="p-3">How many addons are in the order?</div>
        <div>
          <Button size="lg" className="mr-2" onClick={handleClickDecrease}>
            -
          </Button>
          <Input
            style={{ fontSize: 24, width: 100 }}
            className="d-inline text-center"
            name="orderNumber"
            type="text"
            value={numAddons.value}
            onInput={handleChangeInput}
          />
          <Button size="lg" className="ml-2" onClick={handleClickIncrease}>
            +
          </Button>
        </div>
        <Button size="lg" color="primary" className="m-3">
          Submit
        </Button>
        {!orderLocker.isLocked && isWrong.value ? (
          <div className="alert alert-danger" role="alert">
            Number of addons is wrong. Please check again.
          </div>
        ) : null}
        {orderLocker.isLocked ? (
          <div className="alert alert-danger" role="alert">
            Too many false answers. Please use supervisor LABELACTIONS barcode
            to continue.
          </div>
        ) : null}
      </Form>
    </CardBody>
  )
}

export default Step
