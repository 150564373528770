import moment from 'moment'

export const getDateRange = (dateId: string) => {
  const from = moment()
  const to = moment()

  if (dateId === 'yesterday') {
    from.add(-1, 'day')
    to.add(-1, 'day')
  } else if (dateId === 'this_week') {
    from.startOf('week')
    to.endOf('week')
  } else if (dateId === 'last_week') {
    from.add(-1, 'week').startOf('week')
    to.add(-1, 'week').endOf('week')
  } else if (dateId === 'this_month') {
    from.startOf('month')
    to.endOf('month')
  } else if (dateId === 'last_month') {
    from.add(-1, 'month').startOf('month')
    to.add(-1, 'month').endOf('month')
  } else if (dateId === 'this_year') {
    from.startOf('year')
    to.endOf('year')
  } else if (dateId === 'last_year') {
    from.add(-1, 'year').startOf('year')
    to.add(-1, 'year').endOf('year')
  }

  return [from.startOf('day'), to.endOf('day')]
}
