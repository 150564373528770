import { StrictMode } from 'react'
import App from './App'
import './app.scss'
import ReactDOMClient from 'react-dom/client'

const rootEl = document.getElementById('root')

if (rootEl) {
  const root = ReactDOMClient.createRoot(rootEl)

  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  )
}
