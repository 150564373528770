import AuthContext from 'AuthContext'
import { ADMIN } from 'caps'
import { ClipboardButton } from 'components'
import { useCallback, useContext } from 'react'

type LinkToOrderProps = {
  onClick?: ({
    orderNumber,
    orderId,
  }: {
    orderNumber: string
    orderId: number
  }) => void
  orderNumber: string
  orderId: number
  hasClipButton?: boolean
}

const LinkToOrder = ({
  onClick,
  orderId,
  orderNumber,
  hasClipButton,
}: LinkToOrderProps) => {
  const { hasCap } = useContext(AuthContext)

  const isAdmin = hasCap(ADMIN)

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      onClick({ orderNumber, orderId })
    },
    [onClick, orderNumber, orderId],
  )

  const href = isAdmin
    ? `${global.appConfig.wpBaseUrl}/wp-admin/post.php?action=edit&post=${orderId}`
    : '#'

  if (!onClick) {
    return (
      <>
        {orderNumber}

        {hasClipButton ? (
          <ClipboardButton className="ml-1" value={orderNumber} />
        ) : null}
      </>
    )
  }

  return (
    <>
      <a target="noopener" href={href} onClick={handleClick}>
        {orderNumber}
      </a>
      {hasClipButton ? (
        <ClipboardButton className="ml-1" value={orderNumber} />
      ) : null}
    </>
  )
}

export default LinkToOrder
