import type { PurchaseOrder } from '@ttc/api/purchaseOrders'
import EditPanelCard from 'components/EditPanelCard'
import Loading from 'components/Loading'
import useApi from 'hooks/useApi'
import PurchaseOrderListItem from './PurchaseOrderListItem'

const PurchaseOrdersCard = ({ productId }: { productId: number }) => {
  const apiGet = useApi<{ rows: PurchaseOrder[] }>(
    () => ({ action: 'inventory_getProductPurchaseOrders', id: productId }),
    null,
    () => ({ autoPerform: true, cache: true }),
  )

  const entries = apiGet.result?.rows || []

  if (apiGet.isLoading && !apiGet.hasResult) {
    return <Loading />
  }

  return (
    <EditPanelCard
      caption="Purchase Orders"
      stateId="product-purchase-orders"
      bodyProps={{ className: 'p-2' }}
    >
      {entries.length === 0 ? (
        <i>No purchase orders found.</i>
      ) : (
        <ul>
          {entries.map((purchaseOrder) => (
            <PurchaseOrderListItem
              key={purchaseOrder.id}
              {...{ purchaseOrder }}
            />
          ))}
        </ul>
      )}
    </EditPanelCard>
  )
}

export default PurchaseOrdersCard
