import { EditPanel, EditPanelCard, Loading, SelectField } from 'components'
import { useApi, useForm, usePrevious } from 'hooks'
import moment from 'moment'
import { Fragment, useCallback, useEffect } from 'react'
import { Col, CustomInput, FormGroup, Input, Row } from 'reactstrap'

const initialState = {
  changeEnabledState: false,
  enabledState: null,
  changePreorderDate: false,
  preorderDate: '',
}

const enabledStateOptions = [
  { value: 'enabled', label: 'Enabled' },
  { value: 'disabled', label: 'Disabled' },
]

const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD')

const CardEditor = ({ form }) => {
  const { state } = form

  return (
    <EditPanelCard caption="Properties" stateId="product-properties">
      <Row>
        <Col>
          <FormGroup className="mb-1">
            <CustomInput
              {...form.inputProps}
              bsSize="lg"
              checked={state.changeEnabledState}
              label="Change Enabled State"
              type="checkbox"
              id="product-changeEnabledState"
              name="changeEnabledState"
            />
          </FormGroup>
        </Col>
      </Row>
      {state.changeEnabledState ? (
        <Row className="mb-1 mt-1">
          <Col>
            <FormGroup>
              <SelectField
                {...form.selectFieldProps}
                options={enabledStateOptions}
                value={state.enabledState}
                id="product-enabledState"
                name="enabledState"
                isClearable
                required
              />
            </FormGroup>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col>
          <FormGroup className="mb-1">
            <CustomInput
              {...form.inputProps}
              bsSize="lg"
              checked={state.changePreorderDate}
              label="Change Preorder Date"
              type="checkbox"
              id="product-changePreorderDate"
              name="changePreorderDate"
            />
          </FormGroup>
        </Col>
      </Row>
      {state.changePreorderDate ? (
        <Row className="mb-1 mt-1">
          <Col>
            <FormGroup className="mb-0">
              <Input
                {...form.inputProps}
                value={state.preorderDate}
                id="product-preorderDate"
                type="date"
                name="preorderDate"
                min={tomorrow}
              />
            </FormGroup>
          </Col>
        </Row>
      ) : null}
    </EditPanelCard>
  )
}

type EditProductsPanelProps = {
  isOpen: boolean
  onClose: (x?: boolean) => void
  triggerSearch: () => Promise<void> | void
  readOnly?: boolean
  purchaseOrderId: number
}

const EditProductsPanel = (props: EditProductsPanelProps) => {
  const { isOpen, onClose, triggerSearch, readOnly, purchaseOrderId } = props
  const form = useForm(initialState)
  const editId = purchaseOrderId

  const updateProducts = useApi(
    () => ({ action: 'purchaseOrders_bulkEditProducts' }),
    null,
    () => ({ errorModal: true }),
  )

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (isOpen && prevIsOpen !== isOpen) {
      form.reset()
    }
  }, [form, prevIsOpen, isOpen])

  const handleSubmit = useCallback(async () => {
    await updateProducts.performRequest({
      json: {
        id: purchaseOrderId,
        ...form.state,
      },
    })

    await triggerSearch()
    onClose(true)
  }, [onClose, form.state, updateProducts, triggerSearch, purchaseOrderId])

  const isLoading = updateProducts.isLoading
  const error = updateProducts.error

  const canSubmit =
    form.state.changePreorderDate || form.state.changeEnabledState

  return (
    <EditPanel
      noHeader
      onSubmit={isLoading || !canSubmit ? null : handleSubmit}
      {...{ error, isOpen, onClose, isLoading }}
    >
      <Row>
        <Col className="d-flex justify-content-between align-items-center m-3 pl-1 pr-1">
          <span style={{ fontSize: '1rem' }}>Bulk Edit Products</span>
        </Col>
      </Row>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment key={editId}>
          <CardEditor {...{ form, readOnly }} />
        </Fragment>
      )}
    </EditPanel>
  )
}

export default EditProductsPanel
