import { useCallback } from 'react'
import { Col } from 'reactstrap'
import type { ProductType } from '.'

type ProductLineProps = {
  product: ProductType
  onClick: (id: string) => void
  checked: boolean
}

const ProductLine = (props: ProductLineProps) => {
  const { product, onClick, checked } = props

  const handleClick = useCallback(() => {
    onClick(product.id)
  }, [product, onClick])

  return (
    <div
      className="d-flex"
      key={product.id}
      style={{ borderTop: '1px solid #c5dbea' }}
    >
      <Col
        className="d-flex flex-grow-0"
        style={{
          padding: 5,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'right',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <div style={{ padding: '10px 20px 10px 10px' }}>
          <input
            style={{ cursor: 'pointer' }}
            type="checkbox"
            checked={checked}
            onChange={handleClick}
          />
        </div>
      </Col>
      <Col style={{ padding: '10px 10px 10px 0' }}>
        <div>
          <a
            target="noopener"
            href={`${global.appConfig.wpBaseUrl}/wp-admin/post.php?action=edit&post=${product.parentProductId}`}
          >
            {product.name} {product.size}
          </a>
        </div>
        <div style={{ color: '#999' }}>{product.sku}</div>
      </Col>
      <Col
        className="flex-grow-0 d-flex p-0 flex-column"
        style={{
          borderLeft: '1px solid #c5dbea',
          alignItems: 'center',
        }}
      >
        <div
          className="d-flex text-center"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            fontSize: 22,
            width: 70,
          }}
        >
          {product.quantity}
        </div>
      </Col>
    </div>
  )
}

export default ProductLine
