import { useCallback } from 'react'
import { Button, CustomInput } from 'reactstrap'
import { formatPrice } from 'utils/price'
import type { Action, CartItemType } from '../useCart/types'
import {
  removeCartItem,
  setCartItemRestoreInventory,
  setEditExistingCartItem,
} from './../useCart'
import ProductInfo from './ProductInfo'

type CartItemProps = {
  dispatch: React.Dispatch<Action>
  cartItem: CartItemType
  readOnly: boolean
  isEditing: boolean
  shippingState: string
}

const CartItem = (props: CartItemProps) => {
  const { cartItem, readOnly, isEditing, dispatch, shippingState } = props

  const handleClickEditCartItem = useCallback(
    (e) => {
      const id = e.currentTarget.getAttribute('data-id')
      const lineItemId = e.currentTarget.getAttribute('data-lineitemid')

      if (id) {
        dispatch(setEditExistingCartItem(id, lineItemId))
      }
    },
    [dispatch],
  )

  const handleClickRemoveCartItem = useCallback(
    (e) => {
      const id = e.currentTarget.getAttribute('data-id')
      const lineItemId = e.currentTarget.getAttribute('data-lineitemid')

      if (id) {
        dispatch(removeCartItem(id, lineItemId))
      }
    },
    [dispatch],
  )

  const handleChangeReturnInventory = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const id = e.currentTarget.getAttribute('data-id')
      const lineItemId = e.currentTarget.getAttribute('data-lineitemid')

      if (id) {
        dispatch(
          setCartItemRestoreInventory(id, lineItemId, e.currentTarget.checked),
        )
      }
    },
    [dispatch],
  )

  const {
    id,
    quantity,
    originalQuantity,
    price,
    lineItemId,
    restoreInventory,
    isManagingStock,
  } = cartItem

  return (
    <div
      style={{
        alignItems: 'center',
        borderBottom: '1px dotted #c8ced3',
      }}
      className="d-flex pt-2 pb-2"
    >
      <div style={{ flex: 2 }}>
        <ProductInfo shippingState={shippingState} {...cartItem} />
      </div>
      <div style={{ textAlign: 'right', whiteSpace: 'nowrap', flex: 1 }}>
        <div>
          {quantity == 0 ? (
            <span>(Removed)</span>
          ) : quantity == 1 ? (
            <span>{quantity}x </span>
          ) : (
            <span>
              {quantity}x ${formatPrice(price)} ={' '}
            </span>
          )}
          {quantity > 0 ? <span>${formatPrice(quantity * price)}</span> : null}
        </div>
        {isManagingStock &&
        lineItemId != null &&
        originalQuantity != quantity ? (
          <div>
            <span style={{ backgroundColor: 'rgb(255, 243, 205)' }}>
              Change qty {originalQuantity} → {quantity}
              {quantity < originalQuantity && (
                <CustomInput
                  data-id={id}
                  data-lineitemid={lineItemId}
                  className="checkbox"
                  type="checkbox"
                  bsSize="sm"
                  name="return-warranty"
                  label="Restore inventory"
                  id={`return-inventory-checkbox-${id}-${lineItemId}`}
                  checked={restoreInventory}
                  onChange={handleChangeReturnInventory}
                />
              )}
            </span>
          </div>
        ) : null}
      </div>
      {cartItem.isEditable && !readOnly ? (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Button
            data-id={id}
            data-lineitemid={lineItemId}
            className="ml-2 mr-1"
            color="primary"
            size="sm"
            onClick={handleClickEditCartItem}
            disabled={isEditing}
          >
            Edit
          </Button>
          <Button
            data-id={id}
            data-lineitemid={lineItemId}
            color="danger"
            size="sm"
            onClick={handleClickRemoveCartItem}
            disabled={isEditing || quantity == 0}
          >
            Remove
          </Button>
        </div>
      ) : null}
    </div>
  )
}

export default CartItem
