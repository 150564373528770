import type { Action } from 'containers/AddOrder/useCart/types'
import { useCallback } from 'react'
import { CustomInput } from 'reactstrap'
import { setValue } from './../useCart'

type EditInputProps = {
  name: string
  value: string
  oldvalue: string
  type: string
  editable: boolean
  toggleState: boolean
  onChange: (e: any) => void
}

const EditInput = (props: EditInputProps) => {
  const { name, value, oldvalue, type, editable, toggleState, onChange } = props

  if (type === 'date' && !toggleState) {
    return null
  }

  if (!editable) {
    return (
      <>
        {oldvalue ? <del style={{ paddingRight: 5 }}>{oldvalue}</del> : null}
        <span style={{ paddingRight: 12 }}>${value}</span>
      </>
    )
  }

  if (type === 'date') {
    return (
      <input
        {...{ name, value, onChange }}
        required
        style={{ maxWidth: 170 }}
        type="date"
        id={`input-text-${name}`}
        className="d-inline-block form-control"
      />
    )
  }

  return (
    <input
      {...{ name, value, onChange }}
      required
      style={{ maxWidth: 85, textAlign: 'right' }}
      type={type ? type : 'text'}
      id={`input-text-${name}`}
      className="d-inline-block form-control"
    />
  )
}

type CartTotalItem = {
  dispatch: React.Dispatch<Action>
  name?: string
  type?: string
  value: string
  oldvalue?: string
  valueCalculated?: string
  toggleName?: string
  toggleState?: boolean
  label: string
  editable?: boolean
  gray?: boolean
  readOnly?: boolean
  bold?: boolean
}

const CartTotalItem = (props: CartTotalItem) => {
  const {
    dispatch,
    name,
    type,
    value,
    oldvalue,
    valueCalculated,
    toggleName,
    toggleState,
    label,
    editable,
    gray,
    readOnly,
  } = props

  const handleChangeInput = useCallback(
    (e) => {
      const { type, name, value, checked } = e.currentTarget

      dispatch(setValue(name, type === 'checkbox' ? checked : value))
    },
    [dispatch],
  )

  const fullLabel = `${label}${type === 'date' ? '' : ':'}`

  return (
    <div
      className="d-flex pb-2 pt-2 position-relative"
      style={{
        fontWeight: props.bold ? 'bold' : 'normal',
        borderBottom: '1px dotted #c8ced3',
        alignItems: 'center',
        minHeight: 52,
        color: gray ? '#aaa' : undefined,
      }}
    >
      {!readOnly && valueCalculated ? (
        <div
          className="calculated"
          style={{
            position: 'absolute',
            top: 15,
            right: -75,
            width: 80,
            textAlign: 'right',
          }}
        >
          {valueCalculated}
        </div>
      ) : null}

      {!readOnly && toggleName != null ? (
        <div style={{ flex: 2 }}>
          <CustomInput
            {...{ name: toggleName }}
            id={`input-checkbox-${name}`}
            className="checkbox"
            bsSize="lg"
            checked={toggleState}
            onChange={handleChangeInput}
            type="checkbox"
            label={fullLabel}
          />
        </div>
      ) : (
        <div style={{ flex: 2 }}>
          <div style={{ paddingLeft: 28 }}>{fullLabel}</div>
        </div>
      )}
      <div className="text-right" style={{ flex: 1 }}>
        <EditInput
          {...{
            name,
            value,
            oldvalue,
            type,
            editable,
            toggleState,
            onChange: handleChangeInput,
          }}
        />
      </div>
    </div>
  )
}

export default CartTotalItem
