import { useCallback } from 'react'
import { Badge } from 'reactstrap'

const fallbackImageUrl = `${global.appConfig.wpBaseUrl}/wp-content/uploads/woocommerce-placeholder-1-150x150.jpg`

export type OnClickCellProductNameProps = {
  productId: number
  parentProductId: number
  thumbnailImageUrl: string
  name: string
}

type CellProductNameProps = {
  name: string
  variationDescription?: string
  productId: number
  parentProductId: number
  thumbnailImageUrl: string
  status: string
  isBlocked: boolean
  onClick?: (props: OnClickCellProductNameProps) => void
}

/**
 * Table cell widget used to display product names.
 *
 * Accepts the following props:
 *
 * - `name`: The name of the product (string).
 * - `productId`: The ID of the product (number).
 * - `parentProductId`: The ID of the parent product (number).
 * - `thumbnailImageUrl`: The URL of the thumbnail image for the product (string).
 * - `status`: The status of the product (string).
 * - `isBlocked`: A boolean value indicating if the product is blocked.
 *
 * The component also accepts an optional `onClick` callback function that will be invoked when
 * the product name link is clicked.
 *
 * The `onClick` function receives an object containing
 * - `productId`
 * - `parentProductId`
 * - `thumbnailImageUrl`
 * - `name`
 *
 * If the product status is not "publish",
 * the product name link will be displayed with a line-through text decoration.
 * The product name link is also a hyperlink that points to the product edit page in the WordPress admin area.
 *
 * If the `isBlocked` prop is true, a "Blocked" badge will be displayed next to the product name.
 */
const CellProductName = (props: CellProductNameProps) => {
  const {
    onClick,
    name,
    variationDescription,
    productId,
    parentProductId,
    thumbnailImageUrl,
    status,
    isBlocked,
  } = props

  const productUrl = `${global.appConfig.wpBaseUrl}/wp-admin/post.php?action=edit&post=${parentProductId}`

  const isDisabled = status && status !== 'publish'

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()

      if (onClick) {
        const { productId, parentProductId, thumbnailImageUrl, name } = props
        onClick({
          productId,
          parentProductId,
          thumbnailImageUrl,
          name,
        })
      }
    },
    [onClick, props],
  )

  return (
    <td className="cell product-name">
      <img
        style={{ objectFit: 'cover' }}
        alt=""
        width="28"
        height="28"
        src={thumbnailImageUrl || fallbackImageUrl}
      />
      <a
        style={{ textDecoration: isDisabled ? 'line-through' : 'none' }}
        onClick={handleClick}
        href={productUrl}
        target="noopener"
        title={String(productId)}
      >
        {name}
        {variationDescription ? <i> - {variationDescription}</i> : ''}
      </a>
      {isBlocked ? (
        <Badge className="ml-2" color="danger">
          Blocked
        </Badge>
      ) : null}
    </td>
  )
}

export default CellProductName
