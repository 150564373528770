import { EditPanelCard } from 'components'
import { Col, Row } from 'reactstrap'
import { formatDateReadable } from 'utils'
import { AddressRow } from './CustomerCard'

const PaymentCard = (props) => {
  const { getOrder, readOnly } = props
  const { result } = getOrder

  return (
    <EditPanelCard
      caption="Payment"
      stateId="orders_payment"
      bodyProps={{ className: 'p-3' }}
    >
      {result.paidDate ? (
        <Row>
          <Col>{formatDateReadable(result.paidDate)}</Col>
        </Row>
      ) : null}
      {result.paymentMethodName ? (
        <Row>
          <Col>{result.paymentMethodName}</Col>
        </Row>
      ) : null}
      {result.coupon_lines && result.coupon_lines.length
        ? result.coupon_lines.map((coupon) => {
            return (
              <Row key={coupon.id} className="mt-3">
                <Col>
                  <i className="fa fa-gift mr-2" />
                  {coupon.desc}
                </Col>
              </Row>
            )
          })
        : null}
      <Row className="mt-3">
        <Col>
          <AddressRow {...{ getOrder, readOnly }} addressType="billing" />
        </Col>
      </Row>
    </EditPanelCard>
  )
}

export default PaymentCard
