import { Badge } from 'reactstrap'

const ShipmentLabel = (props) => {
  const {
    tracking_number,
    is_voided,
    formatted_tracking_provider,
    date_shipped_format,
    shipped_by,
    is_delivered,
    formatted_tracking_link,
    date_delivered_format,
  } = props

  return (
    <div key={tracking_number} className="mb-2">
      <span style={is_voided ? { textDecoration: 'line-through' } : null}>
        {formatted_tracking_provider}{' '}
        <a target="noopener" href={formatted_tracking_link}>
          {tracking_number}
        </a>{' '}
        shipped on {date_shipped_format} by {shipped_by}
      </span>
      {!is_voided && is_delivered ? (
        <Badge pill color="info" className="ml-2">
          DELIVERED ON {date_delivered_format}
        </Badge>
      ) : null}
      {is_voided && !is_delivered ? (
        <Badge pill color="danger" className="ml-2">
          VOIDED
        </Badge>
      ) : null}
      {is_voided && is_delivered ? (
        <Badge pill color="warning" className="ml-2">
          VOIDED BUT DELIVERED
        </Badge>
      ) : null}
    </div>
  )
}

export default ShipmentLabel
