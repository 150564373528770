import { Badge } from 'reactstrap'

type PurchaseOrderStatusBadgeProps = {
  value?: string
  className?: string
}

const colors = {
  DELIVERED: 'success',
  PARTLY_DELIVERED: 'warning',
  DRAFT: 'warning',
  CONFIRMED: 'success',
}

const PurchaseOrderStatusBadge = ({
  className,
  value,
}: PurchaseOrderStatusBadgeProps) => {
  const statusColor = colors[value] || 'secondary'

  return (
    <Badge {...{ className }} color={statusColor}>
      {value.toUpperCase()}
    </Badge>
  )
}

export default PurchaseOrderStatusBadge
