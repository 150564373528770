import { DropDownFilterButton } from 'components'

export const Filters = ({ filters }) => {
  return (
    <>
      <DropDownFilterButton
        label="Subscription"
        prefixLabel="Subscription: "
        values={['ANY', 'PENDING', 'ACTIVE', 'ACTIVE_CANCELLED', 'INACTIVE']}
        exclusiveValues={['ANY']}
        filter={filters.subscription}
      />
    </>
  )
}

export default Filters
