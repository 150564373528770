import { useBoolean, useStateful } from 'hooks'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import useZebraBrowserPrint from './useZebraBrowserPrint'

let autoLoad = true

/* Only for debugging. */
if (localStorage.getItem('DISABLE_ZEBRA')) {
  autoLoad = false
}

let isReloading = false

let checkingIsPrinterReady = false

export const useZebraPrinter = () => {
  const isLoaded = useZebraBrowserPrint()

  const printer = useRef(null)
  const status = useStateful('')
  const isReady = useStateful(null)
  const isLoading = useBoolean(false)
  const isBrowserPrintInitialized = useBoolean(false)

  /* Work around buggy BrowserPrinter. */
  useEffect(() => {
    const handler = () => {
      if (checkingIsPrinterReady) {
        console.log('useZebraPrinter: Catched unhandled promise reject') // eslint-disable-line
        isReady.set(false)
        isLoading.off()
        isReloading = false
      }
    }

    global.addEventListener('unhandledrejection', handler)

    return () => global.removeEventListener('unhandledrejection', handler)
  }, [isLoading, isReady])

  const reload = useCallback(() => {
    if (!global.BrowserPrint) {
      return
    }

    isBrowserPrintInitialized.on()

    if (isReloading) {
      return
    }

    isReloading = true

    isLoading.on()
    status.set('checking')

    const checkPrinterStatus = async () => {
      const device = printer.current

      if (!device) {
        return
      }

      status.set(`Connecting to: ${device.name}`)

      isReady.set(null)

      try {
        checkingIsPrinterReady = true
        await device.isPrinterReady()
        checkingIsPrinterReady = false
      } catch (_e) {
        status.set(`Print not ready yet: ${device.name}`)
        isReady.set(false)
        isLoading.off()
        isReloading = false
        return
      }

      device.sendThenRead(
        '~hs',
        () => {
          isReady.set(true)
          isLoading.off()
          status.set(`Device ready: ${device.name}`)
          isReloading = false
        },
        () => {
          status.set('Unable to communicate with printer.')
          isReady.set(false)
          isLoading.off()
          isReloading = false
        },
      )
    }

    if (printer.current != null) {
      checkPrinterStatus()
    } else {
      global.BrowserPrint.getDefaultDevice(
        'printer',
        async (device: any) => {
          // For debugging
          global.debugDevice = device

          if (!device) {
            status.set('No device detected.')
            printer.current = null
            isReady.set(false)
            isLoading.off()
            isReloading = false
            return
          }

          printer.current = new global.Zebra.Printer(device)

          // For debugging
          global.debugPrinter = printer.current

          checkPrinterStatus()
        },
        () => {
          status.set('Error: unable to connect to BrowserPrint app.')
          printer.current = null
          isReady.set(false)
          isLoading.off()
          isReloading = false
        },
      )
    }
  }, [status, isReady, isLoading, isBrowserPrintInitialized])

  useEffect(() => {
    if (autoLoad && isLoaded && !isBrowserPrintInitialized.value) {
      reload()
    }
  }, [isBrowserPrintInitialized, reload, isLoaded])

  return useMemo(
    () => ({
      printer: printer.current,
      reload,
      status,
      isReady: isReady.value,
      isLoading: isLoading.value,
    }),
    [reload, status, isReady, isLoading],
  )
}

export default useZebraPrinter
