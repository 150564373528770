import type { PurchaseOrder } from '@ttc/api/purchaseOrders'
import PurchaseOrderStatusBadge from 'components/Badges/PurchaseOrderStatusBadge'
import Loading from 'components/Loading'
import { useCallback } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { formatDateReadable } from 'utils/date'
import { formatPrice } from 'utils/price'

type BodyProps = {
  purchaseOrder: PurchaseOrder
  isDraftsView?: boolean
}

const Body = (props: BodyProps) => {
  const { purchaseOrder, isDraftsView } = props
  const {
    invoice_number,
    order_status,
    status,
    order_date,
    order_total,
    net_order_total,
    total_freight,
    num_items,
    discount,
  } = purchaseOrder

  return (
    <>
      <Row>
        <Col className="text-nowrap">Invoice Number:</Col>
        <Col className="text-nowrap text-right">
          {invoice_number ? invoice_number : <>&mdash;</>}
        </Col>
      </Row>
      <Row>
        <Col className="text-nowrap">Status:</Col>
        <Col className="text-nowrap text-right">
          <PurchaseOrderStatusBadge value={order_status} />
        </Col>
      </Row>
      {!isDraftsView ? (
        <Row>
          <Col className="text-nowrap">Delivery Status:</Col>
          <Col className="text-nowrap text-right">
            <PurchaseOrderStatusBadge value={status} />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col className="text-nowrap">Order Date:</Col>
        <Col className="text-nowrap text-right">
          {order_date ? formatDateReadable(order_date) : <>&mdash;</>}
        </Col>
      </Row>
      <Row>
        <Col className="text-nowrap">Order Total:</Col>
        <Col className="text-nowrap text-right">
          ${formatPrice(order_total * 100)}
        </Col>
      </Row>
      <Row>
        <Col className="text-nowrap">Net Order Total:</Col>
        <Col className="text-nowrap text-right">
          ${formatPrice(net_order_total * 100)}
        </Col>
      </Row>
      <Row>
        <Col className="text-nowrap">Total Freight:</Col>
        <Col className="text-nowrap text-right">
          ${formatPrice(total_freight * 100)}
        </Col>
      </Row>
      <Row>
        <Col className="text-nowrap">Total Items:</Col>
        <Col className="text-nowrap text-right">{num_items}</Col>
      </Row>
      <Row>
        <Col className="text-nowrap">Global Discount:</Col>
        <Col className="text-nowrap text-right">
          {discount > 0 ? `${discount} %` : '-'}
        </Col>
      </Row>
    </>
  )
}

type OrderDetailsCardProps = {
  purchaseOrder: PurchaseOrder
  isLoading: boolean
  onClickEdit: () => void
  isDraftsView?: boolean
}

const OrderDetailsCard = (props: OrderDetailsCardProps) => {
  const { purchaseOrder, isLoading, onClickEdit } = props

  const handleClickEdit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      onClickEdit()
    },
    [onClickEdit],
  )

  return (
    <Card className="h-100">
      <CardHeader>
        <span className="align-bottom">Order Details</span>
        <div className="card-header-actions">
          {onClickEdit ? (
            <a
              href="#"
              onClick={handleClickEdit}
              className="card-header-action btn btn-setting"
            >
              <i className="fa fa-pencil" />
            </a>
          ) : null}
        </div>
      </CardHeader>
      <CardBody>
        {!purchaseOrder && isLoading ? (
          <Loading />
        ) : purchaseOrder ? (
          <Body {...props} />
        ) : null}
      </CardBody>
    </Card>
  )
}

export default OrderDetailsCard
