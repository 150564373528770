import { ReturnStatusBadge, TagsCell } from 'components'
import type { ColumnDef } from 'components/Table/types'
import { customerCellRenderer } from 'components/cellRenderer'
import { formatDateReadable } from 'utils/date'
import StatusBadge from './StatusBadge'

const columnDef: ColumnDef[] = [
  {
    label: '',
    id: 'checkbox',
    canExport: false,
  },
  {
    label: 'Return ID',
    id: 'id',
    sortColumn: true,
    cursor: 'pointer',
    hasClipboardButton: true,
  },
  {
    label: 'Order Number',
    id: 'order_number',
    sortColumn: true,
    hasClipboardButton: true,
  },
  {
    label: 'Reason',
    id: 'return_reason',
    sortColumn: true,
    renderer: ({ value }) => {
      return (
        <td>
          <StatusBadge status={value} />
        </td>
      )
    },
  },
  {
    label: 'Status',
    id: 'status',
    sortColumn: true,
    renderer: ({ value }) => {
      return (
        <td>
          <ReturnStatusBadge value={value} />
        </td>
      )
    },
  },
  {
    label: 'Tags',
    id: 'tags',
    renderer: (props: any) => <TagsCell {...props} />,
    canExport: false,
  },
  {
    label: 'Return date',
    id: 'return_date',
    sortColumn: true,
    renderer: ({ value }) => {
      return <td>{formatDateReadable(value)}</td>
    },
  },
  {
    label: 'Received date',
    id: 'items_received_date',
    sortColumn: true,
    renderer: ({ value }) => {
      return <td>{formatDateReadable(value)}</td>
    },
  },
  {
    label: 'Exp Qty',
    id: 'num_expected_items',
  },
  {
    label: 'Recv Qty',
    id: 'num_received_items',
  },
  {
    label: 'Item Name',
    id: 'item_name',
    canExport: false,
  },
  {
    label: 'Customer Name',
    id: 'customer_name',
    renderer: customerCellRenderer,
  },
  {
    label: 'Created by',
    id: 'created_by_name',
    sortColumn: true,
  },
]

export default columnDef
