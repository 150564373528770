import { EditPanel, EditPanelCard, Portal } from 'components'

const Sidebar = (props) => {
  const { onToggle, shipment } = props

  return (
    <Portal id="sidebar-content">
      <ul className="nav nav-tabs flex-row-reverse">
        <li className="nav-item">
          <a onClick={onToggle} className="active nav-link">
            <i className="icon-list" />
          </a>
        </li>
      </ul>

      {shipment == null ? (
        <div className="tab-content">
          <div className="p-3">No shipments selected.</div>
        </div>
      ) : (
        <div className="tab-content pb-4">
          <EditPanel embedded isOpen caption={`Shipment #${shipment.id}`}>
            {shipment.label == null ? (
              <EditPanelCard stateId="label_info" caption="Label">
                This shipment has no label.
              </EditPanelCard>
            ) : (
              <EditPanelCard stateId="label_info" caption="Label">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Label ID</td>
                      <td>{shipment.label.id}</td>
                    </tr>
                    <tr>
                      <td>Shipengine ID</td>
                      <td>{shipment.label.se_label_id}</td>
                    </tr>
                    <tr>
                      <td>Label Status</td>
                      <td>{shipment.label.status}</td>
                    </tr>
                    <tr>
                      <td>Service Code</td>
                      <td>{shipment.label.service_code}</td>
                    </tr>
                    <tr>
                      <td>Package Code</td>
                      <td>{shipment.label.package_code}</td>
                    </tr>
                    <tr>
                      <td>Cost</td>
                      <td>${shipment.label.cost}</td>
                    </tr>
                    <tr>
                      <td>Shippped at</td>
                      <td>{shipment.label.shipped_at}</td>
                    </tr>
                    {shipment.label.voided_at ? (
                      <tr>
                        <td>Voided at</td>
                        <td>{shipment.label.voided_at}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>Actual delivery date</td>
                      <td>{shipment.label.tracking_actual_delivery_date}</td>
                    </tr>
                    <tr>
                      <td>Tracking number</td>
                      <td>
                        <a
                          href={shipment.label.formatted_tracking_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {shipment.label.tracking_number}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Package count</td>
                      <td>{shipment.package_count}</td>
                    </tr>
                    <tr>
                      <td>Packages</td>
                      <td>
                        <ul>
                          {shipment.packages.map((p, i) => {
                            return (
                              <li key={i}>
                                {p.length}x{p.width}x{p.height}
                              </li>
                            )
                          })}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Label download</td>
                      <td>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={shipment.label.label_url_pdf}
                        >
                          PDF
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </EditPanelCard>
            )}
          </EditPanel>
        </div>
      )}
    </Portal>
  )
}

export default Sidebar
