export const TTCRESET = 'TTCRESET'
export const TTCINC = 'TTCINC'
export const TTCDEC = 'TTCDEC'
export const TTCCONFIRM = 'TTCCONFIRM'
export const TTCNO = 'TTCNO'
export const TTCCLEAR = 'TTCCLEAR'
export const TTCSHIPOPTIONS = 'TTCSHIPOPTIONS'
export const TTCNEWLABEL = 'TTCNEWLABEL'
export const TTCLABELACTIONS = 'TTCLABELACTIONS'

export type BarcodeType = {
  code: string
  desc: string
  desc2: string
  adminOnly?: boolean
}

const barcodes: BarcodeType[] = [
  { code: TTCRESET, desc: 'RESET', desc2: 'Discard changes and start over.' },
  { code: TTCINC, desc: '+', desc2: 'Increment value by one.' },
  { code: TTCDEC, desc: '—', desc2: 'Decrease value by one.' },
  { code: TTCCONFIRM, desc: 'CONFIRM', desc2: 'Confirm and continue.' },
  { code: TTCNO, desc: 'NO', desc2: 'Answer with NO.' },
  {
    code: TTCCLEAR,
    desc: 'CLEAR BOX COUNTS',
    desc2: 'Reset all box counters to zero.',
  },
  {
    code: TTCSHIPOPTIONS,
    desc: 'SHIPOPTIONS',
    desc2: 'Show advanced shipping options.',
    adminOnly: true,
  },
  {
    code: TTCNEWLABEL,
    desc: 'NEWLABEL',
    desc2: 'Create additional label.',
    adminOnly: true,
  },
  {
    code: TTCLABELACTIONS,
    desc: 'LABELACTIONS',
    desc2: 'Show label actions (reprint, void).',
    adminOnly: true,
  },
]

export default barcodes
