import { useDropDownToggler } from 'hooks'
import { useCallback } from 'react'
import Select from 'react-select'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import { getStyles } from './../selectStyles'

const styles = getStyles<SelectOption, false>({ highlightIfEmpty: true })

const options = [
  { label: 'Equals', value: '=' },
  { label: 'Greater than', value: '>' },
  { label: 'Greater than or equal to', value: '>=' },
  { label: 'Less than', value: '<' },
  { label: 'Less than or equal to', value: '<=' },
]

type NumericFilterButtonProps = {
  label: string
  filter: FilterType
}

const NumericFilterButton = (props: NumericFilterButtonProps) => {
  const { label, filter } = props

  const toggler = useDropDownToggler()

  const opValue = filter.values.length === 2 ? filter.values[0] : '='
  const value = filter.values.length === 2 ? filter.values[1] : '0'

  const handleChangeOperator = useCallback(
    (newValue: SelectOption) => {
      const v = [newValue.value, value]
      filter.setValues(v)
    },
    [filter, value],
  )

  const handleChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = [opValue, e.currentTarget.value]
      filter.setValues(v)
    },
    [filter, opValue],
  )

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle
        nav
        caret
        className={filter.values.length ? 'hasValue' : ''}
      >
        <span>
          {filter.values.length ? `${label} ${opValue} ${value} ` : label}
        </span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={filter.clear}>
          <i className="mr-1 fa fa-ban" /> Clear
        </DropdownItem>
        <Form
          className="m-3"
          action=""
          method="post"
          style={{ minWidth: 300, maxWidth: 600 }}
        >
          <FormGroup>
            <Label htmlFor="input-filter-op" className="pr-1">
              Operator
            </Label>
            <Select<SelectOption>
              {...{ options }}
              styles={styles}
              isClearable
              escapeClearsValue
              value={options.find((o) => o.value === opValue)}
              onChange={handleChangeOperator}
            />
            <Label htmlFor="input-filter-value" className="pr-1">
              Value
            </Label>
            <Input
              name="value"
              onChange={handleChangeValue}
              value={value}
              type="text"
              id="input-filter-value"
              placeholder=""
              required
            />
          </FormGroup>
        </Form>
      </DropdownMenu>
    </Dropdown>
  )
}

export default NumericFilterButton
