import type React from 'react'
import { type DragEvent, useCallback, useMemo, useState } from 'react'

type DropZone = {
  isDragOver: boolean
  eventHandlers: {
    onDrop: (e: React.DragEvent<HTMLDivElement>) => Promise<void>
    onDragOver: (e: React.DragEvent<HTMLDivElement>) => void
    onDragLeave: (e) => void
  }
}

const useDropZone: (
  acceptType: string,
  onDrop: (files: File[]) => void,
) => DropZone = (acceptType: string, onDrop: (files: File[]) => void) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false)

  const handleDrop = useCallback(
    async (e: DragEvent<HTMLDivElement>) => {
      const { dataTransfer } = e

      e.preventDefault()
      setIsDragOver(false)

      const files = []

      if (dataTransfer && dataTransfer.items) {
        for (let i = 0; i < dataTransfer.items.length; i++) {
          const item = dataTransfer.items[i]

          if (item.type === acceptType && item.kind === 'file') {
            const file = item.getAsFile()

            files.push(file)
          }
        }
      }

      if (onDrop && files.length) {
        onDrop(files)
      }
    },
    [setIsDragOver, onDrop, acceptType],
  )

  const handleDragOver = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault()

      const { dataTransfer } = e

      if (dataTransfer && dataTransfer.items) {
        for (let i = 0; i < dataTransfer.items.length; i++) {
          const item = dataTransfer.items[i]

          if (item.type === acceptType && item.kind === 'file') {
            setIsDragOver(true)
            break
          }
        }
      }
    },
    [setIsDragOver, acceptType],
  )

  const handleDragLeave = useCallback(
    (e) => {
      e.preventDefault()
      setIsDragOver(false)
    },
    [setIsDragOver],
  )

  return useMemo(
    () => ({
      isDragOver,
      eventHandlers: {
        onDrop: handleDrop,
        onDragOver: handleDragOver,
        onDragLeave: handleDragLeave,
      },
    }),
    [isDragOver, handleDrop, handleDragOver, handleDragLeave],
  )
}

export default useDropZone
