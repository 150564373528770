import { apiRequest } from 'api'
import { PURCHASEORDERS_READWRITE } from 'caps'
import { PageNavHead, PageNavTail } from 'components'
import {
  Table,
  type Row as TableRow,
  setQuery,
  setRow,
  setSelectedRow,
  useTableApi,
  useTableWithUrlUpdater,
} from 'components/Table'
import { useBoolean, usePageTitle, usePanelControl } from 'hooks'
import { useCallback, useContext } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { getStorage } from 'utils/storage'
import AuthContext from './../../AuthContext'
import SupplierEditor from './SupplierEditor'
import columnDef from './columnDef'

const Alert = withReactContent(Swal)

const pageTitle = 'Manage Suppliers'

const [setItem, getItem] = getStorage('manageSuppliers')

const ManageSuppliers = () => {
  const { hasCap } = useContext(AuthContext)

  const readOnly = !hasCap(PURCHASEORDERS_READWRITE)

  const [state, dispatch] = useTableWithUrlUpdater({
    getItem,
    setItem,
    cacheKey: 'manageSuppliers',
  })
  const { query, isLoading, selectedRows, selectedRowId } = state
  const [triggerSearch] = useTableApi('suppliers_getAll', state, dispatch, {})

  const editPanel = usePanelControl({
    selectedRows,
    openForSingleRow: true,
  })

  const addPanel = useBoolean(false)

  usePageTitle(pageTitle)

  const handleChangeSearch = useCallback(
    (query: string) => {
      dispatch(setQuery(query))
    },
    [dispatch],
  )

  const handleCloseEditPanel = useCallback(
    (didChange: boolean) => {
      const reloadTable = didChange && addPanel.value

      addPanel.off()
      editPanel.close()

      if (reloadTable) {
        triggerSearch()
      }
    },
    [addPanel, editPanel, triggerSearch],
  )

  const handleClickAdd = useCallback(() => {
    addPanel.on()
    editPanel.open()
  }, [addPanel, editPanel])

  const handleClickDelete = useCallback(
    async (rowId: string, name: string) => {
      setSelectedRow(null)

      const { value } = await Alert.fire({
        icon: 'warning',
        text: `Are you sure you want to delete Supplier "${name}"?`,
        title: 'Delete Supplier',
        showCancelButton: true,
        cancelButtonColor: '#3085d6',
        confirmButtonColor: '#d33',
        confirmButtonText: 'Delete',
      })

      if (!value) {
        return
      }

      try {
        await apiRequest({
          action: 'suppliers_trashRow',
          id: rowId,
        })
        triggerSearch()
      } catch (e) {
        await Alert.fire({
          icon: 'error',
          text: e.message,
          title: 'Error deleting',
        })
      }
    },
    [triggerSearch],
  )

  const handleUpdateRow = useCallback(
    (rowId: string, row: TableRow) => {
      dispatch(setRow(rowId, row))
    },
    [dispatch],
  )

  return (
    <>
      <PageNavHead {...{ pageTitle, isLoading, onClickReload: triggerSearch }}>
        {!readOnly ? (
          <Button onClick={handleClickAdd}>Add Supplier</Button>
        ) : null}
      </PageNavHead>
      <PageNavTail {...{ query, handleChangeSearch, isLoading }} />
      <Container fluid className="mt-4">
        <div className="manage-orders animated fadeIn">
          <SupplierEditor
            {...{ readOnly }}
            editId={addPanel.value ? null : selectedRowId}
            isOpen={editPanel.isOpen}
            onUpdateRow={handleUpdateRow}
            onClickDelete={handleClickDelete}
            onClose={handleCloseEditPanel}
          />
          <Row>
            <Col>
              <Table
                entityName="suppliers"
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef,
                  dispatch,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default ManageSuppliers
