import EditPanelCard from 'components/EditPanelCard'
import Loading from 'components/Loading'
import useApi from 'hooks/useApi'
import { formatPrice, numberWithCommas } from 'utils/price'
import type { ReportPurchaseCostYearlyAverageResponse } from './types'

export const PurchaseYearlyAverageCostTable = ({
  productId,
  withLegend,
  onSuccess,
}: {
  productId: number
  withLegend?: boolean
  onSuccess?: () => void
}) => {
  const apiGet = useApi<ReportPurchaseCostYearlyAverageResponse>(
    () => ({
      action: 'inventory_getReportPurchaseCostYearlyAverage',
      id: productId,
    }),
    null,
    () => ({ autoPerform: true, cache: true, onSuccess }),
  )

  const entries = apiGet.result?.rows || []

  if (apiGet.isLoading && !apiGet.hasResult) {
    return <Loading />
  }

  return entries.length === 0 ? (
    <div>
      <i>No data found.</i>
    </div>
  ) : (
    <>
      <table
        className="table table-striped table-condenced2"
        style={{ fontSize: 'smaller' }}
      >
        <thead>
          <tr>
            <th>Year</th>
            <th>PO Count</th>
            <th>Avg. Cost</th>
            <th>Total Qty</th>
            <th>Total Cost</th>
          </tr>
        </thead>
        <tbody>
          {entries.map((row) => {
            return (
              <tr key={row.year}>
                <td>{row.year}</td>
                <td>{numberWithCommas(String(row.po_count))}</td>
                <td>{formatPrice(row.avg_cost * 100)}</td>
                <td>{numberWithCommas(String(row.total_qty))}</td>
                <td>{formatPrice(row.total_cost * 100)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {withLegend ? (
        <small>
          <i>Includes data starting {apiGet.result.start_date}</i>
        </small>
      ) : null}
    </>
  )
}

const PurchaseYearlyAverageCost = ({ productId }: { productId: number }) => {
  return (
    <EditPanelCard
      bodyProps={{ className: 'p-2' }}
      caption="Yearly average cost"
      stateId="product-yearly-average-cost"
    >
      <PurchaseYearlyAverageCostTable withLegend productId={productId} />
    </EditPanelCard>
  )
}

export default PurchaseYearlyAverageCost
