import { DropDownItemCheckbox } from 'components'
import { useDropDownToggler } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'

const items = [
  { id: 'today', label: 'Today' },
  { id: 'yesterday', label: 'Yesterday' },
  { id: 'last7', label: 'Last 7 days' },
  { id: 'last30', label: 'Last 30 days' },
  { id: 'last12month', label: 'Last 12 months' },
  { id: 'this-month', label: 'This month' },
  { id: 'last-month', label: 'Last month' },
  { id: 'this-year', label: 'This year' },
  { id: 'last-year', label: 'Last year' },
]

type Props = {
  filter: FilterType
  label: string
  labelPrefix?: string
  defaultValue?: string
  clearButtonName?: string
  minDate?: string
}

const DateRangeFilterButton = ({
  filter,
  label,
  labelPrefix,
  defaultValue,
  clearButtonName,
  minDate,
}: Props) => {
  const toggler = useDropDownToggler()

  const handleClick = useCallback(
    (id: string) => {
      filter.setValues([id])
    },
    [filter],
  )

  const handleClickClear = useCallback(() => {
    filter.clear()
  }, [filter])

  const value = filter.values.length ? filter.values[0] : null
  const fromDate = filter.values.length >= 2 ? filter.values[1] : null
  const toDate = filter.values.length >= 3 ? filter.values[2] : null

  const handleClickCustom = useCallback(() => {
    if (value != null) {
      filter.clear()
    } else {
      filter.setValues(['custom'])
    }
  }, [value, filter])

  const handleChangeDateFrom = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      filter.setValues(['custom', e.currentTarget.value, toDate])
    },
    [filter, toDate],
  )

  const handleChangeDateTo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      filter.setValues(['custom', fromDate, e.currentTarget.value])
    },
    [filter, fromDate],
  )

  const selectedItems = items.filter((item) => item.id === value)
  const selectedItem = selectedItems.length ? selectedItems[0] : null

  const _label =
    value === 'custom' ? 'Custom' : selectedItem ? selectedItem.label : label

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret className={value != null ? 'hasValue' : ''}>
        {labelPrefix}
        {_label}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickClear}>
          <i className="mr-1 fa fa-ban" /> {clearButtonName || 'Clear'}
        </DropdownItem>
        <DropDownItemCheckbox
          autoclose={false}
          {...{
            id: 'custom',
            onClick: handleClickCustom,
            isChecked: value === 'custom',
          }}
        >
          Custom Range
        </DropDownItemCheckbox>
        {value === 'custom' ? (
          <Form className="m-3" action="" method="post">
            <FormGroup>
              <Label htmlFor="input-date-from" className="pr-1">
                From
              </Label>
              <Input
                name="from"
                onChange={handleChangeDateFrom}
                value={fromDate || ''}
                min={minDate}
                type="date"
                id="input-date-from"
                placeholder=""
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="input-date-to" className="pr-1">
                To
              </Label>
              <Input
                name="to"
                onChange={handleChangeDateTo}
                value={toDate || ''}
                type="date"
                id="input-date-to"
                placeholder=""
                required
              />
            </FormGroup>
          </Form>
        ) : null}
        {value !== 'custom'
          ? items.map((item) => {
              const isChecked =
                value === item.id || (!value && item.id === defaultValue)

              return (
                <DropDownItemCheckbox
                  autoclose
                  key={item.id}
                  id={item.id}
                  onClick={handleClick}
                  {...{ isChecked }}
                >
                  {item.label}
                </DropDownItemCheckbox>
              )
            })
          : null}
      </DropdownMenu>
    </Dropdown>
  )
}

export default DateRangeFilterButton
