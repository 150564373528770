import find from 'lodash/find'
import { useCallback, useEffect, useRef } from 'react'
import { Table as RSTable } from 'reactstrap'
import { clearSelection } from 'utils/ui'
import TableHeader from './TableHeader'
import TablePagination from './TablePagination'
import TableRow, { MemoTableRow } from './TableRow'
import TableRowDropTarget from './TableRowDropTarget'
import {
  dragDropMoveItem,
  selectRowRange,
  setEditCell,
  setRowOrder,
  setSelectedRow,
  setSelectedRows,
  setSortAsc,
  setSortColumn,
  toggleSelectedRow,
} from './reducer/actions'
import type { BaseRow, Column, Row } from './reducer/types'
import type { ColumnDef, TableProps } from './types'
import useTableColumns from './useTableColumns'

const findNextColumn = (
  currentColId: string | boolean,
  columnDef: ColumnDef[],
  backwards: boolean,
) => {
  let found = false
  if (currentColId === true) {
    found = true
  }
  const columns = backwards ? columnDef.slice().reverse() : columnDef
  for (const col of columns) {
    if (found && col.isEditable) {
      return col.id
    }
    if (col.id === currentColId && !found) {
      found = true
    }
  }

  return false
}

const findNextRow = <RowType extends BaseRow>(
  currentRowId: string,
  _rows: RowType[],
  backwards: boolean,
) => {
  let found = false
  const rows = backwards ? _rows.slice().reverse() : _rows
  for (const row of rows) {
    if (found) {
      return row.id
    }
    if (row.id === currentRowId && !found) {
      found = true
    }
  }

  return false
}

/**
 * `Table` Component
 *
 * A flexible and interactive table component for displaying, editing, and sorting data within an administrative dashboard or similar applications.
 *
 * Features:
 * - **Editing**: Allows inline editing of cells with support for custom input components.
 *   - Tracks the editing state with `editCell`.
 *   - Uses `onChangeCell` to handle updates to cell values.
 *   - `EditorProps` and `CellEditableProps` define properties for editable cells.
 *
 * - **Sorting**: Enables sorting of table data by column headers.
 *   - State properties `sortColumn` and `sortAsc` track current sort column and order.
 *   - `handleClickSort` updates sorting state based on user interaction.
 *   - Automatically determines default sort direction based on data type.
 *
 * - **Row Definition**: Manages data rows with unique identifiers for operations.
 *   - Rows are defined by the `rows` prop, each extending `BaseRow`.
 *   - Renders rows using `TableRow` or `TableRowDropTarget` based on drag-and-drop needs.
 *
 * - **Column Definitions**: Configures columns with a variety of display and behavior options.
 *   - `columnDef` prop accepts an array of `ColumnDef` objects for column configuration.
 *   - Supports custom renderers and formatting options for cell content.
 *
 * - **Selection**: Facilitates row selection for batch operations.
 *   - `selectedRows` state keeps track of selected row identifiers.
 *   - Actions like `toggleSelectedRow` and `setSelectedRows` manage selection.
 *
 * - **Pagination**: Optionally includes pagination controls for large datasets.
 *   - Conditionally renders `TablePagination` based on `hasPagination` prop.
 *
 * - **Drag-and-Drop**: Offers row reordering through drag-and-drop interactions.
 *   - Enabled with `hasDragDrop` prop and managed by related callbacks and actions.
 *
 * - **Custom Rendering**: Allows for custom cell and row rendering.
 *   - `renderCell` and `renderRowClassName` callbacks for dynamic content and styles.
 *
 * - **Error Handling**: Provides user feedback for loading states and errors.
 *   - Displays messages or indicators for loading, errors, or empty search results.
 */
const Table = <RowType extends BaseRow = Row>(props: TableProps<RowType>) => {
  const {
    isLoading,
    query,
    error,
    rows,
    sortColumn,
    sortAsc,
    columnDef,
    dispatch,
    editCell,
    cellsLoading,
    onChangeCell,
    onClickCell,
    onClickRow,
    renderCell,
    renderRowClassName,
    renderHeaderLabel,
    hasPagination,
    border,
    selectedRows,
    children,
    hasDragDrop,
    didDrop,
    beginDrag,
    allRowsSelected,
    useMemoTableRow,
  } = props

  const columns = useTableColumns(null, props, dispatch, columnDef)

  /* TAB key navigation for editable cells */
  const handlePressTab = useCallback(
    (e: CustomEvent) => {
      const { rowId, colId, shiftKey } = e.detail

      e.preventDefault()

      const backwards = shiftKey
      const nextCol = findNextColumn(colId, columns.visibleSorted, backwards)

      if (nextCol) {
        dispatch(setEditCell(rowId, nextCol))
      } else {
        const nextRow = findNextRow(rowId, rows, backwards)

        if (nextRow) {
          const nextCol2 = findNextColumn(
            true,
            columns.visibleSorted,
            backwards,
          )
          dispatch(setEditCell(nextRow, nextCol2))
        }
      }
    },
    [columns.visibleSorted, dispatch, rows],
  )

  useEffect(() => {
    document.addEventListener('tablePressTab', handlePressTab)

    return () => {
      document.removeEventListener('tablePressTab', handlePressTab)
    }
  }, [editCell, handlePressTab])

  const cachedRowOrder = useRef<string[]>()
  const handleBeginDrag = useCallback(() => {
    cachedRowOrder.current = rows.map((row) => row.id)

    if (beginDrag != null) {
      beginDrag()
    }
  }, [rows, beginDrag])

  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      dispatch(dragDropMoveItem(dragIndex, hoverIndex))
    },
    [dispatch],
  )

  const didCancelDrop = useCallback(() => {
    if (cachedRowOrder.current != null) {
      dispatch(setRowOrder(cachedRowOrder.current))
    }
  }, [dispatch])

  const handleClickSort = useCallback(
    (column: string) => {
      /* Sort numeric columns ascending by default, otherwise descending. */
      const col = find(columnDef, { id: column })
      const defaultSortAsc = col ? col.isNumeric || false : false

      if (sortAsc === defaultSortAsc && column === sortColumn) {
        dispatch(setSortColumn(null))
      } else if (column === sortColumn) {
        const asc = !sortAsc
        dispatch(setSortAsc(asc))
      } else {
        dispatch(setSortColumn(column))
        dispatch(setSortAsc(!defaultSortAsc))
      }
    },
    [dispatch, columnDef, sortAsc, sortColumn],
  )

  const handleClickRow = useCallback(
    (rowId: string, colId: string, e: React.MouseEvent<HTMLElement>) => {
      if (onClickRow) {
        const handled = onClickRow(rowId, e)
        if (handled) {
          return
        }
      }

      if (onClickCell && colId) {
        const handled = onClickCell(rowId, colId, e)
        if (handled) {
          return
        }
      }

      const target = e.target as HTMLElement

      if (
        target &&
        (target.closest('.checkbox') || target.matches('.checkbox'))
      ) {
        dispatch(toggleSelectedRow(rowId))
        return
      }

      const { shiftKey, metaKey, ctrlKey } = e

      if (shiftKey) {
        clearSelection()
        dispatch(selectRowRange(rowId))
      } else if (ctrlKey || metaKey) {
        clearSelection()
        dispatch(toggleSelectedRow(rowId))
      } else if (selectedRows.length === 1 && selectedRows[0] === rowId) {
        dispatch(toggleSelectedRow(rowId))
      } else {
        dispatch(setSelectedRow(rowId))
      }
    },
    [dispatch, onClickRow, onClickCell, selectedRows],
  )

  const handleClickTable = useCallback(
    (e: React.MouseEvent<HTMLTableElement>) => {
      if (!e.target) {
        return
      }

      const target = e.target as HTMLElement
      const td = target.closest('td')
      const tr = target.closest('tr')

      if (!td || !tr) {
        return
      }

      const rowId = tr.getAttribute('data-id')
      const colId = td.getAttribute('data-col-id')

      const row = find(rows, { id: rowId })
      if (!row) {
        return
      }

      handleClickRow(rowId, colId, e)
    },
    [handleClickRow, rows],
  )

  const handleClickToggleAll = useCallback(() => {
    if (allRowsSelected) {
      dispatch(setSelectedRows([]))
    } else {
      dispatch(setSelectedRows(rows.map((row) => row.id)))
    }
  }, [dispatch, rows, allRowsSelected])

  const hasBorder = border === true || border == null

  const TableRowEl = hasDragDrop
    ? TableRowDropTarget
    : useMemoTableRow === false
      ? TableRow
      : MemoTableRow

  return (
    <>
      <RSTable
        onClick={handleClickTable}
        hover
        responsive
        style={{ border: hasBorder ? null : 'none' }}
        className={`mb-0 d-sm-table table-outline`}
      >
        <colgroup>
          {columns.visibleSorted.map((column: Column) => {
            const color = columns.colors[column.id]
            return (
              <col
                key={column.id}
                style={color ? { backgroundColor: color } : null}
              />
            )
          })}
        </colgroup>
        <thead className="thead-light">
          <TableHeader
            onClickSort={handleClickSort}
            {...{
              handleClickToggleAll,
              renderHeaderLabel,
              sortColumn,
              sortAsc,
              columnDef: columns.visibleSorted,
              allRowsSelected,
            }}
          />
        </thead>
        <tbody>
          {!isLoading && error != null ? (
            <tr>
              <td
                colSpan={columns.visibleSorted.length}
                className="text-danger"
              >
                {error}
              </td>
            </tr>
          ) : null}
          {!isLoading && error == null && query !== '' && rows.length === 0 ? (
            <tr>
              <td colSpan={columns.visibleSorted.length}>
                Your search query did not return any results.
              </td>
            </tr>
          ) : null}
          {rows.map((row, index) => (
            <TableRowEl
              key={row.id}
              {...{
                dispatch,
                onChangeCell,
                columnDef: columns.visibleSorted,
                row,
                editCell:
                  editCell && editCell.rowId === row.id ? editCell : null,
                cellsLoading: Object.hasOwn(cellsLoading, row.id)
                  ? cellsLoading[row.id]
                  : null,
                renderCell,
                renderRowClassName,
                isSelectedRow: selectedRows.includes(row.id),
                moveItem,
                beginDrag: hasDragDrop ? handleBeginDrag : null,
                index,
                id: row.id,
                didDrop: hasDragDrop ? didDrop : null,
                didCancelDrop: hasDragDrop ? didCancelDrop : null,
              }}
            />
          ))}
        </tbody>
        {children}
      </RSTable>
      {hasPagination !== false ? <TablePagination {...props} /> : null}
    </>
  )
}

export default Table
