import { EditPanelCard } from 'components'
import type { ApiCall } from 'hooks'
import { Col, Row } from 'reactstrap'
import { formatPrice } from 'utils/price'
import InvoiceLineItem from './InvoiceLineItem'

type InvoiceCardProps = {
  getOrder: ApiCall
}

const InvoiceCard = (props: InvoiceCardProps) => {
  const { getOrder } = props
  const { result } = getOrder

  return (
    <EditPanelCard
      caption="Items"
      stateId="orders_invoice"
      bodyProps={{ className: 'p-0' }}
    >
      {result.line_items.map((item) => (
        <InvoiceLineItem key={item.id} item={item} />
      ))}
      <div className="pt-3 pb-3">
        {result.coupon_lines
          .filter((c) => !c.isGiftcard)
          .map((coupon, i) => (
            <Row key={i} className="p-0 pr-3 pl-3 pb-2">
              <Col sm={3}>Coupon</Col>
              <Col sm={9} className="text-right">
                {coupon.code}
              </Col>
            </Row>
          ))}
        {result.total_discount ? (
          <Row className="p-0 pr-3 pl-3 pb-2">
            <Col sm={3}>Discount</Col>
            <Col sm={9} className="text-right">
              ${formatPrice(result.total_discount)}
            </Col>
          </Row>
        ) : null}
        {result.shipping_lines.map((shipping, i) => (
          <Row key={i} className="p-0 pr-3 pl-3 pb-2">
            <Col sm={9}>Shipping: {shipping.method_title}</Col>
            {shipping.total ? (
              <Col sm={3} className="text-right">
                ${formatPrice(shipping.total)}
              </Col>
            ) : (
              <Col sm={3} className="text-right">
                FREE
              </Col>
            )}
          </Row>
        ))}
        {result.tax_lines.map((tax, i) => (
          <Row key={i} className="p-0 pr-3 pl-3 pb-2">
            <Col sm={9}>{tax.title}</Col>
            <Col sm={3} className="text-right">
              ${formatPrice(tax.total)}
            </Col>
          </Row>
        ))}
        {result.fee_lines.map((tax, i) => (
          <Row key={i} className="p-0 pr-3 pl-3 pb-2">
            <Col sm={9}>{tax.title}</Col>
            <Col sm={3} className="text-right">
              ${formatPrice(tax.total)}
            </Col>
          </Row>
        ))}
        <Row className="p-0 pr-3 pl-3 pb-2">
          <Col className="font-weight-bold" sm={9}>
            Total
          </Col>
          <Col className="font-weight-bold text-right" sm={3}>
            ${formatPrice(result.orderTotal)}
          </Col>
        </Row>
        {result.refundAmount && result.refundAmount !== result.orderTotal ? (
          <Row className="p-0 pr-3 pl-3 pb-2">
            <Col className="font-weight-bold" sm={9}>
              Total after refunds
            </Col>
            <Col className="font-weight-bold text-right" sm={3}>
              ${formatPrice(result.refundAmount)}
            </Col>
          </Row>
        ) : null}
      </div>
      {result.refunds.length ? (
        <div className="pt-3 pb-3">
          <Row className="p-0 pr-3 pl-3 pb-2">
            <Col className="font-weight-bold">Partial refunds</Col>
          </Row>
          {result.refunds.map((refund, i) => (
            <Row key={i} className="p-0 pr-3 pl-3 pb-2">
              <Col sm={9}>{refund.date}</Col>
              <Col sm={3} className="text-right">
                ${formatPrice(refund.amount)}
              </Col>
            </Row>
          ))}
        </div>
      ) : null}
    </EditPanelCard>
  )
}

export default InvoiceCard
