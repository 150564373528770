import { useTable, useTableApi, useTableColumns } from 'components/Table'
import columnDef from 'containers/ManageOrders/columnDef'
import { useMemo } from 'react'
import { getStorage } from 'utils'

const storageKey = 'editCustomer.ordersTable'
const [setItem, getItem] = getStorage(storageKey)

const visibleColumns = [
  'id',
  'checkbox',
  'age',
  'createdAt',
  'orderNumberNumeric',
  'status',
  'return_status',
  'paymentMethod',
  'total',
  'numItems',
  'itemName',
  'scheduledFor',
]
const filteredColumnDef = columnDef.filter((col) =>
  visibleColumns.includes(col.id),
)

export const useOrdersTable = (id: string) => {
  const [state, dispatch] = useTable({
    cacheKey: `${storageKey}.${id}`,
    getItem,
    setItem,
  })
  const [triggerSearch] = useTableApi('orders_getAll', state, dispatch, {
    filterCustomers: id,
    offset: 0,
    limit: 1000,
  })

  const columns = useTableColumns(
    'manageCustomerOrders',
    state,
    dispatch,
    filteredColumnDef,
  )

  const isEmpty = state.rows.length === 0 && state.didLoad

  return useMemo(
    () => ({
      ...state,
      setItem,
      getItem,
      columnDef: filteredColumnDef,
      dispatch,
      triggerSearch,
      isEmpty,
      columns,
    }),
    [state, dispatch, triggerSearch, isEmpty, columns],
  )
}
