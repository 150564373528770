import { useCallback } from 'react'
import { CustomInput } from 'reactstrap'
import AddressForm from './AddressForm'
import { setValue } from './useCart'
import type { Action, State } from './useCart/types'

type AddressFormProps = {
  dispatch: React.Dispatch<Action>
} & Partial<State>

const AddressesForm = (props: AddressFormProps) => {
  const { dispatch } = props

  const handleChange = useCallback(
    (name, value) => {
      dispatch(setValue(name, value))
    },
    [dispatch],
  )

  const handleChangeInput = useCallback(
    (e) => {
      const { type, name, value, checked } = e.currentTarget

      dispatch(setValue(name, type === 'checkbox' ? checked : value))
    },
    [dispatch],
  )

  return (
    <>
      <AddressForm
        title="Shipping Address"
        prefix="shipping"
        hasEmail
        hasPhone
        {...props}
        onChange={handleChange}
      />

      <CustomInput
        name="isBillingSameAsShipping"
        className="checkbox input-billing-same-as-shipping pt-3"
        bsSize="lg"
        id="input-billing-same-as-shipping"
        checked={props.isBillingSameAsShipping}
        onChange={handleChangeInput}
        type="checkbox"
        label="Billing Address is same as Shipping Address"
      />

      {!props.isBillingSameAsShipping ? (
        <AddressForm
          title="Billing Address"
          prefix="billing"
          {...props}
          onChange={handleChange}
        />
      ) : null}
    </>
  )
}

export default AddressesForm
