import type { Subscription } from '@ttc/api/subscriptions'
import SubscriptionStatusBadge from 'components/Badges/SubscriptionStatusBadge'
import type { ColumnDef } from 'components/Table/types'
import { formatDateReadable } from 'utils/date'

const columnDef: ColumnDef[] = [
  {
    label: '',
    id: 'checkbox',
  },
  {
    label: 'ID',
    id: 'id',
  },
  {
    label: 'Order',
    id: 'order_number',
    hasClipboardButton: true,
  },
  {
    label: 'Plan',
    id: 'plan_description',
  },
  {
    label: 'Price',
    id: 'price_description',
  },
  {
    label: 'Status',
    id: 'status',
    renderer: ({ row }) => (
      <td>
        <SubscriptionStatusBadge subscription={row as Subscription} />
      </td>
    ),
  },
  {
    label: 'Stripe Status',
    id: 'stripe_status',
  },
  {
    label: 'Created at',
    id: 'created_at',
    sortColumn: true,
    renderer: ({ value }) => {
      return <td>{value ? formatDateReadable(String(value), true) : ''}</td>
    },
  },
]

export default columnDef
