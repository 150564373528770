import {
  DRAFTPURCHASEORDERS_READ,
  EDITORDERS_READWRITE,
  MANAGEBATCHES_READ,
  MANAGECONTENT_READ,
  MANAGECUSTOMERS_READ,
  MANAGEDELIVERIES_READ,
  MANAGEDISCOUNTS_READ,
  MANAGEGIFTCARDS_READ,
  MANAGEINVENTORYPURCHASING_READ,
  MANAGEINVENTORY_READ,
  MANAGEORDERS_READ,
  MANAGEORDERS_READWRITE,
  MANAGEORDERS_STATUS_FILTER_TABS_READWRITE,
  MANAGERETURNS_READ,
  MANAGESHIPMENTS_READ,
  MANAGESTORECREDIT_READ,
  MANAGESUBSCRIPTIONPLANS_READ,
  MANAGEZONES_READ,
  PURCHASEORDERS_READ,
  SHIPNOW_READWRITE,
  SHIPPINGEMPLOYEES_READ,
  SHIPPINGREPORT_READ,
} from 'caps'

export type NavigationItem = {
  name: string
  wrapper?: any
  title?: boolean
  class?: string
  requiredCap?: string | Array<string>
  url?: string
  icon?: string
  children?: Array<NavigationItem>
  badgeKey?: string
  badgeVariant?: string
}

type Navigation = {
  items: Array<NavigationItem>
}

const nav: Navigation = {
  items: [
    {
      title: true,
      name: 'Orders',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
      requiredCap: [MANAGEORDERS_READWRITE, EDITORDERS_READWRITE],
    },
    {
      name: 'Manage Orders',
      url: '/orders/manage',
      icon: 'fa fa-list',
      requiredCap: [MANAGEORDERS_READ, MANAGEBATCHES_READ],
      children: [
        {
          name: 'All orders',
          url: '/orders/manage/all',
          requiredCap: [MANAGEORDERS_READ],
        },
        {
          name: 'Scheduled',
          url: '/orders/manage/scheduled',
          badgeKey: 'scheduled',
          requiredCap: [MANAGEORDERS_STATUS_FILTER_TABS_READWRITE],
        },
        {
          name: 'Processing',
          url: '/orders/manage/processing',
          badgeKey: 'processing',
          requiredCap: [MANAGEORDERS_STATUS_FILTER_TABS_READWRITE],
        },
        {
          name: 'Completed',
          url: '/orders/manage/completed',
          requiredCap: [MANAGEORDERS_STATUS_FILTER_TABS_READWRITE],
        },
        {
          name: 'Batches',
          url: '/orders/manage/batches',
          badgeKey: 'batches',
          badgeVariant: 'success',
          requiredCap: [MANAGEBATCHES_READ],
        },
      ],
    },
    {
      name: 'Add Order',
      url: '/orders/add',
      icon: 'fa fa-shopping-cart',
      requiredCap: EDITORDERS_READWRITE,
    },
    {
      name: 'Edit Order',
      url: '/orders/edit/',
      icon: 'fa fa-edit',
      requiredCap: EDITORDERS_READWRITE,
    },
    {
      name: 'Customers',
      url: '/customers/',
      icon: 'fa fa-user',
      requiredCap: MANAGECUSTOMERS_READ,
    },
    {
      name: 'Manage Returns',
      url: '/returns/',
      icon: 'fa fa-exchange',
      requiredCap: MANAGERETURNS_READ,
    },
    {
      name: 'Manage Store Credits',
      url: '/store-credit/',
      icon: 'fa fa-credit-card',
      requiredCap: MANAGESTORECREDIT_READ,
    },
    {
      name: 'Manage Gift Cards',
      url: '/gift-cards/',
      icon: 'fa fa-gift',
      requiredCap: MANAGEGIFTCARDS_READ,
    },
    {
      title: true,
      name: 'Subscriptions',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
      requiredCap: [MANAGESUBSCRIPTIONPLANS_READ],
    },
    {
      name: 'Manage Plans',
      url: '/subscription-plans',
      icon: 'fa fa-cubes',
      requiredCap: MANAGESUBSCRIPTIONPLANS_READ,
    },
    {
      title: true,
      name: 'Content',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
      requiredCap: MANAGECONTENT_READ,
    },
    {
      name: 'Manage Content',
      url: '/content',
      icon: 'fa fa-list',
      requiredCap: MANAGECONTENT_READ,
    },
    {
      title: true,
      name: 'Inventory',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
      requiredCap: MANAGEINVENTORY_READ,
    },
    {
      name: 'Manage Inventory',
      url: '/inventory',
      icon: 'fa fa-list',
      requiredCap: MANAGEINVENTORY_READ,
    },
    {
      name: 'Purchase Orders',
      url: '/purchase-orders',
      icon: 'fa fa-list',
      requiredCap: PURCHASEORDERS_READ,
    },
    {
      name: 'Deliveries',
      url: '/deliveries',
      icon: 'fa fa-shopping-bag',
      requiredCap: MANAGEDELIVERIES_READ,
    },
    {
      name: 'Suppliers',
      url: '/suppliers',
      icon: 'fa fa-truck',
      requiredCap: PURCHASEORDERS_READ,
    },
    {
      name: 'Discounts',
      url: '/discounts',
      icon: 'fa fa-percent',
      requiredCap: MANAGEDISCOUNTS_READ,
    },
    {
      title: true,
      name: 'Purchasing',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
      requiredCap: MANAGEINVENTORY_READ,
    },
    {
      name: 'Inventory Purchasing',
      url: '/inventory-purchasing',
      icon: 'fa fa-list',
      requiredCap: [MANAGEINVENTORYPURCHASING_READ, DRAFTPURCHASEORDERS_READ],
    },
    {
      name: 'Draft Purchase Orders',
      url: '/draft-purchase-orders',
      icon: 'fa fa-list',
      requiredCap: DRAFTPURCHASEORDERS_READ,
    },
    {
      name: 'Suppliers',
      url: '/suppliers',
      icon: 'fa fa-truck',
      requiredCap: DRAFTPURCHASEORDERS_READ,
    },
    {
      title: true,
      name: 'Shipping',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
      requiredCap: [SHIPPINGEMPLOYEES_READ, SHIPPINGREPORT_READ],
    },
    {
      name: 'Ship Now',
      url: '/shipnow',
      icon: 'fa fa-truck',
      requiredCap: SHIPNOW_READWRITE,
    },
    {
      name: 'Manage Shipments',
      url: '/shipments',
      icon: 'fa fa-list',
      requiredCap: MANAGESHIPMENTS_READ,
    },
    {
      name: 'Manage Zones',
      url: '/zones',
      icon: 'fa fa-compass',
      requiredCap: MANAGEZONES_READ,
    },
    {
      name: 'Employees',
      icon: 'fa fa-user',
      url: '/shipping/employees',
      children: [
        {
          name: 'Shipping',
          url: '/shipping/employees',
          requiredCap: SHIPPINGEMPLOYEES_READ,
        },
        {
          name: 'Scanapp',
          url: '/shipping/employees/scanapp',
          requiredCap: SHIPPINGEMPLOYEES_READ,
        },
      ],
      requiredCap: [SHIPPINGEMPLOYEES_READ],
    },
    {
      name: 'Report',
      url: '/shipping/report2',
      icon: 'fa fa-bar-chart',
      requiredCap: SHIPPINGREPORT_READ,
    },
    {
      name: 'Tools',
      icon: 'fa fa-circle',
      url: '/shipping/tools',
      children: [
        {
          name: 'Barcodes',
          url: '/shipping/tools/barcodes',
          icon: 'fa fa-barcode',
        },
      ],
      requiredCap: [SHIPPINGEMPLOYEES_READ, SHIPPINGREPORT_READ],
    },
    //{
    //  title: true,
    //  name: 'DEV',
    //  wrapper: {
    //    element: '',
    //    attributes: {},
    //  },
    //  class: '',
    //},
    //{
    //  name: 'Playground',
    //  url: '/playground',
    //  icon: 'fa fa-play',
    //},
  ],
}

export default nav
