export const numberWithCommas = (x: string): string => {
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatPrice = (cents: number): string => {
  return numberWithCommas(String((cents / 100).toFixed(2)))
}

export function toCents(amount: number): number {
  return Number(Number(amount * 100).toFixed(0))
}
