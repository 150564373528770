import type { FormDef, FormDefElement } from 'app/components/Form/types'
import {
  EditPanel,
  EditPanelCard,
  FormRenderer,
  SelectCustomer,
} from 'components'
import {
  type ApiCall,
  getFormInitState,
  useApi,
  useEffectIfObjectChanges,
  useFormWithDef,
  usePrevious,
} from 'hooks'
import moment from 'moment'
import { useCallback, useEffect, useMemo } from 'react'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'

const CardActions = ({ handleClickDelete }) => {
  return (
    <EditPanelCard caption="Actions" defaultIsOpen={false}>
      <Row>
        <Col>
          <FormGroup className="form-actions mt-3 text-center">
            <Button
              name="delete"
              size="lg"
              type="button"
              color="danger"
              onClick={handleClickDelete}
            >
              Delete Return
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </EditPanelCard>
  )
}

const formDefTemplate: FormDef = [
  {
    label: 'Order Number',
    name: 'order_number',
    type: 'text',
  },
  {
    label: 'Customer',
    name: 'customer',
    type: 'custom',
    render: (form: any) => {
      return (
        <Col>
          <FormGroup className="mb-0">
            <Label className="mb-0">Customer</Label>
            <SelectCustomer
              readOnly
              className="mb-3"
              value={form.state.customer}
              onSelect={(value) => form.set('customer', value)}
            />
          </FormGroup>
        </Col>
      )
    },
  },
  {
    label: 'Return Reason',
    name: 'return_reason',
    type: 'select',
    options: [],
    defaultValue: 'UNSPECIFIED',
  },
  {
    label: 'Return Reason Note',
    name: 'return_reason_note',
    type: 'text',
    defaultValue: '',
  },
  {
    label: 'Status',
    name: 'status',
    type: 'select',
    options: [],
    defaultValue: 'NEW',
  },
  {
    label: 'Return date',
    name: 'return_date',
    type: 'date',
    defaultValue: moment().format('YYYY-MM-DD'),
  },
  {
    label: 'Items received date',
    name: 'items_received_date',
    type: 'date',
    defaultValue: '',
  },
]

const EditReturnPanel = ({
  isOpen,
  onClose,
  editId,
  getStatuses,
  onClickDelete,
  defaultValues,
}: {
  isOpen: boolean
  onClose: (id?: string, isNew?: boolean) => void
  editId: string
  getStatuses: ApiCall
  onClickDelete?: (id: string) => void
  defaultValues?: any
}) => {
  const formDef = useMemo(() => {
    let formDef = [...formDefTemplate]

    formDef = formDef.map((def: FormDefElement) => {
      if (def.name === 'return_reason') {
        let options = getStatuses.result ? getStatuses.result.return_reason : []
        options = options.map((value: string) => ({ label: value, value }))
        return { ...def, options }
      }

      if (def.name === 'status') {
        let options = getStatuses.result ? getStatuses.result.status : []
        options = options.map((value: string) => ({ label: value, value }))
        return { ...def, options }
      }

      return def
    })

    return formDef
  }, [getStatuses])

  const form = useFormWithDef(formDef)

  const isNew = editId == null

  const apiAdd = useApi({ action: 'returns_save' })

  const apiGetReturn = useApi({ action: 'returns_get', id: editId }, null, {
    cache: true,
  })

  // Reset form as soon as panel opens.
  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (prevIsOpen !== isOpen) {
      if (isOpen) {
        if (editId != null) {
          apiGetReturn.performRequest()
        } else {
          const initState = getFormInitState(formDef)
          form.reset({ ...initState, ...defaultValues })
        }
      } else {
        form.reset()
        apiGetReturn.reset()
      }
    }
  }, [form, prevIsOpen, isOpen, editId, apiGetReturn, formDef, defaultValues])

  // Fill form with fetched data.
  useEffectIfObjectChanges(
    useCallback(() => {
      if (apiGetReturn.result != null) {
        let customer = apiGetReturn.result ? apiGetReturn.result.customer : null

        customer = customer
          ? {
              label: `${customer.fullName} - (${customer.id} ${customer.email})`,
              value: customer.id,
            }
          : null

        const state = {
          ...apiGetReturn.result,
          customer,
        }

        form.setState(state)
      }
    }, [apiGetReturn.result, form]),
    apiGetReturn.result,
    true,
  )

  const handleSubmit = useCallback(async () => {
    const formSubmit = async () => {
      const json = { ...form.state }
      json.customer_id = json.customer ? json.customer.value : null
      delete json.customer

      const ret = await apiAdd.performRequest({ json })

      if (ret) {
        onClose(ret.id, isNew)
      }
    }

    formSubmit()
  }, [apiAdd, form.state, onClose, isNew])

  const isLoading = apiAdd.isLoading

  const handleClickDelete = useCallback(() => {
    if (onClickDelete) {
      onClickDelete(editId)
    }
  }, [onClickDelete, editId])

  return (
    <EditPanel
      onSubmit={handleSubmit}
      caption={isNew ? 'Add Return' : 'Edit Return'}
      submitButtonLabel={isNew ? 'Create Return' : 'Submit'}
      {...{ isOpen, onClose, isLoading }}
    >
      {editId != null && onClickDelete ? (
        <CardActions {...{ handleClickDelete }} />
      ) : null}
      <EditPanelCard caption="Return details">
        {apiAdd.error ? (
          <Row>
            <Col className="text-danger">{apiAdd.error}</Col>
          </Row>
        ) : null}
        <FormRenderer
          key={`${editId}-${isOpen ? 1 : 0}`}
          {...{ form, formDef }}
        />
      </EditPanelCard>
    </EditPanel>
  )
}

export default EditReturnPanel
