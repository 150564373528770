import { getWarrantyPercent } from 'utils/settings'
import { calculateTotals } from './totals'
import type { ImportEditOrderAction, State } from './types'

const calcWarrantyPercent = (cartItems: any[], warranty: number): number => {
  const itemsSubtotal = cartItems
    .filter((i) => i.hasWarranty)
    .reduce((a, p) => a + p.price * p.quantity, 0)

  const warrantyPercent = Math.round(
    itemsSubtotal > 0 ? (warranty / itemsSubtotal) * 100 : 0,
  )

  return Number(warrantyPercent)
}

/* Backwards compatibility: Set the missing hasWarranty-flag. */
const fixWarrantySelection = (state: State, warranty: number): State => {
  const numItemsWithWarranty = state.cartItems.reduce(
    (a, item) => a + (item.hasWarranty ? 1 : 0),
    0,
  )

  const warrantyPercent = calcWarrantyPercent(state.cartItems, warranty)

  if (numItemsWithWarranty === 0 && warrantyPercent === 15) {
    return {
      ...state,
      cartItems: state.cartItems.map((cartItem) => ({
        ...cartItem,
        hasWarranty: true,
      })),
    }
  }

  return state
}

const importOrder = (_state: any, action: ImportEditOrderAction): State => {
  const { data } = action

  const {
    orderId,
    orderNumber,
    orderStatus,
    customerId,
    cards,
    shippingFirstName,
    shippingLastName,
    shippingCompany,
    shippingAddress,
    shippingAddress2,
    shippingCity,
    shippingState,
    shippingZip,
    shippingPhone,
    shippingEmail,
    billingFirstName,
    billingLastName,
    billingCompany,
    billingAddress,
    billingAddress2,
    billingCity,
    billingState,
    billingZip,
    refunds,
    canEdit,
    disallowEditError,
    hasPercentageCoupon,
    orderSplits,
    line_items_combined,
  } = data

  const cartItems = []

  for (const item of line_items_combined || []) {
    cartItems.push({
      ...item['product_data'],
      price: item.quantity > 0 ? item.subtotal / item.quantity : 0,
      quantity: item.quantity,
      originalQuantity: item.quantity,
      lineItemId: item.id,
      orderNumber: item.orderNumber,
      splitOrderStatus: item.splitOrderStatus,
      isEditable: item.isEditable,
      hasWarranty: item.hasWarranty,
      currentPrice: item.currentPrice,
      restoreInventory: true,
    })
  }

  let shipping = 0
  for (const item of data['shipping_lines']) {
    shipping += item.total
  }

  let warrantyTotal = 0
  let discount = 0
  let oversizeFee = 0
  for (const item of data['fee_lines']) {
    const total = item.total

    if (total > 0 && item.title.toLowerCase().indexOf('oversize') !== -1) {
      oversizeFee += total
      continue
    }

    if (total > 0) {
      warrantyTotal += total
    }
    if (total < 0) {
      discount += total * -1
    }
  }

  discount += data.total_discount

  let tax = 0
  data['tax_lines'].forEach((item: any) => {
    const total = item.total

    if (total > 0) {
      tax += total
    }
  })

  const shipDate = data['preferred_shipping_date'] || ''

  let state = {
    ..._state,
    orderId,
    orderNumber,
    orderStatus,
    customer: {
      value: customerId,
    },
    cartItems,
    shipping: Number(shipping / 100).toFixed(2),
    hasShipping: shipping > 0,
    discount: Number(discount / 100).toFixed(2),
    hasDiscount: discount > 0,
    oversizeFee: Number(oversizeFee / 100).toFixed(2),
    hasOversizeFee: oversizeFee > 0,
    tax,
    hasTax: tax > 0,
    isCustomTax: true,
    hasShipDate: shipDate != '',
    shipDate,
    refund: data.refundTotal,
    originaltotal: data.orderTotal,
    coupons: data.coupon_lines,
    shippingFirstName,
    shippingLastName,
    shippingCompany,
    shippingAddress,
    shippingAddress2,
    shippingCity,
    shippingState,
    shippingZip,
    shippingPhone,
    shippingEmail,
    billingFirstName,
    billingLastName,
    billingCompany,
    billingAddress,
    billingAddress2,
    billingCity,
    billingState,
    billingZip,
    isBillingSameAsShipping: null,
    cardsOnFile: [],
    cardsOnFileId: null,
    isCardOnFile: false,
    isStoreCredit: false,
    refunds,
    lastImport: new Date().getTime(),
    canEdit,
    disallowEditError,
    canCancelSplits: orderSplits.length > 1,
  }

  if (cards && cards.length) {
    state = {
      ...state,
      cardsOnFile: cards,
      cardsOnFileId: cards[cards.length - 1].value,
      isCardOnFile: true,
    }
  }

  state = fixWarrantySelection(state, warrantyTotal)

  let hasWarranty = false
  let warranty = `${getWarrantyPercent()}%`

  if (warrantyTotal) {
    hasWarranty = true
    warranty = calcWarrantyPercent(cartItems, warrantyTotal) + '%'
  }

  state = {
    ...state,
    hasWarranty,
    warranty,
  }

  state = calculateTotals(state)

  if (hasPercentageCoupon) {
    state.discount = `${state.discountPercentCalculated}%`
    state = calculateTotals(state)
  }

  state = {
    ...state,
    originaltotal_calculated: state.total,
    originalWarrantyPercentCalculated: state.warrantyPercentCalculated,
  }

  return state
}

export default importOrder
