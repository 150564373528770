import { useForm } from 'hooks'
import { useEffect } from 'react'
import { Col, FormGroup, Input, Label } from 'reactstrap'
import { formatPrice } from 'utils/price'

type RefundModalProps = {
  onChangeState: (state: any) => void
  orderNumber: string
  refundAmount: number
}

const RefundModal = (props: RefundModalProps) => {
  const { onChangeState, orderNumber, refundAmount } = props

  const initialState = {
    amount: formatPrice(refundAmount),
    reason: '',
  }

  const form = useForm(initialState)

  useEffect(() => {
    if (onChangeState) {
      onChangeState(form.state)
    }
  }, [onChangeState, form.state])

  return (
    <div style={{ padding: 5 }}>
      <div className="font-weight-bold">
        Partial Refund for Order {orderNumber}
      </div>
      <div className="text-left">
        <br />
        <FormGroup row>
          <Col md="3" style={{ alignSelf: 'center' }}>
            <Label className="mb-0" htmlFor="refund-amount">
              Amount
            </Label>
          </Col>
          <Col md="9">
            <Input
              {...form.inputProps}
              id="refund-amount"
              className="form-control d-inline"
              type="text"
              placeholder={initialState.amount}
              name="amount"
              value={form.state.amount}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3" style={{ alignSelf: 'center' }}>
            <Label className="mb-0" htmlFor="refund-reason">
              Reason
            </Label>
          </Col>
          <Col md="9">
            <Input
              {...form.inputProps}
              id="refund-reason"
              className="form-control d-inline"
              type="text"
              placeholder={initialState.reason}
              name="reason"
              value={form.state.reason}
            />
          </Col>
        </FormGroup>
      </div>
    </div>
  )
}

export default RefundModal
