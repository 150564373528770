import { ClipboardButton } from 'components'
import { LinkToInventory } from 'components/Links/LinkToInventory'
import { Col } from 'reactstrap'

import type { Product } from '@ttc/api/products'

type LineItem = {
  id: string
  product_data: Product
  num_unavailable: number
  quantity: number
  sku: string
}

type LineItemProps = {
  item: LineItem
  isLast?: boolean
  showAvailability: boolean
}

const LineItem = (props: LineItemProps) => {
  const { item, isLast, showAvailability } = props

  const thumbnailImage = (
    <img
      alt=""
      style={{ objectFit: 'cover' }}
      width={75}
      height={75}
      src={item.product_data.thumbnailImageUrl}
    />
  )

  return (
    <div
      className="d-flex"
      key={item.id}
      style={isLast ? null : { borderBottom: '1px solid #c5dbea' }}
    >
      <Col className="flex-grow-0" style={{ padding: 10 }}>
        {item.product_data.permalink !== undefined ? (
          <a target="noopener" href={item.product_data.permalink ?? '#'}>
            {thumbnailImage}
          </a>
        ) : (
          thumbnailImage
        )}
      </Col>
      <Col
        style={{
          fontSize: 11,
          padding: '10px 10px 10px 0',
        }}
      >
        <div>
          {item.product_data.permalink !== undefined ? (
            <a target="noopener" href={item.product_data.permalink ?? '#'}>
              {item.product_data.name}
            </a>
          ) : (
            item.product_data.name
          )}
          {item.product_data.variationDescription ? (
            <i> - {item.product_data.variationDescription}</i>
          ) : (
            ''
          )}
        </div>
        <div>
          <ClipboardButton value={item.sku} text={`SKU: ${item.sku}`} />
          <LinkToInventory
            popOverProductData={item.product_data}
            sku={item.sku}
            text="Manage"
          />
        </div>
        {item.product_data.size ? (
          <div>Size: {item.product_data.size}</div>
        ) : null}
      </Col>
      <Col
        className="flex-grow-0 d-flex p-0 flex-column"
        style={{
          borderLeft: '1px solid #c5dbea',
          alignItems: 'center',
        }}
      >
        <div
          className="d-flex text-center"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            fontSize: 22,
            width: 70,
          }}
        >
          {item.quantity}
        </div>
        {showAvailability ? (
          <>
            {Number(item.num_unavailable) === 0 ? (
              <div className="bg-success text-center" style={{ width: 70 }}>
                <i
                  style={{ color: '#256c2b' }}
                  className="fa fa-check-circle"
                />
              </div>
            ) : (
              <div className="bg-danger text-center" style={{ width: 70 }}>
                <i className="fa fa-times-circle mr-1" />
                {item.num_unavailable}
              </div>
            )}
          </>
        ) : null}
      </Col>
    </div>
  )
}

export default LineItem
