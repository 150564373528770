import { useCallback } from 'react'
import { useNavigate } from 'react-router'

type LinkButtonProps = {
  title?: string
  to?: string
  children: React.ReactNode
  style?: React.CSSProperties
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  className?: string
}

const LinkButton = (props: LinkButtonProps) => {
  const navigate = useNavigate()

  const { onClick, children, title, className, to } = props

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        e.preventDefault()
        onClick(e)
      } else if (to) {
        e.preventDefault()
        navigate(to)
      }
    },
    [onClick, to, navigate],
  )

  return (
    <a
      className={className ?? 'text-primary'}
      onClick={handleClick}
      style={{ cursor: 'pointer', ...props.style }}
      {...{ title }}
    >
      {children}
    </a>
  )
}

export default LinkButton
