import { useBoolean } from 'hooks'
import { useEffect, useReducer } from 'react'

const jsFiles = [
  'BrowserPrint-3.1.250.min.js',
  'BrowserPrint-Zebra-1.1.250.min.js',
]

/* Load BrowserPrint JS-files. */
const useZebraBrowserPrint = () => {
  const [numLoaded, incNumLoaded] = useReducer((s) => s + 1, 0)
  const isLoaded = useBoolean(false)

  const isInitialized = useBoolean(false)
  useEffect(() => {
    if (isInitialized.value) {
      return
    }
    isInitialized.on()

    const baseUrl = `${global.appConfig.wpBaseUrl}/wp-content/mu-plugins/tadmin/assets/zebra/`

    for (const file of jsFiles) {
      const js = window.document.createElement('script')
      js.src = `${baseUrl}/${file}`
      js.type = 'text/javascript'
      js.onload = () => {
        incNumLoaded()
      }
      document.getElementsByTagName('head')[0].appendChild(js)
    }
  }, [isInitialized])

  useEffect(() => {
    if (!isLoaded.value && numLoaded >= jsFiles.length) {
      isLoaded.on()
    }
  }, [isLoaded, numLoaded])

  return isLoaded.value
}

export default useZebraBrowserPrint
