import type { GetOrderApiParams, GetOrderApiResult } from '@ttc/api/orders'
import { apiRequest } from 'api'
import {
  Header,
  Loading,
  LoadingImage,
  SelectField,
  TextField,
} from 'components'
import { SingleOrderSummarySidebar } from 'containers/common/OrderSummarySidebar'
import { useApi, usePageTitle, useSidebar } from 'hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  BreadcrumbItem,
  Button,
  Col,
  Container,
  Progress,
  Row,
} from 'reactstrap'
import Swal from 'sweetalert2'
import AddressesForm from './../AddOrder/AddressesForm'
import PaymentForm from './../AddOrder/PaymentForm'
import ShoppingCart from './../AddOrder/ShoppingCart'
import useCart, {
  importEditOrder,
  reset,
  setValue,
  setValues,
} from './../AddOrder/useCart'
import { placeOrder } from './../AddOrder/useCart/api'
import {
  askCancelOrder,
  askCancelOrderSplit,
  askPartialRefund,
  askReasonModal,
  askShouldRefundDifference,
} from './modals'
import { orderStatusOptions } from './options'

const pageTitle = 'Edit Order'

const EditOrder = () => {
  const navigate = useNavigate()

  const [state, dispatch] = useCart('editorder')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const {
    canUpdateOrder,
    orderStatus,
    orderNumber,
    orderNumberInput,
    orderProgress,
    canCancelOrder,
    canCancelSplits,
    reloadOrder,
    disallowEditError,
  } = state

  const canPartialRefund = true

  usePageTitle(pageTitle)

  const sidebar = useSidebar()

  const getOrder = useApi<GetOrderApiResult, GetOrderApiParams>(
    useMemo(
      () => ({ action: 'editOrder_getOrder', id: orderNumber }),
      [orderNumber],
    ),
    null,
    { autoPerform: sidebar.isOpen && Boolean(orderNumber) },
  )

  const loadOrder = useCallback(
    async (orderNumber: any) => {
      try {
        if (!orderNumber) {
          return
        }

        setIsLoading(true)
        setError(null)

        dispatch(reset())
        dispatch(setValues({ orderNumberInput: orderNumber }))

        const ret = await apiRequest({
          action: 'editOrder_getOrder',
          id: orderNumber,
        })

        if (ret.isSplit && ret.parentOrderNumber) {
          navigate(`/orders/edit/${ret.parentOrderNumber}`, { replace: true })
          return
        }

        dispatch(importEditOrder(ret))

        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        setError(e.message)
      }
    },
    [dispatch, navigate],
  )

  const params = useParams()
  const selectedOrderNumber = params['*']
  useEffect(() => {
    if (selectedOrderNumber !== orderNumber) {
      if (selectedOrderNumber) {
        loadOrder(selectedOrderNumber)
      } else {
        dispatch(reset())
      }
    }
  }, [dispatch, loadOrder, selectedOrderNumber, orderNumber])

  useEffect(() => {
    if (reloadOrder) {
      loadOrder(orderNumber)
      dispatch(setValues({ reloadOrder: false }))
    }
  }, [dispatch, orderNumber, loadOrder, reloadOrder])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.currentTarget

      dispatch(setValue(name, value))
    },
    [dispatch],
  )

  const handleChangeSelect = useCallback(
    (name: string, value: any) => {
      dispatch(setValue(name, value ? value.value : ''))
    },
    [dispatch],
  )

  const handlePressReturn = useCallback(() => {
    if (!orderNumberInput) {
      navigate(`/orders/edit/`)
      return
    }

    navigate(`/orders/edit/${orderNumberInput}`)

    /* Pressing return with the same order number reloads the order. */
    if (orderNumberInput === selectedOrderNumber) {
      loadOrder(orderNumberInput)
    }
  }, [orderNumberInput, selectedOrderNumber, loadOrder, navigate])

  const updateOrder = useCallback(async () => {
    const showModalRefund = async () => {
      return await askShouldRefundDifference(
        state.originaltotal || 0,
        state.total,
      )
    }

    let shouldChargeDifference = false
    let updateOrderReason = ''

    if (!state.isStoreCredit && state.total != state.originaltotal) {
      const ret = await showModalRefund()
      if (ret.result === null) {
        /* Cancelled */
        return
      }

      shouldChargeDifference = ret.result
      updateOrderReason = ret.reason
    }

    if (updateOrderReason === '') {
      const ret = await askReasonModal(orderNumber)
      if (!ret) {
        /* Cancelled */
        return
      }

      updateOrderReason = ret
    }

    dispatch(
      setValues({
        shouldChargeDifference,
        updateOrderReason,
      }),
    )

    const newState = {
      ...state,
      shouldChargeDifference,
      updateOrderReason,
    }

    try {
      const newOrderNumber = await placeOrder(newState, dispatch)
      dispatch(setValues({ orderProgress: 'Order updated' }))
      setTimeout(() => {
        dispatch(
          setValues({
            reloadOrder: true,
            orderNumber: newOrderNumber,
            orderNumberInput: newOrderNumber,
            updateOrderReason: '',
          }),
        )
      }, 1000)
    } catch (e) {
      Swal.fire({
        text: e.message,
        icon: 'error',
      })
    }
  }, [state, dispatch, orderNumber])

  const handleClickUpdateOrder = useCallback(async () => {
    updateOrder()
  }, [updateOrder])

  const handleClickCancelOrder = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()

      try {
        const ret = await askCancelOrder(orderNumber, state)
        if (!ret) {
          return
        }

        const {
          shouldRefund,
          refundAmount,
          reason,
          skipRestoreInventoryProductIds,
        } = ret

        Swal.showLoading()

        await apiRequest({
          action: 'editOrder_cancelOrder',
          json: {
            orderNumber,
            shouldRefund,
            refundAmount,
            reason,
            skipRestoreInventoryProductIds,
          },
        })

        Swal.close()

        dispatch(setValues({ reloadOrder: true }))
      } catch (e) {
        Swal.fire({
          text: e.message,
          icon: 'error',
        })
      }
    },
    [dispatch, orderNumber, state],
  )

  const handleClickCancelSplit = useCallback(
    async (splitOrderNumber: string) => {
      try {
        const ret = await askCancelOrderSplit(splitOrderNumber, state)
        if (!ret) {
          return
        }

        const {
          shouldRefund,
          refundAmount,
          reason,
          skipRestoreInventoryProductIds,
        } = ret

        Swal.showLoading()

        await apiRequest({
          action: 'editOrder_cancelOrderSplit',
          json: {
            orderNumber: splitOrderNumber,
            shouldRefund,
            refundAmount,
            reason,
            skipRestoreInventoryProductIds,
          },
        })

        Swal.close()

        dispatch(setValues({ reloadOrder: true }))
      } catch (e) {
        Swal.fire({
          text: e.message,
          icon: 'error',
        })
      }
    },
    [dispatch, state],
  )

  const handleClickPartialRefund = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()

      try {
        const handleRefund = async (amount: string, reason: string) => {
          await apiRequest({
            action: 'editOrder_partialRefund',
            json: {
              orderNumber,
              refundAmount: amount,
              reason,
            },
          })

          dispatch(setValues({ reloadOrder: true }))
        }

        askPartialRefund(orderNumber, 0, handleRefund)
      } catch (e) {
        Swal.fire({
          text: e.message,
          icon: 'error',
        })
      }
    },
    [dispatch, orderNumber],
  )

  return (
    <>
      <nav>
        <ol
          className="breadcrumb align-items-center"
          style={{
            minHeight: 46,
            padding: '5px 4px 5px 1rem',
            margin: 0,
          }}
        >
          <BreadcrumbItem active>
            {pageTitle}
            {isLoading ? <LoadingImage className="ml-2" small /> : null}
          </BreadcrumbItem>
          {!sidebar.isOpen ? (
            <div style={{ marginLeft: 'auto' }}>
              <Button color="link" onClick={sidebar.userToggle}>
                {' '}
                <i className="icon-list" />{' '}
              </Button>
            </div>
          ) : null}
        </ol>
      </nav>
      <Container fluid>
        <div className="mt-4 addorder animated fadeIn">
          <Header isLoading={false}>{pageTitle}</Header>
          <Row style={{ maxWidth: 1200, minHeight: 1600 }}>
            <Col className="pr-4">
              <div className="floating-labels pb-4">
                <h5 className="mb-3">Order Number</h5>
                <TextField
                  disabled={isLoading}
                  style={{ flex: 1 }}
                  className="mr-1"
                  name="orderNumberInput"
                  value={orderNumberInput}
                  onChange={handleChange}
                  onPressReturn={handlePressReturn}
                  id="input-ordernumber"
                  required
                  label="Order Number"
                />
                {isLoading ? <Loading /> : null}

                {!error && disallowEditError ? (
                  <div
                    style={{ fontSize: 20, color: 'red', paddingBottom: 20 }}
                    className="error"
                  >
                    {disallowEditError}
                  </div>
                ) : null}

                {error ? (
                  <div
                    style={{ fontSize: 20, color: 'red', paddingBottom: 20 }}
                    className="error"
                  >
                    {error}
                  </div>
                ) : (
                  <>
                    {!isLoading && orderNumber ? (
                      <>
                        <h5 className="mb-3 mt-4">Order Status</h5>
                        <SelectField
                          isDisabled
                          highlightIfEmpty
                          name="orderStatus"
                          value={orderStatus}
                          options={orderStatusOptions}
                          placeholder="Order Status"
                          onChange={handleChangeSelect}
                        />
                      </>
                    ) : null}

                    {!isLoading && orderNumber ? (
                      <AddressesForm {...{ dispatch, ...state }} />
                    ) : null}
                  </>
                )}
              </div>
              {!error && !isLoading && orderNumber ? (
                <PaymentForm {...{ dispatch, ...state }} />
              ) : null}
            </Col>
            <Col style={{ paddingRight: '60px' }}>
              {!error && !isLoading && orderNumber ? (
                <>
                  <ShoppingCart {...{ dispatch, ...state }} />

                  <Button
                    disabled={!canUpdateOrder}
                    style={{ fontWeight: 'bold' }}
                    className="float-right mt-3"
                    color="primary"
                    onClick={handleClickUpdateOrder}
                  >
                    UPDATE ORDER
                  </Button>

                  <div className="clearfix" />

                  {orderProgress ? (
                    <Progress
                      className="mt-3"
                      bar
                      value="100"
                      animated
                      color="success"
                    >
                      {orderProgress}
                    </Progress>
                  ) : null}

                  <div
                    className="pt-2 mt-3 text-right"
                    style={{ borderTop: '1px solid #ccc' }}
                  >
                    <a
                      className={`btn ${!canCancelOrder ? 'disabled' : ''}`}
                      style={{ padding: '0 5px' }}
                      href="#"
                      title="Cancel this order"
                      onClick={canCancelOrder ? handleClickCancelOrder : null}
                    >
                      Cancel Order
                    </a>
                    -
                    <a
                      className={`btn ${!canPartialRefund ? 'disabled' : ''}`}
                      style={{ padding: '0 5px' }}
                      href="#"
                      title="Refund full amount or a partial amount"
                      onClick={
                        canPartialRefund ? handleClickPartialRefund : null
                      }
                    >
                      Partial Refund
                    </a>
                  </div>
                </>
              ) : null}
            </Col>
          </Row>
        </div>
      </Container>
      <SingleOrderSummarySidebar
        {...{
          getOrder,
          onToggle: sidebar.userToggle,
          onClickCancel: canCancelSplits ? handleClickCancelSplit : null,
        }}
      />
    </>
  )
}

export default EditOrder
