import type { WarrantyGetOrderResponse } from '@ttc/api/warranty'
import { Alert, Col, Row } from 'reactstrap'

const WarrantyInfo = ({
  warrantyInfo,
}: {
  warrantyInfo: WarrantyGetOrderResponse
}) => {
  const {
    isPotentialReplacementOrder,
    originalOrders,
    hasWarranty,
    daysRemaining,
    usdaZone,
    deliveryDate,
    lastDate,
    numWarrantyPlants,
  } = warrantyInfo

  const daysRemainingFormat =
    daysRemaining > 0 && daysRemaining < 1
      ? `<1 (${Number(daysRemaining * 24).toFixed(2)} hours)`
      : Math.floor(daysRemaining)

  return (
    <>
      {isPotentialReplacementOrder ? (
        <>
          {originalOrders.map((order) => {
            return (
              <Row key={order.orderNumber}>
                <Col>
                  <Alert key={order.orderNumber} color="danger">
                    <i className="fa fa-warning mr-1" />
                    The store credit with code {order.giftCardCode} has been
                    used to place this order. Please check the original order{' '}
                    {order.orderNumber} to determine if the customer is still
                    eligible for warranty.
                  </Alert>
                </Col>
              </Row>
            )
          })}
        </>
      ) : (
        <Row>
          <Col>
            <i className="fa fa-info-circle" /> Warranty selected:{' '}
            <span style={{ color: hasWarranty ? 'green' : 'red' }}>
              {hasWarranty ? 'Yes' : 'No'}
            </span>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <div>Customer USDA Zone: {usdaZone}</div>
          {deliveryDate ? (
            <>
              <div>Delivery Date: {deliveryDate}</div>
              {hasWarranty ? (
                <>
                  {daysRemaining == 0 ? (
                    <div style={{ color: 'red' }}>
                      Days remaining: {daysRemaining} (Expired at {lastDate})
                    </div>
                  ) : (
                    <div>Days remaining: {daysRemainingFormat}</div>
                  )}
                  <div>Plants Available For Warranty: {numWarrantyPlants}</div>
                </>
              ) : null}
            </>
          ) : hasWarranty ? (
            <div>Unknown delivery date, cannot calculate days remaining.</div>
          ) : null}
        </Col>
      </Row>
    </>
  )
}

export default WarrantyInfo
