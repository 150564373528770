import { useDropDownToggler } from 'hooks'
import { useApi } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { askQuestion } from 'utils'

export const VoidActionButton = (props) => {
  const { selectedRows, onUpdated } = props

  const apiCancelShipments = useApi(
    { action: 'shipments_cancelShipments' },
    null,
    {
      errorModal: true,
    },
  )

  const toggler = useDropDownToggler()

  const handleClickVoid = useCallback(async () => {
    if (
      !(await askQuestion(
        'Are you sure you want to cancel the selected shipments?',
      ))
    ) {
      return
    }

    await apiCancelShipments.performRequest({ json: { ids: selectedRows } })

    if (onUpdated) {
      onUpdated()
    }
  }, [onUpdated, apiCancelShipments, selectedRows])

  const disabled = selectedRows.length === 0

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret className={disabled ? 'disabled' : ''}>
        Void
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickVoid}>Void Label</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default VoidActionButton
