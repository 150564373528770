import { apiRequest } from 'api'
import { usePrevious } from 'hooks'
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { showError } from 'utils'

type State = {
  isLoading: boolean
  result: any
  error: string | null
  hasResult: boolean
}

type Action =
  | { type: 'RESET'; initialData: any }
  | { type: 'SET_RESULT_FROM_CACHE'; result: any }
  | { type: 'SET_RESULT'; result: any }
  | { type: 'SET_LOADING'; isLoading: boolean }
  | { type: 'SET_ERROR'; error: string }

const cache = {}

const initialState: State = {
  isLoading: false,
  result: null,
  error: null,
  hasResult: false,
}

const getInitialState = (initialData: Record<string, unknown> | null) => {
  if (initialData != null) {
    return { ...initialState, result: initialData }
  }

  return { ...initialState }
}

const RESET = 'RESET'
const SET_RESULT = 'SET_RESULT'
const SET_RESULT_FROM_CACHE = 'SET_RESULT_FROM_CACHE'
const SET_LOADING = 'SET_LOADING'
const SET_ERROR = 'SET_ERROR'

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case RESET: {
      const { initialData } = action
      return getInitialState(initialData)
    }
    case SET_RESULT_FROM_CACHE: {
      const { result } = action
      return { ...state, result, hasResult: true }
    }
    case SET_RESULT: {
      const { result } = action
      return { ...state, isLoading: false, result, hasResult: true }
    }
    case SET_LOADING: {
      const { isLoading } = action
      return { ...state, isLoading, error: null }
    }
    case SET_ERROR: {
      const { error } = action
      return { ...state, error, isLoading: false }
    }
    default:
      return state
  }
}

/** If initValue is a function, evaluate it once and return the result. */
export const useCallOnce = (initValue: any) => {
  const ref = useRef(null)

  if (typeof initValue !== 'function') {
    return initValue
  }

  if (!ref.current) {
    ref.current = initValue()
  }

  return ref.current
}

const getCacheKey = (payload: object) => {
  return JSON.stringify(payload)
}

type Options = {
  /**
   * (default false) If true, the response will be cached.
   */
  cache?: boolean
  /**
   * (default false) If true, the request will be performed automatically.
   */
  autoPerform?: boolean
  /**
   * (default false) By default, it is assumed that the cache is stale,
   * thus `autoPerform:true` will always perform a request. However if this
   * option is true, the cache is considered fresh and a request will only
   * be performed if the cache is empty.
   */
  freshCache?: boolean
  /**
   * (default false) If true, an error modal will be shown if an API error (500) occurs.
   */
  errorModal?: boolean
  /**
   * (default false) If true, performRequest throws an exception if an API error (500) occurs.
   */
  throws?: boolean
  /**
   * (default false) If true, multiple requests parallel requests are allowed.
   */
  allowMultiple?: boolean
  /**
   * Filter result using this function (normalize(result, normalizeOptions))
   */
  normalize?: (input?: any, normalizeOptions?: any) => any
  /**
   * 2nd parameter for normalize function.
   */
  normalizeOptions?: any
  /**
   * Fires any time new data has been fetched or loaded from cache.
   */
  onSuccess?: (data: any) => void
}

// Use this when you only need to display the result of an API call, not trigger it
export type ApiCallResult<ResultType = any> = {
  /**
   * True if request is currently being processed.
   */
  isLoading: boolean
  /**
   * Contains the response.
   */
  result: ResultType
  /**
   * True if a `result` contains a response from the backend.
   */
  hasResult: boolean
  /**
   * Error message of the last response.
   */
  error: string
}

// This exposes the ApiCall result, and methods to reset / trigger and invalidate the call itself
export type ApiCall<
  ResultType = any,
  ParamsType = ApiParamsType,
> = ApiCallResult<ResultType> & {
  /**
   * performRequest Start the request.
   */
  performRequest: (params?: any) => Promise<ResultType>
  /**
   * Reset response to `initialData`.
   */
  reset: () => void
  /**
   * invalidateCache Invalidates the response cache.
   */
  invalidateCache: () => void
  /**
   * Original API request parameters.
   */
  params: ParamsType
}

type ApiParamsType =
  | Record<string, unknown>
  | (() => Record<string, unknown>)
  | null

type OptionsParam = Options | (() => Options)

/**
 * Hook to perform requests against the TTC treeadmin API.
 *
 * @param apiParams   - POST parameters to send. To send JSON, wrap the post data in a JSON-object like `{ json: {...} }`
 * @param initialData - initial data of response object
 * @param options     - Options
 * @returns Hook interface
 */
function useApi<ResultType = any, ParamsType = ApiParamsType>(
  apiParams: ApiParamsType = {},
  initialData: any = null,
  options: OptionsParam = {},
): ApiCall<ResultType, ParamsType> {
  const requestId = useRef(0)
  const _apiParams = useCallOnce(apiParams)
  const _initialData = useCallOnce(initialData)
  const _options = useCallOnce(options)

  const {
    autoPerform,
    normalize,
    normalizeOptions,
    errorModal,
    throws,
    allowMultiple,
    freshCache,
    onSuccess,
  } = _options

  const isCacheEnabled = _options.cache

  const [state, dispatch] = useReducer(reducer, _initialData, getInitialState)

  const performRequest = useCallback(
    async (params = {}) => {
      try {
        let cacheKey: string

        if (isCacheEnabled) {
          cacheKey = getCacheKey({ ..._apiParams, ...params })

          if (Object.hasOwn(cache, cacheKey) && cache[cacheKey] != null) {
            dispatch({ type: 'SET_RESULT_FROM_CACHE', result: cache[cacheKey] })
            if (onSuccess) {
              onSuccess(cache[cacheKey])
            }

            if (freshCache) {
              return cache[cacheKey]
            }
          }
        }

        dispatch({ type: 'SET_LOADING', isLoading: true })

        requestId.current += 1
        const currentRequest = requestId.current
        const result = await apiRequest({ ..._apiParams, ...params })
        if (allowMultiple !== true && requestId.current !== currentRequest) {
          return false
        }

        if (normalize != null) {
          const normal = normalize(result, normalizeOptions)
          dispatch({ type: 'SET_RESULT', result: normal })
          if (onSuccess) {
            onSuccess(normal)
          }

          if (isCacheEnabled) {
            cache[cacheKey] = normal
          }

          return normal
        } else {
          dispatch({ type: 'SET_RESULT', result })
          if (onSuccess) {
            onSuccess(result)
          }

          if (isCacheEnabled) {
            cache[cacheKey] = result
          }

          return result
        }
      } catch (e) {
        if (errorModal) {
          showError(e.message)
        }

        dispatch({ type: 'SET_ERROR', error: e.message })

        if (throws === true) {
          throw e
        }

        return false
      }
    },
    [
      dispatch,
      normalize,
      normalizeOptions,
      _apiParams,
      throws,
      allowMultiple,
      isCacheEnabled,
      errorModal,
      freshCache,
      onSuccess,
    ],
  )

  const invalidateCache = useCallback(() => {
    const cacheKey = getCacheKey({ ..._apiParams })

    if (Object.hasOwn(cache, cacheKey)) {
      delete cache[cacheKey]
    }
  }, [_apiParams])

  const reset = useCallback(() => {
    dispatch({ type: 'RESET', initialData: _initialData })
  }, [dispatch, _initialData])

  const prevParams = usePrevious(_apiParams)
  const prevAutoPerform = usePrevious(autoPerform)
  useEffect(() => {
    if (!autoPerform) {
      if (prevAutoPerform !== autoPerform) {
        reset()
      }

      return
    }

    if (
      JSON.stringify(_apiParams) !== JSON.stringify(prevParams) ||
      prevAutoPerform !== autoPerform
    ) {
      reset()
      performRequest()
    }
  }, [
    _apiParams,
    prevParams,
    prevAutoPerform,
    autoPerform,
    performRequest,
    reset,
  ])

  return useMemo(
    () => ({
      performRequest,
      reset,
      invalidateCache,
      isLoading: state.isLoading,
      result: state.result,
      error: state.error,
      hasResult: state.hasResult,
      params: _apiParams,
    }),
    [
      performRequest,
      reset,
      invalidateCache,
      state.isLoading,
      state.result,
      state.error,
      state.hasResult,
      _apiParams,
    ],
  )
}

export default useApi
