import { InfoIcon } from 'components/InfoIcon/InfoIcon'
import type { DropDownState } from 'components/InfoIcon/types'
import { PurchaseCostPerSupplierTable } from 'containers/common/EditProductPanel/DeliveriesCard/PurchaseCostPerSupplier'
import { PurchaseYearlyAverageCostTable } from 'containers/common/EditProductPanel/DeliveriesCard/PurchaseYearlyAverageCost'
import type { StatefulState } from 'hooks'
import React, { useCallback } from 'react'

type CogsHoverCellProps = {
  id: string
  value: string
  dropDownState: StatefulState<DropDownState>
}

/**
 * This component is used in the Inventory table to display the COGS value when
 * hovering over the COGS info icon.
 *
 * The embedded tables have their content loaded asynchronously, which requires
 * the popover to be repositioned after the content has been loaded. This is done using the
 * handleSuccess callback.
 */
const CogsHoverCell = React.memo((props: CogsHoverCellProps) => {
  const { id, value, dropDownState } = props

  const handleSuccess = useCallback(() => {
    // Repositions the bootstrap popover after content has been loaded.
    window.dispatchEvent(new Event('resize'))
  }, [])

  return (
    <div className="inventory-cogs-cell">
      <span className="mr-1">{value}</span>
      <InfoIcon
        className="cogs-icon"
        id={`cogs-${id}-popover1`}
        {...{ dropDownState }}
      >
        <div>
          <strong>PO cost per supplier</strong>
        </div>
        <PurchaseCostPerSupplierTable
          productId={Number(id)}
          onSuccess={handleSuccess}
        />
        <div>
          <strong>Yearly average cost</strong>
        </div>
        <PurchaseYearlyAverageCostTable
          productId={Number(id)}
          onSuccess={handleSuccess}
        />
      </InfoIcon>
    </div>
  )
})

export default CogsHoverCell
