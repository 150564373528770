import { useCallback } from 'react'
import Select from 'react-select'
import states from 'utils/us-states'
import { getStyles } from './../selectStyles'

const styles = getStyles<SelectOption>({ highlightIfEmpty: true })

const options: SelectOption[] = states.map((s) => {
  return {
    label: s.name,
    value: s.abbreviation,
  }
})

type SelectStateProps = {
  name: string
  value: string
  onChange: (name: string, value: string) => void
  className?: string
}

const SelectState = (props: SelectStateProps) => {
  const { name, value, onChange, className } = props

  const handleChange = useCallback(
    (val) => {
      if (onChange) {
        onChange(name, val)
      }
    },
    [name, onChange],
  )

  return (
    <Select
      {...{ name, styles, options }}
      isClearable
      escapeClearsValue
      className={`select-state ${className}`}
      placeholder="State"
      value={options.find((o) => o.value === value)}
      onChange={handleChange}
      aria-label="State"
    />
  )
}

export default SelectState
