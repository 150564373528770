import { ClipboardButton } from 'components'
import { Button, ButtonGroup, Col } from 'reactstrap'

type QtyColProps = {
  label: string
  title: string
  item: any
  qty: number
  field?: string
  isEditMode: boolean
  onClickDecQty?: (field: string, productId: number) => void
  onClickIncQty?: (field: string, productId: number) => void
}

const QtyCol = ({
  label,
  title,
  item,
  qty,
  field,
  isEditMode,
  onClickDecQty,
  onClickIncQty,
}: QtyColProps) => {
  return (
    <Col
      className="flex-grow-0 d-flex p-0 flex-column"
      style={{
        borderLeft: '1px solid #c5dbea',
        alignItems: 'center',
      }}
      {...{ title }}
    >
      <div
        className="d-flex text-center p-1"
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          fontSize: 12,
          opacity: 0.5,
        }}
      >
        {label}
      </div>
      <div
        className="d-flex text-center m-2"
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          fontSize: 22,
          width: 50,
        }}
      >
        {qty}
      </div>
      {isEditMode ? (
        <ButtonGroup>
          <Button onClick={() => onClickDecQty(field, item.product_id)}>
            -
          </Button>
          <Button onClick={() => onClickIncQty(field, item.product_id)}>
            +
          </Button>
        </ButtonGroup>
      ) : null}
    </Col>
  )
}

const ItemLineItem = ({
  item,
  isLast,
  isEditMode,
  expectedQty,
  receivedQty,
  onClickIncQty,
  onClickDecQty,
}) => {
  const style = {
    borderBottom: !isLast ? '1px solid #c5dbea' : undefined,
    opacity: expectedQty === 0 && receivedQty === 0 ? 0.5 : undefined,
  }

  return (
    <div className="d-flex" key={item.id} style={style}>
      <Col className="flex-grow-0" style={{ padding: 10 }}>
        <a target="noopener" href={item.product_data.permalink}>
          <img
            alt=""
            style={{ objectFit: 'cover' }}
            width={75}
            height={75}
            src={item.product_data.thumbnailImageUrl}
          />
        </a>
      </Col>
      <Col style={{ fontSize: 11, padding: '10px 10px 10px 0' }}>
        <div>
          <a target="noopener" href={item.product_data.permalink}>
            {item.product_data.name}
          </a>
        </div>
        <div>
          <ClipboardButton
            value={item.product_data.sku}
            text={`SKU: ${item.product_data.sku}`}
          />
        </div>
        <div>
          <a target="noopener" href={item.product_data.permalink}>
            Inventory: {item.product_data.availableQty}
          </a>
        </div>
        {item.product_data.size ? (
          <div>Size: {item.product_data.size}</div>
        ) : null}
      </Col>
      <QtyCol
        label="Order"
        title="Original order quantity"
        {...{ item, qty: item.original_qty || 0, isEditMode: false }}
      />
      <QtyCol
        label="Expected"
        title="Expected quantity"
        field="expected_qty"
        {...{
          item,
          qty: expectedQty,
          isEditMode,
          onClickDecQty,
          onClickIncQty,
        }}
      />
      <QtyCol
        label="Received"
        title="Received quantity"
        field="received_qty"
        {...{
          item,
          qty: receivedQty,
          isEditMode,
          onClickDecQty,
          onClickIncQty,
        }}
      />
    </div>
  )
}

export default ItemLineItem
