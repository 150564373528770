import { useCallback } from 'react'

type LinkCellProps = {
  children: React.ReactNode
  onClick?: (id: string, e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  className?: string
  id: string
  title?: string
}

const LinkCell = (props: LinkCellProps) => {
  const { onClick, className, id, title, children } = props

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (onClick) {
        onClick(id, e)
      }
    },
    [id, onClick],
  )

  return (
    <td
      {...{ className }}
      title={title}
      onClick={handleClick}
      style={{ cursor: 'pointer' }}
    >
      <a className="text-primary">{children}</a>
    </td>
  )
}

export default LinkCell
