import useStateful from 'hooks/useStateful'
import { useCallback, useMemo } from 'react'
import type { TabsConfig } from './types'

export const useTabBar = <TabType extends string>(
  defaultTab: TabType,
  tabsConfig: TabsConfig<TabType>,
  storageKey: string,
) => {
  const selectedTab = useStateful<TabType>(defaultTab, storageKey)

  const handleClick = useCallback(
    (name: TabType) => {
      selectedTab.set(name)
    },
    [selectedTab],
  )

  return useMemo(
    () => ({
      selectedTab: selectedTab.value,
      onClick: handleClick,
      tabsConfig,
    }),
    [selectedTab, handleClick, tabsConfig],
  )
}

export default useTabBar
