import type {
  FormDef,
  FormDefElement,
  FormDefGroup,
  UseFormReturn,
} from 'components/Form/types'
import useForm, { getFormInitState } from 'components/Form/useForm'
import pick from 'lodash/pick'
import { useCallback, useMemo } from 'react'

/**
 * useFormWithDef is a custom hook that uses {@link useForm} to create and manage
 * form state based on a {@link FormDef} form definition.
 *
 * @param formDef - The form definition.
 * @param storageKey - The key to store the form state in local storage. If it's null, the form state will not be persisted.
 * @returns object - Contains the initial state, current state, and several methods to manipulate the form state.
 */
export function useFormWithDef<T = any>(
  formDef: FormDef,
  storageKey: string = null,
): UseFormReturn<T> {
  const initState = useMemo(() => getFormInitState(formDef), [formDef])

  const storeFieldsWhitelist = useMemo(() => {
    const fields = []

    for (const field of formDef) {
      if (field.group === undefined) {
        const f = field as FormDefElement

        if (f.store) {
          fields.push(f.name)
        }
      } else {
        const f = field as FormDefGroup

        for (const groupField of f.group) {
          if (groupField.store) {
            fields.push(groupField.name)
          }
        }
      }
    }

    return fields
  }, [formDef])

  const storeFilter = useCallback(
    (state: any) => {
      if (storageKey === null) {
        return state
      }

      if (typeof state !== 'object') {
        return state
      }

      return pick(state, storeFieldsWhitelist)
    },
    [storageKey, storeFieldsWhitelist],
  )

  const options = useMemo(() => ({ storeFilter }), [storeFilter])

  return useForm<T>(initState, storageKey, options)
}
