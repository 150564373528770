import range from 'lodash/range'
import { useCallback } from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

type TablePaginationProps = {
  pages: number
  activePage: number
  listClassName: string
  onChange: (page: number) => void
}

const TablePagination = (props: TablePaginationProps) => {
  const { pages, activePage, listClassName, onChange } = props

  const handleClickPage = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const page = Number(e.currentTarget.getAttribute('data-page') || 1)
      onChange(page)
    },
    [onChange],
  )

  const handleClickNext = useCallback(() => {
    onChange(activePage + 1)
  }, [activePage, onChange])

  const handleClickPrev = useCallback(() => {
    onChange(activePage - 1)
  }, [activePage, onChange])

  if (pages <= 1) {
    return null
  }

  const pageItems = range(1, pages + 1).filter((page) => {
    return (
      pages <= 8 ||
      page === 1 ||
      page === activePage ||
      (page >= activePage - 3 && page <= activePage + 3) ||
      page === pages
    )
  })

  return (
    <Pagination className="table-pagination" listClassName={listClassName}>
      <PaginationItem disabled={activePage === 1} title="Previous Page">
        <PaginationLink onClick={handleClickPrev} previous tag="button">
          «
        </PaginationLink>
      </PaginationItem>
      {pageItems.map((i) => (
        <PaginationItem key={i} active={activePage === i}>
          <PaginationLink data-page={i} onClick={handleClickPage} tag="button">
            {i}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={activePage >= pages} title="Next Page">
        <PaginationLink onClick={handleClickNext} next tag="button">
          »
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  )
}

export default TablePagination
