import { DropDownItemCheckbox } from 'components'
import { useDropDownToggler } from 'hooks'
import { intersection } from 'lodash'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

type DropDownFilterButtonProps = {
  label: string
  prefixLabel?: string
  filter: FilterType
  values: string[]
  exclusiveValues?: string[]
}

const DropDownFilterButton = ({
  label,
  prefixLabel,
  filter,
  values,
  exclusiveValues,
}: DropDownFilterButtonProps) => {
  const toggler = useDropDownToggler()

  const handleToggle = useCallback(
    (val: string) => {
      if (!exclusiveValues) {
        filter.toggle(val)
        return
      }

      if (
        intersection(filter.values, exclusiveValues).length > 0 &&
        !exclusiveValues.includes(val)
      ) {
        const values = filter.values.filter((v) => !exclusiveValues.includes(v))
        filter.setValues([val, ...values])
        return
      }

      if (exclusiveValues.includes(val) && !filter.values.includes(val)) {
        filter.setValues([val])
        return
      }

      filter.toggle(val)
    },
    [filter, exclusiveValues],
  )

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle
        nav
        caret
        className={filter.values.length !== 0 ? 'hasValue' : ''}
      >
        {filter.values.length === 0 ? (
          <span>{label}</span>
        ) : filter.values.length === 1 ? (
          <span>
            {prefixLabel}
            {filter.values[0]}
          </span>
        ) : (
          <span>
            {prefixLabel}
            {filter.values.join(',')}
          </span>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={filter.clear}>
          <i className="mr-1 fa fa-ban" /> Clear
        </DropdownItem>
        {values.map((status) => {
          return (
            <DropDownItemCheckbox
              id={status}
              key={status}
              onClick={handleToggle}
              isChecked={filter.has(status)}
            >
              {status}
            </DropDownItemCheckbox>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

export default DropDownFilterButton
