import { useTable, useTableApi, useTableColumns } from 'components/Table'
import columnDef from 'containers/ManageStoreCredits/columnDef'
import { useMemo } from 'react'
import { getStorage } from 'utils'

const storageKey = 'editCustomer.storeCreditsTable'
const [setItem, getItem] = getStorage(storageKey)

const filteredColumnDef = columnDef.filter(
  (column) => column.id !== 'billing_email' && column.id !== 'billing_name',
)

export const useStoreCreditsTable = (id: string) => {
  const [state, dispatch] = useTable({
    cacheKey: `${storageKey}.${id}`,
    getItem,
    setItem,
  })
  const [triggerSearch] = useTableApi('storeCredits_getAll', state, dispatch, {
    filterCustomerId: id,
  })

  const columns = useTableColumns(
    'manageCustomerStoreCredits',
    state,
    dispatch,
    filteredColumnDef,
  )

  const isEmpty = state.rows.length === 0 && state.didLoad

  return useMemo(
    () => ({
      ...state,
      setItem,
      getItem,
      columnDef: filteredColumnDef,
      dispatch,
      triggerSearch,
      isEmpty,
      columns,
    }),
    [state, dispatch, triggerSearch, isEmpty, columns],
  )
}
