import { useDropDownToggler } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

type PrintActionButtonProps = {
  selectedRows: string[]
  role: string
}

export const PrintActionButton = (props: PrintActionButtonProps) => {
  const { selectedRows, role } = props

  const toggler = useDropDownToggler()

  const handleClickPrintLoginBadge = useCallback(async () => {
    const ids = selectedRows
    const url = `${
      global.appConfig.wpBaseUrl
    }/wp-admin/admin-ajax.php?action=treeadmin_users_printLoginBadge&role=${role}&ids=${ids.join(
      ',',
    )}`

    window.open(url, '_blank')
  }, [selectedRows, role])

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret>
        Print
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickPrintLoginBadge}>
          Login Badge
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default PrintActionButton
