import { useCallback, useMemo } from 'react'
import { importShortcode } from './importShortcode'

export const useShortcodePaste = (filters: FiltersTypeWithUrlUpdater) => {
  /* Handle shortcode paste. */
  const handlePaste = useCallback(
    (e: ClipboardEvent) => {
      const text = e.clipboardData.getData('text')

      if (text.indexOf('[ttc_products ') === 0) {
        e.preventDefault()
        const query = importShortcode(filters, text)
        if (query != null) {
          return query
        }
      }
    },
    [filters],
  )

  return useMemo(() => ({ handlePaste }), [handlePaste])
}
