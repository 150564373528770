import { SearchForm } from 'components'
import { Nav, NavItem } from 'reactstrap'

const PageNavTail = ({
  children,
  handleChangeSearch,
  isLoading,
  query,
  searchPlaceholder,
  onPaste,
}: {
  children?: React.ReactNode
  handleChangeSearch?: (query: string) => void
  isLoading?: boolean
  query?: string
  searchPlaceholder?: string
  onPaste?: (e: ClipboardEvent) => string
}) => {
  return (
    <Nav
      pills
      style={{
        background: 'white',
        margin: 0,
        padding: '6px 10px 13px 1em',
        borderBottom: '1px solid #c8ced3',
      }}
    >
      {handleChangeSearch ? (
        <NavItem className="mr-1" style={{ minWidth: 300 }}>
          <SearchForm
            {...{ query, isLoading, placeholder: searchPlaceholder, onPaste }}
            onChange={handleChangeSearch}
          />
        </NavItem>
      ) : null}
      {children}
    </Nav>
  )
}

export default PageNavTail
