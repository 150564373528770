import type { PurchaseOrder } from '@ttc/api/purchaseOrders'
import Loading from 'components/Loading'
import { useCallback, useState } from 'react'
import {
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
} from 'reactstrap'

type OverviewCardProps = {
  isLoading: boolean
  onClickEdit: () => void
  onClickPrintOverview: (e: React.MouseEvent<HTMLElement>) => void
  onClickLog: (e: React.MouseEvent<HTMLElement>) => void
  purchaseOrder: PurchaseOrder
  isDraftsView?: boolean
}

const OverviewCard = (props: OverviewCardProps) => {
  const {
    isLoading,
    onClickEdit,
    onClickPrintOverview,
    onClickLog,
    purchaseOrder,
    isDraftsView,
  } = props
  const [showDropDown, setShowDropDown] = useState(false)

  const handleClickEdit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      onClickEdit()
    },
    [onClickEdit],
  )

  const renderAddress = useCallback(() => {
    if (!purchaseOrder || !purchaseOrder.supplier) {
      return null
    }

    const { supplier } = purchaseOrder

    return (
      <div>
        {supplier.name}
        <br />
        {supplier.phone}
        <br />
        {supplier.email}
        <br />
      </div>
    )
  }, [purchaseOrder])

  return (
    <Card className="h-100">
      <CardHeader>
        <span className="align-bottom">Overview</span>
        <div className="card-header-actions">
          {onClickEdit ? (
            <a
              href="#"
              onClick={handleClickEdit}
              className="card-header-action btn btn-setting"
            >
              <i className="fa fa-pencil" />
            </a>
          ) : null}
          <ButtonDropdown
            isOpen={showDropDown}
            toggle={() => {
              setShowDropDown(!showDropDown)
            }}
          >
            <DropdownToggle
              style={{ width: '20px' }}
              className="p-0"
              color="transparent"
            >
              <i className="fa fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={onClickPrintOverview}>Print</DropdownItem>
              <DropdownItem onClick={onClickLog}>Log</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      </CardHeader>
      <CardBody className="d-flex flex-column">
        {!purchaseOrder && isLoading ? (
          <Loading />
        ) : purchaseOrder ? (
          <>
            <div className="mb-2" style={{ flex: 1 }}>
              {renderAddress()}
            </div>
            {purchaseOrder.supplier?.source_location ? (
              <div className="mb-2 font-italic" style={{ flex: 1 }}>
                Source Location for internal transfer: <br />
                {purchaseOrder.supplier.source_location.name}
              </div>
            ) : null}
            {!isDraftsView ? (
              <>
                <div className="mb-2" style={{ flex: 0 }}>
                  Products Received
                  <div className="float-right">
                    {purchaseOrder.percent_arrived_format}
                  </div>
                  <Progress
                    color="success"
                    value={purchaseOrder.percent_arrived}
                  />
                </div>
                <div style={{ flex: 0 }}>
                  Purchase Order Listed
                  <div className="float-right">
                    {purchaseOrder.percent_listed_format}
                  </div>
                  <Progress
                    color="primary"
                    value={purchaseOrder.percent_listed}
                  />
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </CardBody>
    </Card>
  )
}

export default OverviewCard
