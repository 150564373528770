import { setSelectedRow } from 'components/Table'
import { useSidebar, useStateful } from 'hooks/index'
import { useCallback } from 'react'

/**
 * Toggle sidebar (using useSidebar) when a cell is clicked.
 * Also toggles the selection of the row
 * when the cell is clicked.
 *
 * NOTE(manuel, 2023-07-26) This gets recomputed everytime a new row is selected.
 * If we consider using a state store for each table that is more easily passed through
 * (instead of the manual useReducer from useTable), we could avoid that.
 *
 * @param tableDispatch - dispatch function returned by useTable
 * @param selectedTableRows - array of selected row ids
 * @param colId - id of the column that can be clicked
 */
export const useSidebarForClickOnCell = (
  tableDispatch: React.Dispatch<any>,
  selectedTableRows: string[],
  colId: string,
) => {
  const clickedRowId = useStateful<string>(null)
  const sidebarRowId =
    clickedRowId.value != null &&
    selectedTableRows.length === 1 &&
    selectedTableRows.includes(clickedRowId.value) &&
    Number(clickedRowId.value) > 0
      ? clickedRowId.value
      : null
  const sidebarRowIds = sidebarRowId != null ? [sidebarRowId] : []

  const sidebar = useSidebar({
    openForSingleRow: true,
    openForMultiRows: false,
    selectedRows: sidebarRowIds,
  })

  const handleClickCell = useCallback(
    (rowId: string, clickedColId: string) => {
      if (clickedColId === colId) {
        if (clickedRowId.value === rowId) {
          clickedRowId.set(null)
          tableDispatch(setSelectedRow(null))
        } else {
          clickedRowId.set(rowId)
          tableDispatch(setSelectedRow(rowId))
        }
        return true
      }

      clickedRowId.set(null)

      return false
    },
    [tableDispatch, clickedRowId, colId],
  )

  return {
    sidebar,
    handleClickCell,
    clickedRowId,
    sidebarRowIds,
  }
}
