import type { TableApiParams } from '@ttc/api/orders'
import { apiRequest, apiRequestParams } from 'api'
import { useInterval } from 'hooks'
import { usePrevious } from 'hooks'
import pickBy from 'lodash/pickBy'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { isEmpty } from 'utils/types'
import {
  setError,
  setLoading,
  setPaginationPage,
  setResponse,
  setTable,
} from './reducer/actions'
import type { Action, BaseRow, Row, State, TableType } from './reducer/types'

const DEFAULT_AUTO_RELOAD_INTERVAL = 30

/**
 * useTableApi is a custom hook that manages the state and behavior of a table component.
 * It handles loading, error, and response states, pagination, sorting, and auto-reloading of table data.
 * It also provides customization options through callbacks and extra request parameters.
 *
 * @param action - The action string to be used for the API request.
 * @param state - The current state of the table, from useTable.
 * @param dispatch - The dispatch function to update the table's state, from useTable.
 * @param extraRequestParams - Additional parameters for the API request.
 * @param options - An object containing optional callbacks and settings.
 *    options.onChange - A callback function that is called when the state changes.
 *    options.onAutoReload - A callback function that is called when the table auto-reloads.
 *    options.autoReloadInterval - The interval (in seconds) at which the table should auto-reload. Set to false to disable auto-reloading.
 *    options.shouldLoad - A boolean indicating whether the table should load data initially.
 *
 * @returns triggerSearch - A promise that triggers a search operation on the table.
 */
const useTableApi = <
  RowType extends BaseRow = Row,
  ApiParamsType extends Record<string, any> = Record<string, never>,
>(
  action: string,
  state: State<RowType>,
  dispatch: React.Dispatch<Action<RowType>>,
  extraRequestParams: ApiParamsType,
  options: {
    onChange?: (state: State<RowType>) => void
    onAutoReload?: () => void
    autoReloadInterval?: number | false
    shouldLoad?: boolean
  } = {},
): Array<(silent?: boolean) => Promise<void>> => {
  const { onChange, onAutoReload, autoReloadInterval, shouldLoad } = options
  const requestId = useRef<number | null>()
  const isCancelled = useRef(false)
  const {
    query,
    paginationLimit,
    paginationPage,
    sortColumn,
    sortAsc,
    editCell,
    didLoad,
  } = state

  /* If filters change, jump back to first page of results.
   * This excludes the "selected" filter which contains the currently selected row(s). */
  const prevExtraRequestParams = usePrevious(extraRequestParams)
  useEffect(() => {
    const filteredExtraRequestParams = pickBy(
      extraRequestParams,
      (_, key) => key !== 'filterSelected',
    )
    const filteredPrevExtraRequestParams = pickBy(
      prevExtraRequestParams,
      (_, key) => key !== 'filterSelected',
    )

    if (
      paginationPage !== 1 &&
      JSON.stringify(filteredExtraRequestParams) !==
        JSON.stringify(filteredPrevExtraRequestParams)
    ) {
      dispatch(setPaginationPage(1))
    }
  }, [dispatch, paginationPage, extraRequestParams, prevExtraRequestParams])

  const requestParams = useMemo(
    () =>
      apiRequestParams<TableApiParams & ApiParamsType>({
        action,
        query,
        limit: paginationLimit,
        offset: (paginationPage - 1) * paginationLimit,
        sortColumn: sortColumn ? sortColumn : '',
        sortAsc: sortAsc ? '1' : '0',
        ...extraRequestParams,
      }),
    [
      action,
      query,
      paginationLimit,
      paginationPage,
      sortColumn,
      sortAsc,
      extraRequestParams,
    ],
  )

  const triggerSearch = useCallback(
    async (silent = false) => {
      if (shouldLoad === false) {
        return
      }

      if (!didLoad || !silent) {
        dispatch(setLoading(true))
      }

      const _requestId = Math.random()

      try {
        requestId.current = _requestId

        const table = await apiRequest<
          TableType<RowType>,
          TableApiParams & ApiParamsType
        >(requestParams)

        if (
          !isCancelled.current &&
          requestId.current === _requestId &&
          !isEmpty(table)
        ) {
          requestId.current = null
          dispatch(setResponse(table))
          dispatch(setTable<RowType>(table))
        } else {
          dispatch(setLoading(false))
        }
      } catch (e) {
        if (!isCancelled.current && requestId.current === _requestId) {
          dispatch(setError(e.message))
        }
      }
    },
    [didLoad, requestParams, dispatch, shouldLoad],
  )

  const deps = [
    query,
    paginationPage,
    paginationLimit,
    sortColumn,
    sortAsc,
    shouldLoad,
  ]

  const debouncedTriggerSearch = useDebouncedCallback(
    useCallback(() => {
      triggerSearch()
    }, [triggerSearch, ...deps]), // eslint-disable-line
    250,
  )

  useEffect(() => {
    debouncedTriggerSearch()
  }, [debouncedTriggerSearch, ...deps]) // eslint-disable-line

  useEffect(() => {
    if (onChange) {
      onChange(state)
    }
  }, [onChange, state])

  useInterval(
    useCallback(() => {
      if (autoReloadInterval === false) {
        return
      }

      if (requestId.current != null) {
        return
      }

      if (editCell == null) {
        triggerSearch(true)
      }

      if (onAutoReload) {
        onAutoReload()
      }
    }, [triggerSearch, editCell, autoReloadInterval, onAutoReload]),
    (autoReloadInterval || DEFAULT_AUTO_RELOAD_INTERVAL) * 1000,
  )

  return [triggerSearch]
}

export default useTableApi
