import { EditPanel, EditPanelCard, Loading } from 'components'
import useApi from 'hooks/useApi'
import { formatDateReadable } from 'utils/date'

type LogPanelProps = {
  isOpen: boolean
  onClose: () => void
}

type LogEntry = {
  id: number
  name: string
  message: string
  event_type: string
  user: string
  created_at: string
  app_context: string
  field_name: string
  discount_id: number
}

const LogEntry = (entry: LogEntry) => {
  const {
    id,
    name,
    event_type,
    message,
    user,
    created_at,
    app_context,
    field_name,
  } = entry

  return (
    <li key={id}>
      <div>
        {event_type} {name} {field_name}{' '}
      </div>
      <div dangerouslySetInnerHTML={{ __html: message }} />
      <div style={{ fontSize: 11, fontStyle: 'italic' }}>
        {user} on {formatDateReadable(created_at, true)} via {app_context}
      </div>
    </li>
  )
}

const Log = ({ apiName }) => {
  const apiGetLog = useApi(() => ({ action: apiName }), null, {
    autoPerform: true,
  })

  const entries = apiGetLog.result?.rows || []

  if (apiGetLog.isLoading) {
    return <Loading />
  }

  if (entries.length === 0) {
    return <i>Log empty</i>
  }

  return (
    <>
      <ul>
        {entries.map((entry: LogEntry) => (
          <LogEntry key={entry.id} {...entry} />
        ))}
      </ul>
    </>
  )
}

type LogCardProps = {
  apiName: string
}

const LogCard = (props: LogCardProps) => {
  const { apiName } = props

  return (
    <EditPanelCard caption="Discounts Log" stateId="discounts-log">
      <Log {...{ apiName }} />
    </EditPanelCard>
  )
}

const LogPanel = (props: LogPanelProps) => {
  const { isOpen, onClose } = props

  return (
    <EditPanel caption="Log" {...{ isOpen, onClose }}>
      {isOpen ? <LogCard apiName="discounts_getLog" /> : <Loading />}
    </EditPanel>
  )
}

export default LogPanel
