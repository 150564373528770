import { apiRequest } from 'api'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Tooltip,
} from 'chart.js'
import Header from 'components/Header'
import { useInterval, usePageTitle } from 'hooks'
import { useCallback, useEffect, useReducer } from 'react'
import { Chart } from 'react-chartjs-2'
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import { getStorage } from 'utils/storage'
import DatePickerRow from './DatePickerRow'
import { LinkButton } from 'components/index'

ChartJS.register(LinearScale, CategoryScale, BarElement, BarController, Tooltip)

const isLegacy = document.location.pathname.indexOf('legacy') !== -1

const [setItem, getItem] = getStorage(
  `shippingReport${isLegacy ? 'legacy' : ''}`,
)

export type State = {
  isLoading: boolean
  error: string | null
  dateId: string | null
  dateFrom: string | null
  dateTo: string | null
  numBoxesShipped: string
  numPlantsShipped: string
  numLabels: string
  mostUsedBox: string
  bestEmployee: string
  avgBoxesPerOrder: string
  boxesPerEmployeeChart: {
    labels: string[]
    datasets: any
  }
  avgBoxesPerOrderChart: {
    labels: string[]
    datasets: any
  }
  boxSizesChart: {
    labels: string[]
    datasets: any
  }
  selectedEmployeeTab: number
}

export type Action =
  | { type: 'SET_ERROR'; error: string | null }
  | { type: 'SET_LOADING'; isLoading: boolean }
  | { type: 'RESET' }
  | { type: 'SET_DATE'; dateId: string; dateFrom: string; dateTo: string }
  | { type: 'SET_VALUE'; name: string; value: string }
  | { type: 'SET_VALUES'; values: { [key: string]: string } }
  | { type: 'SET_CHART_DATA'; name: string; labels: string[]; data: any }
  | { type: 'SET_EMPLOYEE_TAB'; tab: number }

const initialState: State = {
  isLoading: false,
  error: '',
  dateId: null,
  dateFrom: null,
  dateTo: null,
  numBoxesShipped: '-',
  numPlantsShipped: '-',
  numLabels: '-',
  mostUsedBox: '-',
  bestEmployee: '-',
  avgBoxesPerOrder: '-',
  boxesPerEmployeeChart: {
    labels: [],
    datasets: [
      {
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [],
      },
    ],
  },
  avgBoxesPerOrderChart: {
    labels: [],
    datasets: [
      {
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [],
      },
    ],
  },
  boxSizesChart: {
    labels: [],
    datasets: [
      {
        backgroundColor: 'rgba(98, 194, 222, 0.2)',
        borderColor: 'rgba(98, 194, 222, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(98, 194, 222, 0.4)',
        hoverBorderColor: 'rgba(98, 194, 222, 1)',
        data: [],
      },
    ],
  },
  selectedEmployeeTab: 0,
}

const init = (state: State): State => {
  const dateId = getItem('dateId') || 'today'
  const selectedEmployeeTab = getItem('selectedEmployeeTab') || 0

  return { ...state, dateId, selectedEmployeeTab: Number(selectedEmployeeTab) }
}

const SET_ERROR = 'SET_ERROR'
const SET_LOADING = 'SET_LOADING'
const RESET = 'RESET'
const SET_DATE = 'SET_DATE'
const SET_VALUE = 'SET_VALUE'
const SET_VALUES = 'SET_VALUES'
const SET_CHART_DATA = 'SET_CHART_DATA'
const SET_EMPLOYEE_TAB = 'SET_EMPLOYEE_TAB'

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case SET_CHART_DATA: {
      const { labels, data } = action
      return {
        ...state,
        error: '',
        [action.name]: {
          labels,
          datasets: [{ ...state[action.name].datasets[0], data }],
        },
      }
    }
    case SET_DATE: {
      const { dateId, dateFrom, dateTo } = action
      setItem('dateId', dateId)
      return { ...state, dateId, dateFrom, dateTo }
    }
    case SET_LOADING: {
      return { ...state, isLoading: action.isLoading }
    }
    case SET_ERROR: {
      return { ...state, error: action.error }
    }
    case SET_VALUE: {
      return { ...state, [action.name]: action.value }
    }
    case SET_VALUES: {
      return { ...state, ...action.values }
    }
    case RESET: {
      return init(initialState)
    }
    case SET_EMPLOYEE_TAB: {
      setItem('selectedEmployeeTab', `${action.tab}`)
      return { ...state, selectedEmployeeTab: action.tab }
    }
    default:
      return state
  }
}

const options = {
  showTooltips: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { display: false },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
}

const pageTitle = 'Shipping Report (Legacy)'
const pageTitleLegacy = 'Shipping Report LEGACY'

let lastFetchPromise: Promise<any> | null = null

const ShippingReport = ({ legacy }: { legacy?: boolean }) => {
  const [state, dispatch] = useReducer<React.Reducer<State, Action>, any>(
    reducer,
    initialState,
    init,
  )

  const {
    isLoading,
    error,
    dateId,
    dateFrom,
    dateTo,
    numBoxesShipped,
    numPlantsShipped,
    numLabels,
    bestEmployee,
    avgBoxesPerOrder,
    boxSizesChart,
    boxesPerEmployeeChart,
    avgBoxesPerOrderChart,
    selectedEmployeeTab,
  } = state

  const fetchData = useCallback(
    async (reset = true) => {
      if (reset) {
        dispatch({
          type: 'SET_CHART_DATA',
          name: 'boxSizesChart',
          labels: [],
          data: [],
        })
        dispatch({
          type: 'SET_CHART_DATA',
          name: 'boxesPerEmployeeChart',
          labels: [],
          data: [],
        })
        dispatch({
          type: 'SET_CHART_DATA',
          name: 'avgBoxesPerOrderChart',
          labels: [],
          data: [],
        })
      }

      if (!dateFrom || !dateTo) {
        return
      }

      if (reset) {
        dispatch({ type: 'SET_LOADING', isLoading: true })
      }

      try {
        const promise = apiRequest({
          action: `shippingreport${legacy ? 'legacy' : ''}_getReport`,
          from: dateFrom,
          to: dateTo,
        })
        lastFetchPromise = promise

        const ret = await promise

        if (promise !== lastFetchPromise) {
          dispatch({ type: 'SET_LOADING', isLoading: false })
          return
        }

        const {
          numBoxesShipped,
          numPlantsShipped,
          numLabels,
          bestEmployee,
          avgBoxesPerOrder,
          boxSizesChart,
          boxesPerEmployeeChart,
          avgBoxesPerOrderChart,
        } = ret

        dispatch({ type: 'SET_LOADING', isLoading: false })
        dispatch({
          type: 'SET_CHART_DATA',
          name: 'boxSizesChart',
          labels: boxSizesChart.labels,
          data: boxSizesChart.data,
        })
        dispatch({
          type: 'SET_CHART_DATA',
          name: 'boxesPerEmployeeChart',
          labels: boxesPerEmployeeChart.labels,
          data: boxesPerEmployeeChart.data,
        })
        dispatch({
          type: 'SET_CHART_DATA',
          name: 'avgBoxesPerOrderChart',
          labels: avgBoxesPerOrderChart.labels,
          data: avgBoxesPerOrderChart.data,
        })
        dispatch({
          type: 'SET_VALUES',
          values: {
            numBoxesShipped,
            numPlantsShipped,
            numLabels,
            bestEmployee,
            avgBoxesPerOrder,
          },
        })
      } catch (e) {
        dispatch({ type: 'SET_LOADING', isLoading: false })
        dispatch({ type: 'SET_ERROR', error: e.message })
      }
    },
    [dateFrom, dateTo, legacy],
  )

  useInterval(() => {
    fetchData(false)
  }, 30000)

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleChangeDateId = (
    _dateId: string,
    dateFrom: string,
    dateTo: string,
  ) => {
    dispatch({ type: 'SET_DATE', dateId: _dateId, dateFrom, dateTo })
  }

  const handleClickTab0 = () => {
    dispatch({ type: 'SET_EMPLOYEE_TAB', tab: 0 })
  }

  const handleClickTab1 = () => {
    dispatch({ type: 'SET_EMPLOYEE_TAB', tab: 1 })
  }

  const title = legacy ? pageTitleLegacy : pageTitle
  usePageTitle(title)

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem active>
          Shipping Report
          <LinkButton className="ml-2" to="/shipping/report2">
            (✨ Show new Report)
          </LinkButton>
        </BreadcrumbItem>
      </Breadcrumb>
      <Container fluid>
        <div className="shipping-report animated fadeIn">
          <Header {...{ isLoading }}>{title}</Header>
          <DatePickerRow
            {...{ setItem, getItem, dateId }}
            onChange={handleChangeDateId}
          />
          {error !== '' ? (
            <div className="pb-4 text-danger">{error}</div>
          ) : null}
          {dateFrom && dateTo ? (
            <>
              <Row className="pb-4">
                <Col>
                  <Card className="text-white bg-info">
                    <CardBody>
                      <div className="text-value">{numBoxesShipped}</div>
                      <div>Boxes shipped</div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="text-white bg-primary">
                    <CardBody>
                      <div className="text-value">{numLabels}</div>
                      <div>Orders shipped</div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="text-white bg-success">
                    <CardBody>
                      <div className="text-value">{numPlantsShipped}</div>
                      <div>Plants shipped</div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="text-white bg-warning">
                    <CardBody>
                      <div className="text-value">{bestEmployee}</div>
                      <div>Best employee</div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="text-white bg-danger">
                    <CardBody>
                      <div className="text-value">{avgBoxesPerOrder}</div>
                      <div>Average boxes per order</div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6" className="mb-4">
                  <Nav tabs>
                    <NavItem active>
                      <NavLink active={true}>Box Sizes</NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent>
                    <TabPane>
                      <div className="chart-wrapper">
                        <Chart
                          type="bar"
                          data={boxSizesChart}
                          options={options}
                        />
                      </div>
                    </TabPane>
                  </TabContent>
                </Col>
                <Col xs="12" md="6" className="mb-4">
                  <Nav tabs>
                    <NavItem active>
                      <NavLink
                        onClick={handleClickTab0}
                        active={selectedEmployeeTab === 0}
                      >
                        Boxes per Employee
                      </NavLink>
                    </NavItem>
                    <NavItem active>
                      <NavLink
                        onClick={handleClickTab1}
                        active={selectedEmployeeTab === 1}
                      >
                        Average Boxes per Order
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent>
                    <TabPane>
                      <div className="chart-wrapper">
                        {selectedEmployeeTab === 1 ? (
                          <Chart
                            type="bar"
                            data={avgBoxesPerOrderChart}
                            options={options}
                          />
                        ) : (
                          <Chart
                            type="bar"
                            data={boxesPerEmployeeChart}
                            options={options}
                          />
                        )}
                      </div>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </>
          ) : null}
        </div>
      </Container>
    </>
  )
}

export default ShippingReport
