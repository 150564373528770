import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

const StatsCard = ({ numBoxesShipped, numPlantsShipped, onToggleStats }) => {
  return (
    <Card className="mb-3" onClick={onToggleStats}>
      <CardHeader className="d-flex justify-content-center">
        <img style={{ width: '50px' }} alt="logo" src="/assets/img/logo.png" />
      </CardHeader>
      <CardBody className="text-align-center">
        <Row>
          <Col
            className="p-0 d-flex flex-column justify-content-center align-items-center"
            style={{ borderRight: '1px solid #c5dbea' }}
          >
            <div style={{ fontSize: '150%' }}>{numBoxesShipped}</div>
            <div className="small">BOXES SHIPPED</div>
          </Col>
          <Col className="p-0 d-flex flex-column justify-content-center align-items-center">
            <div style={{ fontSize: '150%' }}>{numPlantsShipped}</div>
            <div className="small">PLANTS SHIPPED</div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default StatsCard
