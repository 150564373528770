import { TagsCell } from 'components'

import type { ColumnDef } from 'components/Table/types'

const columnDef: ColumnDef[] = [
  {
    label: '',
    id: 'checkbox',
    sortColumn: false,
  },
  {
    label: 'Name',
    id: 'name',
    sortColumn: true,
    cursor: 'pointer',
    hasClipboardButton: true,
  },
  {
    label: '',
    id: 'tags',
    sortColumn: false,
    renderer: (props: any) => <TagsCell {...props} />,
  },
  {
    label: 'Plants',
    id: 'num_plants',
    sortColumn: false,
    cursor: 'pointer',
  },
  {
    thStyle: { width: 250 },
    label: 'Status',
    id: 'status',
    sortColumn: true,
    isSelect: true,
    selectOptions: [
      { label: 'NEW', value: 'NEW' },
      { label: 'PRINTED', value: 'PRINTED' },
      { label: 'PULLING', value: 'PULLING' },
      { label: 'CHECKING_IN', value: 'CHECKING_IN' },
      { label: 'ASSEMBLING', value: 'ASSEMBLING' },
      { label: 'SHIPPING', value: 'SHIPPING' },
      { label: 'COMPLETED', value: 'COMPLETED' },
    ],
  },
]

export default columnDef
