export const orderStatusOptions = [
  {
    label: 'Pending payment',
    value: 'pending',
  },
  {
    label: 'Processing',
    value: 'processing',
  },
  {
    label: 'Scheduled',
    value: 'scheduled',
  },
  {
    label: 'Shipped',
    value: 'completed',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
  {
    label: 'Refunded',
    value: 'refunded',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
]
