import trim from 'lodash/trim'
import { toCents } from 'utils/price'
import type { State } from './types'

export const calculateTotals = (state: State): State => {
  const subtotal = state.cartItems.reduce((a, p) => a + p.quantity * p.price, 0)

  const shippingTotal = state.hasShipping
    ? toCents(Number(state.shipping.replace(/,/, '.')))
    : 0

  let warrantyTotal = 0
  let warrantyPercentCalculated = 0
  if (state.hasWarranty) {
    const subtotalWarranty = state.cartItems
      .filter((p) => p.allowWarranty && p.hasWarranty)
      .reduce((a, p) => a + p.quantity * p.price, 0)
    const isPercentWarranty = state.warranty.indexOf('%') > 0
    const warranty = trim(String(state.warranty).replace(/,/, '.'), '%')

    if (isPercentWarranty) {
      warrantyTotal = subtotalWarranty * (Number(warranty) / 100)
      warrantyPercentCalculated = warrantyTotal
    } else {
      warrantyTotal = toCents(Number(warranty))
      warrantyPercentCalculated = toCents(
        subtotalWarranty > 0 ? warrantyTotal / subtotalWarranty : 0,
      )
    }
  }

  let oversizeFeeTotal = 0
  if (state.hasOversizeFee) {
    const subtotalOversizeItems = state.cartItems
      .filter((p) => p.isOversize)
      .reduce((a, p) => a + p.quantity * p.price, 0)
    const isPercentOversizeFee = state.oversizeFee.indexOf('%') > 0
    const oversizeFee = trim(String(state.oversizeFee).replace(/,/, '.'), '%')

    if (isPercentOversizeFee) {
      oversizeFeeTotal = subtotalOversizeItems * (Number(oversizeFee) / 100)
    } else {
      oversizeFeeTotal = toCents(Number(oversizeFee))
    }
  }

  const taxable = subtotal + oversizeFeeTotal
  const nonTaxable = warrantyTotal + shippingTotal

  let discountTotal = 0
  let discountPercentCalculated = 0
  if (state.hasDiscount) {
    const isPercentDiscount = state.discount.indexOf('%') > 0
    const discount = trim(String(state.discount).replace(/,/, '.'), '%')

    if (isPercentDiscount) {
      discountTotal = subtotal * (Number(discount) / 100)
      discountPercentCalculated = discountTotal
    } else {
      discountTotal = toCents(Number(discount))
      discountPercentCalculated =
        subtotal > 0
          ? Number(Number((discountTotal / subtotal) * 100).toFixed(2))
          : 0
    }
  }

  let gross = taxable + nonTaxable
  gross -= discountTotal

  let tax = 0
  if (state.isCustomTax) {
    tax = state.tax
  } else {
    tax = taxable * 0.06
  }

  const total = Math.round(gross + (state.hasTax ? tax : 0))

  const newState = {
    ...state,
    subtotal,
    tax,
    total,
    shippingCalculated: shippingTotal,
    discountCalculated: discountTotal,
    discountPercentCalculated,
    warrantyCalculated: warrantyTotal,
    warrantyPercentCalculated,
    oversizeFeeCalculated: oversizeFeeTotal,
  }

  return newState
}
