import type { GiftCard } from '@ttc/api/giftcards'
import { EditPanel, EditPanelCard, Loading } from 'components'
import GiftCardCard from 'components/Cards/GiftCardCard'
import type { ApiCall } from 'hooks/useApi'
import { Col, Row } from 'reactstrap'
import LogCard from './LogCard'

const Panels = ({
  id,
  readOnly,
  onToggle,
  getGiftCard,
  onUpdate,
}: {
  id: string
  readOnly: boolean
  onToggle?: (e: any) => void
  getGiftCard?: ApiCall<GiftCard>
  onUpdate?: () => void
}) => {
  const result = getGiftCard.result || null
  const caption = `Gift Card ${result?.id}`

  return (
    <>
      <ul className="nav nav-tabs flex-row-reverse">
        <li className="nav-item m-0">
          <a onClick={onToggle} className="active nav-link">
            <i className="icon-list"></i>
          </a>
        </li>
      </ul>

      {!getGiftCard.hasResult && getGiftCard.isLoading ? (
        <div className="tab-content">
          <Loading />
        </div>
      ) : (
        <>
          <EditPanel noHeader embedded isOpen {...{ caption }}>
            <Row>
              <Col className="d-flex justify-content-between align-items-center m-3 pl-1 pr-1">
                <div style={{ flex: 1 }}>{caption}</div>
              </Col>
            </Row>
            {getGiftCard.error ? (
              <div className="tab-content">
                <Row>
                  <Col>
                    <div className="text-danger">{getGiftCard.error}</div>
                  </Col>
                </Row>
              </div>
            ) : null}
          </EditPanel>
          <div
            className="tab-content"
            style={{ height: 'calc(100vh - 210px)' }}
          >
            <EditPanel noHeader embedded isOpen>
              {!getGiftCard.error && getGiftCard.hasResult ? (
                <>
                  <EditPanelCard
                    caption="Info"
                    stateId="giftcards_info"
                    cardProps={{ style: { borderWidth: 0 } }}
                  >
                    <GiftCardCard
                      {...{ data: getGiftCard.result, readOnly, onUpdate }}
                    />
                  </EditPanelCard>
                  {getGiftCard.result ? (
                    <LogCard {...{ id, giftCard: getGiftCard.result }} />
                  ) : null}
                </>
              ) : null}
            </EditPanel>
          </div>
        </>
      )}
    </>
  )
}

export default Panels
