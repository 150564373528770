import type { ColumnDef } from 'components/Table/types'

const columnDef: ColumnDef[] = [
  {
    label: 'Pulled',
    id: 'pulled_qty',
    sortColumn: true,
    isEditable: false,
  },
  {
    label: 'Checked In',
    id: 'checked_in_qty',
    sortColumn: true,
  },
  {
    label: 'Qty',
    id: 'qty',
    sortColumn: true,
  },
  {
    label: 'Product',
    id: 'product',
    sortColumn: true,
    valueColumn: 'product_data.name',
  },
  {
    label: 'Size',
    id: 'size',
    sortColumn: true,
    valueColumn: 'product_data.size',
  },
  {
    label: 'SKU',
    id: 'sku',
    sortColumn: true,
    valueColumn: 'product_data.sku',
  },
]

export default columnDef
