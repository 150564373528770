import {
  EditPanelCard,
  LinkToCustomer,
  LinkToOrder,
  ReturnStatusBadge,
} from 'components'
import CardEditButton from 'containers/common/CardEditButton'
import { useCallback } from 'react'
import { Col, Row } from 'reactstrap'
import { formatDateReadable } from 'utils'
import StatusBadge from '../StatusBadge'

const InfoCard = ({ getReturn, onClickEdit, selectedOrder }) => {
  const { result } = getReturn

  const handleClickOrderNumber = useCallback(
    ({ orderId }) => {
      selectedOrder.set(orderId)
    },
    [selectedOrder],
  )

  const headerActions = <CardEditButton onClick={onClickEdit} />

  return (
    <EditPanelCard
      caption="Info"
      stateId="returns_info"
      cardProps={{ style: { borderWidth: 0 } }}
      headerActions={onClickEdit ? headerActions : null}
    >
      <Row>
        {result.order_number ? (
          <>
            <Col xs={4}>Order Number:</Col>
            <Col>
              <LinkToOrder
                orderNumber={result.order_number}
                orderId={result.order_id}
                onClick={selectedOrder ? handleClickOrderNumber : null}
              />
            </Col>
          </>
        ) : (
          <>
            <Col xs={4}>Order Number:</Col>
            <Col>
              <i>unknown</i>
            </Col>
          </>
        )}
      </Row>
      {result.customer ? (
        <Row>
          <Col xs={4}>Customer:</Col>
          <Col>
            <LinkToCustomer
              {...{
                customerName: result.customer.fullName,
                customerId: result.customer.id,
                email: result.customer.email,
                subscription: result.customer.subscription,
              }}
            />
          </Col>
        </Row>
      ) : null}
      {result.return_reason ? (
        <Row>
          <Col xs={4}>Return Reason:</Col>
          <Col>
            <StatusBadge status={result.return_reason} />
          </Col>
        </Row>
      ) : null}
      {result.return_reason_note ? (
        <Row>
          <Col xs={4}>Reason Note:</Col>
          <Col>{result.return_reason_note}</Col>
        </Row>
      ) : null}
      {result.status ? (
        <Row>
          <Col xs={4}>Status:</Col>
          <Col>
            <ReturnStatusBadge value={result.status} />
          </Col>
        </Row>
      ) : null}
      {result.return_date ? (
        <Row>
          <Col xs={4}>Return Date:</Col>
          <Col>{formatDateReadable(result.return_date)}</Col>
        </Row>
      ) : null}
      {result.items_received_date ? (
        <Row>
          <Col xs={4}>Items received:</Col>
          <Col>{formatDateReadable(result.items_received_date)}</Col>
        </Row>
      ) : null}
    </EditPanelCard>
  )
}

export default InfoCard
