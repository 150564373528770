import type { FormDef } from 'components/Form/types'

const formDef: FormDef = [
  {
    group: [
      {
        label: 'Mature Height',
        name: 'matureHeight',
        type: 'text',
        pattern: '^([\\d.]+)(-[\\d.]+)?$',
      },
      {
        label: 'Mature Width',
        name: 'matureWidth',
        type: 'text',
        pattern: '^([\\d.]+)(-[\\d.]+)?$',
      },
    ],
  },
  {
    label: 'Excluded States',
    name: 'pa_excluded-states',
    type: 'select',
    multi: true,
    options: [],
  },
  {
    label: 'Brand',
    name: 'pa_brand',
    type: 'select',
    options: [],
  },
  {
    group: [
      {
        label: 'GTIN',
        name: 'gtin',
        type: 'text',
      },
      {
        label: 'MPN',
        name: 'mpn',
        type: 'text',
      },
    ],
  },
  {
    label: 'Plant Type',
    name: 'pa_plant_type',
    type: 'select',
    multi: true,
    options: [],
  },
  {
    label: 'Sun Needs',
    name: 'pa_sun-needs',
    type: 'select',
    multi: true,
    options: [],
  },
  {
    label: 'Special Features',
    name: 'pa_special-features',
    type: 'select',
    multi: true,
    options: [],
  },
  {
    label: 'Water Needs',
    name: 'pa_water-needs',
    type: 'select',
    multi: true,
    options: [],
  },
  {
    label: 'Style',
    name: 'pa_style',
    type: 'select',
    multi: true,
    options: [],
  },
  {
    label: 'Flower Color',
    name: 'pa_flower-color',
    type: 'select',
    multi: true,
    options: [],
  },
  {
    label: 'Flowering Season',
    name: 'pa_flowering-season',
    type: 'select',
    multi: true,
    options: [],
  },
  {
    label: 'Growth Rate',
    name: 'pa_growth-rate',
    type: 'select',
    multi: true,
    options: [],
  },
]

export default formDef
