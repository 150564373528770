import type { NoteEntry } from '@ttc/api/notes'
import { useCallback } from 'react'
import { formatDateReadable } from 'utils/date'

const Note = ({
  note,
  onRemove,
}: {
  note: NoteEntry
  onRemove?: (id: NoteEntry) => void
}) => {
  const { id, created_by_name, created_at } = note

  const handleClickRemove = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()

      if (onRemove) {
        onRemove(note)
      }
    },
    [note, onRemove],
  )

  return (
    <li className="mb-2" key={id}>
      <div style={{ whiteSpace: 'pre-wrap' }}>{note.note}</div>
      <div style={{ fontSize: 11, fontStyle: 'italic' }}>
        {created_by_name} on {formatDateReadable(created_at, true)}
        {onRemove ? (
          <a className="pl-2" href="#" onClick={handleClickRemove}>
            (Remove)
          </a>
        ) : null}
      </div>
    </li>
  )
}

export default Note
