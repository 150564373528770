import { useStateful } from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { parentHasClass } from 'utils'

export const useNotesCell = () => {
  const dropDownState = useStateful({
    id: null,
    isEditing: false,
  })

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement

      if (
        dropDownState.value &&
        dropDownState.value.id != null &&
        dropDownState.value.isEditing !== false &&
        !parentHasClass(target, 'popover-inner') &&
        !parentHasClass(target, 'notes-icon')
      ) {
        dropDownState.set({ id: null, isEditing: false })
      }
    },
    [dropDownState],
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  return useMemo(
    () => ({
      dropDownState,
    }),
    [dropDownState],
  )
}
