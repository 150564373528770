import type { OrderLineItem } from '@ttc/api/orders'
import LineItem from './LineItem'
import type { QtyEditor } from './useQtyEditor'

/**
 * LineItemEditor is a functional component that displays and manages a list of items in an order.
 * It leverages useQtyEditor hook for the management and interaction with line items' quantities.
 */
const LineItemEditor = ({
  items,
  qtyEditor,
}: {
  items: OrderLineItem[]
  qtyEditor: QtyEditor
}) => {
  return (
    <div
      style={{
        borderTop: '1px solid #c5dbea',
        borderBottom: '1px solid #c5dbea',
      }}
    >
      {items.map((item, i: number) => (
        <LineItem
          key={`${item.id}.${item.product_data.id}}`}
          item={item}
          isLast={i === items.length - 1}
          selectQty={qtyEditor.getProductQty(item.product_data.id)}
          onClickIncQty={qtyEditor.inc}
          onClickDecQty={qtyEditor.dec}
        />
      ))}
    </div>
  )
}

export default LineItemEditor
