const getStorage = (
  prefix: string,
): [(key: string, value: string) => void, (key: string) => string] => {
  const setItem = (key: string, value: string) => {
    if (!prefix) {
      return
    }
    try {
      localStorage.setItem(`${prefix}.${key}`, value)
    } catch (e) {
      console.warn(e) // eslint-disable-line
    }
  }
  const getItem = (key: string) => {
    if (!prefix) {
      return ''
    }
    try {
      return localStorage.getItem(`${prefix}.${key}`)
    } catch (e) {
      console.warn(e) // eslint-disable-line
      return ''
    }
  }

  return [setItem, getItem]
}

export { getStorage }
