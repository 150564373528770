import SubscriptionStatusBadge from 'components/Badges/SubscriptionStatusBadge'
import type { ColumnDef } from 'components/Table/types'
import { customerCellRenderer } from 'components/cellRenderer'

const columnDef: ColumnDef[] = [
  {
    label: '',
    id: 'checkbox',
  },
  {
    label: 'ID',
    id: 'id',
    sortColumn: true,
    cursor: 'pointer',
  },
  {
    label: 'Login Email',
    id: 'email',
    sortColumn: true,
    hasClipboardButton: true,
  },
  {
    label: 'Username',
    id: 'username',
    sortColumn: true,
  },
  {
    label: 'Name',
    id: 'fullName',
    sortColumn: true,
    renderer: customerCellRenderer,
  },
  {
    label: 'Billing Email',
    id: 'billingEmail',
    sortColumn: true,
    defaultVisible: false,
    hasClipboardButton: true,
  },
  {
    label: 'Phone',
    id: 'billingPhone',
    sortColumn: false,
    defaultVisible: false,
  },
  {
    label: 'Shipping address',
    id: 'fullShippingAddress',
    sortColumn: true,
    defaultVisible: false,
  },
  {
    label: 'Billing address',
    id: 'fullBillingAddress',
    sortColumn: true,
    defaultVisible: false,
  },
  {
    label: 'Subscription',
    id: 'subscription',
    valueColumn: 'subscription.status',
    sortColumn: true,
    renderer: ({ row }) => (
      <td>
        {row.subscription ? (
          <SubscriptionStatusBadge subscription={row.subscription} />
        ) : null}
      </td>
    ),
  },
  {
    label: 'Created at',
    id: 'createdAt',
    sortColumn: true,
  },
  {
    label: 'Last login',
    id: 'lastLogin',
    sortColumn: true,
  },
  {
    label: 'Last active',
    id: 'lastActive',
    sortColumn: true,
  },
  {
    label: 'Orders',
    id: 'orderCount',
    defaultVisible: false,
    sortColumn: true,
  },
]

export default columnDef
