import { Component } from 'react'

type Props = Record<string, never>

type State = {
  activeTab: string
}

class DefaultAside extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: '1',
    }
  }

  toggle(tab: string) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  render() {
    return <div id="sidebar-content"></div>
  }
}

export default DefaultAside
