import { useCallback } from 'react'

const ZoneButton = ({
  selected,
  index,
  onClick,
  disabled,
}: {
  selected?: boolean
  index: number
  onClick?: (index: number) => void
  disabled?: boolean
}) => {
  const className = selected ? 'btn-primary' : 'btn-outline-primary'

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      if (onClick) {
        onClick(index)
      }
    },
    [index, onClick],
  )

  return (
    <button
      type="button"
      onClick={handleClick}
      style={{ flex: 1, fontWeight: selected ? 'bold' : null }}
      className={`m-0 btn btn-sm btn-block display-inline ${className}`}
      {...{ disabled }}
    >
      {index}
    </button>
  )
}

export default ZoneButton
