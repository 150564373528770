import {
  EditPanelCard,
  LinkButton,
  LinkToOrder,
  OrderStatusBadge,
} from 'components'
import CardEditButton from 'containers/common/CardEditButton'
import { useApi, useBoolean, useStateful } from 'hooks'
import { useCallback } from 'react'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { askQuestion } from 'utils'

type Order = {
  id: number
  orderNumber: string
  status: string
}

const ReplacementsCard = ({ id, getReturn, readOnly, selectedOrder }) => {
  const { result } = getReturn
  const { replacement_orders } = result
  const editMode = useBoolean()
  const orderNumberInput = useStateful('')

  const handleClickOrderNumber = useCallback(
    ({ orderId }) => {
      selectedOrder.set(orderId)
    },
    [selectedOrder],
  )

  const apiRemoveOrder = useApi(
    { action: 'returns_removeReplacementOrder' },
    null,
    () => ({ errorModal: true }),
  )

  const handleClickRemoveOrder = useCallback(
    async (orderNumber) => {
      if (
        !(await askQuestion(
          'Are you sure you want to remove this order association?',
        ))
      ) {
        return
      }

      await apiRemoveOrder.performRequest({
        json: { id, orderNumber },
      })
      getReturn.performRequest()
    },
    [id, apiRemoveOrder, getReturn],
  )

  const headerActions = (
    <CardEditButton isEditing={editMode.value} onClick={editMode.toggle} />
  )

  const handleChangeOrderNumber = useCallback(
    (e) => {
      orderNumberInput.set(e.target.value)
    },
    [orderNumberInput],
  )

  const apiAddOrder = useApi(
    { action: 'returns_addReplacementOrder' },
    null,
    () => ({
      errorModal: true,
      throws: true,
    }),
  )

  const handleClickSubmit = useCallback(async () => {
    try {
      await apiAddOrder.performRequest({
        json: { id, orderNumber: orderNumberInput.value },
      })
      editMode.off()
      orderNumberInput.set('')
      getReturn.performRequest()
    } catch (_e) {
      /* Ignore */
    }
  }, [orderNumberInput, getReturn, id, apiAddOrder, editMode])

  const handleClickCancel = useCallback(() => {
    editMode.off()
  }, [editMode])

  const isLoading = apiAddOrder.isLoading
  const canSubmit = !isLoading && orderNumberInput.value.length

  return (
    <EditPanelCard
      caption="Replacement Orders"
      stateId="returns_replacements"
      headerActions={!readOnly ? headerActions : null}
    >
      {editMode.value ? (
        <>
          <Row>
            <Col>
              <a
                target="_blank"
                rel="noreferrer"
                href={`/orders/add?returnId=${id}`}
              >
                Create new replacement order with "Add Order"...
              </a>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <FormGroup>
                <Label for="note_textarea">
                  Associate existing Replacement Order:
                </Label>
                <Input
                  type="text"
                  name="orderNumber"
                  id="orderNumber_input"
                  placeholder="Enter Order Number"
                  value={orderNumberInput.value}
                  onChange={handleChangeOrderNumber}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              <Button
                disabled={!canSubmit}
                size="sm"
                className="m-2"
                onClick={handleClickSubmit}
              >
                Save
              </Button>
              <Button
                disabled={isLoading}
                size="sm"
                className="m-2"
                onClick={handleClickCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
          <hr />
        </>
      ) : null}
      {replacement_orders.length ? (
        <Row className="mt-2 mb-2">
          <Col>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              {replacement_orders.map((order: Order) => {
                return (
                  <li className="mb-2" key={order.id}>
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                      <LinkToOrder
                        hasClipButton
                        orderNumber={order.orderNumber}
                        orderId={order.id}
                        onClick={selectedOrder ? handleClickOrderNumber : null}
                      />
                      <OrderStatusBadge className="ml-2" value={order.status} />
                      {editMode.value ? (
                        <LinkButton
                          className="ml-2 text-danger"
                          onClick={() =>
                            handleClickRemoveOrder(order.orderNumber)
                          }
                        >
                          Remove
                        </LinkButton>
                      ) : null}
                    </div>
                  </li>
                )
              })}
            </ul>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <i>No replacement orders have been associated yet.</i>
          </Col>
        </Row>
      )}
    </EditPanelCard>
  )
}

export default ReplacementsCard
