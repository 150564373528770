import DiscountBadge from 'components/Badges/DiscountBadge'
import type { ColumnDef } from 'components/Table/types'
import LinkToInventory from './LinkToInventory'

const columnDef: ColumnDef[] = [
  {
    label: 'Name',
    id: 'name',
    sortColumn: true,
    renderer: ({ value, row }) => {
      return (
        <td>
          <DiscountBadge
            {...{ value, isDisabled: row.status === 'INACTIVE' }}
          />
        </td>
      )
    },
  },
  {
    label: 'Type',
    id: 'type',
    sortColumn: true,
    renderer: ({ value }) => {
      return (
        <td>
          <DiscountBadge {...{ value }} />
        </td>
      )
    },
  },
  {
    label: 'Description',
    id: 'description',
    sortColumn: true,
  },
  {
    label: 'Configuration',
    id: 'configuration',
    sortColumn: false,
  },
  {
    label: 'Used by',
    id: 'usedBy',
    sortColumn: false,
    renderer: ({ value, row }) => {
      return (
        <td>
          {value ? (
            <LinkToInventory discountId={Number(row.id)}>
              {value} products
            </LinkToInventory>
          ) : null}
        </td>
      )
    },
  },
]

export default columnDef
