import { ClipboardButton, Loading } from 'components'
import { useCallback } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'

type AddressCardProps = {
  title: string
  isLoading: boolean
  onClickEdit: () => void
  addressType: string
  customer: any
}

const AddressCard = (props: AddressCardProps) => {
  const { title, isLoading, onClickEdit, addressType, customer } = props

  const handleClickEdit = useCallback(
    (e) => {
      e.preventDefault()
      onClickEdit()
    },
    [onClickEdit],
  )

  const renderAddress = useCallback(() => {
    if (!customer) {
      return null
    }

    return (
      <div>
        {customer[`${addressType}FirstName`]}{' '}
        {customer[`${addressType}LastName`]}
        <br />
        {customer[`${addressType}Address1`] ? (
          <>
            {customer[`${addressType}Address1`]}
            <br />
          </>
        ) : null}
        {customer[`${addressType}Address2`] ? (
          <>
            {customer[`${addressType}Address2`]}
            <br />
          </>
        ) : null}
        {customer[`${addressType}City`]}
        {customer[`${addressType}City`] ? ', ' : null}
        {customer[`${addressType}State`]} {customer[`${addressType}Postcode`]}
        <br />
        {customer[`${addressType}Email`] ? (
          <>
            <ClipboardButton
              value={customer[`${addressType}Email`]}
              text={customer[`${addressType}Email`]}
            />
            <br />
          </>
        ) : null}
        {customer[`${addressType}Phone`] ? (
          <>
            {customer[`${addressType}Phone`]}
            <br />
          </>
        ) : null}
      </div>
    )
  }, [customer, addressType])

  return (
    <Card className="h-100">
      <CardHeader>
        <span className="align-bottom">{title}</span>
        {onClickEdit ? (
          <div className="card-header-actions">
            <a
              href="#"
              onClick={handleClickEdit}
              className="card-header-action btn btn-setting"
            >
              <i className="fa fa-pencil" />
            </a>
          </div>
        ) : null}
      </CardHeader>
      <CardBody className="d-flex flex-column">
        {!customer && isLoading ? (
          <Loading />
        ) : customer ? (
          <>
            <div className="mb-2" style={{ flex: 1 }}>
              {renderAddress()}
            </div>
          </>
        ) : null}
      </CardBody>
    </Card>
  )
}

export default AddressCard
