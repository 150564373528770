import type { StoreCreditTemplate } from '@ttc/api/storeCredits'
import { getStyles } from 'components/selectStyles'
import { useApi } from 'hooks'
import sortBy from 'lodash/sortBy'
import { useCallback, useEffect, useState } from 'react'
import Select from 'react-select'

const styles = getStyles({ highlightIfEmpty: true })

const normalizeTemplates = (templates: StoreCreditTemplate[]) => {
  const ret = []
  for (const templateId of Object.keys(templates)) {
    const template = templates[templateId]
    const { title, mail, prio } = template

    const label = `${prio} - ${title}`
    ret.push({
      ...template,
      value: templateId,
      label,
      mail,
      prio: Number(prio),
    })
  }
  return sortBy(ret, 'prio').reverse()
}

type SelectTemplateProps = {
  name: string
  value: string
  onChange: (name: string, option: StoreCreditTemplate) => void
  className: string
  autoSelectFirst?: boolean
}

const SelectTemplate = (props: SelectTemplateProps) => {
  const { name, value, onChange, className, autoSelectFirst } = props

  const apiTemplates = useApi(
    () => ({ action: 'storeCredits_getTemplates' }),
    () => [],
    () => ({
      normalize: normalizeTemplates,
      autoPerform: true,
    }),
  )
  const options = apiTemplates.result
  const { isLoading, error, hasResult } = apiTemplates
  const [didInit, setDidInit] = useState(false)

  useEffect(() => {
    if (!didInit && onChange && options.length) {
      if (value == null && autoSelectFirst !== false) {
        onChange(name, options[0])
      }
      if (value != null) {
        const option = options.find((o: any) => o.value === value)
        if (option != null) {
          onChange(name, option)
        }
      }
      setDidInit(true)
    }
  }, [
    autoSelectFirst,
    didInit,
    setDidInit,
    onChange,
    value,
    hasResult,
    name,
    options,
  ])

  const handleChange = useCallback(
    (val: any) => {
      if (onChange) {
        onChange(name, val)
      }
    },
    [onChange, name],
  )

  return (
    <Select
      {...{ name, styles, options }}
      isClearable
      escapeClearsValue
      isDisabled={!hasResult || isLoading}
      className={`select-state ${className}`}
      placeholder={
        isLoading
          ? 'Loading Templates...'
          : error
            ? error
            : 'Select E-Mail Template'
      }
      value={
        typeof value === 'object'
          ? value
          : options.find((o: any) => o.value == value)
      }
      onChange={handleChange}
    />
  )
}

export default SelectTemplate
