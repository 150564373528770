export const customerCellRendererInner = ({ value, row }) => {
  const subscription = row.subscription
    ? row.subscription
    : row.customer?.subscription

  return (
    <>
      {subscription ? (
        <>
          <span title={subscription.plan_description}>
            {subscription.plan_admin_icon}
          </span>{' '}
          {value}
        </>
      ) : (
        value
      )}
    </>
  )
}

export const customerCellRenderer = (props) => (
  <td>{customerCellRendererInner(props)}</td>
)
