import { sleep } from 'utils'

const JobHandler = {
  test: async ({ job }) => {
    console.log(`START ${job.id}`) // eslint-disable-line
    await sleep(500)
    console.log(`STOP ${job.id}`) // eslint-disable-line
  },

  sendFileData: async ({ zebraPrinter, job }) => {
    const { data } = job
    const { printer } = zebraPrinter

    return new Promise((resolve, reject) => {
      try {
        printer.sendFile(data, resolve, (e: any) => {
          if (e) {
            reject(new Error(e))
          } else {
            reject(
              new Error(
                'Unable to print, make sure the printer is correctly setup.',
              ),
            )
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  },

  printConfigLabel: async ({ zebraPrinter }) => {
    const { printer } = zebraPrinter

    return new Promise((resolve, reject) => {
      try {
        printer.send('~wc', resolve, (e: any) => {
          if (e) {
            reject(new Error(e))
          } else {
            reject(
              new Error(
                'Unable to print, make sure the printer is correctly setup.',
              ),
            )
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  },

  printTestLabel: async ({ job, zebraPrinter }) => {
    const { data } = job
    const { printer } = zebraPrinter

    return new Promise((resolve, reject) => {
      try {
        printer.sendFile(data, resolve, (e: any) => {
          if (e) {
            reject(new Error(e))
          } else {
            reject(
              new Error(
                'Unable to print, make sure the printer is correctly setup.',
              ),
            )
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  },
}

export default JobHandler
