import type { CartItemType } from 'containers/AddOrder/useCart/types'
import { useForm } from 'hooks'
import { useCallback, useEffect } from 'react'
import { Col, CustomInput, FormGroup, Input, Label } from 'reactstrap'
import { formatPrice } from 'utils/price'

type CancelOrderModalProps = {
  title: string
  subtitle?: string
  refundPlan: any
  onChangeState: (state: any) => void
  cartItems: CartItemType[]
}

const CancelOrderModal = (props: CancelOrderModalProps) => {
  const { onChangeState, title, subtitle, refundPlan, cartItems } = props

  const initialState = {
    shouldRefund: true,
    refundAmount: formatPrice(refundPlan.refundAmount).replace(/,/g, ''),
    reason: '',
    skipRestoreInventoryProductIds: [],
  }

  const form = useForm(initialState)

  const isRestoreInventoryChecked = (productId: number) =>
    !form.state.skipRestoreInventoryProductIds.includes(productId)

  const handleChangeRestoreInventory = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const productId = Number(e.currentTarget.getAttribute('data-product-id'))

      if (!productId) {
        return
      }

      const { checked } = e.currentTarget

      let skipRestoreInventoryProductIds =
        form.state.skipRestoreInventoryProductIds

      if (checked && skipRestoreInventoryProductIds.includes(productId)) {
        skipRestoreInventoryProductIds =
          form.state.skipRestoreInventoryProductIds.filter(
            (id: number) => id !== productId,
          )
      } else if (!checked) {
        skipRestoreInventoryProductIds = [
          ...skipRestoreInventoryProductIds,
          productId,
        ]
      }

      form.set('skipRestoreInventoryProductIds', skipRestoreInventoryProductIds)
    },
    [form],
  )

  useEffect(() => {
    if (onChangeState) {
      onChangeState(form.state)
    }
  }, [onChangeState, form.state])

  return (
    <div style={{ padding: 5 }}>
      <div className="font-weight-bold">{title}</div>
      <div>{subtitle}</div>
      <div className="text-left">
        <br />
        <FormGroup row>
          <Col md="12">
            <CustomInput
              {...form.inputProps}
              className="checkbox"
              type="checkbox"
              bsSize="lg"
              name="shouldRefund"
              id="refund-checkbox"
              label="Perform Refund"
              checked={form.state.shouldRefund}
            />
          </Col>
        </FormGroup>
        {form.state.shouldRefund ? (
          <>
            <table
              style={{ fontSize: 12 }}
              className="mt-4 table table-condenced2 table-bordered text-left"
            >
              <tbody>
                {refundPlan.removeItems.map((item: any, i: number) => {
                  return (
                    <tr
                      style={{ fontWeight: item.isBold ? 'bold' : null }}
                      key={i}
                    >
                      <td>{item.name}</td>
                      <td>{formatPrice(item.amount)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <FormGroup row>
              <Col md="3" style={{ alignSelf: 'center' }}>
                <Label className="mb-0" htmlFor="refund-amount">
                  Amount
                </Label>
              </Col>
              <Col md="9">
                <Input
                  {...form.inputProps}
                  id="refund-amount"
                  className="form-control d-inline"
                  placeholder={initialState.refundAmount}
                  name="refundAmount"
                  value={form.state.refundAmount}
                />
              </Col>
            </FormGroup>
          </>
        ) : null}
        <FormGroup row>
          <Col md="3" style={{ alignSelf: 'center' }}>
            <Label className="mb-0" htmlFor="refund-reason">
              Reason
            </Label>
          </Col>
          <Col md="9">
            <Input
              {...form.inputProps}
              id="refund-reason"
              className="form-control d-inline"
              placeholder={initialState.reason}
              name="reason"
              value={form.state.reason}
            />
          </Col>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Col md="12">
            {cartItems
              .filter((item) => item.isManagingStock)
              .map((item) => {
                return (
                  <div key={item.id}>
                    <label style={{ fontSize: '70%' }}>
                      <input
                        data-product-id={item.productId}
                        onChange={handleChangeRestoreInventory}
                        className="checkbox mr-2"
                        type="checkbox"
                        name={`refund-checkbox-${item.id}`}
                        id={`refund-checkbox-${item.id}`}
                        checked={isRestoreInventoryChecked(item.productId)}
                      />
                      Restore Inventory for {item.name} {item.sizeShort} (Qty:{' '}
                      {item.originalQuantity})
                    </label>
                  </div>
                )
              })}
          </Col>
        </FormGroup>
      </div>
    </div>
  )
}

export default CancelOrderModal
