import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler,
} from '@coreui/react'
import AuthContext from 'AuthContext'
import { Component, Fragment } from 'react'
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
} from 'reactstrap'

type DefaultHeaderProps = {
  onLogout: (e: React.MouseEvent<HTMLElement>) => void
}

class DefaultHeader extends Component<DefaultHeaderProps> {
  static contextType = AuthContext
  declare context: React.ContextType<typeof AuthContext>

  render() {
    const login = this.context.userName || ''

    const envBadge = global.appConfig.envBadge
    const profileImageUrl =
      this.context.profileImageUrl || '../../assets/img/default_profile.jpg'

    return (
      <Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{
            src: '/assets/img/logo.png',
            width: 46,
            height: 37,
            alt: 'TTC',
          }}
          minimized={{
            src: '/assets/img/logo.png',
            width: 46 * 0.8,
            height: 37 * 0.8,
            alt: 'TTC',
          }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <div style={{ color: 'white' }}>The Tree Center</div>
        <Nav className="ml-auto" navbar>
          {envBadge ? (
            <NavItem>
              <Badge style={{ fontSize: '15px' }} color="primary">
                {envBadge}
              </Badge>
            </NavItem>
          ) : null}
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <img src={profileImageUrl} className="img-avatar" alt={login} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header tag="div" className="text-center">
                <strong>{login}</strong>
              </DropdownItem>
              <a
                target="noopener"
                href={`${global.appConfig.wpBaseUrl}/wp-admin/admin.php?page=WFLS`}
                tabIndex={0}
                role="menuitem"
                className="dropdown-item"
              >
                <i className="fa fa-gear"></i> 2FA Settings...
              </a>
              <DropdownItem onClick={(e) => this.props.onLogout(e)}>
                <i className="fa fa-lock" /> Logout
              </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </Fragment>
    )
  }
}

export default DefaultHeader
