import type { FormDef } from 'components/Form/types'

const formDef: FormDef = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
  },
  {
    label: 'Last Name',
    name: 'lastName',
    type: 'text',
  },
  {
    label: 'Email',
    name: 'email',
    type: 'text',
    required: true,
  },
]

export default formDef
