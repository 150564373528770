import type { Action } from '../useCart/types'
import CartItem from './CartItem'

type CartItemsProps = {
  dispatch: React.Dispatch<Action>
  readOnly: boolean
  isEditing: boolean
  cartItems: any
  shippingState: string
}

const CartItems = (props: CartItemsProps) => {
  const { dispatch, readOnly, isEditing, cartItems, shippingState } = props

  if (cartItems.length === 0) {
    return (
      <div className="mt-3 mb-2">
        <div
          style={{ borderBottom: '1px dotted #c8ced3' }}
          className="pt-2 pb-2"
        >
          Cart empty.
        </div>
      </div>
    )
  }

  return (
    <div className="mt-3 mb-2">
      {cartItems.map((cartItem: any) => {
        return (
          <CartItem
            key={`${cartItem.id}-${cartItem.lineItemId}`}
            {...{ dispatch, shippingState, readOnly, isEditing, cartItem }}
          />
        )
      })}
    </div>
  )
}

export default CartItems
