import type { ApiCall } from 'app/hooks/useApi'
import { Portal } from 'components'
import type { Row } from 'components/Table'
import Editor from './Editor'

const Sidebar = ({
  readOnly,
  selectedOrder,
  selectedRows,
  onToggle,
  getReturn,
  onClickEdit,
  onBack,
}: {
  readOnly?: boolean
  selectedOrder?: any
  selectedRows?: Row[]
  onToggle?: (e: any) => void
  getReturn?: ApiCall
  onClickEdit?: (id: string) => void
  onBack?: () => void
}) => {
  if (selectedRows.length === 1) {
    return (
      <Portal id="sidebar-content">
        <Editor
          {...{
            readOnly,
            id: selectedRows[0],
            getReturn,
            onToggle,
            onClickEdit,
            selectedOrder,
            onBack,
          }}
        />
      </Portal>
    )
  }

  return (
    <Portal id="sidebar-content">
      <ul className="nav nav-tabs flex-row-reverse">
        <li className="nav-item">
          <a onClick={onToggle} className="active nav-link">
            <i className="icon-list"></i>
          </a>
        </li>
      </ul>

      <div className="tab-content">
        {selectedRows.length ? (
          <div className="p-3">{selectedRows.length} returns selected.</div>
        ) : (
          <div className="p-3">No return selected.</div>
        )}
      </div>
    </Portal>
  )
}

export default Sidebar
