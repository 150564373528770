export const ADMIN = 'admin'
export const MANAGEORDERS_READ = 'manageOrders_read'
export const MANAGEORDERS_READWRITE = 'manageOrders_readwrite'
export const MANAGEORDERS_STATUS_FILTER_TABS_READWRITE =
  'manageOrdersStatusFilterTabs_readwrite'
export const MANAGECUSTOMERS_READ = 'manageCustomers_read'
export const MANAGECUSTOMERS_READWRITE = 'manageCustomers_readwrite'
export const MANAGERETURNS_READ = 'manageReturns_read'
export const MANAGERETURNS_READWRITE = 'manageReturns_readwrite'
export const MANAGEGIFTCARDS_READ = 'manageGiftCards_read'
export const MANAGEGIFTCARDS_READWRITE = 'manageGiftCards_readwrite'
export const MANAGESTORECREDIT_READ = 'manageStoreCredit_read'
export const MANAGESTORECREDIT_READWRITE = 'manageStoreCredit_readwrite'
export const MANAGESTORECREDITTEMPLATES_READ = 'manageStoreCreditTemplates_read'
export const MANAGESTORECREDITTEMPLATES_READWRITE =
  'manageStoreCreditTemplates_readwrite'
export const MANAGEBATCHES_READ = 'manageBatches_read'
export const MANAGEBATCHES_READWRITE = 'manageBatches_readwrite'
export const EDITORDERS_READWRITE = 'editOrders_readwrite'
export const MANAGEINVENTORY_READ = 'manageInventory_read'
export const MANAGEINVENTORY_READWRITE = 'manageInventory_readwrite'
export const MANAGEINVENTORYPURCHASING_READ = 'manageInventoryPurchasing_read'
export const MANAGEINVENTORYPURCHASING_READWRITE =
  'manageInventoryPurchasing_readwrite'
export const PURCHASEORDERS_READ = 'purchaseOrders_read'
export const PURCHASEORDERS_READWRITE = 'purchaseOrders_readwrite'
export const DRAFTPURCHASEORDERS_READ = 'draftPurchaseOrders_read'
export const DRAFTPURCHASEORDERS_READWRITE = 'draftPurchaseOrders_readwrite'
export const MANAGEDELIVERIES_READ = 'manageDeliveries_read'
export const MANAGEDELIVERIES_READWRITE = 'manageDeliveries_readwrite'
export const SHIPPINGEMPLOYEES_READ = 'shippingEmployees_read'
export const SHIPPINGEMPLOYEES_READWRITE = 'shippingEmployees_readwrite'
export const SHIPPINGREPORT_READ = 'shippingReport_read'
export const SHIPNOW_READ = 'shipNow_read'
export const SHIPNOW_READWRITE = 'shipNow_readwrite'
export const MANAGESHIPMENTS_READ = 'manageShipments_read'
export const MANAGESHIPMENTS_READWRITE = 'manageShipments_readwrite'
export const MANAGEZONES_READ = 'manageZones_read'
export const MANAGEZONES_READWRITE = 'manageZones_readwrite'
export const MANAGECONTENT_READ = 'manageContent_read'
export const MANAGECONTENT_READWRITE = 'manageContent_readwrite'
export const MANAGETABLECOLUMNPRESETS_READWRITE =
  'manageTableColumnPresets_readwrite'
export const MANAGESUBSCRIPTIONS_READ = 'manageSubscriptions_read'
export const MANAGESUBSCRIPTIONS_READWRITE = 'manageSubscriptions_readwrite'
export const MANAGESUBSCRIPTIONPLANS_READ = 'manageSubscriptionPlans_read'
export const MANAGESUBSCRIPTIONPLANS_READWRITE =
  'manageSubscriptionPlans_readwrite'
export const MANAGEDISCOUNTS_READ = 'manageDiscounts_read'
export const MANAGEDISCOUNTS_READWRITE = 'manageDiscounts_readwrite'
