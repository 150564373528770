import { apiRequest } from 'api'
import { trim } from 'lodash'
import get from 'lodash/get'
import { _reducer, setCustomerId, setValues } from './index'
import type { Action, State } from './types'

export async function placeOrder(
  _state: State,
  _dispatch: React.Dispatch<Action>,
) {
  let ret: any = null
  let state = { ..._state }

  const dispatch = (action: Action) => {
    _dispatch(action)
    state = _reducer(state, action)
  }

  try {
    /* Update Customer */
    dispatch(setValues({ orderProgress: 'Update customer...' }))

    await apiRequest({
      action: 'placeOrder_checkAllowed',
      json: state,
    })

    ret = await apiRequest({
      action: 'placeOrder_updateCustomer',
      json: state,
    })

    dispatch(setCustomerId(ret.customerId))

    /* Update Payment Info */
    dispatch(setValues({ orderProgress: 'Update payment info...' }))

    ret = await apiRequest({
      action: 'placeOrder_updatePayment',
      json: state,
    })

    if (ret.cards != null) {
      dispatch(setValues({ cardsOnFile: ret.cards }))
    }
    if (ret.cardId != null) {
      dispatch(setValues({ cardsOnFileId: ret.cardId }))
    }

    /* Create Order */
    dispatch(setValues({ orderProgress: 'Saving order...' }))

    ret = await apiRequest({
      action: 'placeOrder_updateOrder',
      json: state,
    })

    dispatch(
      setValues({
        orderNumber: ret.orderNumber,
        orderId: ret.orderId,
        orderPlaced: true,
        orderProgress: '',
      }),
    )

    return ret.orderNumber
  } catch (e) {
    dispatch(setValues({ orderProgress: '' }))
    throw e
  }
}

export async function fetchCustomerDetails(
  customerId: string,
  fallbackEmail: string,
) {
  const data = await apiRequest({
    action: 'customers_getDetails',
    customerId,
  })
  const billingEmail = get(data, 'billing.email', '')
  const shippingPhone = String(get(data, 'billing.phone', '')).replace(
    /\D/g,
    '',
  )

  const cardsOnFile = await apiRequest({
    action: 'customers_getCards',
    customerId,
  })
  const cardsOnFileId = cardsOnFile.length
    ? cardsOnFile[cardsOnFile.length - 1].value
    : null

  const firstName = get(data, 'first_name', '')
  const lastName = get(data, 'last_name', '')
  const subscriptionIcon = get(data, 'subscription_icon', '')

  return {
    customer: {
      value: customerId,
      label: trim(
        `${subscriptionIcon} ${firstName} ${lastName} (${customerId} - ${billingEmail})`,
      ),
    },
    shippingFirstName: get(data, 'shipping.first_name', ''),
    shippingLastName: get(data, 'shipping.last_name', ''),
    shippingCompany: get(data, 'shipping.company', ''),
    shippingAddress: get(data, 'shipping.address_1', ''),
    shippingAddress2: get(data, 'shipping.address_2', ''),
    shippingCity: get(data, 'shipping.city', ''),
    shippingState: get(data, 'shipping.state', ''),
    shippingZip: get(data, 'shipping.postcode', ''),
    shippingEmail: billingEmail.length ? billingEmail : fallbackEmail,
    shippingPhone,
    billingFirstName: get(data, 'billing.first_name', ''),
    billingLastName: get(data, 'billing.last_name', ''),
    billingCompany: get(data, 'billing.company', ''),
    billingAddress: get(data, 'billing.address_1', ''),
    billingAddress2: get(data, 'billing.address_2', ''),
    billingCity: get(data, 'billing.city', ''),
    billingState: get(data, 'billing.state', ''),
    billingZip: get(data, 'billing.postcode', ''),
    cardsOnFile,
    cardsOnFileId,
    isCardOnFile: cardsOnFileId != null,
    isStoreCredit: false,
  }
}

export async function deleteCard(customerId: string, cardId: string) {
  await apiRequest({
    action: 'customers_deleteCard',
    customerId,
    cardId,
  })
  return await apiRequest({
    action: 'customers_getCards',
    customerId,
  })
}
