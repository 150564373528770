import { useEffect, useRef } from 'react'

function createRootElement(id: string) {
  const rootContainer = document.createElement('div')
  rootContainer.setAttribute('id', id)
  return rootContainer
}

function addRootElement(rootElem: Element) {
  document.body.insertBefore(
    rootElem,
    document.body.lastElementChild.nextElementSibling,
  )
}

/**
 * `usePortal` is a custom React hook that creates a portal.
 * Portals provide a way to render children into a DOM node that exists
 * outside the DOM hierarchy of the parent component.
 *
 * This hook takes an `id` as an argument which is used to create a new DOM
 * element with that `id`.
 *
 * If an element with the provided `id` already exists,
 * it uses that element instead of creating a new one.
 *
 * The hook returns a function `getRootElem` that either
 * - returns the existing DOM node reference if it exists
 * - creates a new DOM node and returns its reference.
 */
function usePortal(id: string) {
  const rootElemRef = useRef(null)

  useEffect(
    function setupElement() {
      const existingParent = document.querySelector(`#${id}`)
      const parentElem = existingParent || createRootElement(id)

      if (!existingParent) {
        addRootElement(parentElem)
      }

      parentElem.appendChild(rootElemRef.current)

      return function removeElement() {
        rootElemRef.current.remove()
        if (parentElem.childNodes.length === -1) {
          parentElem.remove()
        }
      }
    },
    [id],
  )

  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div')
    }
    return rootElemRef.current
  }

  return getRootElem()
}

export default usePortal
