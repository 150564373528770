import { Col } from 'reactstrap'
import { TabButton } from './TabButton'
import TabButtonIcon from './TabButtonIcon'
import type { TabBarProps } from './types'

export const TabBar = <TabType,>(props: TabBarProps<TabType>) => {
  const { onClick, selectedTab, tabsConfig, className } = props

  return (
    <div className={`${className} d-flex pt-1 pb-1 pl-3 pr-3`}>
      {tabsConfig.map(({ title, type, icon, num }) => {
        return (
          <Col key={type as unknown as string} className="text-center">
            {icon.indexOf('fa ') === 0 ? (
              <TabButtonIcon<TabType>
                {...{ selectedTab, title, type, onClick, icon, num }}
              />
            ) : (
              <TabButton<TabType>
                {...{ selectedTab, title, type, onClick, icon, num }}
              />
            )}
          </Col>
        )
      })}
    </div>
  )
}

export default TabBar
