import {
  LinkButton,
  LinkToCustomer,
  LinkToReturn,
  OrderStatusBadge,
  ReturnStatusBadge,
} from 'components'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { formatDateReadable, formatPrice } from 'utils'

const InfoCard = ({ getOrder, onClickReturnId, onClickCreateReturn }) => {
  const { result } = getOrder
  const { customerName, customerId, customerSubscription } = result

  return (
    <Card style={{ borderWidth: 0 }}>
      <CardBody className="p-3">
        {customerName ? (
          <Row>
            <Col>
              <LinkToCustomer
                {...{
                  customerName,
                  customerId,
                  subscription: customerSubscription,
                }}
              />
            </Col>
          </Row>
        ) : null}
        {result.orderStatusLabel ? (
          <Row>
            <Col>
              Order Status: <OrderStatusBadge value={result.orderStatus} />
            </Col>
          </Row>
        ) : null}
        {result.orderDate ? (
          <Row>
            <Col>Order Date: {formatDateReadable(result.orderDate)}</Col>
          </Row>
        ) : null}
        {result.scheduledForDate ? (
          <Row>
            <Col>
              Scheduled For: {formatDateReadable(result.scheduledForDate)}
            </Col>
          </Row>
        ) : null}
        {result.preferred_shipping_date ? (
          <Row>
            <Col>
              Preferred Ship Date:{' '}
              {formatDateReadable(result.preferred_shipping_date)}
            </Col>
          </Row>
        ) : null}
        {result.orderTotal ? (
          <Row>
            <Col>Order Total: ${formatPrice(result.orderTotal)}</Col>
          </Row>
        ) : null}
        {result.createdVia ? (
          <Row>
            <Col>
              Created via: {result.createdVia}{' '}
              {result.createdByName ? `(by ${result.createdByName})` : null}
            </Col>
          </Row>
        ) : null}
        {result.returnId ? (
          <Row>
            <Col>
              <i className="fa fa-warning" />{' '}
              <LinkToReturn
                returnId={result.returnId}
                label={
                  <>
                    Return exists (ID {result.returnId})
                    <ReturnStatusBadge
                      className="ml-2"
                      value={result.returnStatus}
                    />
                  </>
                }
                onClick={onClickReturnId}
              />
            </Col>
          </Row>
        ) : onClickCreateReturn ? (
          <Row>
            <Col>
              <LinkButton
                onClick={() => onClickCreateReturn(result.orderNumber)}
              >
                Create a return for this order...
              </LinkButton>
            </Col>
          </Row>
        ) : null}
      </CardBody>
    </Card>
  )
}

export default InfoCard
