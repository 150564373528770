import { useCallback } from 'react'

type CardEditButtonProps = {
  isEditing?: boolean
  onClick: () => void
}

const CardEditButton = ({ isEditing, onClick }: CardEditButtonProps) => {
  const handleClickEdit = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      e.stopPropagation()
      if (onClick) {
        onClick()
      }
    },
    [onClick],
  )

  return (
    <a
      href="#"
      className="card-header-action btn btn-setting"
      onClick={handleClickEdit}
    >
      <i
        className="icon-pencil"
        style={{ color: isEditing ? 'black' : null }}
      ></i>
    </a>
  )
}

export default CardEditButton
