import type { ColumnDef } from 'components/Table/types'

const columnDef: ColumnDef[] = [
  {
    label: 'Name',
    id: 'name',
    sortColumn: true,
  },
  {
    label: 'Description',
    id: 'description',
    sortColumn: true,
  },
  {
    label: 'Location',
    id: 'location_name',
    valueColumn: 'location.name',
    sortColumn: true,
  },
  {
    label: 'Column Label',
    id: 'column_label',
    sortColumn: true,
  },
  {
    label: 'Products',
    id: 'num_products',
    sortColumn: false,
  },
  {
    label: 'Quantity',
    id: 'qty',
    sortColumn: false,
  },
  {
    label: 'Batchable',
    id: 'is_batchable',
    sortColumn: false,
  },
  {
    label: 'Drop Zone',
    id: 'is_drop_zone',
    sortColumn: false,
  },
  {
    label: 'Addon Zone',
    id: 'is_addon_zone',
    sortColumn: false,
  },
  {
    label: 'Box Zone',
    id: 'is_box_zone',
    sortColumn: false,
  },
  {
    label: 'Created at',
    id: 'created_at',
    sortColumn: true,
  },
  {
    label: 'Prio',
    id: 'priority',
    sortColumn: true,
  },
]

export default columnDef
