import type { ColumnDef } from 'components/Table/types'

const columnDef: ColumnDef[] = [
  {
    label: '',
    id: 'checkbox',
  },
  {
    label: 'ID',
    id: 'id',
    sortColumn: true,
    hasClipboardButton: true,
  },
  {
    label: 'Shipment ID',
    id: 'se_shipment_id',
    sortColumn: false,
    hasClipboardButton: true,
  },
  {
    label: 'Status',
    id: 'status',
    sortColumn: true,
  },
  {
    label: 'Label Status',
    id: 'label_status',
    valueColumn: 'label.status',
    sortColumn: false,
  },
  {
    label: 'Order Number',
    id: 'order_number',
    sortColumn: true,
    hasClipboardButton: true,
  },
  {
    label: 'Tracking Number',
    id: 'tracking_number',
    sortColumn: false,
    valueColumn: 'label.tracking_number',
    hasClipboardButton: true,
  },
  {
    label: 'Customer Name',
    id: 'customer_name',
    sortColumn: true,
  },
  {
    label: 'Created at',
    id: 'created_at',
    sortColumn: true,
  },
  {
    label: 'Delivered at',
    id: 'delivered_at',
    valueColumn: 'label.tracking_actual_delivery_date',
  },
  {
    label: 'Created by',
    id: 'created_by',
    sortColumn: true,
  },
]

export default columnDef
