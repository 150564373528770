import { useTable, useTableApi, useTableColumns } from 'components/Table'
import { useMemo } from 'react'
import { getStorage } from 'utils'
import columnDef from './columnDef'

const storageKey = 'editCustomer.subscriptionsTable'
const [setItem, getItem] = getStorage(storageKey)

export const useSubscriptionsTable = (id: string) => {
  const [state, dispatch] = useTable({
    cacheKey: `${storageKey}.${id}`,
    getItem,
    setItem,
  })
  const [triggerSearch] = useTableApi('subscriptions_getAll', state, dispatch, {
    filterCustomerId: id,
  })

  const columns = useTableColumns(
    'manageCustomerSubscriptions',
    state,
    dispatch,
    columnDef,
  )

  const isEmpty = state.rows.length === 0 && state.didLoad

  return useMemo(
    () => ({
      ...state,
      setItem,
      getItem,
      columnDef,
      dispatch,
      triggerSearch,
      isEmpty,
      columns,
    }),
    [state, dispatch, triggerSearch, isEmpty, columns],
  )
}
