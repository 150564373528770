import { useForm } from 'hooks'
import { useEffect } from 'react'
import { Input } from 'reactstrap'
import { formatPrice } from 'utils/price'

export type RefundOrChargeDifferenceModalState = {
  reason: string
}

type RefundOrChargeDifferenceModalProps = {
  isRefund: boolean
  diffAmount: number
  originalAmount: number
  updateAmount: number
  onChangeState: (state: RefundOrChargeDifferenceModalState) => void
}

const initialState = {
  reason: '',
}

const RefundOrChargeDifferenceModal = (
  props: RefundOrChargeDifferenceModalProps,
) => {
  const { onChangeState, isRefund, diffAmount, originalAmount, updateAmount } =
    props

  const form = useForm<RefundOrChargeDifferenceModalState>(initialState)

  useEffect(() => {
    if (onChangeState) {
      onChangeState(form.state)
    }
  }, [onChangeState, form.state])

  return (
    <table className="table">
      <tbody>
        <tr>
          <td colSpan={2} style={{ borderTop: 0 }}>
            {isRefund ? (
              <>Refund ${formatPrice(diffAmount)} back to credit card?</>
            ) : (
              <>Charge ${formatPrice(diffAmount)} to credit card on file?</>
            )}
          </td>
        </tr>
        <tr>
          <td className="text-left"> Original Invoice: </td>
          <td className="text-right"> ${formatPrice(originalAmount)} </td>
        </tr>
        <tr>
          <td className="text-left"> Updated Invoice: </td>
          <td className="text-right"> ${formatPrice(updateAmount)} </td>
        </tr>
        <tr>
          <td className="text-left"> Difference: </td>
          <td className="text-right"> ${formatPrice(diffAmount)} </td>
        </tr>
        <tr>
          <td className="text-left"> Reason: </td>
          <td className="text-right align-middle">
            <Input
              {...form.inputProps}
              id="refund-reason"
              className="form-control d-inline"
              type="text"
              placeholder="Enter reason (not visible to customer)"
              name="reason"
              value={form.state.reason}
            />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default RefundOrChargeDifferenceModal
