import { DropDownItemCheckbox } from 'components'
import { useDropDownToggler } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

const orderStatuses = [
  'scheduled',
  'processing',
  'internal_batched',
  'internal_assembled',
  'completed',
  'pending',
  'cancelled',
  'refunded',
  'failed',
]

const orderStatusLabel = (status: string, internalOnly: boolean) => {
  if (status.indexOf('internal_') === 0) {
    const internalPrefix = internalOnly ? '' : 'Internal: '
    const _status = status.slice(9)
    return internalPrefix + _status.charAt(0).toUpperCase() + _status.slice(1)
  }

  return status.charAt(0).toUpperCase() + status.slice(1)
}

const statuses = orderStatuses.map((id) => ({
  id,
  label: orderStatusLabel(id, false),
}))

const internalStatuses = orderStatuses
  .filter((id) => id.indexOf('internal_') === 0)
  .map((id) => ({
    id,
    label: orderStatusLabel(id, true),
  }))

const StatusFilterButton = ({ filter, internalOnly }) => {
  const _statuses = internalOnly ? internalStatuses : statuses

  const toggler = useDropDownToggler()

  const handleClick = useCallback(
    (id: string) => {
      filter.toggle(id)
    },
    [filter],
  )

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle
        nav
        caret
        className={filter.values.length != '' ? 'hasValue' : ''}
      >
        {filter.values.length === 0 ? (
          <span>Status</span>
        ) : filter.values.length === 1 ? (
          <span>{orderStatusLabel(filter.values[0], internalOnly)}</span>
        ) : (
          <span>
            {filter.values
              .map((s: string) => orderStatusLabel(s, internalOnly))
              .join(',')}
          </span>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={filter.clear}>
          <i className="mr-1 fa fa-ban" /> Clear
        </DropdownItem>
        {_statuses.map((status) => {
          return (
            <DropDownItemCheckbox
              id={status.id}
              key={status.id}
              onClick={handleClick}
              isChecked={filter.has(status.id)}
            >
              {status.label}
            </DropDownItemCheckbox>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

export default StatusFilterButton
