import { Badge } from 'reactstrap'

const statusColors = {
  active: 'success',
  deactivated: 'danger',
  expired: 'warning',
  consumed: 'primary',
}

type GiftCardStatusBadgeProps = {
  status?: string
  className?: string
}

const GiftCardStatusBadge = ({
  className,
  status,
}: GiftCardStatusBadgeProps) => {
  return (
    <Badge {...{ className }} color={statusColors[status]}>
      {String(status).toUpperCase()}
    </Badge>
  )
}

export default GiftCardStatusBadge
