import type { PurchaseOrder } from '@ttc/api/purchaseOrders'
import { EditPanel, EditPanelCard, FormRenderer } from 'components'
import type { FormDef } from 'components/Form/types'
import { type ApiCall, useApi, useFormWithDef, usePrevious } from 'hooks'
import { useCallback, useEffect } from 'react'

type FormState = {
  replyto: string
  to: string
  subject: string
  body: string
  format_excel: boolean
  format_csv: boolean
  format_pdf: boolean
}

const formDef: FormDef = [
  {
    label: 'Reply-To',
    placeholder: '',
    name: 'replyto',
    type: 'email',
    required: false,
    store: true,
  },
  {
    label: 'Recipient',
    placeholder: '',
    name: 'to',
    type: 'email',
    required: true,
    store: false,
  },
  {
    label: 'Subject',
    placeholder: '',
    name: 'subject',
    type: 'text',
    store: true,
  },
  {
    label: 'Body',
    placeholder: '',
    name: 'body',
    type: 'textarea',
    store: true,
    rows: 6,
  },
  {
    group: [
      {
        label: 'PDF',
        placeholder: '',
        name: 'format_pdf',
        type: 'checkbox',
        store: true,
      },
      {
        label: 'Excel',
        placeholder: '',
        name: 'format_excel',
        type: 'checkbox',
        store: true,
      },
      {
        label: 'CSV',
        placeholder: '',
        name: 'format_csv',
        type: 'checkbox',
        store: true,
      },
    ],
  },
]

type PlacePanelProps = {
  id: number
  isOpen: boolean
  onClose: () => void
  getPurchaseOrder: ApiCall<PurchaseOrder>
}

const PlacePanel = (props: PlacePanelProps) => {
  const { id, isOpen, onClose, getPurchaseOrder } = props

  const defaultEmail = getPurchaseOrder.result?.supplier?.email

  const form = useFormWithDef<FormState>(formDef, 'purchaseOrders.place')

  const apiPlace = useApi({ action: 'purchaseOrders_place' }, null, () => ({
    errorModal: true,
  }))

  // Prefill email with supplier's email address.
  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (isOpen && prevIsOpen !== isOpen && !form.state.to) {
      form.set('to', defaultEmail)
    }
  }, [isOpen, prevIsOpen, form, defaultEmail])

  const handleSubmit = useCallback(() => {
    const formSubmit = async () => {
      const ret = await apiPlace.performRequest({ json: { id, ...form.state } })

      if (ret) {
        onClose()
      }
    }

    formSubmit()
  }, [id, apiPlace, form.state, onClose])

  const didSelectFormat =
    form.state.format_csv || form.state.format_pdf || form.state.format_excel

  const { isLoading } = apiPlace

  return (
    <EditPanel
      onSubmit={handleSubmit}
      caption="Send Request"
      submitButtonLabel="Send Request"
      canSubmit={!isLoading && didSelectFormat}
      {...{ isOpen, onClose }}
    >
      <EditPanelCard caption="Send email">
        <FormRenderer {...{ form, formDef }} />
      </EditPanelCard>
    </EditPanel>
  )
}

export default PlacePanel
