import type { GetOrderApiResult, GetOrdersApiParams } from '@ttc/api/orders'
import { MANAGERETURNS_READWRITE } from 'caps'
import { FilterBar, PageNavHead, PageNavTail } from 'components'
import {
  Table,
  setSelectedRow,
  useTable,
  useTableApi,
  useTableColumns,
} from 'components/Table'
import ManageColumnsPanel from 'containers/common/ManageColumnsPanel'
import ManageTagsPanel from 'containers/common/ManageTagsPanel'
import { SingleOrderSummarySidebar } from 'containers/common/OrderSummarySidebar'
import TagActionButton from 'containers/common/TagActionButton'
import {
  useApi,
  useEffectIfObjectChanges,
  useFiltersWithUrlUpdaterWithTableSync,
  usePageTitle,
  usePanelControl,
  useSidebar,
  useStateful,
} from 'hooks'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { askQuestion } from 'utils'
import { getStorage } from 'utils/storage'
import AuthContext from './../../AuthContext'
import ExportActionButton from './Actions/ExportActionButton'
import EditReturnPanel from './EditReturnPanel'
import Filters from './Filters'
import Sidebar from './Sidebar'
import columnDef from './columnDef'

const pageTitle = 'Manage Returns'

const [setItem, getItem] = getStorage('manageReturns')

const visibleFilters = ['q', 'tags', 'status', 'reason']

const ManageReturns = ({ readOnly }: { readOnly: boolean }) => {
  usePageTitle(pageTitle)

  const { hasCap } = useContext(AuthContext)

  const selectedOrder = useStateful(null)

  const getOrder = useApi<GetOrderApiResult, GetOrdersApiParams>(
    useMemo(
      () => ({ action: 'editOrder_getOrder', order_id: selectedOrder.value }),
      [selectedOrder.value],
    ),
    null,
    { autoPerform: Boolean(selectedOrder.value) },
  )

  const [state, dispatch] = useTable({
    cacheKey: 'manageReturns',
    getItem,
    setItem,
  })

  const filters = useFiltersWithUrlUpdaterWithTableSync(
    'manageReturnsFilters',
    visibleFilters,
    state,
    dispatch,
  )

  const query = filters.searchQueryString

  const { rows, isLoading, selectedRows, sortColumn, sortAsc } = state
  const [triggerSearch] = useTableApi('returns_getAll', state, dispatch, {
    query: '',
    ...filters.requestProps,
  })

  const sidebar = useSidebar({
    openForSingleRow: true,
    openForMultiRows: false,
    selectedRows,
  })

  const createdReturnId = useStateful(null)

  /* Selected return determines the sitebar context. It is either the table
   * selection or the newly created return ID. */
  const selectedReturnId =
    selectedRows.length === 1 ? selectedRows[0] : createdReturnId.value

  /* Forget newly created return ID as soon as selection changes. */
  useEffect(() => {
    if (createdReturnId.value && selectedReturnId !== createdReturnId.value) {
      createdReturnId.set(null)
    }
  }, [selectedReturnId, createdReturnId])

  const getReturn = useApi(
    useMemo(
      () => ({
        action: 'returns_get',
        id: selectedReturnId,
        withNotes: true,
        withItems: true,
        withImages: true,
        withReplacementOrders: true,
      }),
      [selectedReturnId],
    ),
    null,
    { autoPerform: sidebar.isOpen && selectedReturnId != null },
  )

  /* Trigger search when filters change. */
  useEffectIfObjectChanges(triggerSearch, filters.requestProps)

  const columns = useTableColumns('manageReturns', state, dispatch, columnDef)

  const handleChangeSearch = useCallback(
    (query) => {
      filters.q.setValues([query])
    },
    [filters.q],
  )

  const manageColumnsPanel = usePanelControl()
  const manageTagsPanel = usePanelControl()

  const editPanel = usePanelControl()
  const editId = useStateful(null)

  const handleClickAdd = useCallback(() => {
    editId.set(null)
    editPanel.open()
  }, [editPanel, editId])

  const handleCloseReturnPanel = useCallback(
    (id, isNew) => {
      editPanel.close()

      triggerSearch().then(() => {
        /* Either: Open sidebar with newly created return. */
        if (isNew && id) {
          dispatch(setSelectedRow(id))
          createdReturnId.set(id)
          sidebar.open()
        }
      })

      /* Otherwise: update currently selected return to reflect changes. */
      if (!isNew && selectedReturnId) {
        getReturn.performRequest()
      }
    },
    [
      dispatch,
      sidebar,
      createdReturnId,
      editPanel,
      triggerSearch,
      selectedReturnId,
      getReturn,
    ],
  )

  const handleClickEdit = useCallback(() => {
    editId.set(selectedReturnId)
    editPanel.open()
  }, [selectedReturnId, editId, editPanel])

  const getStatuses = useApi(() => ({ action: 'returns_getStatuses' }), null, {
    autoPerform: true,
  })

  const handleOrderSidebarBack = useCallback(() => {
    selectedOrder.set(null)
  }, [selectedOrder])

  const apiDeleteReturn = useApi({ action: 'returns_delete' }, null, () => ({
    errorModal: true,
  }))

  const handleClickDelete = useCallback(
    async (id) => {
      const ret = await askQuestion(
        `Are you sure you want to delete Return ${id}?`,
      )
      if (!ret) {
        return
      }

      dispatch(setSelectedRow(null))

      await apiDeleteReturn.performRequest({ id })

      editId.set(null)
      editPanel.close()
      triggerSearch()
    },
    [dispatch, apiDeleteReturn, triggerSearch, editId, editPanel],
  )

  const getTags = useApi(
    () => ({ action: 'returnTags_getAll' }),
    () => [],
    () => ({
      autoPerform: true,
      normalize: (input) => input.rows,
      cache: true,
    }),
  )

  const handleCloseTagsPanel = useCallback(() => {
    manageTagsPanel.close()
    getTags.performRequest()
  }, [manageTagsPanel, getTags])

  return (
    <>
      <PageNavHead {...{ isLoading, pageTitle, onClickReload: triggerSearch }}>
        {sidebar && !sidebar.isOpen ? (
          <div style={{ marginLeft: 'auto' }}>
            {hasCap(MANAGERETURNS_READWRITE) ? (
              <Button className="mr-2" onClick={handleClickAdd}>
                Add Return
              </Button>
            ) : null}
            <Button color="link" onClick={sidebar.userToggle}>
              <i className="icon-list" />
            </Button>
          </div>
        ) : null}
      </PageNavHead>
      <PageNavTail {...{ isLoading, query, handleChangeSearch }}>
        <TagActionButton
          toggleTagApiAction="returns_toggleTags"
          {...{
            selectedRows,
            rows,
            tags: getTags.result,
            onClickManage: manageTagsPanel.open,
            onModified: triggerSearch,
            disabled: readOnly,
          }}
        />
        <ExportActionButton
          {...{
            requestProps: filters.requestProps,
            query,
            sortColumn,
            sortAsc,
            columns,
          }}
        />
      </PageNavTail>
      <FilterBar {...{ filters, manageColumnsPanel, columns }}>
        <Filters {...{ filters, getStatuses, tags: getTags.result }} />
      </FilterBar>
      <Container fluid className="mt-4">
        <div className="manage-returns animated fadeIn">
          <ManageTagsPanel
            apiPrefix="returnTags"
            isOpen={manageTagsPanel.isOpen}
            onClose={handleCloseTagsPanel}
          />
          <EditReturnPanel
            editId={editId.value}
            isOpen={editPanel.isOpen}
            onClose={handleCloseReturnPanel}
            onClickDelete={handleClickDelete}
            {...{ getStatuses }}
          />
          <ManageColumnsPanel
            {...{ columns }}
            isOpen={manageColumnsPanel.isOpen}
            onClose={manageColumnsPanel.close}
          />
          <Row>
            <Col>
              <Table
                entityName="returns"
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef,
                  dispatch,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
      {selectedOrder.value ? (
        <SingleOrderSummarySidebar
          {...{
            getOrder,
            onToggle: sidebar.userToggle,
            onBack: handleOrderSidebarBack,
          }}
        />
      ) : (
        <Sidebar
          {...{
            readOnly,
            selectedOrder,
            selectedRows: selectedReturnId ? [selectedReturnId] : [],
            getReturn,
            onToggle: sidebar.userToggle,
            onClickEdit: handleClickEdit,
            onBack: null,
          }}
        />
      )}
    </>
  )
}

export default ManageReturns
