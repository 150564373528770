import { EditPanel, EditPanelCard, Loading } from 'components'
import useApi from 'hooks/useApi'
import { formatDateReadable } from 'utils/date'

type LogPanelProps = {
  id: number
  isOpen: boolean
  onClose: () => void
}

type LogEntry = {
  id: number
  message: string
  event_type: string
  user: string
  created_at: string
  app_context: string
  field_name: string
  subscription_plan_price_id: number
}

const LogEntry = (entry: LogEntry) => {
  const {
    id,
    event_type,
    message,
    user,
    created_at,
    app_context,
    field_name,
    subscription_plan_price_id,
  } = entry

  return (
    <li key={id}>
      <div>
        {event_type} {field_name}{' '}
        {subscription_plan_price_id ? subscription_plan_price_id : ''}
      </div>
      <div dangerouslySetInnerHTML={{ __html: message }} />
      <div style={{ fontSize: 11, fontStyle: 'italic' }}>
        {user} on {formatDateReadable(created_at, true)} via {app_context}
      </div>
    </li>
  )
}

const Log = ({ id, apiName }) => {
  const apiGetLog = useApi(
    () => ({ action: apiName, id }),
    null,
    () => ({ autoPerform: id != null }),
  )

  const entries = apiGetLog.result?.rows || []

  if (apiGetLog.isLoading) {
    return <Loading />
  }

  if (entries.length === 0) {
    return <i>Log empty</i>
  }

  return (
    <>
      <ul>
        {entries.map((entry: LogEntry) => (
          <LogEntry key={entry.id} {...entry} />
        ))}
      </ul>
    </>
  )
}

type LogCardProps = {
  id: number
  apiName: string
}

const LogCard = (props: LogCardProps) => {
  const { id, apiName } = props

  return (
    <EditPanelCard caption="Subscription Log" stateId="subscription-log">
      <Log key={id} {...{ id, apiName }} />
    </EditPanelCard>
  )
}

const LogPanel = (props: LogPanelProps) => {
  const { isOpen, onClose, id } = props

  return (
    <EditPanel caption="Log" {...{ isOpen, onClose }}>
      {isOpen ? (
        <LogCard apiName="subscriptionPlans_getLog" id={id} />
      ) : (
        <Loading />
      )}
    </EditPanel>
  )
}

export default LogPanel
