import type { PurchaseOrder } from '@ttc/api/purchaseOrders'
import Loading from 'components/Loading'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

const DeliveryDetailsCard = (props: {
  purchaseOrder: PurchaseOrder
  isLoading: boolean
}) => {
  const { purchaseOrder, isLoading } = props

  return (
    <Card className="h-100">
      <CardHeader>Delivery Details</CardHeader>
      <CardBody>
        {!purchaseOrder && isLoading ? (
          <Loading />
        ) : purchaseOrder ? (
          <>
            <Row>
              <Col className="text-nowrap">Trucks Scheduled:</Col>
              <Col className="text-nowrap text-right">
                {purchaseOrder.num_trucks_scheduled}
              </Col>
            </Row>
            <Row>
              <Col className="text-nowrap">Trucks OTW:</Col>
              <Col className="text-nowrap text-right">
                {purchaseOrder.num_trucks_otw}
              </Col>
            </Row>
            <Row>
              <Col className="text-nowrap">Completed Deliveries:</Col>
              <Col className="text-nowrap text-right">
                {purchaseOrder.num_trucks_completed}
              </Col>
            </Row>
          </>
        ) : null}
      </CardBody>
    </Card>
  )
}

export default DeliveryDetailsCard
