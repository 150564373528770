import { useCallback } from 'react'
import colors from './colors'

const ColorSelector = ({ id, columns, onClose }) => {
  const handleClickColor = useCallback(
    (color: string) => {
      columns.setColor(id, color)
      onClose()
    },
    [id, columns, onClose],
  )

  return (
    <li className="list-group-item">
      {colors.map((color) => {
        return (
          <div
            onClick={() => handleClickColor(color)}
            key={color}
            style={{ backgroundColor: color, width: 'auto', height: 20 }}
          ></div>
        )
      })}
    </li>
  )
}

export default ColorSelector
