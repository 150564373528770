import type { Product } from '@ttc/api/products'
import AuthContext from 'AuthContext'
import { ADMIN } from 'caps'
import ProductStatusBadge from 'components/Badges/ProductStatusBadge'
import ClipboardButton from 'components/ClipboardButton'
import { useContext } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

const InfoCard = ({ product }: { product?: Product }) => {
  const { hasCap } = useContext(AuthContext)
  const isAdmin = hasCap(ADMIN)

  const wpadminHref = product
    ? `${global.appConfig.wpBaseUrl}/wp-admin/post.php?action=edit&post=${product.id}`
    : ''

  const deepLink = product
    ? 'https://' +
      document.location.host +
      `/content?q=${product.sku}&selected=${product.id}`
    : null

  return (
    <Card style={{ borderWidth: 0 }}>
      <CardBody className="p-3">
        <Row>
          <Col>
            Status:{' '}
            {product ? <ProductStatusBadge value={product.status} /> : null}
          </Col>
          <Col>Type: {product?.type}</Col>
        </Row>
        <Row>
          <Col>SKU: {product?.sku}</Col>
          {isAdmin ? (
            <Col>
              {wpadminHref ? (
                <a rel="noopener noreferrer" target="_blank" href={wpadminHref}>
                  Edit in wp-admin
                </a>
              ) : null}
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col>ID: {product?.id}</Col>
          <Col>
            Deep Link <ClipboardButton value={deepLink} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default InfoCard
