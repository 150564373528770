import { apiFetch } from 'api'
import { useCallback, useState } from 'react'
import { showError } from 'utils'

type UploadImageDropZoneProps = {
  returnId: number
  onUploadDone: () => void
}

const UploadImageDropZone = (props: UploadImageDropZoneProps) => {
  const { returnId, onUploadDone } = props
  const [isDragOver, setIsDragOver] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleDrop = useCallback(
    async (e) => {
      const { dataTransfer } = e

      e.preventDefault()
      setIsDragOver(false)

      const promises = []

      if (dataTransfer && dataTransfer.items) {
        for (let i = 0; i < dataTransfer.items.length; i++) {
          const item = dataTransfer.items[i]

          if (item.kind === 'file') {
            const file = item.getAsFile()

            const url = `${global.appConfig.wpBaseUrl}/wp-admin/admin-ajax.php`
            const formData = new FormData()
            formData.append('action', 'treeadmin_returns_uploadImage')
            formData.append('id', String(returnId))
            formData.append('file', file)

            promises.push(apiFetch(url, formData, false))
          }
        }
      }

      try {
        setIsLoading(true)
        await Promise.all(promises)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        showError(e.message)
      }

      if (onUploadDone) {
        onUploadDone()
      }
    },
    [setIsDragOver, returnId, onUploadDone],
  )

  const handleDragOver = useCallback(
    (e) => {
      e.preventDefault()
      setIsDragOver(true)
    },
    [setIsDragOver],
  )

  const handleDragLeave = useCallback(
    (e) => {
      e.preventDefault()
      setIsDragOver(false)
    },
    [setIsDragOver],
  )

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      className={`file-upload-dropzone d-flex ${isDragOver ? 'drag-over' : ''}`}
    >
      <div>
        <i className={`m-3 fa fa-${isDragOver ? 'check' : 'upload'}`} />
      </div>
      <div className="pl-4">
        {isLoading ? (
          <>Uploading...</>
        ) : (
          <>
            Upload Images <br />
            (Drag Files Here)
          </>
        )}
      </div>
    </div>
  )
}

export default UploadImageDropZone
