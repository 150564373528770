import { EditPanelCard, NotesList } from 'components'
import CardEditButton from 'containers/common/CardEditButton'
import { type ApiCall, useApi, useBoolean, useStateful } from 'hooks'
import { useCallback } from 'react'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'

type NotesCardProps = {
  getOrder: ApiCall
  readOnly?: boolean
}

const NotesCard = (props: NotesCardProps) => {
  const { getOrder, readOnly } = props
  const { result } = getOrder
  const { notesInternal } = result
  const note = useStateful<string>('')
  const editMode = useBoolean()

  const headerActions = (
    <CardEditButton isEditing={editMode.value} onClick={editMode.toggle} />
  )

  const handleClickCancel = useCallback(() => {
    editMode.off()
  }, [editMode])

  const handleChangeText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      note.set(e.target.value)
    },
    [note],
  )

  const apiAddNote = useApi(
    () => ({ action: 'orders_addInternalNote' }),
    null,
    () => ({ errorModal: true, throws: true }),
  )

  const isLoading = apiAddNote.isLoading
  const canSubmit = !isLoading && note.value.length

  const { orderId } = getOrder.result
  const handleClickSubmit = useCallback(async () => {
    try {
      await apiAddNote.performRequest({
        json: { id: orderId, notes: note.value },
      })
      editMode.off()
      note.set('')

      getOrder.performRequest()
    } catch (_e) {
      /* Ignore */
    }
  }, [orderId, note, apiAddNote, editMode, getOrder])

  return (
    <EditPanelCard
      caption="Order Log"
      stateId="orders_log"
      bodyProps={{ className: 'p-3' }}
      headerActions={!readOnly ? headerActions : null}
    >
      {editMode.value ? (
        <>
          <Row>
            <Col>
              <FormGroup>
                <Label for="note_textarea">Add internal note</Label>
                <Input
                  type="textarea"
                  name="text"
                  id="note_textarea"
                  value={note.value}
                  rows={3}
                  onChange={handleChangeText}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              <Button
                disabled={!canSubmit}
                size="sm"
                className="m-2"
                onClick={handleClickSubmit}
              >
                Submit
              </Button>
              <Button
                disabled={isLoading}
                size="sm"
                className="m-2"
                onClick={handleClickCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </>
      ) : null}
      <Row>
        <Col>
          <div>
            <NotesList notes={notesInternal} />
          </div>
        </Col>
      </Row>
    </EditPanelCard>
  )
}

export default NotesCard
