import { SelectState } from 'components'
import TextField from 'components/TextField'
import { useApi, usePrevious } from 'hooks'
import { Fragment, useCallback, useEffect, useMemo, useReducer } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import type { State as CartState } from './useCart/types'

type State = {
  address: any
  verifyResult: any
}

const initialState: State = {
  address: null,
  verifyResult: null,
}

type Action =
  | { type: 'INVALIDATE_RESULT' }
  | { type: 'SET_ADDRESS'; addressData: any }
  | { type: 'SET_RESULT'; address: any; result: any }

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'INVALIDATE_RESULT': {
      return { ...state, verifyResult: null }
    }
    case 'SET_ADDRESS': {
      return { ...state, address: action.addressData }
    }
    case 'SET_RESULT': {
      const { address, result } = action

      if (JSON.stringify(address) === JSON.stringify(state.address)) {
        return { ...state, verifyResult: result }
      }

      return state
    }
  }
}

const VerifyResult = ({ hasAddress, isAddressComplete, status, messages }) => {
  if (!hasAddress) {
    return null
  }

  if (!isAddressComplete) {
    return (
      <>
        <i
          style={{ paddingRight: 3 }}
          className="text-danger fa fa-times-circle"
        />
        <span>Address incomplete.</span>
      </>
    )
  }

  if (status == null) {
    return null
  }

  if (status === 'verified') {
    return (
      <>
        <i
          style={{ color: 'rgb(37, 108, 43)', paddingRight: 3 }}
          className="fa fa-check-circle"
        />
        <span>Address verified.</span>
      </>
    )
  }

  if (Array.isArray(messages)) {
    return (
      <>
        {messages.map((m) => {
          return (
            <Fragment key={m.message}>
              <i
                style={{ paddingRight: 3 }}
                className="text-danger fa fa-times-circle"
              />
              <span key={m.message}>{m.message}</span>
            </Fragment>
          )
        })}
      </>
    )
  }

  return null
}

type AddressFormProps = {
  title: string
  prefix: string
  onChange: (name: string, value: string) => void
  hasEmail?: boolean
  hasPhone?: boolean
} & Partial<CartState>

const AddressForm = (props: AddressFormProps) => {
  const { title, prefix, onChange, hasEmail, hasPhone } = props

  const [state, dispatch] = useReducer<React.Reducer<State, Action>>(
    reducer,
    initialState,
  )

  const addressApi = useApi({ action: 'editOrder_verifyAddress' })

  const addressData = useMemo(() => {
    return {
      address_1: props[`${prefix}Address`],
      address_2: props[`${prefix}Address2`],
      city: props[`${prefix}City`],
      state: props[`${prefix}State`],
      postcode: props[`${prefix}Zip`],
    }
  }, [props, prefix])

  const isAddressComplete = useMemo(() => {
    return (
      addressData.address_1 !== '' &&
      addressData.city != '' &&
      addressData.state != '' &&
      addressData.postcode != ''
    )
  }, [addressData])

  const hasAddress = useMemo(() => {
    return (
      addressData.address_1 !== '' ||
      addressData.address_2 !== '' ||
      addressData.city != '' ||
      addressData.state != '' ||
      addressData.postcode != ''
    )
  }, [addressData])

  const verifyAddressNow = useCallback(() => {
    const doRequest = async () => {
      const result = await addressApi.performRequest({ json: addressData })
      dispatch({ type: 'SET_RESULT', address: addressData, result })
    }

    if (isAddressComplete) {
      doRequest()
    }
  }, [addressData, addressApi, isAddressComplete])

  const verifyAddress = useDebouncedCallback(
    useCallback(() => {
      verifyAddressNow()
    }, [verifyAddressNow]),
    750,
  )

  const prevAddressData = usePrevious(addressData)
  useEffect(() => {
    dispatch({ type: 'SET_ADDRESS', addressData })

    if (JSON.stringify(addressData) !== JSON.stringify(prevAddressData)) {
      verifyAddress()
    }
  }, [addressData, verifyAddress, prevAddressData])

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.currentTarget

      if (onChange) {
        onChange(name, value)

        dispatch({ type: 'INVALIDATE_RESULT' })
      }
    },
    [onChange],
  )

  const handleChangeSelect = useCallback(
    (name, value) => {
      if (onChange) {
        onChange(
          name,
          value && Object.hasOwn(value, 'value') ? value.value : '',
        )

        dispatch({ type: 'INVALIDATE_RESULT' })
      }
    },
    [onChange],
  )

  return (
    <>
      <div className="d-flex">
        <h5 style={{ flex: 1 }} className="mb-3 mt-4">
          {title}
        </h5>
        <div className="mb-3 mt-4">
          <VerifyResult
            {...{ isAddressComplete, hasAddress }}
            {...state.verifyResult}
          />
        </div>
      </div>
      <div className="d-flex">
        <TextField
          style={{ flex: 1 }}
          className="mr-1"
          name={`${prefix}FirstName`}
          value={props[`${prefix}FirstName`]}
          onChange={handleChange}
          id={`input-${prefix}-first-name`}
          required
          label="First Name"
        />
        <TextField
          style={{ flex: 1 }}
          className="ml-1"
          name={`${prefix}LastName`}
          value={props[`${prefix}LastName`]}
          onChange={handleChange}
          id={`input-${prefix}-last-name`}
          required
          label="Last Name"
        />
      </div>
      <TextField
        name={`${prefix}Company`}
        value={props[`${prefix}Company`]}
        onChange={handleChange}
        id={`input-${prefix}-company`}
        label="Company"
      />
      <div className="d-flex">
        <TextField
          className="mr-1"
          style={{ flex: 2 }}
          name={`${prefix}Address`}
          value={props[`${prefix}Address`]}
          onChange={handleChange}
          id={`input-${prefix}-address`}
          label="Address"
          required
        />
        <TextField
          className="ml-1"
          style={{ flex: 1 }}
          name={`${prefix}Address2`}
          value={props[`${prefix}Address2`]}
          onChange={handleChange}
          id={`input-${prefix}-address2`}
          label="Apt, suite, etc."
        />
      </div>
      <TextField
        name={`${prefix}City`}
        value={props[`${prefix}City`]}
        onChange={handleChange}
        id={`input-${prefix}-city`}
        label="City"
        required
      />
      <div className="d-flex">
        <div className="mr-1 form-label-group" style={{ flex: 2 }}>
          <SelectState
            name={`${prefix}State`}
            value={props[`${prefix}State`]}
            onChange={handleChangeSelect}
          />
        </div>
        <TextField
          className="ml-1"
          style={{ flex: 1 }}
          name={`${prefix}Zip`}
          value={props[`${prefix}Zip`]}
          onChange={handleChange}
          id={`input-${prefix}-zip`}
          label="Zip code"
          required
          isZipCode
        />
      </div>
      {hasPhone ? (
        <TextField
          name={`${prefix}Phone`}
          value={props[`${prefix}Phone`]}
          onChange={handleChange}
          mask="(999)999-9999"
          id={`input-${prefix}-phone`}
          label="Phone"
          isPhoneNumber
        />
      ) : null}
      {hasEmail ? (
        <TextField
          name={`${prefix}Email`}
          value={props[`${prefix}Email`]}
          onChange={handleChange}
          id={`input-${prefix}-email`}
          label="Email"
          isEmail
        />
      ) : null}
    </>
  )
}

export default AddressForm
