import { EditPanel, Loading, TabBar, useTabBar } from 'components'
import type { TabsConfig } from 'components/TabBar/types'
import NotesPanelCard from 'containers/common/NotesPanelCard'
import { useMemo } from 'react'
import { Button, Col, Row } from 'reactstrap'
import ImagesCard from './ImagesCard'
import InfoCard from './InfoCard'
import ItemsCard from './ItemsCard'
import LogCard from './LogCard'
import ReplacementsCard from './ReplacementsCard'

const TAB_ORDER = 'order'
const TAB_IMAGES = 'images'
const TAB_REPLACEMENTS = 'replacements'
const TAB_NOTES = 'notes'

type Tab =
  | typeof TAB_ORDER
  | typeof TAB_IMAGES
  | typeof TAB_REPLACEMENTS
  | typeof TAB_NOTES

const Editor = ({
  readOnly,
  onToggle,
  getReturn,
  id,
  onClickEdit,
  selectedOrder,
  onBack,
}) => {
  const result = getReturn.result || {}
  const caption = `Return ${result.id}`

  const numItems = getReturn.result?.num_skus || 0
  const numImages = getReturn.result?.num_images || 0
  const numNotes = getReturn.result?.num_notes || 0
  const numReplacementOrders = getReturn.result?.num_replacement_orders || 0

  const tabsConfig = useMemo<TabsConfig<Tab>>(
    () => [
      { type: TAB_ORDER, title: 'Product', icon: 'leaf', num: numItems },
      {
        type: TAB_IMAGES,
        title: 'Images',
        icon: 'fa fa-image',
        num: numImages,
      },
      {
        type: TAB_REPLACEMENTS,
        title: 'Replacement Orders',
        icon: 'fa fa-exchange',
        num: numReplacementOrders,
      },
      { type: TAB_NOTES, title: 'Notes', icon: 'notes', num: numNotes },
    ],
    [numItems, numImages, numNotes, numReplacementOrders],
  )

  const tabBarState = useTabBar<Tab>(
    TAB_ORDER,
    tabsConfig,
    'returns.sidebar.selectedTab',
  )

  return (
    <>
      <ul className="nav nav-tabs flex-row-reverse">
        <li className="nav-item m-0">
          <a onClick={onToggle} className="active nav-link">
            <i className="icon-list"></i>
          </a>
        </li>
      </ul>

      {!getReturn.hasResult && getReturn.isLoading ? (
        <div className="tab-content">
          <Loading />
        </div>
      ) : (
        <>
          <EditPanel noHeader embedded isOpen {...{ caption }}>
            <Row>
              <Col className="d-flex justify-content-between align-items-center m-3 pl-1 pr-1">
                {onBack ? (
                  <Button className="mr-2" onClick={onBack}>
                    <i className="fa fa-chevron-left" />
                  </Button>
                ) : null}
                <div style={{ flex: 1 }}>{caption}</div>
              </Col>
            </Row>
            {getReturn.error ? (
              <div className="tab-content">
                <Row>
                  <Col>
                    <div className="text-danger">{getReturn.error}</div>
                  </Col>
                </Row>
              </div>
            ) : getReturn.hasResult ? (
              <TabBar className="border-top" {...tabBarState} />
            ) : null}
          </EditPanel>
          <div
            className="tab-content"
            style={{ height: 'calc(100vh - 210px)' }}
          >
            <EditPanel noHeader embedded isOpen>
              {!getReturn.error && getReturn.hasResult ? (
                <>
                  <InfoCard
                    {...{
                      id,
                      getReturn,
                      onClickEdit: !readOnly ? onClickEdit : null,
                      selectedOrder,
                    }}
                  />
                  {tabBarState.selectedTab === TAB_ORDER ? (
                    <ItemsCard {...{ readOnly, getReturn }} />
                  ) : null}
                  {tabBarState.selectedTab === TAB_REPLACEMENTS ? (
                    <ReplacementsCard
                      {...{ id, readOnly, getReturn, selectedOrder }}
                    />
                  ) : null}
                  {tabBarState.selectedTab === TAB_IMAGES ? (
                    <ImagesCard {...{ readOnly, returnId: id, getReturn }} />
                  ) : null}
                  {tabBarState.selectedTab === TAB_NOTES ? (
                    <>
                      <NotesPanelCard
                        isLoading={getReturn.isLoading}
                        apiAddAction="returns_addNote"
                        apiRemoveAction="returns_removeNote"
                        id={getReturn.result.id}
                        notes={getReturn.result.notes}
                        onSaved={getReturn.performRequest}
                        {...{ readOnly }}
                      />
                      <LogCard {...{ returnId: id }} />
                    </>
                  ) : null}
                </>
              ) : null}
            </EditPanel>
          </div>
        </>
      )}
    </>
  )
}

export default Editor
