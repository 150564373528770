import type { ColumnDef } from 'components/Table/types'
import { Badge } from 'reactstrap'
import { formatDateReadable } from 'utils/date'
import { formatPrice } from 'utils/price'

const columnDef: ColumnDef[] = [
  {
    label: '',
    id: 'checkbox',
  },
  {
    label: 'ID',
    id: 'id',
  },
  {
    label: 'Product Title',
    id: 'stripe_product_title',
    renderer: ({ row, value }) => (
      <td>
        {row.admin_icon} {value}
      </td>
    ),
  },
  {
    label: 'Default Price',
    id: 'stripe_default_price_description',
    title: 'Plan price configured in the stripe dashboard.',
  },
  {
    label: 'Status',
    id: 'status',
    title:
      'Can the subscription plan be purchased? You can activate the plan within the edit plan pane.',
    renderer: ({ value }) => (
      <td>
        {value === 'ACTIVE' ? (
          <Badge color="success"> ACTIVE </Badge>
        ) : (
          <Badge color="danger"> INACTIVE </Badge>
        )}
      </td>
    ),
  },
  {
    label: 'Stripe Active',
    id: 'stripe_is_active',
    title: 'Is the stripe product active?',
    renderer: ({ value }) => (
      <td>
        {value == 1 ? (
          <i className="fa fa-check-circle text-success" />
        ) : (
          <i className="fa fa-times-circle text-danger" />
        )}
      </td>
    ),
  },
  {
    label: 'Valid',
    id: 'is_valid',
    title: 'Did all the validation checks pass?',
    renderer: ({ value, row }) => (
      <td>
        {value ? (
          <i className="fa fa-check-circle text-success" />
        ) : (
          <span className="text-danger">Error: {row.validation_errors[0]}</span>
        )}
      </td>
    ),
  },
  {
    label: 'Cart Discount',
    id: 'coupon_description',
    renderer: ({ value }) => (
      <td>{value ? <Badge color="success">{value}</Badge> : null}</td>
    ),
  },
  {
    label: 'Free Shipping Threshold',
    id: 'free_shipping_threshold_cents',
    renderer: ({ value }) => <td>{value ? '$' + formatPrice(value) : ''}</td>,
  },
  {
    label: 'GC Amount',
    id: 'welcome_gift_card_amount_cents',
    renderer: ({ value }) => <td>{value ? '$' + formatPrice(value) : ''}</td>,
  },
  {
    label: 'Created at',
    id: 'created_at',
    sortColumn: true,
    renderer: ({ value }) => {
      return <td>{value ? formatDateReadable(String(value), true) : ''}</td>
    },
  },
]

export default columnDef
