import type { OrderLineItem } from '@ttc/api/orders'
import { ClipboardButton } from 'components'
import { Button, ButtonGroup, Col } from 'reactstrap'
import { formatPrice } from 'utils/price'

type QtyColProps = {
  item: any
  qty: number
  onClickDecQty?: (productId: number) => void
  onClickIncQty?: (productId: number) => void
}

const QtyCol = ({ item, qty, onClickDecQty, onClickIncQty }: QtyColProps) => {
  return (
    <Col
      className="flex-grow-0 d-flex p-0 flex-column"
      style={{
        borderLeft: '1px solid #c5dbea',
        alignItems: 'center',
      }}
    >
      <div
        className="d-flex text-center m-0"
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          fontSize: 22,
          width: 50,
        }}
      >
        {qty}
      </div>
      <ButtonGroup className="m-2">
        <Button onClick={() => onClickDecQty(item.product_id)}>-</Button>
        <Button onClick={() => onClickIncQty(item.product_id)}>+</Button>
      </ButtonGroup>
    </Col>
  )
}

const ItemLineItem = ({
  item,
  isLast,
  selectQty,
  onClickIncQty,
  onClickDecQty,
}: {
  item: OrderLineItem
  isLast?: boolean
  selectQty: any
  onClickIncQty: (productId: number) => void
  onClickDecQty: (productId: number) => void
}) => {
  const style = {
    borderBottom: !isLast ? '1px solid #c5dbea' : undefined,
    opacity: selectQty === 0 ? 0.5 : undefined,
  }

  return (
    <>
      <div className="pl-2 d-flex" key={item.id} style={style}>
        <Col className="flex-grow-0 p-2">
          <a target="noopener" href={item.product_data.permalink}>
            <img
              alt=""
              style={{ objectFit: 'cover' }}
              width={75}
              height={75}
              src={item.product_data.thumbnailImageUrl}
            />
          </a>
        </Col>
        <Col
          className="pt-2 pr-2 pb-2"
          style={{ fontSize: 11, lineHeight: '1.6em' }}
        >
          <div>
            <a target="noopener" href={item.product_data.permalink}>
              {item.product_data.name}
            </a>
          </div>
          <div>
            <ClipboardButton
              value={item.product_data.sku}
              text={`SKU: ${item.product_data.sku}`}
            />
          </div>
          {item.product_data.size ? (
            <div>Size: {item.product_data.size}</div>
          ) : null}
          <div>
            {item.quantity}x ${formatPrice(item.price)}
            {item.quantity > 1 && (
              <> = ${formatPrice(item.quantity * item.price)}</>
            )}
          </div>
          <div>Order price: ${formatPrice(item.product_data.price)}</div>
          <div className={`text-${item.hasWarranty ? 'success' : 'danger'}`}>
            {item.hasWarranty ? 'Warranty available' : 'Warranty unavailable'}
          </div>
          {!item.usdaZoneMatch ? (
            <div className="text-danger">
              <i className="fa fa-warning" /> USDA zone mismatch (
              {item.usdaZone})
            </div>
          ) : null}
        </Col>
        <QtyCol
          {...{
            item,
            qty: selectQty,
            onClickDecQty,
            onClickIncQty,
          }}
        />
      </div>
    </>
  )
}

export default ItemLineItem
