import type { GetOrderApiResult, Mail } from '@ttc/api/orders'
import { openPrintWindow } from 'api/index'
import { EditPanelCard, LinkButton } from 'components'
import { type ApiCall, useApi } from 'hooks'
import { useCallback } from 'react'
import { Col, Row } from 'reactstrap'
import { askQuestion, showInfo } from 'utils/alert'
import MailRow from './MailRow'

type NotesCardProps = {
  getOrder: ApiCall<GetOrderApiResult>
  canResend: boolean
}

const MailsCard = (props: NotesCardProps) => {
  const { getOrder, canResend } = props
  const { result } = getOrder
  const { sentMails } = result

  const handleClickMail = useCallback((mail: Mail) => {
    openPrintWindow({
      action: 'treeadmin_mails_render',
      id: String(mail.id),
      key: mail.key,
    })
  }, [])

  const sendOrderDetailsApi = useApi(
    { action: 'orders_sendOrderDetails', id: result.orderId },
    null,
    { errorModal: true },
  )

  const handleClickSentOrderDetails = useCallback(async () => {
    if (
      !(await askQuestion(
        'This will send the invoice / order details to the customer. Continue?',
      ))
    ) {
      return
    }

    try {
      await sendOrderDetailsApi.performRequest()
      showInfo('Order details sent to customer.')
    } catch (_e) {
      /* Ignore */
    }

    getOrder.performRequest()
  }, [sendOrderDetailsApi, getOrder])

  return (
    <EditPanelCard
      caption="E-Mails"
      stateId="sent_mails"
      bodyProps={{ className: 'p-3' }}
    >
      <Row className="mb-2">
        <Col>
          <LinkButton onClick={handleClickSentOrderDetails}>
            Email invoice / order details to customer...
          </LinkButton>
        </Col>
      </Row>
      {sentMails.length > 0 && (
        <Row className="mb-2 font-weight-bold">
          <Col>Sent emails</Col>
        </Row>
      )}
      {sentMails.map((mail) => (
        <MailRow
          key={mail.id}
          {...{ mail, canResend }}
          onClick={handleClickMail}
          onResent={getOrder.performRequest}
        />
      ))}
    </EditPanelCard>
  )
}

export default MailsCard
