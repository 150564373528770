import { usePrevious } from 'hooks'
import { useEffect } from 'react'

const useEffectIfObjectChanges = (
  effect: any,
  obj: any,
  triggerOnInitialChange = false,
) => {
  const prevObj = usePrevious(obj)

  useEffect(() => {
    if (triggerOnInitialChange) {
      if (JSON.stringify(prevObj) !== JSON.stringify(obj)) {
        effect()
      }
      return
    }

    if (prevObj != null && JSON.stringify(prevObj) !== JSON.stringify(obj)) {
      effect()
    }
  }, [effect, prevObj, obj, triggerOnInitialChange])
}

export default useEffectIfObjectChanges
