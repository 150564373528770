import type { ColumnDef } from 'components/Table/types'

const columnDef: ColumnDef[] = [
  {
    label: '',
    id: 'checkbox',
  },
  {
    label: 'Username',
    id: 'username',
    sortColumn: true,
  },
  {
    label: 'Name',
    id: 'fullName',
    sortColumn: true,
  },
  {
    label: 'Capabilities',
    id: 'capSummary',
    sortColumn: false,
  },
  {
    label: 'Created at',
    id: 'createdAt',
    sortColumn: true,
  },
  {
    label: 'Last login',
    id: 'lastLogin',
    sortColumn: true,
  },
]

export default columnDef
