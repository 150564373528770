import { apiRequest } from 'api'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js'
import { useInterval, usePageTitle, useStateful } from 'hooks'
import { useCallback, useEffect, useReducer } from 'react'
import { Chart } from 'react-chartjs-2'
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import { getStorage } from 'utils/storage'
import DatePickerRow from './DatePickerRow'
import { LinkButton, PageNavHead, PageNavTail } from 'components/index'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  BarController,
  Tooltip,
  Legend,
)

const [setItem, getItem] = getStorage('shippingReport')

const numFormat = new Intl.NumberFormat('en-US')
const numFormatAvg = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export type State = {
  isLoading: boolean
  error: string | null
  dateId: string | null
  dateFrom: string | null
  dateTo: string | null
  numBoxesShipped: string
  numPlantsShipped: string
  numLabels: string
  numOrders: string
  numPlants: string
  numPlantsPerOrder: string
  bestEmployee: string
  avgBoxesPerOrder: string
  shipperStatsChart: {
    labels: string[]
    datasets: any
  }
  avgBoxesPerOrderChart: {
    labels: string[]
    datasets: any
  }
  boxSizesChart: {
    labels: string[]
    datasets: any
  }
  selectedEmployeeTab: number
}

export type Action =
  | { type: 'SET_ERROR'; error: string | null }
  | { type: 'SET_LOADING'; isLoading: boolean }
  | { type: 'RESET' }
  | { type: 'SET_DATE'; dateId: string; dateFrom: string; dateTo: string }
  | { type: 'SET_VALUE'; name: string; value: string }
  | { type: 'SET_VALUES'; values: { [key: string]: string } }
  | {
      type: 'SET_CHART_DATA'
      name: string
      labels: string[]
      data: any
    }
  | { type: 'SET_EMPLOYEE_TAB'; tab: number }
  | { type: 'SET_LEADERBOARD'; name: string; data: any }

const initialState: State = {
  isLoading: false,
  error: '',
  dateId: null,
  dateFrom: null,
  dateTo: null,
  numBoxesShipped: '-',
  numPlantsShipped: '-',
  numLabels: '-',
  numOrders: '-',
  numPlants: '-',
  numPlantsPerOrder: '-',
  bestEmployee: '-',
  avgBoxesPerOrder: '-',
  shipperStatsChart: {
    labels: [],
    datasets: [
      {
        label: 'Plants',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
        hoverBorderColor: 'rgba(75, 192, 192, 1)',
      },
      {
        label: 'Boxes',
        data: [],
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(153, 102, 255, 0.4)',
        hoverBorderColor: 'rgba(153, 102, 255, 1)',
      },
      {
        label: 'Orders',
        data: [],
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
      },
    ],
  },
  avgBoxesPerOrderChart: {
    labels: [],
    datasets: [
      {
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [],
      },
    ],
  },
  boxSizesChart: {
    labels: [],
    datasets: [
      {
        backgroundColor: 'rgba(98, 194, 222, 0.2)',
        borderColor: 'rgba(98, 194, 222, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(98, 194, 222, 0.4)',
        hoverBorderColor: 'rgba(98, 194, 222, 1)',
        data: [],
      },
    ],
  },
  selectedEmployeeTab: 0,
}

const init = (state: State): State => {
  const dateId = getItem('dateId') || 'today'
  const selectedEmployeeTab = getItem('selectedEmployeeTab') || 0

  return { ...state, dateId, selectedEmployeeTab: Number(selectedEmployeeTab) }
}

const SET_LEADERBOARD = 'SET_LEADERBOARD'
const SET_ERROR = 'SET_ERROR'
const SET_LOADING = 'SET_LOADING'
const RESET = 'RESET'
const SET_DATE = 'SET_DATE'
const SET_VALUE = 'SET_VALUE'
const SET_VALUES = 'SET_VALUES'
const SET_CHART_DATA = 'SET_CHART_DATA'
const SET_EMPLOYEE_TAB = 'SET_EMPLOYEE_TAB'

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case SET_CHART_DATA: {
      const { labels, data } = action
      return {
        ...state,
        error: '',
        [action.name]: {
          labels,
          datasets: [{ ...state[action.name].datasets[0], data }],
        },
      }
    }
    case SET_LEADERBOARD: {
      const { data } = action

      const labels = []
      const datasets = [
        { ...state[action.name].datasets[0], data: [] },
        { ...state[action.name].datasets[1], data: [] },
        { ...state[action.name].datasets[2], data: [] },
      ]

      for (const row of data) {
        labels.push(row.nickname)
        datasets[0].data.push(row.num_plants)
        datasets[1].data.push(row.package_count)
        datasets[2].data.push(row.num_orders)
      }

      return {
        ...state,
        error: '',
        [action.name]: {
          labels,
          datasets,
        },
      }
    }
    case SET_DATE: {
      const { dateId, dateFrom, dateTo } = action
      setItem('dateId', dateId)
      return { ...state, dateId, dateFrom, dateTo }
    }
    case SET_LOADING: {
      return { ...state, isLoading: action.isLoading }
    }
    case SET_ERROR: {
      return { ...state, error: action.error }
    }
    case SET_VALUE: {
      return { ...state, [action.name]: action.value }
    }
    case SET_VALUES: {
      return { ...state, ...action.values }
    }
    case RESET: {
      return init(initialState)
    }
    case SET_EMPLOYEE_TAB: {
      setItem('selectedEmployeeTab', `${action.tab}`)
      return { ...state, selectedEmployeeTab: action.tab }
    }
    default:
      return state
  }
}

const options = {
  showTooltips: true,
  maintainAspectRatio: false,
  scales: { y: { beginAtZero: true } },
  plugins: { legend: { display: false } },
}

const optionsWithLegend = {
  ...options,
  plugins: { legend: { display: true, position: 'bottom' } },
}

const pageTitle = 'Shipping Report'

const promises: any = {}

const ShippingReport = () => {
  const [state, dispatch] = useReducer<React.Reducer<State, Action>, any>(
    reducer,
    initialState,
    init,
  )

  const {
    isLoading,
    error,
    dateId,
    dateFrom,
    dateTo,
    numBoxesShipped,
    numPlantsShipped,
    numLabels,
    bestEmployee,
    avgBoxesPerOrder,
    boxSizesChart,
    shipperStatsChart,
    avgBoxesPerOrderChart,
    selectedEmployeeTab,
    numOrders,
    numPlants,
    numPlantsPerOrder,
  } = state

  const fetchOrderStats = useCallback(async () => {
    try {
      const promise = apiRequest({
        action: `shippingreport2_getOrderStats`,
        from: dateFrom,
        to: dateTo,
      })

      promises.getOrderStats = promise
      const ret = await promise

      if (promise !== promises.getOrderStats) {
        return
      }

      const { numOrders, numPlants, numPlantsPerOrder } = ret

      dispatch({
        type: 'SET_VALUES',
        values: {
          numOrders: numFormat.format(numOrders),
          numPlants: numFormat.format(numPlants),
          numPlantsPerOrder: numFormatAvg.format(numPlantsPerOrder),
        },
      })
    } catch (e) {
      dispatch({ type: 'SET_ERROR', error: e.message })
    }
  }, [dateFrom, dateTo])

  const fetchLeaderboard = useCallback(async () => {
    try {
      const promise = apiRequest({
        action: `shippingreport2_getLeaderboard`,
        from: dateFrom,
        to: dateTo,
      })

      promises.getLeaderboard = promise
      const ret = await promise

      if (promise !== promises.getLeaderboard) {
        return
      }

      const {
        leaderboard,
        numBoxesShipped,
        numPlantsShipped,
        numLabels,
        bestEmployee,
        avgBoxesPerOrder,
      } = ret

      dispatch({
        type: 'SET_LEADERBOARD',
        name: 'shipperStatsChart',
        data: leaderboard,
      })
      dispatch({
        type: 'SET_VALUES',
        values: {
          numBoxesShipped: numFormat.format(numBoxesShipped),
          numPlantsShipped: numFormat.format(numPlantsShipped),
          numLabels: numFormat.format(numLabels),
          bestEmployee,
          avgBoxesPerOrder: numFormatAvg.format(avgBoxesPerOrder),
        },
      })
    } catch (e) {
      dispatch({ type: 'SET_ERROR', error: e.message })
    }
  }, [dateFrom, dateTo])

  const fetchCharts = useCallback(async () => {
    try {
      const promise = apiRequest({
        action: `shippingreport2_getCharts`,
        from: dateFrom,
        to: dateTo,
      })

      promises.getCharts = promise
      const ret = await promise

      if (promise !== promises.getCharts) {
        return
      }

      const { boxSizesChart, avgBoxesPerOrderChart } = ret

      dispatch({
        type: 'SET_CHART_DATA',
        name: 'boxSizesChart',
        labels: boxSizesChart.labels,
        data: boxSizesChart.data,
      })
      dispatch({
        type: 'SET_CHART_DATA',
        name: 'avgBoxesPerOrderChart',
        labels: avgBoxesPerOrderChart.labels,
        data: avgBoxesPerOrderChart.data,
      })
    } catch (e) {
      dispatch({ type: 'SET_ERROR', error: e.message })
    }
  }, [dateFrom, dateTo])

  const isInitialized = useStateful(false)

  const fetchData = useCallback(
    async (reset = true) => {
      if (reset) {
        dispatch({
          type: 'SET_CHART_DATA',
          name: 'boxSizesChart',
          labels: [],
          data: [],
        })
        dispatch({
          type: 'SET_CHART_DATA',
          name: 'avgBoxesPerOrderChart',
          labels: [],
          data: [],
        })
        dispatch({
          type: 'SET_LEADERBOARD',
          name: 'shipperStatsChart',
          data: [],
        })
      }

      if (!dateFrom || !dateTo) {
        return
      }

      if (reset) {
        // dispatch({ type: 'SET_LOADING', isLoading: true })
      }

      isInitialized.set(true)

      fetchOrderStats()
      fetchLeaderboard()
      fetchCharts()
    },
    [
      dateFrom,
      dateTo,
      fetchOrderStats,
      fetchLeaderboard,
      fetchCharts,
      isInitialized,
    ],
  )

  useInterval(() => {
    if (isInitialized.value) {
      return
    }

    fetchData(false)
  }, 30000)

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleChangeDateId = (
    _dateId: string,
    dateFrom: string,
    dateTo: string,
  ) => {
    dispatch({ type: 'RESET' })
    dispatch({ type: 'SET_DATE', dateId: _dateId, dateFrom, dateTo })
    fetchData(true)
  }

  const title = pageTitle
  usePageTitle(title)

  return (
    <>
      <PageNavHead
        {...{ pageTitle: title, isLoading, onClickReload: fetchData }}
      >
        <LinkButton className="ml-2" to="/shipping/report">
          (Show old Report)
        </LinkButton>
      </PageNavHead>
      <PageNavTail />
      <Container fluid className="mt-4">
        <div className="shipping-report animated fadeIn">
          <DatePickerRow
            {...{ setItem, getItem, dateId }}
            onChange={handleChangeDateId}
          />
          {error !== '' ? (
            <div className="pb-4 text-danger">{error}</div>
          ) : null}
          {dateFrom && dateTo ? (
            <>
              <Row className="pb-4">
                <Col>
                  <Card className="text-white bg-success">
                    <CardBody
                      className="d-flex justify-content-center"
                      style={{ gap: 20 }}
                    >
                      <div>
                        <div className="text-value">{numLabels}</div>
                        <div style={{ whiteSpace: 'nowrap' }}>
                          Orders shipped
                        </div>
                      </div>
                      <div>
                        <div className="text-value">{numPlantsShipped}</div>
                        <div style={{ whiteSpace: 'nowrap' }}>
                          Plants shipped
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="text-white bg-info">
                    <CardBody
                      className="d-flex justify-content-center"
                      style={{ gap: 20 }}
                    >
                      <div>
                        <div className="text-value">{numBoxesShipped}</div>
                        <div style={{ whiteSpace: 'nowrap' }}>
                          Boxes shipped
                        </div>
                      </div>
                      <div>
                        <div className="text-value">{avgBoxesPerOrder}</div>
                        <div style={{ whiteSpace: 'nowrap' }}>
                          Av. boxes per order
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="text-white bg-primary">
                    <CardBody
                      className="d-flex justify-content-center"
                      style={{ gap: 20 }}
                    >
                      <div>
                        <div className="text-value">{numOrders}</div>
                        <div style={{ whiteSpace: 'nowrap' }}>Total orders</div>
                      </div>
                      <div>
                        <div className="text-value">{numPlants}</div>
                        <div style={{ whiteSpace: 'nowrap' }}>Total plants</div>
                      </div>
                      <div>
                        <div className="text-value">{numPlantsPerOrder}</div>
                        <div style={{ whiteSpace: 'nowrap' }}>
                          Av. plants per order
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="text-white bg-warning">
                    <CardBody>
                      <div className="text-value">{bestEmployee}</div>
                      <div style={{ whiteSpace: 'nowrap' }}>Best shipper</div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6" className="mb-4">
                  <Nav tabs>
                    <NavItem active>
                      <NavLink active={true}>Box Sizes</NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent>
                    <TabPane>
                      <div className="chart-wrapper">
                        <Chart
                          type="bar"
                          data={boxSizesChart}
                          options={options}
                        />
                      </div>
                    </TabPane>
                  </TabContent>
                </Col>
                <Col xs="12" md="6" className="mb-4">
                  <Nav tabs>
                    <NavItem active>
                      <NavLink
                        onClick={() =>
                          dispatch({ type: 'SET_EMPLOYEE_TAB', tab: 0 })
                        }
                        active={selectedEmployeeTab === 0}
                      >
                        Shipper stats
                      </NavLink>
                    </NavItem>
                    <NavItem active>
                      <NavLink
                        onClick={() =>
                          dispatch({ type: 'SET_EMPLOYEE_TAB', tab: 1 })
                        }
                        active={selectedEmployeeTab === 1}
                      >
                        Average Boxes per Order
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent>
                    <TabPane>
                      <div className="chart-wrapper">
                        {selectedEmployeeTab === 0 && (
                          <Chart
                            type="bar"
                            data={shipperStatsChart}
                            options={optionsWithLegend as any}
                          />
                        )}
                        {selectedEmployeeTab === 1 && (
                          <Chart
                            type="bar"
                            data={avgBoxesPerOrderChart}
                            options={options}
                          />
                        )}
                      </div>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </>
          ) : null}
        </div>
      </Container>
    </>
  )
}

export default ShippingReport
