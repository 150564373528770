import { encodeQueryString, getApiBaseUrl } from 'api'
import { LoadingImage } from 'components'
import { useCallback } from 'react'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import Swal from 'sweetalert2'
import { showError } from 'utils'
import type { PrinterQueueType } from './usePrinterQueue'

const PrinterSetup = ({
  printQueue,
  isVisible,
}: {
  printQueue: PrinterQueueType
  isVisible: boolean
}) => {
  const { status, isLoading, isReady, printer } = printQueue.zebraPrinter

  const handleClickPrintConfigLabel = useCallback(() => {
    printQueue.addJob({ name: 'printConfigLabel', desc: 'Print Config Label' })
  }, [printQueue])

  const handleClickRetry = useCallback(() => {
    printQueue.reloadPrinterStatus()
  }, [printQueue])

  const handleClickPrintGenerateTestZPL = useCallback(() => {
    const params = encodeQueryString({
      action: 'treeadmin_orderSplits_printPackingSlips',
      zpl: '1',
      order_split_ids: 'test',
    })

    const url = `${getApiBaseUrl()}?${params}`
    window.open(url, '_blank')
  }, [])

  const handleClickPrintTestSlip = useCallback(
    async (type) => {
      const args = {
        action: 'treeadmin_orderSplits_printPackingSlips',
        order_split_ids: 'test',
      }
      if (type === 'zpl') {
        args['zpl'] = 1
      }
      const params = encodeQueryString(args)

      const url = `${getApiBaseUrl()}?${params}`

      const ret = await fetch(url, { credentials: 'include' })
      const data = await ret.blob()

      printQueue.addSendFileDataJob(`Print Test Slip (${type})`, data)
    },
    [printQueue],
  )

  const handleClickCheckPDFSupport = useCallback(() => {
    printer.sendThenRead(
      '! U1 getvar "apl.enable"\n',
      (response: string) => {
        if (response === '"pdf"') {
          Swal.fire({ icon: 'info', text: 'PDF Support active.' })
        } else {
          showError(response)
        }
      },
      () => {
        showError('Error')
      },
    )
  }, [printer])

  const handleClickPrintTestLabel = useCallback(
    async (type) => {
      const url = `${global.appConfig.wpBaseUrl}/wp-content/mu-plugins/tadmin/assets/zebra/testlabel.${type}`
      const ret = await fetch(url, { credentials: 'include' })
      const data = await ret.blob()

      printQueue.addSendFileDataJob(`Print Test Label (${type})`, data)
    },
    [printQueue],
  )

  if (!isVisible) {
    return null
  }

  return (
    <div className="animated fadeIn mb-4">
      <Card>
        <CardHeader>Printer Status</CardHeader>
        <CardBody>
          <div className="mb-2">
            <a
              href="https://www.zebra.com/gb/en/support-downloads/printers/desktop/zd420c.html#downloadlistitem_abe"
              target="_blank"
              rel="noreferrer"
            >
              Download Zebra ZD420 Printer Drivers.
            </a>
          </div>
          <div className="mb-2">
            <a
              href="https://ttc-wp-plugins.s3.amazonaws.com/zebra-browser-print-windows-v132489.exe"
              rel="noreferrer"
            >
              Download BrowserPrint app for Windows.
            </a>
          </div>
          <div>
            <div className="mb-2">Status: {status.value}</div>
            {isLoading ? (
              <LoadingImage />
            ) : (
              <>
                <Button onClick={handleClickRetry}>Retry</Button>
                <Button
                  className="ml-2"
                  onClick={handleClickPrintGenerateTestZPL}
                >
                  Generate Test Slip ZPL
                </Button>
                {isReady ? (
                  <>
                    <Button
                      className="ml-2"
                      onClick={handleClickPrintConfigLabel}
                    >
                      Print Config Label
                    </Button>
                    <Button
                      className="ml-2"
                      onClick={() => handleClickPrintTestSlip('zpl')}
                    >
                      Print Test Slip ZPL
                    </Button>
                    <Button
                      className="ml-2"
                      onClick={() => handleClickPrintTestLabel('zpl')}
                    >
                      Print Test Label ZPL
                    </Button>
                    <Button
                      className="ml-2"
                      onClick={() => handleClickPrintTestLabel('pdf')}
                    >
                      Print Test Label PDF
                    </Button>
                    <Button
                      className="ml-2"
                      onClick={handleClickCheckPDFSupport}
                    >
                      Check PDF Support
                    </Button>
                  </>
                ) : null}
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default PrinterSetup
