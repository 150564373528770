import { Portal } from 'components'
import type { ApiCall, StatefulState } from 'hooks'
import BatchOrders from './BatchOrders'

type SidebarProps = {
  onToggle: () => void
  selectedRows: string[]
  getOrders: ApiCall
  selectedOrder: StatefulState<any>
  loadOrders: () => void
  loadOrdersNow: () => void
  readOnly: boolean
  triggerSearch: () => void
}

const Sidebar = (props: SidebarProps) => {
  const {
    onToggle,
    selectedRows,
    triggerSearch,
    getOrders,
    selectedOrder,
    loadOrders,
    loadOrdersNow,
    readOnly,
  } = props

  if (selectedRows.length > 0) {
    return (
      <Portal id="sidebar-content">
        <BatchOrders
          {...{
            readOnly,
            batchIds: selectedRows,
            onToggle,
            getOrders,
            triggerSearch,
            selectedOrder,
            loadOrders,
            loadOrdersNow,
          }}
        />
      </Portal>
    )
  }

  return (
    <Portal id="sidebar-content">
      <ul className="nav nav-tabs flex-row-reverse">
        <li className="nav-item">
          <a onClick={onToggle} className="active nav-link">
            <i className="icon-list"></i>
          </a>
        </li>
      </ul>

      <div className="tab-content">
        {selectedRows.length === 0 ? (
          <div className="p-3">Selection is empty.</div>
        ) : null}
      </div>
    </Portal>
  )
}

export default Sidebar
