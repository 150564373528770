import { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { parseQueryStr, updateQueryStr } from 'utils/url'
import CellEditable from './CellEditable'
import Table from './Table'
import { defaultHeaderLabelRenderer } from './defaultHeaderLabelRenderer'
import { defaultCellRenderer } from './defaultCellRenderer'
import {
  setNextPage,
  setNextRow,
  setPrevPage,
  setPrevRow,
  setQuery,
} from './reducer/actions'
import { reducer } from './reducer/reducer'
import type {
  Action,
  BaseRow,
  InitializerProps,
  Row,
  State,
} from './reducer/types'
import type { UseTableProps } from './types'
import useTableApi from './useTableApi'
import useTableColumns from './useTableColumns'

export type {
  Action,
  BaseRow,
  InitializerProps,
  Row,
  State,
} from './reducer/types'

export { Table }
export { CellEditable }
export { defaultCellRenderer }
export { defaultHeaderLabelRenderer }
export { useTableApi }
export { useTableColumns }

export * from './reducer/actions'

const cachedState = {}

/**
 * Function to get the initial state of the table.
 *
 * The initial state is composed of the following properties:
 *
 * Query and Loading State:
 * - query: The search query for the table, initialized from the URL or local storage.
 * - isLoading: A boolean indicating if the table is currently loading data.
 * - didLoad: A boolean indicating if the table has loaded data at least once.
 * - error: Any error that occurred while loading data.
 *
 * Table Data:
 * - rows: The rows of data in the table.
 * - resultTotal: The total number of results.
 * - resultOffset: The offset of the current results.
 * - resultLimit: The limit of the current results.
 *
 * Pagination and Sorting:
 * - paginationLimit: The number of rows per page, initialized from local storage.
 * - paginationPage: The current page number.
 * - sortColumn: The column the table is sorted by, initialized from local storage.
 * - sortAsc: A boolean indicating if the sorting is in ascending order, initialized from local storage.
 *
 * Row Selection:
 * - initialSelectedRows: The initially selected rows.
 * - selectedRows: The currently selected rows.
 * - selectedRowId: The ID of the currently selected row.
 * - allRowsSelected: A boolean indicating if all rows are selected.
 *
 * Column Configuration:
 * - visibleColumns: The columns that are currently visible, initialized from local storage.
 * - sortedColumns: The columns that are currently sorted, initialized from local storage.
 * - columnColors: The colors of the columns, initialized from local storage.
 *
 * @param getItem - Function to get an item from local storage.
 * @param queryStr - Query string used for initial state of the table.
 * @param cacheKey - Key used for caching the state of the table.
 *
 * @returns The initial state of the table.
 */
const getInitialState = ({ getItem, queryStr, cacheKey }) => {
  if (cacheKey && cachedState[cacheKey] != null) {
    return cachedState[cacheKey]
  }

  let query = ''
  const queryParams = parseQueryStr(queryStr || '')

  /* If url has ?q=, use that as the query. */
  if (queryParams.q) {
    query = queryParams.q
  } else if (Object.keys(queryParams).length === 0) {
    /* otherwise read it from localStorage, if url is empty and has no other
     * filter query params. */
    query = getItem('query') || ''
  }

  return {
    query,
    isLoading: false,
    didLoad: false,
    error: null,
    rows: [],
    resultTotal: 0,
    resultOffset: 0,
    resultLimit: 0,
    paginationLimit: Number(getItem('limit') || 10) || 10,
    paginationPage: 1,
    sortColumn: getItem('sortColumn') || null,
    sortAsc: getItem('sortAsc') === '1' || false,
    editCell: null,
    cellsLoading: {},
    initialSelectedRows: [],
    selectedRows: [],
    selectedRowId: null,
    allRowsSelected: false,
    visibleColumns: (getItem('visibleColumns') || '')
      .split(',')
      .filter((a: string) => a !== ''),
    sortedColumns: (getItem('sortedColumns') || '')
      .split(',')
      .filter((a: string) => a !== ''),
    columnColors: JSON.parse(getItem('columnColors') || '{}'),
    response: null,
  }
}

/**
 * useTableKeyboardNavigation
 * @param dispatch - The dispatch function from useReducer, by way of useTable.
 *
 * This hook listens to keyboard events and dispatches actions to navigate through the table.
 *
 * The keys handled by this hook are:
 * - 'j': Moves to the next row in the table.
 * - 'k': Moves to the previous row in the table.
 * - 'l': Moves to the next page in the table.
 * - 'h': Moves to the previous page in the table.
 */
export function useTableKeyboardNavigation<RowType extends BaseRow = Row>(
  dispatch: React.Dispatch<Action<RowType>>,
) {
  useEffect(() => {
    const handle = (e: KeyboardEvent) => {
      if (
        e.target instanceof HTMLInputElement &&
        e.target.type !== 'checkbox' &&
        e.target.type !== 'radio'
      ) {
        return
      }
      if (
        e.target instanceof HTMLSelectElement ||
        e.target instanceof HTMLTextAreaElement
      ) {
        return
      }

      const downKey = 'j'
      const upKey = 'k'
      const rightKey = 'l'
      const leftKey = 'h'

      switch (e.key) {
        case downKey: {
          dispatch(setNextRow())
          break
        }
        case upKey: {
          dispatch(setPrevRow())
          break
        }
        case rightKey: {
          dispatch(setNextPage())
          break
        }
        case leftKey: {
          dispatch(setPrevPage())
          break
        }
      }
    }

    document.addEventListener('keydown', handle)

    return () => document.removeEventListener('keydown', handle)
  }, [dispatch])
}

/**
 * Custom React hook for managing the state and behavior of a table component.
 *
 * - uses the `useReducer` hook to manage the state of the table.
 *   pagination, and selected rows. See types.ts for a list of the reducer actions.
 * - `useTableKeyboardNavigation` function is used to handle keyboard navigation within the table.
 * - persists the sort column, sort direction, pagination limit, query, sorted columns, column colors, and visible columns to local storage.
 * - the table state is cached in memory by way of props.cacheKey (which needs to be unique to this table)
 *
 * @param props - The properties passed to the hook.
 *
 * props.setItem - Function to set an item in local storage.
 * props.getItem - Function to get an item from local storage.
 * props.cacheKey - Key used for caching the state of the table.
 * props.queryStr - Query string used for initial state of the table.
 *
 * @returns An array containing the state of the table and a dispatch function for updating the state.
 */
export function useTable<RowType extends BaseRow = Row>(
  props: UseTableProps,
): [State<RowType>, React.Dispatch<Action<RowType>>] {
  const { setItem, getItem, cacheKey, queryStr } = props

  const [state, dispatch] = useReducer<
    React.Reducer<State<RowType>, Action<RowType>>,
    InitializerProps
  >(
    reducer,
    {
      getItem,
      queryStr,
      cacheKey,
    },
    getInitialState,
  )

  useTableKeyboardNavigation(dispatch)

  useEffect(() => {
    if (cacheKey) {
      cachedState[cacheKey] = state
    }
  }, [cacheKey, state])

  useEffect(() => {
    if (setItem) {
      setItem('sortColumn', state.sortColumn || '')
      setItem('sortAsc', state.sortAsc ? '1' : '0')
      setItem('limit', String(state.paginationLimit))
      setItem('query', state.query)
      setItem('sortedColumns', state.sortedColumns.join(','))
      setItem('columnColors', JSON.stringify(state.columnColors))
      setItem('visibleColumns', state.visibleColumns.join(','))
    }
  }, [
    setItem,
    state.sortColumn,
    state.sortAsc,
    state.paginationLimit,
    state.query,
    state.sortedColumns,
    state.columnColors,
    state.visibleColumns,
  ])

  return [state, dispatch]
}

/**
 * `useTableWithUrlUpdater` is a custom function that manages the state of a table
 * and synchronizes it with the URL query string, allowing the state to be preserved across
 * page refreshes or navigation.
 *
 * It listens to the 'popstate' event to handle browser back/forward navigation and updates the URL query string whenever the table state changes.
 *
 * @param props - The properties to pass to the `useTable` hook.
 *
 * @returns The current state of the table and a dispatch function to update the state.
 */
export const useTableWithUrlUpdater = <RowType extends BaseRow = Row>(
  props: UseTableProps,
): [State<RowType>, React.Dispatch<Action<RowType>>] => {
  const pathNameRef = useRef<string>()
  const [state, dispatch] = useTable<RowType>({
    ...props,
    queryStr: window.location.search,
  })
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    pathNameRef.current = window.location.pathname
  }, [])

  /* Initial URL query string update. */
  useEffect(() => {
    const handlePopState = () => {
      if (pathNameRef.current !== window.location.pathname) {
        return
      }

      const queryParams = parseQueryStr(window.location.search)
      const query = queryParams.q || ''

      if (state.query !== query) {
        dispatch(setQuery(query))
      }
    }

    window.addEventListener('popstate', handlePopState)

    if (!isLoaded && state.query && window.location.search === '') {
      updateQueryStr({
        q: state.query ? state.query : undefined,
      })
    }

    if (!isLoaded) {
      setIsLoaded(true)
    }

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [setIsLoaded, isLoaded, state.query, dispatch])

  const updateUrl = useCallback(() => {
    if (pathNameRef.current !== window.location.pathname) {
      return
    }

    updateQueryStr({ q: state.query ? state.query : undefined })
  }, [state.query])

  const debouncedUpdateUrl = useDebouncedCallback(updateUrl, 1000)

  useEffect(() => {
    debouncedUpdateUrl()
  }, [state.query, debouncedUpdateUrl])

  return [state, dispatch]
}
